import React, { useState, useEffect, useContext } from 'react'
import {
  SearchWrapperBox,
  SmallSearchBox,
  SearchTransparentInput,
  CloseIconWrapper
} from '../SmallSearchInput.styled'
import OutsideClickWrapper from '../OutsideClickWrapper'
import MaterialIcon from '../MaterialIcon';
import { ThemeContext } from 'styled-components'
import styled, { css } from 'styled-components'
import { device } from '../../../global-styles/mediaQueries'
import { handleInputSanitize } from '../../../utils'

const TableSmallSearchBox = styled(SmallSearchBox)`
  width: 100%;
  height: 100%;

  ${({ isActive }) => isActive && css`
    right: unset;
    width: 100%;
    height: 28px;
    padding: 4px 4px;
    border-radius: 4px;
  `}

  svg {
    cursor: auto;
  }

  @media ${device.tablet} {
    width: 200px;
    padding: 5px 8px;
    ${({ isActive }) => isActive && css`
      padding: 4px 7px;
    `}
  }
`

export const SmallSearchBoxz = styled.div<{ isActive?: boolean }>`
  position: relative;
  left: 0;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  padding: 5px;
  border-radius: 4px;
  box-sizing: border-box;
  border: solid 1px ${({ theme }) => theme.colors.borderSecondary};
  z-index: -1;
  svg {
    min-width: 18px;
  }
  ${({ isActive }) => isActive && css`
    height: 28px;
    padding: 4px 4px;
    justify-content: flex-start;
    border-radius: 4px;
    border: solid 2px ${({ theme }) => theme.colors.accent};
    background-color: ${({ theme }) => theme.colors.white};
    transition: width 0.3s;

  `}

  @media ${device.tablet} {
    width: 200px;
    padding: 5px 8px;
    background-color: ${({ theme }) => theme.colors.white};
    .close-icon {
      opacity: 0;
    }
    &:hover {
      border: solid 1px ${({ theme }) => theme.colors.accent};
    }
    ${({ isActive }) => isActive && css`
      padding: 6px 7px;
      border: solid 2px ${({ theme }) => theme.colors.accent};
      .close-icon {
        opacity: 1;
      }
      &:hover {
        border: solid 2px ${({ theme }) => theme.colors.accent};
      }
    `}
  }
`

const TableSearchTransparentInput = styled(SearchTransparentInput)`
  width: 100%;
`

const TableSearchWrapperBox = styled(SearchWrapperBox)`
  height: 28px;
`

const TableSearch = styled.div`
  width: 100%;
  margin-left: 10px;
`


interface TableHeaderSearchProps {
  handleSearch: (value: string) => void;
}

export default function TableHeaderSearch({ handleSearch }: TableHeaderSearchProps) {

  const [inputValue, setInputValue] = useState('')
  const themeContext = useContext(ThemeContext)
  const [isActive, setIsActive] = useState(false)



  function onActiveSearch(e: React.ChangeEvent<HTMLInputElement>) {
    setIsActive(true)
    handleChange(e)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => handleInputSanitize(e, setInputValue)

  useEffect(() => {
    handleSearch(inputValue)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  return (
    <TableSearch>
      <OutsideClickWrapper
        enabled={isActive}
        handlerFunc={() => setIsActive(false)}
      >
        <TableSearchWrapperBox className='wrapper-box'>
          <TableSmallSearchBox
            isActive={isActive}
          >
            <MaterialIcon iconName="search" color={themeContext.colors.secondary} />
            <TableSearchTransparentInput
              value={inputValue}
              onChange={onActiveSearch}
              isActive={isActive}
              placeholder={"Search"}
              onFocus={() => setIsActive(true)}
            />
            <CloseIconWrapper
              isActive={isActive && inputValue !== ''}
              onClick={() => setInputValue('')}
            >
              <MaterialIcon
                color={themeContext.colors.secondary}
                iconName="close"
                additionalClassName="close-icon"
              />
            </CloseIconWrapper>
          </TableSmallSearchBox>
        </TableSearchWrapperBox>
      </OutsideClickWrapper>
    </TableSearch>
  )
}
