import React, { ReactElement } from 'react'

//Interface
import { SocialMediaInterface } from '../../types/TopNewsTypes'

//Component
import Rank from '../global-components/Rank'
import NotificationBar from '../global-components/NotificationBar'
import { GraySmallText } from '../global-components/TextAndLabelStyles.styled'
import CircleGraph from './CircleGraph'
import CustomDropdownList from '../global-components/CustomDropdownList'

//Styles
import {
  SingleSocialMediaCard, ItemRank, ItemContent,
  DropDownWrapper, IconImageWrapper, DistributionWrapper
} from './SocialMediaTab.styled'

//redux
import { useAppDispatch } from '../../state/hooks/hooks'
import { openModal } from '../../state/slices/modalsSlice'

//Utils
import { handleOnErrorImage } from '../../utils'

export default function SingleSocialMedia({ post }: { post: SocialMediaInterface }): ReactElement {

  const dispatch = useAppDispatch();

  const isNotificationSet = post.alert_id !== null

  function openModalForSocialAlerts(id: number, newsTitle: string, newsUrl: string, newsType?: string) {
    dispatch({
      type: openModal.type,
      payload: {
        name: 'newsCoverage',
        props: {
          id: id,
          newsTitle: newsTitle,
          newsURL: newsUrl,
          newsType: newsType,
          cacheType: 'SocialMedia',
          post: post
        }
      }
    })
  }

   // Stopping alerts
  const handleStopAlerts = () => {
    dispatch({
      type: openModal.type,
      payload: {
        name: 'stopAlertsModal',
        props: {
          id: post.alert_id,
          cacheType: 'SocialMedia',
          isTopNewsQuery: true,
          post: post,
          labelTitle: post.title
        }
      }
    })
  }

  return (
    <SingleSocialMediaCard>
      {isNotificationSet && <NotificationBar />}

      <ItemRank>
        {post.rank_number}
        <Rank isRising={parseInt(post.is_rising)} />
      </ItemRank>

      
      <IconImageWrapper>
        <img src={post.icon_name} alt="News Logo" onError={handleOnErrorImage} />
      </IconImageWrapper>

      <ItemContent>
        <a href={post.source_url} target="_blank" rel="noreferrer"><h5>{post.title}</h5></a>

        <GraySmallText>{post.message.substring(0, 100)}</GraySmallText>
      </ItemContent>
      <DistributionWrapper>
        <CircleGraph percentage={post.distribution} />
      </DistributionWrapper>


      {/* We will use it when API is done */}
      <DropDownWrapper>
        <CustomDropdownList
          options={[{
            text: isNotificationSet ? "Stop Alerts" : "Get Alerts",
            icon: "notifications",
            onClick: function () {
              !isNotificationSet
                ? openModalForSocialAlerts(parseInt(post.current_news_key), post.title, post.source_url, 'existing')
                : handleStopAlerts()
            }
          }]}
          dropdownPosition="right"
          icon="more_vert"
          lastItemSeparator={false}
        />
      </DropDownWrapper>

    </SingleSocialMediaCard>
  )
}
