import React from 'react';
import { useAppSelector } from '../../state/hooks/hooks'
import ModalWrapper from './ModalWrapper'

// Import Modals
import DeleteArticle from './DeleteArticle'
import NewsCoverageModal from './NewsCoverageModal'
import DownloadNews from './DownloadNews'
import SearchFirstTime from './SearchFirstTime'
import UnsavedChanges from './UnsavedChanges'
import EditSavedSearch from './EditSavedSearch'
import DateRangePicker from './DateRangePicker'
import LatestNewsCoverage from './LatestNewsCoverage'
import DeleteSavedSearch from './DeleteSavedSearch';
import DeleteCheckedFromTable from './DeleteCheckedFromTable'
import StopAlertsModal from './StopAlertsModal';


const ModalRenderer = () => {
  const modalState = useAppSelector(state => state.modals)

  let Modal: React.ElementType | null;

  switch (modalState.name) {
    case 'deleteArticle':
      Modal = DeleteArticle;
      break;
    case 'deleteSavedSearch':
      Modal = DeleteSavedSearch;
      break;
    case 'newsCoverage':
      Modal = NewsCoverageModal;
      break;
    case 'latestNewsCoverage':
      Modal = LatestNewsCoverage;
      break;
    case 'downloadNews':
      Modal = DownloadNews;
      break;
    case 'unsavedChanges':
      Modal = UnsavedChanges;
      break;
    case 'searchFirstTime':
      Modal = SearchFirstTime;
      break;
    case 'editSavedSearch':
      Modal = EditSavedSearch;
      break;
    case 'dateRangePicker':
      Modal = DateRangePicker;
      break;
    case 'deleteCheckedFromTable':
      Modal = DeleteCheckedFromTable;
      break;
    case 'stopAlertsModal':
      Modal = StopAlertsModal;
      break;
    default:
      Modal = null;
  }


  return Modal && (
    <ModalWrapper>
      <Modal payload={modalState.props} />
    </ModalWrapper>
  )
}

export default ModalRenderer;
