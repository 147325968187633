import styled, { css } from 'styled-components'
import { addOpacityToHex } from '../../utils'
import { globalFocusStyles } from '../../global-styles/globalStyles';

export const DropDownContainer = styled.div<{ location?: string }>`
  position: relative;
  display: inline-block;
  width: auto;
  cursor: pointer;
  margin-right: ${({ location }) => location === 'latest-news' ? '12px' : 0};
`;

export const SelectDropDownHeader = styled.div<{
  type?: 'select-button' | 'select-link',
  isActive: boolean,
  hasIcon: boolean,
  isDisabled?: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  margin-bottom: 5px;
  font-family: ${({ theme }) => theme.fonts};
  font-weight: normal;
  letter-spacing: normal;
  color: ${({ isActive, theme }) => isActive ? theme.colors.primary : theme.colors.secondary};
  background-color: ${({ isActive, theme }) => isActive ? theme.colors.highlight : 'transperent'};
  outline: none;

  // Custom focus styles
  ${globalFocusStyles}

  ${({ type, isActive, hasIcon, theme }) => type === 'select-button' ? `
    height: 28px;
    min-width: ${hasIcon ? '120px' : '100px'};
    padding: 0 26px 0 12px;
    font-size: 12px;
    line-height: 1.33;
    border: 1px solid;
    border-radius: 4px;
    border-color: ${isActive ? "transparent" : theme.colors.borderSecondary};
    ` : `
    margin-top: 3px;
    padding: 0 20px 0 0;
    font-size: 14px;
    line-height: 1.71;
    color: ${theme.colors.primary};

    &:after {
      position: absolute;
      display: block;
      content: '';
      width: calc(100% - 20px);
      height: 2px;
      background-color: ${theme.colors.primary};
      bottom: 4px;
    }
    `
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    border-color: transparent;
    background-color: ${({ type, theme }) => type === 'select-button' ? theme.colors.highlight : 'unset'};
    .dropdown-icon {
      svg { 
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  ${({ isDisabled }) => isDisabled && css`
    pointer-events: none;
    color: ${({ theme }) => theme.colors.default_600}; // default_600
    border: 1px solid ${({ theme }) => theme.colors.borderSecondary};

    .dropdown-icon {
      svg { 
        fill: ${({ theme }) => theme.colors.default_600};
      }
    }
  `}
`;

export const HeaderIcon = styled.div<{ color: string, isDisabled?: boolean }>`
  display: flex;
  margin-left: -2px;
  margin-right: 4px;
  padding-bottom: 2px;
  svg {
    fill: ${({ color }) => color}
  }

  ${({ isDisabled }) => isDisabled && css`
      svg { 
        fill: ${({ theme }) => theme.colors.default_600};
      }
  `}
`


export const DropdownIcon = styled.div<{ type: 'select-button' | 'select-link', isActive: boolean }>`
  position: absolute;
  display: inline-block;
  right: ${({ type }) => type === 'select-button' ? '6px' : "0px"};
  top: ${({ type }) => type === 'select-button' ? '3px' : "1px"};
  height: auto;
  width: auto;
  border: none;

  svg {
    display: block;
    fill: ${({ isActive, theme }) => isActive ? theme.colors.primary : theme.colors.secondary};
    height: 20px;
    width: 20px;
  }
`

export const OptionsListHeader = styled.div<{ isActive: boolean, hasImage: boolean, whiteBackground: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ hasImage }) => hasImage ? "auto" : "28px"};
  width: ${({ hasImage }) => hasImage ? "auto" : "28px"};
  margin-bottom: 4px;
  border-radius: 4px;
  background-color: ${({ whiteBackground }) => whiteBackground ? '#fff' : 'transparent'};

  svg {
    fill: ${({ hasImage, isActive, theme }) => hasImage ? (
    addOpacityToHex(theme.colors.white, 0.7)
  ) : (
    isActive ? theme.colors.primary : theme.colors.secondary
  )}
  }
  // Custom focus styles
  ${globalFocusStyles}

  &:hover {
    background: ${({ hasImage, theme, whiteBackground }) => whiteBackground ? theme.colors.white : hasImage ? "transparent" : theme.colors.highlight};

    svg {
      fill: ${({ hasImage, theme }) => hasImage ? theme.colors.white : theme.colors.primary}
    }
  }
`;



export const DropDownListContainer = styled.div<{ position?: 'left' | 'center' | 'right' | 'fit' }>`
  position: absolute;
  min-width: 120px;
  z-index: 2;
  ${({ position }) => position === 'left' && `left: 0;`}
  ${({ position }) => position === 'right' && `right: 0;`}
  ${({ position }) => position === 'center' && `
    left: 50%;
    transform: translateX(-50%);
  ` }
  ${({ position }) => position === 'fit' && `width: 100%`}
`;

export const DropDownList = styled.ul<{ visibleItems?: number | null }>`
  max-height: ${({ visibleItems }) => visibleItems ? `${(visibleItems * 36) + 8}px` : "unset"};
  padding: 4px 0;
  margin: 0;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.borderPrimary};
  border-radius: 4px;
  box-shadow: 0 4px 16px -2px rgba(49, 65, 97, 0.15);
  overflow-y: auto;

  ${({ visibleItems }) => visibleItems && `
    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
        width: 11px;
    }

    ::-webkit-scrollbar:horizontal {
        height: 11px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white; /* should match background, can't be transparent */
        background-color: rgba(0, 0, 0, .5);
    }
  `}
`;

export const ListItem = styled.li<{ isSelected?: boolean, lastItemSeparator?: boolean, hasLastChildLikeBtn?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 36px;
  font-size: 13px;
  padding: 10px 14px;
  font-family: ${({ theme }) => theme.fonts};
  list-style: none;
  color: ${({ isSelected, theme }) => isSelected ? theme.colors.accent : theme.colors.primary};
  background-color: ${({ isSelected, theme }) => isSelected ? theme.colors.highlight : theme.colors.white};
  white-space: nowrap;

  svg {
    display: block;
    fill: ${({ theme, isSelected }) => isSelected ? theme.colors.accent : theme.colors.secondary};
    margin-right: 10px
  }

  &:hover {
    color: ${({ theme }) => theme.colors.extraDarkBlue};
    background-color: ${({ theme }) => theme.colors.highlight};
    
    svg {
      fill: ${({ theme }) => theme.colors.secondary};
    }
  }

  &:focus-visible {
    outline: 1px solid ${({ theme }) => theme.colors.accent};
    border: 2px solid ${({ theme }) => theme.colors.accent};
    border-radius: 4px;
  }

  ${({ hasLastChildLikeBtn }) => hasLastChildLikeBtn && css`
    &:last-child {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      margin: 10px 14px 8px;
      font-weight: 700;
      font-size: 13px;
      line-height: 18px;
      background-color: ${({ theme }) => addOpacityToHex(theme.colors.accent, 0.1)};
      color: ${({ theme }) => theme.colors.accent};
      border-radius: 4px;

      svg {
        fill: ${({ theme }) => theme.colors.accent};
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.accent};
        color:  ${({ theme }) => theme.colors.white};

        svg {
          fill: ${({ theme }) => theme.colors.white};
        }
      }
      // Custom focus styles
      ${globalFocusStyles}

      &:focus {
        border: none;
      }
    }
  `}

  ${({ lastItemSeparator, theme }) => lastItemSeparator && css`
    &:last-child {
      border-top: 1px solid ${theme.colors.borderPrimary};
    } 
    &:focus-visible {
      &:last-child {
      border-top: 2px solid ${({ theme }) => theme.colors.accent};
      }
    }
  `}
`;

export const FileExportListItem = styled.li<{ lastItemSeparator?: boolean }>`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 36px;
  font-size: 13px;
  font-family: ${({ theme }) => theme.fonts};
  list-style: none;
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.white};

  a, button {
    width: 100%;
    margin: 0;
    padding: 10px 40px 10px 40px;
    color: ${({ theme }) => theme.colors.primary};
    outline: none;
    border: none;
    background-color: transparent;
  }

  a {
    text-decoration: none;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    display: block;
    fill: ${({ theme }) => theme.colors.secondary};
    margin-right: 10px;
    pointer-events: none;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
    background-color: ${({ theme }) => theme.colors.highlight};

    a, button {
      color: ${({ theme }) => theme.colors.accent};
      cursor: pointer;
    }
    
    svg {
      fill: ${({ theme }) => theme.colors.accent};
    }
  }

  ${({ lastItemSeparator, theme }) => lastItemSeparator && `
    &:last-child {
      border-top: 1px solid ${theme.colors.borderPrimary};
    } 
  `}
`


export const SelectFormHeader = styled.div<{
  type?: 'expiration' | 'date-range',
  isActive: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
  margin-bottom: 5px;
  padding: ${({ isActive }) => isActive ? `0 13px` : `0 14px`};
  font-family: ${({ theme }) => theme.fonts};
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  color: ${({ isActive, theme }) => isActive ? theme.colors.primary : theme.colors.secondary};
  background-color: ${({ isActive, theme }) => isActive ? theme.colors.white : theme.colors.highlight};
  border: ${({ isActive, theme }) => isActive ? `2px solid ${theme.colors.accent}` : `1px solid ${theme.colors.borderSecondary}`};
  border-radius: 4px;
  outline: none;

  &:hover {
    border-color: ${({ theme }) => theme.colors.accent};
    background-color: ${({ type, theme }) => type === 'expiration' ? theme.colors.highlight : 'unset'};
  }

  span { 
    display: inline-block;
    line-height: 1.29;
  }

  .calendar-icon {
    margin-right: 10px;
    fill: ${({ theme }) => theme.colors.secondary};
  }

  .dropdown-icon {
    margin-left: auto;
    fill: ${({ theme }) => theme.colors.secondary};
  }
`;