import styled from 'styled-components'
import { device } from '../../global-styles/mediaQueries'

export const ConnectionLostWrapper = styled.div<{isCard: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  background-color: ${({theme, isCard}) => isCard ? theme.colors.white : 'transparent' };
  margin-bottom: ${({isCard}) => isCard ? '20px' : '0px'};
  border-radius: 6px;
  padding: 0 16px;
  text-align: center;

  h3 {
    margin-top: 14px;
    margin-bottom: 8px;
    font-size: 18px;
  }
  button {
    margin-top: 25px;
  }
  p {
    font-size: 14px;
    line-height: 1.71;
    color: ${({ theme }) => theme.colors.secondary};
  }

  @media ${device.mobileL} {
    min-height: 360px;
    padding: 0 24px;
    button {
      margin-top: 30px;
    }
  }

  @media ${device.tablet} {
    min-height: 400px;
  }
`