import styled from 'styled-components'
import { device } from '../../global-styles/mediaQueries'

export const SectionTitleBold = styled.p<{ isSearchActive?: boolean }>`
    display: ${({ isSearchActive }) => isSearchActive ? "none" : "block"};
    font-family: Arial;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: ${({ theme: { colors } }) => colors.primary};
    @media ${device.tablet} {
      display: block;
    }
`

export const GraySmallText = styled.p`
  font-family: Arial;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.secondary};
`