import React, { useState, ReactElement } from 'react'
import { navigationOpenIcon, NavigationItems } from '../dashboard/DashboardNavigation'

//Styles
import { ActiveTab, NavigationWrapper, SingleNavItem, SettingsNavigationContainer } from '../dashboard/DashboardNavigation.styled'
import { SettingsNavigationComponent } from './Settings.styled'

//Lib
import { nanoid } from "@reduxjs/toolkit"

//Redux
import { useAppDispatch, useAppSelector } from '../../state/hooks/hooks'
import { openModal } from '../../state/slices/modalsSlice'

export default function SettingsNavigation({ url }: { url: string }): ReactElement {

  const dispatch = useAppDispatch()
  const isInEditMode = useAppSelector(state => state.appState.isEditModeActive)

  const [activeTab, setActiveTab] = useState(0)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const navigationData: NavigationItems[] = [
    {
      name: 'Account',
      url: '',
    },
    {
      name: 'Sign in & Security',
      url: '/sign-in-security',
    },
    {
      name: 'Notifications & Alerts',
      url: '/notifications-alerts',
    },
    {
      name: 'Search Terms',
      url: '/search-terms',
    },
    // temporarily removed
    // {
    //   name: 'RSS Feed',
    //   url: '/rss-feed',
    // },
  ]

  function changeRouteOrStayInEditMode(
    e: React.MouseEvent<HTMLAnchorElement>,
    path: string,
    index: number
  ) {
    if (isInEditMode) {
      e.preventDefault()
      dispatch(openModal({ name: 'unsavedChanges', props: { fieldName: 'unsavedChanges', pathTo: path } }))
    } else {
      setActiveTab(index)
      setIsMenuOpen(false)
    }

  }

  return (
    <SettingsNavigationComponent className="SettingsNavigationContainer">

      <ActiveTab
        isRound={true}
        tabIndex={0}
        onClick={() => setIsMenuOpen(isMenuOpen => !isMenuOpen)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.keyCode === 13) {
            setIsMenuOpen(isMenuOpen => !isMenuOpen)
          }
        }}
      >
        <p>{navigationData[activeTab].name}</p>
        {navigationOpenIcon(isMenuOpen)}
      </ActiveTab>

      <NavigationWrapper isRound={true}>
        <SettingsNavigationContainer isMenuOpen={isMenuOpen} isRound={true}>
          {navigationData.map((item, index) =>
            <SingleNavItem
              key={nanoid()}
              onClick={(e) => changeRouteOrStayInEditMode(e, `${url}${item.url}`, index)}
              exact
              to={`${url}${item.url}`}
            >
              {item.name}
            </SingleNavItem>)}
        </SettingsNavigationContainer>
      </NavigationWrapper>

    </SettingsNavigationComponent>
  )
}
