import styled from 'styled-components'
import { RowTitle } from '../global-components/checkbox-table-components/CheckboxTableListItem.styled'
import { device } from '../../global-styles/mediaQueries'
import { InvoiceData } from '../../state/query/types/ReportsAndInvoicesTypes'

import { PropertyName, PropertyValue } from '../global-components/checkbox-table-components/CheckboxTableListItem.styled'
import InvoiceStatus from './InvoiceStatus'


interface SingleInvoiceProps {
  isOpen: boolean;
  invoiceData: InvoiceData;
}

export const DateName = styled(PropertyName)`
  grid-row: 2/3;

  @media ${device.tablet} {
    display: none
  }
`

export const DateValue = styled(PropertyValue)`
  grid-row: 2/3;
  grid-column: 3/5;


  @media ${device.tablet} {
    display: block;
    grid-row: 1/2;
    grid-column: 3/4;
    padding-top: 0;
    align-self: center;
  }
`

export const BilledToName = styled(PropertyName)`
  grid-row: 3/4;

  @media ${device.tablet} {
    grid-row: 2/3;
  }
  @media ${device.tabletL} {
   display: none;
  }
`

export const BilledToValue = styled(PropertyValue)`
  grid-row: 3/4;
  grid-column: 3/5;

  @media ${device.tablet} {
    grid-row: 2/3;
    grid-column: 3/5;
    align-self: center;
  }
  @media ${device.tabletL} {
    display: block;
    grid-row: 1/2;
    grid-column: 5/6;
    padding-top: 0;
  }
`

export const AmountName = styled(PropertyName)`
  grid-row: 4/5;

  @media ${device.tablet} {
    display: none;
  }
`

export const AmountValue = styled(PropertyValue)`
  grid-row: 4/5;
  grid-column: 3/5;
  font-weight: bold;

  @media ${device.tablet} {
    display: block;
    grid-row: 1/2;
    grid-column: 5/6;
    padding-top: 0;
    align-self: center;
  }
  @media ${device.tabletL} {
    grid-column: 6/7;
  }
`


export default function SingleInvoice({ isOpen, invoiceData }: SingleInvoiceProps) {

  const handleCurrency = (curency: string): JSX.Element | null => {
    switch (curency) {
      case 'EUR':
        return <span>&euro;</span>
      case 'USD':
        return <span>&#36;</span>
      case 'GBP':
        return <span>&#163;</span>
      default:
        return null;
    }
  }

  return (
    <>
      <p style={{ display: 'none' }}>isOpen: {isOpen}</p>
      <RowTitle href={invoiceData.download_url} target="_black" rel="noreferrer">{invoiceData.invoice_id}</RowTitle>
      <InvoiceStatus status={invoiceData.status} />
      <DateName isOpen={isOpen}>Date:</DateName>
      <DateValue isOpen={isOpen}>{new Date(invoiceData.created_at).toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' })}</DateValue>
      <BilledToName isOpen={isOpen}>Billed To:</BilledToName>
      <BilledToValue isOpen={isOpen}>{invoiceData.billed_to}</BilledToValue>
      <AmountName isOpen={isOpen}>Amount</AmountName>
      <AmountValue isOpen={isOpen}>{invoiceData.amount} {handleCurrency(invoiceData.currency)}</AmountValue>
    </>
  )
}
