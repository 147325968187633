import React from 'react'
import { TooltipTextWrapper } from './BasicTooltip.styled'

export default function BasicTooltip({ content, isInNoDataChart }: { content: string, isInNoDataChart?: boolean }) {
  return (
    <TooltipTextWrapper isInNoDataChart={isInNoDataChart}>
      <p>
        {content}
      </p>
    </TooltipTextWrapper>
  )
}
