import React, { useState, useEffect } from 'react'

//Components
import { SocialStatsWrapper, SocialStatFilterWrapper } from './SocialStats.styled'
import SingleSocialStats from './SingleSocialStats'
import CustomSelect from '../global-components/CustomSelectButton'
import LoadSpinner from '../global-components/LoadSpinner'
//Hooks
import { v4 as uuidv4 } from 'uuid'

import { SocialStatsInterface } from '../../state/query/types/OverviewTypes'

export default function SocialStats({ data, isLoading }: { data: SocialStatsInterface | undefined, isLoading: boolean }) {

  const [filterOptions, setFilterOption] = useState<string[]>([])
  const [socialFilter, setSocialFilter] = useState('week')

  useEffect(() => {
    if (!isLoading && data) {

      const finalFilters = Object.keys(Object.values(data)[0])
      setFilterOption(finalFilters)

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])


  return (

    <SocialStatsWrapper>
      {isLoading && <LoadSpinner size="large" />}
      <SocialStatFilterWrapper>
        <CustomSelect
          type="select-button"
          options={filterOptions}
          value={socialFilter}
          onSelect={setSocialFilter}
        />
      </SocialStatFilterWrapper>

      {data && Object.entries(data).map(([socialName, socialData]) => {

        const socialDataValue = socialData[socialFilter]

        return <SingleSocialStats
          key={uuidv4()}
          name={socialName}
          trend={socialDataValue.trend?.value}
          value={socialDataValue.number}
          selectedFilter={socialFilter}
          isRising={socialDataValue.trend?.isRising}
        />
      }
      )}
    </SocialStatsWrapper>
  )
}
