import styled, { css } from 'styled-components'
import { device } from '../../global-styles/mediaQueries'
import { ReactComponent as LogoLarge } from '../../assets/icons/logo.svg'
import { ReactComponent as LogoSmall } from '../../assets/icons/small-logo.svg'
import { globalFocusStyles } from '../../global-styles/globalStyles'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  padding: 0 16px;
  background-color: ${({ theme }) => theme.colors.primary};
  z-index: 100;

  @media ${device.tablet} {
    padding: 0 24px;
  }
  @media ${device.tablet} {
    padding: 0 30px;
  }
`

export const AppLogoLink = styled.a`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  // Custom focus styles
  ${globalFocusStyles}
  // Overriding custom focus styles
  position: absolute;

  @media ${device.desktopS} {
    position: relative;
    top: unset;
    left: unset;
    transform: translate(0)
  }
`

export const LogoSM = styled(LogoSmall)`
  height: 24px;
  width: auto;
  @media ${device.mobileL} {
    display: none;
  }
`

export const LogoLG = styled(LogoLarge)`
  display: none;
  @media ${device.mobileL} {
    display: block;
    height: 24px;
    width: auto;
  }
`

export const MenuAndNotificationsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const NavButtonWrapper = styled.div`
  // Custom focusStyles
  ${globalFocusStyles}

  @media ${device.desktopS} {
    display: none;
  }
`
export const NavButton = styled.button<{ isOpen: boolean }>`
  position: relative;
  display: block;
  height: 16px;
  width: 26px;
  background-color: transparent;
  background-image:${({ theme: { colors } }) => `linear-gradient(to right, ${colors.white}, ${colors.white})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 2px;
  padding: 0;
  outline: 0;
  border: 0;
  color: #333;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: .25s cubic-bezier(.17,.67,.89,1.4);
  transition-property: transform;
  will-change: transform;

  ${({ isOpen }) => isOpen && css`
    background-image: none;
  `}

  &::before, &::after {
    content: "";
    position: absolute;
    left: 0; right: 0;
    display: block;
    height: 2px;
    background: ${({ theme }) => theme.colors.white};
    transition: .25s;
    transition-property: transform, top;
    will-change: transform, top;
    top: unset;

    ${({ isOpen }) => isOpen && css`
      top: 50%;
    `}
  }

  &::before {
    top: 0;
    ${({ isOpen }) => isOpen && css`
      top: 50%;
      transform: translate3d(0,-50%,0) rotate3d(0,0,1,45deg);
    `}
  }

  &::after {
    top: calc(100% - 2px);
    ${({ isOpen }) => isOpen && css`
      top: 50%;
      transform: translate3d(0,-50%,0) rotate3d(0,0,1,-45deg);
    `}
  }

  @media ${device.desktopS} {
    display: none;
  }
`
