import React, { ReactElement } from 'react'

//SVG Component
import { ReactComponent as ExecutiveLogo } from '../../assets/icons/executive.svg'

//Styles
import { SectionTitle, SettingsSectionWrapper } from './Settings.styled'
import { AccountGridWrapper, InfoWrapper } from './Account.styled'


export default function AccountType({ accountType }: { accountType: string }): ReactElement {

  return (
    <SettingsSectionWrapper>
      <SectionTitle>Account Type</SectionTitle>
      <AccountGridWrapper>
        <ExecutiveLogo />
        <InfoWrapper hasDifferentPaddingRight={true}>
          <h5>{accountType}</h5>
          <p>Preset that best suits your needs, establish during onboarding session</p>
        </InfoWrapper>
      </AccountGridWrapper>
    </SettingsSectionWrapper>
  )
}
