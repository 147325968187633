import React, { useContext } from 'react'
import { formatThousandNumbers, addOpacityToHex } from '../../utils'
//Components
import {
  SingleSocialStatWrapp,
  SingleSocialStatIcon,
  SingleSocialStatInfoNumber,
  SingleSocialStatInfoTrend,
  SingleSocialStatIconWrapp,
} from './SocialStats.styled'
import SubtitleComponent from '../global-components/SubtitleComponent'
import MaterialIcon, { IconName } from '../global-components/MaterialIcon'

import { ThemeContext } from 'styled-components'

interface SingleStat {
  name: string;
  value: number;
  trend?: number | string | undefined;
  selectedFilter: string;
  isRising?: boolean
}

interface IconData {
  name: IconName;
  iconBg: string;
  iconColor: string;
  size?: 18 | 24 | 14 | 36 | 48 | undefined;
}

export default function SingleSocialStats({ name, value, trend, selectedFilter, isRising }: SingleStat) {

  //Styleguide theme context
  const themeContext = useContext(ThemeContext)
  const { colors } = themeContext

  //Create trend component
  const createTrendString = () => isRising ? `+${trend}%` : `-${trend}%`;
  const addIconToTrend = () => isRising ? <MaterialIcon iconName="north_east" size={14} color={colors.green} /> : <MaterialIcon iconName="south_east" color={colors.redAlert} />

  //This function create it when we have more then one engagement, for now we will leave it here if we add more than one engagement
  const concatTrendStringAndIcon = () => <SingleSocialStatInfoTrend isRising={isRising}>
    {createTrendString()} {addIconToTrend()}
  </SingleSocialStatInfoTrend>
  //For different stats return different icons
  const getIconDataFromSocialName = (): IconData => {
    switch (name) {
      case 'relevant_articles':
        return { name: 'article', iconColor: colors.accent, iconBg: addOpacityToHex(colors.accent, 0.1), size: 24 }
      case 'total_engagement':
        return { name: 'groups', iconColor: colors.green, iconBg: addOpacityToHex(colors.green, 0.1), size: 24 }
      default:
        return { name: 'share', iconColor: colors.green, iconBg: addOpacityToHex(colors.green, 0.1) }

    }
  }

  //Return JSX element for stat icon
  const createIconForSocialStat = () => {

    const { name, iconColor, iconBg, size }: IconData = getIconDataFromSocialName()

    return <SingleSocialStatIconWrapp bgColor={iconBg}>
      <MaterialIcon iconName={name} color={iconColor} size={size} />
    </SingleSocialStatIconWrapp>
  }

  return (
    <SingleSocialStatWrapp className="SingleSocialStatWrapp">
      <SingleSocialStatIcon>
        {createIconForSocialStat()}
      </SingleSocialStatIcon>

      <div>
        <SubtitleComponent text={name.split('_').reduce((acc, curr) => acc + ' ' + curr, '')} />
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <SingleSocialStatInfoNumber>
            {formatThousandNumbers(value)}
          </SingleSocialStatInfoNumber>
          {name === 'relevant_articles'
            ? <p style={{ color: colors.secondary, fontSize: '13px', lineHeight: '1,29', gridArea: 'trend', padding: '0 0 7px 12px' }}>This {selectedFilter}</p>
            : concatTrendStringAndIcon()
          }
        </div>
      </div>


    </SingleSocialStatWrapp>
  )
}
