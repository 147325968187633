import { useContext } from 'react'
import styled from 'styled-components'
import MaterialIcon from '../global-components/MaterialIcon'
import { ThemeContext } from 'styled-components'



interface SingleNewsSocialMediaRankProps {
  rankStats: {
    date: string | null;
    range: string | null;
    trend: number | null
  }
  publishedDate: string;
}

const RankWrapper = styled.span<{ isPastRank: boolean }>`
  margin-top: ${({ isPastRank }) => isPastRank ? '8px' : '0px'};
`

const Rank = styled.span<{ isPastRank: boolean }>`
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  line-height: 1.33;
  color: ${({ theme }) => theme.colors.primary};

  .trend-indicator {
    margin-top: 3px;
  }

`

const RangeWrapper = styled.span`
  margin-right: 4px;
`

const Range = styled.span<{ isPastRank: boolean }>` 
  font-weight: bold;
  font-size: ${({ isPastRank }) => isPastRank ? '12px' : '20px'};
`

const DateWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin-left: 2px;

    span {
      font-weight: bold;
    }
  }

  svg {
    display: inline-block;
    margin-top: -2px;
  }
`

const NoRank = styled.span`
  display: inline-block;
  min-width: 117px;
  margin-top: 4px;
  font-size: 12px;
  line-height: 1.33;
  color: ${({ theme }) => theme.colors.secondary};
`

/**
 * SingleNewsSocialMediaRank component is displaying if is specific single news ranked or not and in what range they are ranked.
 * 
 */
export default function SingleNewsSocialMediaRank({ rankStats, publishedDate }: SingleNewsSocialMediaRankProps) {
  const { date, range, trend } = rankStats

  const { colors } = useContext(ThemeContext)

  const formatDate = (date: string, currentlyRanked: boolean): string => {
    const now = new Date()
    const d = new Date(date)

    if (currentlyRanked) {
      const diff = Math.round(((now.getTime() - d.getTime()) / 1000) / 60) // minutes diference

      if (diff < 60) {
        return `${diff}m`
      } else if (diff < 60 * 24) {
        return `${Math.round(diff / 60)}h`
      } else {
        const daysNo = Math.round((diff / 60) / 24)
        return `${daysNo} ${daysNo > 1 ? 'days' : 'day'}`
      }
    } else {
      const date = d.toLocaleDateString('en-GB', { year: '2-digit', month: 'short', day: 'numeric' })
      const lastEmptySpace = date.lastIndexOf(' ')
      return date.substring(0, lastEmptySpace) + "," + date.substring(lastEmptySpace)
    }
  }


  if (range) {
    return (
      <RankWrapper isPastRank={!!date}>
        <Rank isPastRank={!!date}>
          {
            date ? (
              <>
                <RangeWrapper> <Range isPastRank={!!date}>{range}</Range> on publish day</RangeWrapper>
                <DateWrapper>
                  <MaterialIcon
                    iconName="event_available"
                    color={colors.primary}
                  />
                  <span>{formatDate(date, false)}</span>
                </DateWrapper>
              </>
            ) : (
              <>
                <RangeWrapper> <Range isPastRank={!!date}>{range}</Range></RangeWrapper>
                {trend !== null && trend !== 0 && (
                  <MaterialIcon
                    iconName={trend === 1 ? "arrow_drop_up" : "arrow_drop_down"}
                    color={trend === 1 ? colors.green : colors.redAlert}
                    additionalClassName="trend-indicator"
                  />
                )}
                <DateWrapper>
                  <MaterialIcon
                    iconName="history"
                    color={colors.primary}
                  />
                  <span><span>{formatDate(publishedDate, true)}</span> since publishing</span>
                </DateWrapper>
              </>
            )
          }
        </Rank>
      </RankWrapper>
    )
  } else {
    return <NoRank>Not Ranked</NoRank>
  }
}
