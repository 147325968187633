import React, { useContext } from 'react';
//Router
import { useHistory } from "react-router-dom";
//Components
import MaterialIcon from '../global-components/MaterialIcon'
import ModalButton from '../global-components/ModalButton'
//Styles
import { ModalBox, TitleWrapper, DisclaimerText, ButtonWrapper } from './DeleteArticle.styled'
import { ThemeContext } from 'styled-components'
//Redux
import { useAppDispatch, useAppSelector } from '../../state/hooks/hooks'
import { closeModal, UnsavedChangesModalPayload } from '../../state/slices/modalsSlice'
import { cleanEditMode } from '../../state/slices/appState'

import { handleLogout } from '../../utils/handleLogout';

const UnsavedChanges = ({ payload }: { payload: UnsavedChangesModalPayload }) => {
  //Props
  const { fieldName, pathTo, hiddenField } = payload
  const { auth_token } = useAppSelector(state => state.user)

  const dispatch = useAppDispatch();

  const { colors } = useContext(ThemeContext)

  const history = useHistory();

  const handleCloseModal = () => dispatch({ type: closeModal.type })

  const handleOnContinue = (): void => {
    if (pathTo !== undefined) {
      history.push(pathTo)
      dispatch(cleanEditMode('none'))
      handleCloseModal()
    } else if (fieldName === 'logout') {
      handleLogout(auth_token, dispatch)
      dispatch(cleanEditMode('none'))
      handleCloseModal()
    }
    else {
      dispatch(cleanEditMode(hiddenField ? hiddenField : 'none'))
      handleCloseModal()
    }
  }

  return (
    <ModalBox>
      <TitleWrapper>
        <MaterialIcon iconName="warning_filled" color={colors.neutral} size={24} />
        <h3>Continue without saving?</h3>
      </TitleWrapper>
      <DisclaimerText>Changes you’ve made will be permanently lost if you continue.</DisclaimerText>
      <ButtonWrapper>
        <ModalButton type="cancel" onClick={handleCloseModal}>Cancel</ModalButton>
        <ModalButton type="confirm" color={colors.accent} bgColorActiveState="blue" onClick={handleOnContinue}>Continue</ModalButton>
      </ButtonWrapper>
    </ModalBox>
  );
}

export default UnsavedChanges;
