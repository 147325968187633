import React, { useState, useEffect, KeyboardEvent } from 'react'

// Components
import NotificationsInputElements from './NotificationsInputElements'
import { CancelButton } from '../global-components/ModalButton'
import Spinner from '../global-components/ButtonSpinner'

// Icons
import MaterialIcon from '../global-components/MaterialIcon';

// Styles
import {
  TextWrapper,
  NotificationsWrapper,
  Subtitle,
  ButtonsWrapper,
  EditButton,
  SelectionButtons,
  TextAndButtonsWrapper
} from './NotificationsSelections.styled'
import { SettingsConfirmButton } from '../settings/Settings.styled'

// Types
import { NotificationDataSelections } from './Notifications'
import { NotificationsSelectionsData } from '../../state/query/types/SettingsTypes'

// Redux
import { useAppDispatch } from '../../state/hooks/hooks'
import { cleanValuesInEditMode } from '../../state/slices/appState'
import { useEditSettingsNotificationsMutation } from '../../state/query/queryApi'
import { showPopup } from '../../state/slices/popupSlice'

// utils
import { showErrorOrSuccessPopup } from '../../utils/showPopups'


export interface NotificationsSelectionsProps {
  type: 'checkbox' | 'radio-button';
  data: NotificationDataSelections[];
  title: string;
  instructions: string;
  subtitle?: string;
  postRoute: string;
}


export default function NotificationsSelections({ type, data, title, subtitle, instructions, postRoute }: NotificationsSelectionsProps) {

  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const [notificationsData, setNotificationsData] = useState<NotificationDataSelections[]>(data);

  const dispatch = useAppDispatch()

  const [editSettingsNotifications, { isLoading }] = useEditSettingsNotificationsMutation()

  /**
   * cleanComponentValuesFromEditMode
   * 
   * Collects all values that can possible be updated and dispatches action to clean properties that have possibly been changed.
   */
  const cleanComponentValuesFromEditMode = () => {

    const labelItemList: string[] = []

    notificationsData?.forEach((item) => {
      labelItemList.push(`${postRoute} ${item.name}`)

      if (item.extension) {
        labelItemList.push(`${postRoute} select ${item.name}`)
      }
    })

    dispatch(cleanValuesInEditMode(labelItemList))
  }

  /**
   * prepareDataForUpdate
   * 
   * Deletes extensions property since we are not sending it to backend. 
   * 
   * @returns a list of data without extensions property
   */
  const prepareDataForUpdate = () => {
    // Creating a deep copy of state, since shallow copy will reference object properties to state, and state is immutable
    const tempNotificationsData = JSON.parse(JSON.stringify(notificationsData));

    tempNotificationsData.forEach((item: NotificationDataSelections) => {
      if (item.extensions) {
        delete item.extensions
      }
    })

    return tempNotificationsData
  }


  /**
   * onSaveChanges
   * 
   * Makes a POST request using rtk query
   * 
   * @param preparedUpdateData prepared data from prepareDataForUpdate function.
   */
  async function onSaveChanges(preparedUpdateData: NotificationsSelectionsData['requestBody']['']) {

    const response = await editSettingsNotifications({
      endpoint: postRoute,
      requestBody: {
        [postRoute]: preparedUpdateData
      }
    })

    if (response.hasOwnProperty('error')) {
      dispatch(showPopup({
        event: 'error',
        type: 'additionalInfo',
        direction: 'bottom',
        props: {
          popupTitle: 'Error during upload!',
          popupText: 'A network error occurred. Please try again later.',
          additionalComponentName: 'SimpleTextInfo'
        }
      }))
    }
    else {
      showErrorOrSuccessPopup(dispatch, 'Changes are made', 'bottom', 'success', 'alert')

      cleanComponentValuesFromEditMode()
      setIsEditMode(false)
    }
  }


  const onStartEditMode = () => {
    !isEditMode && setIsEditMode(true)
  }

  const handleOnKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      onStartEditMode()
    }
  }

  const onSaveEditMode = () => {
    const preparedUpdateData = prepareDataForUpdate()
    onSaveChanges(preparedUpdateData)
  }

  const onCancelEditMode = () => {
    setNotificationsData(data)

    cleanComponentValuesFromEditMode()
    setIsEditMode(false)
  }


  useEffect(() => {
    // Updating local data state when components rerenders with new data from cash
    if (JSON.stringify(notificationsData) !== JSON.stringify(data)) {
      setNotificationsData(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <NotificationsWrapper isEditMode={isEditMode}>
      <EditButton onClick={onStartEditMode} onKeyDown={handleOnKeyDown} tabIndex={0} isEditMode={isEditMode}>
        {
          isEditMode
            ? <MaterialIcon iconName="mode_edit" />
            : 'Edit'
        }
      </EditButton>
      <TextAndButtonsWrapper>
        <TextWrapper>
          <h5>{title}</h5>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </TextWrapper>

        <div>
          <h5>{instructions}</h5>

          <SelectionButtons disablePointerEvents={isLoading}>
            {
              notificationsData && notificationsData.map((item: NotificationDataSelections) =>
                <NotificationsInputElements
                  key={item.id}
                  type={type}
                  item={item}
                  isEditMode={isEditMode}
                  notificationsData={notificationsData}
                  setNotificationsData={setNotificationsData}
                  postRoute={postRoute}
                />
              )
            }
          </SelectionButtons>
        </div>
      </TextAndButtonsWrapper>
      {
        isEditMode &&
        <ButtonsWrapper>
          <CancelButton
            onClick={() => !isLoading && onCancelEditMode()}
          >Cancel</CancelButton>

          <SettingsConfirmButton
            disabledPointerOnLoad={isLoading || JSON.stringify(notificationsData) === JSON.stringify(data)}
            isDisabled={!isLoading && JSON.stringify(notificationsData) === JSON.stringify(data)}
            width={"77px"}
            bgColorActiveState="blue"
            onClick={onSaveEditMode}
          >
            {isLoading
              ? <Spinner size={18} />
              : "Save"
            }
          </SettingsConfirmButton>
        </ButtonsWrapper>
      }
    </NotificationsWrapper>
  )
}
