import React, { useState, useEffect } from 'react'
import Spinner from './ButtonSpinner'

import { PaginationWrapper, ItemsInfo, PageControls, PageInfo, ControlButtons, ControlButtonDirection } from './ListPagination.styled'


interface ListPaginationProps {
  page: number;
  pageLength: number;
  totalRecords: number | undefined;
  pageNum: number;
  changePageNum: (page: number) => void
}

/**
 * ListPagination is a component that we are using for the pagination for the latest news
 * 
 */
const ListPagination = React.forwardRef<HTMLDivElement, ListPaginationProps>(({
  page = 1,
  pageLength = 10,
  totalRecords = 0,
  changePageNum,
}, parrentRef) => {

  const [inputValue, setInputValue] = useState<string>('1')
  const [buttonLoader, setButtonLoader] = useState<'prev' | 'next' | null>(null)
  const [triggerScrollAnimation, setTriggerScrollAnimation] = useState<boolean>(false)

  const normalizeWithinLimits = (value: number, min: number, max: number): number => Math.max(min, Math.min(max, value))

  const handlePreviosPage = (prevPage: number) => {
    setButtonLoader('prev')
    changePageNum(prevPage)
  }

  const handleNextPage = (nextPage: number) => {
    setButtonLoader('next')
    changePageNum(nextPage)
  }

  const handleBlur = () => {
    if (parseInt(inputValue) !== page) setInputValue(normalizeWithinLimits(parseInt(inputValue), 1, totalPages).toString())
    if (inputValue === "") setInputValue(page.toString())
  }

  const scrollToRef = () => window.scrollTo({ top: (parrentRef as any).current.offsetTop - 125, left: 0, behavior: "smooth" })

  const totalPages = totalRecords > 0 ? Math.ceil(totalRecords / pageLength) : 1
  const from = normalizeWithinLimits((page - 1) * pageLength + 1, 1, totalRecords)
  const to = normalizeWithinLimits((page - 1) * pageLength + pageLength, 1, totalRecords)

  useEffect(() => {
    if (page) setInputValue(`${page}`)
    if (buttonLoader) setButtonLoader(null)
    if (triggerScrollAnimation) {
      scrollToRef()
      setTriggerScrollAnimation(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <PaginationWrapper>
      <ItemsInfo>
        {totalRecords && <span>{`${from} - ${to} of ${totalRecords}`}</span>}
      </ItemsInfo>

      <PageControls>
        <PageInfo>
          <input
            value={inputValue}
            onChange={(event) => {
              const pageNum = Number(event.target.value);
              if (isNaN(pageNum)) {
                return;
              }
              setInputValue(event.target.value)
            }}
            onBlur={handleBlur}
            onKeyPress={(event) => {
              if (event.key !== 'Enter' || inputValue === "") {
                return;
              }
              if (parseInt(inputValue) > page) {
                setButtonLoader('next')
              } else {
                setButtonLoader('prev')
              }
              setTriggerScrollAnimation(true)
              changePageNum(normalizeWithinLimits(parseInt(inputValue), 1, totalPages))
              event.currentTarget.blur()
            }}
          />
          <span>/&nbsp;{totalPages}</span>
        </PageInfo>
        <ControlButtons>
          <button
            disabled={page <= 1}
            onClick={() => {
              const prevPage = page > 1 ? page - 1 : page;
              setTriggerScrollAnimation(true)
              handlePreviosPage(prevPage)
            }}
            tabIndex={0}
          >
            {buttonLoader === 'prev' && page > 1 ? <Spinner size={14} /> : <ControlButtonDirection />}
          </button>

          <button
            disabled={page >= totalPages}
            onClick={() => {
              const nextPage = page < totalPages ? page + 1 : page;
              setTriggerScrollAnimation(true)
              handleNextPage(nextPage);
            }}
            tabIndex={0}
          >

            {buttonLoader === 'next' && page < totalPages ? <Spinner size={14} /> : <ControlButtonDirection isRight={true} />}
          </button>
        </ControlButtons>
      </PageControls>

    </PaginationWrapper>
  )
})


export default ListPagination;