import React from 'react'
import styled from 'styled-components'

import LogoPlaceholder, { getSize, SizeProp } from './LogoPlaceholder'

import { useAppSelector } from '../../state/hooks/hooks'


const getPosition = (isLarge: boolean) => isLarge ? '-3px' : '-1px';


const Image = styled.div<{isLarge: boolean, hasBorder: boolean, size: SizeProp}>`
  position: relative;
  height: ${({size}) => getSize(size)};
  width: ${({size}) => getSize(size)};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: ${({isLarge}) => isLarge ? '2px' : '1px'};
  border: ${({ theme, isLarge, hasBorder }) =>  hasBorder && (isLarge ? `2px solid ${theme.colors.white}` : `1px solid ${theme.colors.white}`)};
  border-radius: 50%;
  ::after {
    position: absolute;
    top: ${({isLarge}) => getPosition(isLarge)};
    left: ${({isLarge}) => getPosition(isLarge)};
    width: ${({size}) => getSize(size)};
    height: ${({size}) => getSize(size)};
    content: "";
    border: 1px solid ${({ theme }) => theme.colors.borderSecondary};
    border-radius: 100%;
  }
`

interface UserImageProps {
  size: SizeProp;
  hasBorder: boolean;
}

export default function UserImage({size, hasBorder}: UserImageProps) {

  const username = useAppSelector(state => state.user.username)
  const image = useAppSelector(state => state.user.image)

  return (
    <>
      {
        image && image !== ""
          ? <Image hasBorder={hasBorder} isLarge={size === 'large'} size={size} style={{ backgroundImage: `url(${image})` }}></Image>
          : <LogoPlaceholder name={username} hasBorder={hasBorder} size={size} />
      }
    </>
  )
}
