import React from 'react';

// Components
import NotificationsSelections from './NotificationsSelections'
import NotificationsMailList from './NotificationsMailList'

import {NotificationsWrapper, TitleContainer, Title, BorderElement} from './Notifications.styled'


export type NotificationDataSelections = {
  id: number;
  name: string;
  extension: string | null;
  extensions?: string[] | null;
  checked: boolean;
}

export type NotificationsData = {
  email_frequency: NotificationDataSelections[];
  email_activity: NotificationDataSelections[];
  def_expiration_time: NotificationDataSelections[]
  emails: string[];
}

const Notifications = ({notificationsData}: {notificationsData: NotificationsData | undefined}) => {

  return (
    <div>
      {
        notificationsData &&
          <NotificationsWrapper>
            <TitleContainer>
              <Title>Email Notifications</Title>
            </TitleContainer>

            <NotificationsSelections
              type="radio-button"
              data={notificationsData.email_frequency}
              title={"Frequency"}
              subtitle={"General notifications will be sent to your account email"}
              instructions={"Send alert to my inbox:"}
              postRoute={"email_frequency"}
            />

            <BorderElement />
            
            <NotificationsSelections
              type="checkbox"
              data={notificationsData.email_activity} 
              title="Activity" 
              instructions="Email me when:" 
              postRoute={"email_activity"}
            />

            <TitleContainer>
              <Title>Social Alerts</Title>
            </TitleContainer>

            <NotificationsMailList data={notificationsData.emails} />

            <BorderElement />

            <NotificationsSelections
              type="radio-button"
              data={notificationsData.def_expiration_time}
              title={"Default Expiration Time"}
              subtitle={"Set default expiration time for social alerts campaign"}
              instructions={"By deafult, coverage expires after:"}
              postRoute={"def_expiration_time"}
            />
          </NotificationsWrapper>
      }
    </div>
  );
}

export default Notifications;
