//Components
import SetPassword from './SetPassword'
import SetUsername from './SetUsername'

//Redux
import { useAppDispatch } from '../../state/hooks/hooks'
import { updateEditMode } from '../../state/slices/appState'


const SignIn = () => {

  const dispatch = useAppDispatch()

  function setChangesInEditModeGlobalState(startFieldValue: string, fieldValue: string, fieldName: string) {
    dispatch(updateEditMode({ startFieldValue: startFieldValue, fieldValue: fieldValue, fieldName: fieldName }))
  }

  return (
    <div>
      <SetUsername setChangesInEditModeGlobalState={setChangesInEditModeGlobalState} dispatch={dispatch} />
      <SetPassword setChangesInEditModeGlobalState={setChangesInEditModeGlobalState} dispatch={dispatch} />
    </div>
  );
}

export default SignIn;
