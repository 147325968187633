import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { decrypt } from '../utils'

import Home from '../components/home/Home'
import Dashboard from '../components/dashboard/Dashboard'
import Reports from '../components/reports/Reports'
import Settings from '../components/settings/Settings'
import Invoices from '../components/invoices/Invoices'
import Login from '../components/login/Login'
import Help from '../components/help/Help'
import AuthRoute from './AuthRoute'
import LoginRoute from './LoginRoute'

import { useAppDispatch, useAppSelector } from '../state/hooks/hooks'
import { updateOnReload } from '../state/slices/userSlice'

export default function Routes(props: { children?: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) {

  const userState = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()

  const handleAuth = () => {
    let auth = false;
    // check if auth token is present
    if (userState.auth_token) {
      auth = true
    } else {
      // on page reload check if encripted token is set
      if (localStorage.getItem("token")) {
        // decript and dispatch set token action
        const hashedToken = localStorage.getItem("token")
        const username = localStorage.getItem("name")
        const email = localStorage.getItem("email")
        const image = localStorage.getItem("image")
        dispatch(updateOnReload({ auth_token: decrypt(hashedToken as string, process.env.REACT_APP_SECRET as string), username: username as string, email: email as string, image: image as string }))
        auth = true
      }
    }
    return auth;
  }

  const isAuthenticated = handleAuth()

  return (
    <Router>
      <Switch>
        <AuthRoute path="/" exact component={Home} isAuthenticated={isAuthenticated} />
        <AuthRoute path="/dashboard" component={Dashboard} isAuthenticated={isAuthenticated} />
        <AuthRoute path="/reports" component={Reports} isAuthenticated={isAuthenticated} />
        <AuthRoute path="/settings" component={Settings} isAuthenticated={isAuthenticated} />
        <AuthRoute path="/invoices" component={Invoices} isAuthenticated={isAuthenticated} />
        <AuthRoute path="/help" component={Help} isAuthenticated={isAuthenticated} />
        <LoginRoute path="/login" component={Login} isAuthenticated={isAuthenticated} />
        <AuthRoute path="*" component={Home} isAuthenticated={isAuthenticated} />
      </Switch>
      {props.children}
    </Router>
  )
}
