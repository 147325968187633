const size = {
  mobileS: '360px',
  mobileL: '480px',
  tablet: '768px',
  tabletL: '1024px',
  desktopS: '1140px',
  desktop: '1440px',
  desktopL: '1920px'
}

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  tabletL: `(min-width: ${size.tabletL})`,
  desktopS: `(min-width: ${size.desktopS})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktopL})`
};