
import React, { useContext } from 'react'
import { closeModal } from '../../state/slices/modalsSlice'
import { useAppDispatch } from '../../state/hooks/hooks'
import { ThemeContext } from 'styled-components'

import ModalButton from '../global-components/ModalButton'
import { ModalBox, TitleWrapper, NewsTitleExcerpt, DisclaimerText, ButtonWrapper } from './DeleteArticle.styled'
import MaterialIcon from '../global-components/MaterialIcon'

import { useDeleteNewsSearchMutation } from '../../state/query/queryApi'
import { showErrorOrSuccessPopup } from '../../utils/showPopups'

interface DeleteSavedSearchProps {
  articleTitle: string;
  id: number;
  newsKey: number;
  modalType: string;
}

export default function DeleteSavedSearch({ payload }: { payload: DeleteSavedSearchProps }) {

  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch({ type: closeModal.type })
  }
  const [deleteSavedSearchById, { isLoading }] = useDeleteNewsSearchMutation()


  const { colors } = useContext(ThemeContext)
  const handleDeleteSavedSearch = () => {
    deleteSavedSearchById(payload.id)
      .then(result => Object.keys(result)[0] === 'data'
        ? showErrorOrSuccessPopup(dispatch, Object.values(result)[0].Message, 'bottom', 'success', 'additionalInfo')
        : showErrorOrSuccessPopup(dispatch, Object.values(result)[0].error, 'bottom', 'error', 'additionalInfo'))
      .then(() => handleCloseModal())
  }
  return (
    <ModalBox>
      <TitleWrapper>
        <MaterialIcon iconName="warning_filled" color={colors.redAlert} size={24} />
        <h3>Delete this saved search?</h3>
      </TitleWrapper>
      <NewsTitleExcerpt>{payload.articleTitle}</NewsTitleExcerpt>
      <DisclaimerText>Once you delete this search, it will no longer be visible in the saved search list</DisclaimerText>
      <ButtonWrapper>
        <ModalButton type="cancel" onClick={handleCloseModal}>Cancel</ModalButton>
        <ModalButton type="confirm" color={colors.redAlert} isLoading={isLoading} onClick={handleDeleteSavedSearch}>Delete Delete</ModalButton>
      </ButtonWrapper>
    </ModalBox>
  );
}