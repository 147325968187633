import React, { useState, useRef, FocusEvent } from 'react'
import { SingleTag, TransparentTextInput, CancelIconWrapper, SubtermsHoverComponent } from '../global-components/SubTermInput.styled'
import MaterialIcon from '../global-components/MaterialIcon'
import { ThemeContext } from 'styled-components';
import { nanoid } from '@reduxjs/toolkit'

import { handleInputSanitize, limitCharacters } from '../../utils'

import { StyledTagsWrapper, DefaultTag } from './NewsTermInput.styled'


interface EventCodeExtended extends React.KeyboardEvent<HTMLInputElement> {
  code: string;
}

export type SubTermInputType = 'email' | 'text';
export type TagStyle = 'primary' | 'secondary' | 'secondary-dark' | 'error';


export interface SubTermInputInterface {
  tags_custom: string[];
  tags_company?: string[];
  type: SubTermInputType;
  inputName: string;
  tagStyle?: TagStyle;
  isDisabled?: boolean;
  maxLength?: number;
  updateCustomTags: (tags: string[]) => void
}

/**
 * SubTermInput component
 */
export default function NewsTermInput({
  tags_custom,
  tags_company,
  type,
  inputName,
  tagStyle = 'primary',
  maxLength = 40,
  updateCustomTags,
  isDisabled = false, }: SubTermInputInterface) {

  const [inputValue, setInputValue] = useState('')
  const inputField = useRef<HTMLInputElement>(null)
  const themeContext = React.useContext(ThemeContext)

  const capitalizeFirstLetter = (str: string) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`

  function setTagsStat(e: EventCodeExtended) {
    const event = e.target as HTMLTextAreaElement;
    if (event.value.trim().length !== 0) {
      if (e.code === 'Enter' || e.key === 'Enter') {
        updateCustomTags([...tags_custom, capitalizeFirstLetter((e.target as HTMLTextAreaElement).value)])
        setInputValue('')
      }
    } else {
      if ((e.code === 'Backspace' || e.key === 'Backspace') && tags_custom.length > 0) {
        const tagsWithoutLast = [...tags_custom]
        tagsWithoutLast.pop()
        updateCustomTags(tagsWithoutLast)
      }
    }
  }

  const handleRemoveTag = (dropIndex: number) => {
    updateCustomTags(tags_custom.filter((_: string, index: number) => index !== dropIndex))
  }

  const handleBlur = (e: FocusEvent) => {
    if (inputValue.trim().length !== 0) {
      updateCustomTags([...tags_custom, capitalizeFirstLetter((e.target as HTMLTextAreaElement).value)])
    }
    setInputValue('')
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value.includes(',')) {
      if (value.indexOf(',') > 0) {
        updateCustomTags([...tags_custom, capitalizeFirstLetter(value.slice(0, value.length - 1))])
        setInputValue('')
      } else {
        handleInputSanitize(e, setInputValue)
      }
    } else {
      handleInputSanitize(e, setInputValue)
    }
  }


  return (
    <div style={{ position: 'relative' }}>
      <StyledTagsWrapper >
        {/* Default tags */}

        {tags_company?.length !== 0 && tags_company?.map((tag: string) =>
          <DefaultTag key={nanoid()}>
            {tag}
          </DefaultTag>)
        }
        {/* Added tags */}
        {tags_custom.length !== 0 && tags_custom.map((tag: string, index: number) =>
          <SingleTag key={nanoid()} tagStyle={tagStyle} isDisabled={isDisabled} type={type}>
            {limitCharacters(tag, 20)}
            <CancelIconWrapper onClick={() => handleRemoveTag(index)}>
              <MaterialIcon size={14} iconName='close' color={tagStyle === 'primary' ? themeContext.colors.white : themeContext.colors.primary} />
            </CancelIconWrapper>
            {tag.length > 20 && <SubtermsHoverComponent>{tag}</SubtermsHoverComponent>}
          </SingleTag>)
        }
        {/* Input field */}
        <form onSubmit={e => e.preventDefault()}>
          {
            <TransparentTextInput
              autoComplete="off"
              ref={inputField}
              placeholder="+ Add tag..."
              maxLength={maxLength}
              onChange={handleOnChange}
              value={inputValue}
              onKeyDown={(e: EventCodeExtended): void => setTagsStat(e)}
              onBlur={handleBlur}
              name={inputName}
              style={{ height: '34px' }}
            />
          }
        </form>
      </StyledTagsWrapper >
    </div>
  )
}

