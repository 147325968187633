import styled from 'styled-components'
import { globalFocusStyles } from '../../../global-styles/globalStyles'

export const TooltipButton = styled.button`
  margin: 2px;
  display: block;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  // Custom focus styles
  ${globalFocusStyles}

  svg {
    display: block;
    &:hover {
      g {
        fill: ${({ theme }) => theme.colors.accent};
      }
    }
  }
`

export const TooltipCallerWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin: 2px;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

`