import React, { ReactElement, useEffect, useRef } from 'react'

//Components
import ButtonSpinner from '../global-components/ButtonSpinner'
import UserImage from '../global-components/UserImage'

//Styles
import { UploadActionButton, AccountGridWrapper, InfoWrapper, ImageWrapper } from './Account.styled'
import { SectionTitle, SettingsSectionWrapper } from './Settings.styled'

//Redux
import { useAppDispatch, useAppSelector } from '../../state/hooks/hooks'
import { openModal } from '../../state/slices/modalsSlice'
import { showPopup } from '../../state/slices/popupSlice'
import { useEditUserImageMutation } from '../../state/query/queryApi'
import { editUserImage } from '../../state/slices/userSlice'

interface AccountImageProps {
  isAccountImageEnabled: boolean
  setIsAccountImageEnabled: React.Dispatch<React.SetStateAction<boolean>>
  imageUrl: string
}
//Currently we don't have logic for adding & deleting image
export default function AccountImage({ isAccountImageEnabled, setIsAccountImageEnabled, imageUrl }: AccountImageProps): ReactElement {

  const uploadImageRef = useRef<HTMLInputElement>(null)

  const [uploadUserImage, { isLoading }] = useEditUserImageMutation()

  const dispatch = useAppDispatch()
  const isInEditMode = useAppSelector(state => state.appState.isEditModeActive)


  /**
   * getBase64 - converts image to base64 format using Promise and FileReader.
   */
  const getBase64 = (file: File): Promise<string> => {
    return new Promise(resolve => {
      let baseUrl: string = "";

      let reader = new FileReader();

      // Starts reading the contents of the specified Blob, once finished, 
      // the result attribute contains a data: URL representing the file's data.
      reader.readAsDataURL(file)

      // Once data is ready and onload event listener fires, a URL string is returned
      reader.onload = () => {
        baseUrl = reader.result as string
        resolve(baseUrl)
      }
    })
  }

  //Simulate click on input field for uploading image
  const activateFileUpload = () => uploadImageRef.current !== null && uploadImageRef.current.click()

  function onUploadClickHandler(e: React.MouseEvent<HTMLButtonElement>) {
    if (isInEditMode) {
      e.preventDefault()
      dispatch(openModal({
        name: 'unsavedChanges',
        props: { fieldName: 'Upload Image', hiddenField: 'contactInfo' }
      }))
    } else {
      activateFileUpload()
      setIsAccountImageEnabled(false)
    }
  }

  async function saveChanges(convertedImage: string) {

    const response = await uploadUserImage({ fileData: convertedImage })

    if (response.hasOwnProperty('error')) {
      dispatch(showPopup({
        event: 'error', type: 'additionalInfo', direction: 'bottom', props: {
          popupTitle: 'Error during upload!',
          popupText: 'A network error occurred. Please try again later.',
          additionalComponentName: 'SimpleTextInfo'
        }
      }))
    }
    else {
      dispatch(editUserImage(convertedImage))
      localStorage.setItem("image", convertedImage)

      dispatch(showPopup({
        event: 'success', type: 'alert', direction: 'bottom', props: {
          popupTitle: 'Image uploaded',
        }
      }))
    }
  }


  function onChangeImageHandler(e: React.ChangeEvent<HTMLInputElement>) {
    let uploadedFile: File | false = e.target.files !== null && e.target.files[0]

    if (uploadedFile !== false && uploadedFile?.size > 2120000) {
      //Show file is to large popup
      dispatch(showPopup({
        event: 'error', type: 'additionalInfo', direction: 'bottom', props: {
          popupTitle: 'Filesize is too large!',
          popupText: 'Maximum upload file size: 2 MB.',
          additionalComponentName: 'SimpleTextInfo'
        }
      }))
    } else if (uploadedFile !== false) {

      getBase64(uploadedFile)
        .then(result => saveChanges(result as string))
        .catch(err => err)
    }

    setIsAccountImageEnabled(false)
  }

  //Sill we don't know what will happend on delete, should we allow then to go without image, oe set some default image... 
  // function onDeleteHandler() {
  //   console.log('We will decide later what you will do, for now stay as procedure ;) ')
  // }

  useEffect(() => {
    isAccountImageEnabled && activateFileUpload()
  }, [isAccountImageEnabled])

  return (
    <SettingsSectionWrapper className="wrapper">
      <SectionTitle>Account Image</SectionTitle>
      <AccountGridWrapper>
        <ImageWrapper>
          {
            isLoading
              ? <ButtonSpinner size={20} color="#0284FE" />
              : <UserImage hasBorder={true} size="large" />
          }
        </ImageWrapper>

        <InfoWrapper hasMarginTop={true} hasDifferentPaddingRight={true}>
          <input
            ref={uploadImageRef}
            type="file"
            style={{ display: 'none' }}
            onChange={(e) => onChangeImageHandler(e)}
            accept=".jpg, .jpeg, .png"
          />
          <div>
            <UploadActionButton
              onClick={(e) => !isLoading && onUploadClickHandler(e)}
              isGrey={isLoading}
            >Upload</UploadActionButton>
            {/* <UploadActionButton
              isGrey={true}
              onClick={onDeleteHandler}
            >
              Remove
            </UploadActionButton> */}
          </div>
          <p>Use JPG or PNG with max size of 2Mb</p>
        </InfoWrapper>
      </AccountGridWrapper>
    </SettingsSectionWrapper>
  )
}
