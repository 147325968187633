import CustomSelect from '../../global-components/CustomSelectButton'
import { SectionTitleBold } from '../../global-components/TextAndLabelStyles.styled'
import styled from 'styled-components'
import TableHeaderButton from './CheckboxTableHeaderButton'
import TableHeaderSearch from './TableHeaderSearch'
import { device } from '../../../global-styles/mediaQueries'



const HeaderWrapper = styled.div`
  position: relative;
  padding: 16px 16px 12px 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  z-index: 4;

  @media ${device.tablet} {
    display: flex;
    justify-content: space-between;
    padding: 19px 24px 14px;
  }


`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 24px;

  button {
    margin-right: 6px;
  }
`

const TableHeaderTop = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 28px;
  margin-bottom: 11px;

  @media ${device.tablet} {
    min-height: unset;
    margin-bottom: 0;
  }
`

const TableHeaderBottom = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`


interface TableHeaderProps {
  filterBy: string;
  handleSelectFilter: (value: string) => void;
  handleSearchTerms: (value: string) => void;
  headerTitle: string;
  handleDelete: () => void;
  handleDownload: () => void;
  itemsChecked: boolean;
}



export default function TableHeader({
  filterBy,
  handleSelectFilter,
  handleSearchTerms,
  headerTitle,
  handleDelete,
  handleDownload,
  itemsChecked
}: TableHeaderProps) {


  return (
    <HeaderWrapper>
      <TableHeaderTop>
        <SectionTitleBold>{headerTitle}</SectionTitleBold>
        {itemsChecked && (
          <ButtonsWrapper>
            {/* <TableHeaderButton onClick={handleDownload} iconName="file_download" /> */}
            <TableHeaderButton onClick={handleDelete} iconName="delete" />
          </ButtonsWrapper>
        )
        }
      </TableHeaderTop>

      <TableHeaderBottom>
        <CustomSelect
          type="select-button"
          options={["newest", "oldest"]}
          value={filterBy}
          onSelect={handleSelectFilter}
          dropdownPosition="right"
          icon="filter_list"
        />
        <TableHeaderSearch handleSearch={handleSearchTerms} />
      </TableHeaderBottom>
    </HeaderWrapper>
  )
}
