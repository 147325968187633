import React from 'react'
import MaterialIcon, { IconName } from './MaterialIcon'
import { DefaultButtonProps, DefaultButton } from './Button.styled'

interface CustomButtom extends DefaultButtonProps {
  children: React.ReactNode;
  iconName?: IconName;
}

export default function CustomButton({ children, bgColor, color, size, hasIcon, hoverColor, isDisabled, iconName, onClick, }: CustomButtom) {
  return (
    <DefaultButton onClick={onClick} size={size} bgColor={bgColor} color={color} isDisabled={isDisabled} hoverColor={hoverColor} disabled={isDisabled ? true : false}>
      {hasIcon && iconName && <MaterialIcon iconName={iconName} size={18} color={color ? color : '#fff'} />}
      {children}
    </DefaultButton>
  )
}
