import React from 'react'

import SEO from '../global-components/SEO'

import { DashboardMainContainer } from './SocialMediaTab.styled'

type DashboardPageWrapperProps = {
  title: string
  children: React.ReactNode
}

export default function DashboardPageWrapper({ title, children }: DashboardPageWrapperProps) {
  return (
    <DashboardMainContainer>
      <SEO title={title} />
      {children}
    </DashboardMainContainer>
  )
}