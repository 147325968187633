import React from 'react';
import { useAppSelector } from '../../../state/hooks/hooks'
import EventPopup from './EventPopup'
import { Renderer } from './PopupRenderer.styled'


const PopupRenderer = () => {
  const { event, type, show, direction, props, popupID, dontHide, forceClose } = useAppSelector(state => state.popups)

  return (
    <Renderer direction={direction}>
      {show && <EventPopup eventType={event} popupType={type} props={props} direction={direction} popupID={popupID} dontHide={dontHide} forceClose={forceClose} />}
    </Renderer>
  );
}

export default PopupRenderer;
