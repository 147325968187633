import React, { useState, useEffect, Dispatch } from 'react';

//Components
import { NavLink } from 'react-router-dom';
import MaterialIcon from '../global-components/MaterialIcon'
import UserImage from '../global-components/UserImage'
import { ReactComponent as Logo } from '../../assets/icons/logo_positive.svg'
import { ReactComponent as CloseBtn } from '../../assets/icons/close-btn-nav.svg'
import OutsideClickWrapper from '../global-components/OutsideClickWrapper';


//Styles
import { NavigationWrapper, Container, CompanyInfo, Navigation, NavigationLabel, NavigationSection, NavHeaderWrapp, LogoWrapper, CloseBtnWrapper } from './MainNavigation.styled'

//Redux
import { useAppDispatch, useAppSelector } from '../../state/hooks/hooks'
import { openModal } from '../../state/slices/modalsSlice'


type MainNavigationProps = {
  shouldShow: boolean;
  setMainNavOpen: Dispatch<React.SetStateAction<boolean>>
}

const MainNavigation = ({ shouldShow, setMainNavOpen }: MainNavigationProps) => {

  const { username } = useAppSelector(state => state.user)

  const [hideWrapper, setHideWrapper] = useState(true)

  const isInEditMode = useAppSelector(state => state.appState.isEditModeActive)

  const dispatch = useAppDispatch()

  function changeRouteOrStayInEditMode(
    e: React.MouseEvent<HTMLAnchorElement>,
    path: string): void {
    if (isInEditMode) {
      e.preventDefault()
      dispatch(openModal({ name: 'unsavedChanges', props: { fieldName: 'unsavedChanges', pathTo: path } }))
    }
    if (document.body.classList.contains('no-scroll')) {
      document.body.classList.remove('no-scroll')
    }
  }


  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (shouldShow) {
      setHideWrapper(false)
    } else {
      if (!shouldShow) setTimeout(() => setHideWrapper(true), 300)
    }

    return () => clearTimeout(timeout)
  }, [shouldShow])

  return (
    <NavigationWrapper shouldShow={shouldShow} hideWrapper={hideWrapper}>
      <OutsideClickWrapper handlerFunc={() => setMainNavOpen(false)}>
        <Container shouldShow={shouldShow}>
          <NavHeaderWrapp>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
            <CloseBtnWrapper tabIndex={0} onKeyDown={(e) => {
              if (e.key === 'Enter' || e.keyCode === 13) {
                setMainNavOpen(false)
              }
            }}>
              <CloseBtn onClick={() => setMainNavOpen(false)} />
            </CloseBtnWrapper>
          </NavHeaderWrapp>
          <CompanyInfo>
            <UserImage hasBorder={true} size="medium" />
            <span className="company-name">{username}</span>
          </CompanyInfo>

          <Navigation>
            <NavigationSection>
              <NavigationLabel>Dashboard</NavigationLabel>
              <NavLink onClick={(e) => changeRouteOrStayInEditMode(e, '/dashboard')} exact to='/dashboard'>
                <MaterialIcon iconName="dashboard" /><span>Overview</span>
              </NavLink>
              <NavLink onClick={(e) => changeRouteOrStayInEditMode(e, '/dashboard/top-news')} to='/dashboard/top-news'>
                <MaterialIcon iconName="grade" /><span>Top News</span>
              </NavLink>
              <NavLink onClick={(e) => changeRouteOrStayInEditMode(e, '/dashboard/news-search')} to='/dashboard/news-search'>
                <MaterialIcon iconName="search" /><span>News Search</span>
              </NavLink>
              <NavLink onClick={(e) => changeRouteOrStayInEditMode(e, '/dashboard/social-alerts')} to='/dashboard/social-alerts'>
                <MaterialIcon iconName="notifications_active" /><span>Social Alerts</span>
              </NavLink>
            </NavigationSection>
            <NavigationSection>
              <NavigationLabel>Docs</NavigationLabel>
              <NavLink onClick={(e) => changeRouteOrStayInEditMode(e, '/reports')} to='/reports'>
                <MaterialIcon iconName="text_snippet" /><span>Reports</span>
              </NavLink>
              <NavLink onClick={(e) => changeRouteOrStayInEditMode(e, '/invoices')} to='/invoices'>
                <MaterialIcon iconName="receipt_long" /><span>Invoices</span>
              </NavLink>
            </NavigationSection>
            <NavigationSection>
              <NavigationLabel>Preferences</NavigationLabel>
              <NavLink onClick={(e) => changeRouteOrStayInEditMode(e, '/settings')} to='/settings'>
                <MaterialIcon iconName="settings" /><span>Settings</span>
              </NavLink>
            </NavigationSection>
          </Navigation>
        </Container>

      </OutsideClickWrapper>
    </NavigationWrapper>
  );
}

export default MainNavigation;
