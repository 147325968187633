import React, { useEffect } from 'react'

/**
 * Hook that calls handler function on clicks outside of the passed ref
 */
export function useOnClickOutside(ref: React.RefObject<HTMLElement>, handlerFunc: () => void, enabled: boolean, refsToIgnore: React.RefObject<HTMLElement>[] = []) {
  useEffect(() => {
    /**
     * Call handler if clicked outside of element
     */

    const isInArray = (arr: React.RefObject<HTMLElement>[], element: EventTarget | null) =>  {
      return arr.some((item: React.RefObject<HTMLElement>) => item.current === element)
    }

    function handleClickOutside(event: MouseEvent | TouchEvent): void {
      if (enabled && ref.current && !ref.current.contains(event.target as Node)) {
        if(refsToIgnore.length > 0) {
          if(!isInArray(refsToIgnore, event.target)) {
            handlerFunc()
          }
        } else {
          handlerFunc()
        }
      }
    }

    // Bind the event listeners
    document.addEventListener("mousedown", handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)

    return () => {
      // Unbind the event listeners on clean up
      document.removeEventListener("mousedown", handleClickOutside)
      document.removeEventListener("touchstart", handleClickOutside)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, enabled, handlerFunc]);
}
