import React from 'react'
import SingleNews from '../dashboard/SingleNews'
import { LatestNewsFetchParams } from '../../state/query/types/LatestNewsTypes'
import Spinner from '../global-components/ButtonSpinner'
import { ReactComponent as NoResultImage } from '../../assets/icons/no-results.svg'
import { ReactComponent as ConnectionLostImage } from '../../assets/icons/no-internet.svg'
import { LoaderAndMessageWrapper } from './LatestNewsList.styled'
import { SingleNewsData } from '../../state/query/types/LatestNewsTypes'
import { DefaultButton } from '../global-components/Button.styled'
import { ThemeContext } from 'styled-components';


interface LatestNewsListProps {
  data: SingleNewsData[] | undefined
  isFetching: boolean
  searchParams: LatestNewsFetchParams
  noInternetConnection: boolean
  refetchAfterConnectionLost: () => void
  error: string | number;
}

const LatestNewsList = ({ data, isFetching, searchParams, noInternetConnection, refetchAfterConnectionLost, error }: LatestNewsListProps) => {
  const theme = React.useContext(ThemeContext)

  if (noInternetConnection) {
    return (
      <LoaderAndMessageWrapper type="no-results">
        <ConnectionLostImage />
        <h3>
          {
            error === 'FETCH_ERROR'
              ? 'You are offline!'
              : 'Server not responding!'
          }
        </h3>
        <p>
          {
            error === 'FETCH_ERROR'
              ? 'Your network is unavailable. Check your data or wifi connection.'
              : 'We are having difficulty connecting to the server. Please try again later.'
          }
        </p>
        <DefaultButton bgColor={theme.colors.accent} size={"small"} onClick={refetchAfterConnectionLost}>Retry</DefaultButton>
      </LoaderAndMessageWrapper>
    )
  } else {
    return (
      <div>
        {!isFetching && data && data.length > 0 ? data.map((item: SingleNewsData) => {
          return <SingleNews
            key={item.id}
            full_url={item.full_url}
            media_logo={item.media_logo}
            title={item.title}
            full_text={item.full_text}
            alert_status={item.alert_status}
            tags_custom={item.tags_custom}
            tags_company={item.tags_company}
            mentions={item.mentions}
            id={item.id}
            newsKey={item.current_news_key}
            alert_id={item.alert_id}
            created_at={item.created_at}
            sentiment={item.sentiment}
            total_engagement={item.total_engagement}
            social_engagement={item.social_engagement}
            rank_number={item.rank_number}
            rank={item.rank}
            rank_trend={item.rank_trend}
            searchParams={searchParams}
            full_text_raw={item.full_text_raw}
            seconds_in_top_ten={item.seconds_in_top_ten}
            article_distribution={item.article_distribution}
            top_ten_rank={item.top_ten_rank}
            time_in_top_ten={item.time_in_top_ten}
            published_date={item.published_date}
          />
        }) : isFetching ? (
          <LoaderAndMessageWrapper type="loader">
            <Spinner size={40} color={theme.colors.accent} />
          </LoaderAndMessageWrapper>
        ) : (
          <LoaderAndMessageWrapper type="no-results">
            <NoResultImage />
            <h3>Nothing is matching your search</h3>
            <p>Please try again with a different term</p>
          </LoaderAndMessageWrapper>
        )
        }
      </div>
    )
  }
}
/**
 * LatestNewsList is a component in charge of mapping through an array of single news and displaying it on the frontend
 */
// wrapping LatestNewsList in React.memo
// to prevent re-render unless props are chaged
export default React.memo(LatestNewsList)