import React, { useState, useEffect, useContext } from 'react'
import { ComposedChart, Area, Line, ResponsiveContainer, Tooltip } from 'recharts'
import { nanoid } from '@reduxjs/toolkit'
import { ThemeContext } from 'styled-components'

// Components
import GeneralTooltipCaller from '../global-components/tooltips/GeneralTooltipCaller'
import WeekChartTooltip from './WeekChartTooltip'

// Utils 
import { getHoursDifference, getDataPointIndex } from '../../utils'

// Styles 
import { ChartWrapper, NoDataChartWrapper } from './SimpleWeekChart.styled'


interface Engagement {
  new: number | null;
  total: number | null;
}

interface TooltipData {
  total: number | null,
  new: number | null,
  publishedAt: string,
  pointIndex: number
}

interface PointData {
  engagement: number | null;
  area: number | null;
  total: number | null;
  point: number;
  published: string;
}

/**
 * NoDataTooltip component  
 *
 * @returns JSX
 */
export const NoDataChart = ({ isInSingleArticle }: { isInSingleArticle?: boolean }) => {
  return (
    <NoDataChartWrapper isInSingleArticle={isInSingleArticle}>
      <GeneralTooltipCaller name="basic" content="No data available yet" isInNoDataChart={true}>
        <svg viewBox="0 0 300 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10.4267L302 10V29.9677L0 30V10.4267Z" fill="url(#paint0_linear_512_193026)" />
          <path opacity="0.8" d="M0 10H301" stroke="#4AC380" />
          <defs>
            <linearGradient id="paint0_linear_512_193026" x1="151" y1="10" x2="151" y2="30" gradientUnits="userSpaceOnUse">
              <stop stop-color="#4AC380" stop-opacity="0.1" />
              <stop offset="1" stop-color="#4AC380" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </GeneralTooltipCaller>
    </NoDataChartWrapper>
  )
}


/**
 * CustomizedDot component
 */
export const CustomizedDot = (props: any) => {
  const { colors } = useContext(ThemeContext)
  const { cx, cy } = props;

  return (
    <svg x={cx - 1} y={cy - 1} width={20} height={20} fill={colors.green} viewBox="0 0 1024 1024">
      <circle cx="50" cy="50" r="40" stroke={colors.accent} strokeWidth="3" fill={colors.green} />
    </svg>
  );
};


/**
 * 
 * ActiveDot component
 * Renders active dot and sets custom tooltip state on mount/unmount lifecycle events
 */
const ActiveDot = (props: any) => {
  const { colors } = useContext(ThemeContext)
  const { cx, cy, payload, onChartMouseOver, onChartMouseLeave } = props;

  useEffect(() => {
    onChartMouseOver({ cx, cy, payload })
    return () => onChartMouseLeave()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <svg x={cx - 11} y={cy - 11} width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <circle fill={colors.green} opacity="0.100000001" cx="11" cy="11" r="11"></circle>
        <g transform="translate(7.000000, 7.000000)">
          <circle fill={colors.white} cx="4" cy="4" r="4"></circle>
          <circle fill={colors.green} cx="4" cy="4" r="2"></circle>
        </g>
      </g>
    </svg>
  );
};


/**
 * SimpleWeekChart is a component that displays week charts. It receiving 2 props:
 *
 *  - published prop which represents date converted to a string
 *  - data prop is an array of objects/interfaces that contains two properties 'new' and 'total' and values must be a number. 
 */
export default function SimpleWeekChart({ data, published, floatingTooltip }: { data: Engagement[], published: string, floatingTooltip?: boolean }) {
  const { colors } = useContext(ThemeContext)
  const [chartData, setChartData] = useState<PointData[]>([]);

  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPos, setTooltipPos] = useState({
    top: 0,
    left: 0
  });

  const [tooltipData, setTooltipData] = useState<TooltipData>({
    total: 0,
    new: 0,
    publishedAt: "",
    pointIndex: 0
  })

  const formatDate = (date: string, index: number): string => {
    interface dateOptions {
      weekday: 'short',
      month: 'long',
      day: 'numeric'
    }
    const options: dateOptions = { weekday: "short", month: 'long', day: 'numeric' }

    // Safari shows an invalid date(problem with the format "YYYY-MM-DD HH:mm:ss" and we replaced it with "YYYY/MM/DD HH:mm:ss").
    let d = new Date(date.replace(/-/g, '/'))

    if (index > 0 && index < 6) {
      d.setHours(d.getHours() + index)
    } else if (index === 6) {
      d.setDate(d.getDate() + 1)
    } else if (index === 7) {
      d.setDate(d.getDate() + 7)
    }

    const formatToTwoDigits = (val: number): string => val < 10 ? `0${val}` : `${val}`


    const hours = d.getHours()
    const minutes = d.getMinutes()
    const year = d.getFullYear()

    // passing 'is' locale instead of 'en' would work in Firefox and Safari but not in Chrome
    // once dual language is implemented this return statement would need to be either polyfilled or substituted with custom function
    return `${d.toLocaleDateString("en", options)} ${year} - ${formatToTwoDigits(hours)}:${formatToTwoDigits(minutes)}`
  }

  /**
   * Sets tooltip states on chart mouse over
   */
  const handleMouseOver = (obj: { cx: number, cy: number, payload: PointData }): void => {
    let x = Math.round(obj.cx);
    let y = Math.round(obj.cy);

    setTooltipPos({ top: y, left: x })
    setTooltipData({
      total: obj.payload.total,
      new: obj.payload.engagement,
      publishedAt: formatDate(obj.payload.published, obj.payload.point),
      pointIndex: obj.payload.point
    })
    setShowTooltip(true)
  };

  /**
   * Hide tooltip on chart mouse leave
   */
  const handleMouseLeave = () => {
    setShowTooltip(false)
  };

  useEffect(() => {
    const prepareChartData = (data: Engagement[]): PointData[] => {
      const currentDate = new Date()
      const publishedDate = new Date(published)
      const hoursPassedSincePublished = getHoursDifference(currentDate, publishedDate);
      const pointDataIndex = getDataPointIndex(hoursPassedSincePublished);

      const preparedData = [{
        engagement: 0,
        area: 0,
        total: 0,
        point: 0,
        published: published,
      } as PointData]

      // Initializing with 0 to keep track of the last known non-zero total value
      // Handling cases where backend data might occasionally return inconsistent totals(which may reset to 0)
      // By keeping track of the last known non-zero total, we can ensure that the total doesn't "drop"
      let lastTotal = 0

      data.forEach((item, index) => {
        if (index > pointDataIndex) return;

        if (item.total !== null) {
          // Updating the lastTotal if the current item's total is non-zero
          if (item.total !== 0) lastTotal = item.total
          preparedData.push({
            engagement: index === 0 ? item.total : item.new,
            area: index === 0 ? item.total : item.new,
            total: lastTotal,
            point: index + 1,
            published
          })
        }
      })

      return preparedData
    }

    setChartData(prepareChartData(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])




  if (chartData.length > 1) {
    const id = nanoid()

    return (
      <ChartWrapper floatingTooltip={floatingTooltip}>

        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={chartData} margin={{ top: 11, right: 15, bottom: 11, left: 15 }} >
            <defs>
              <linearGradient id={`${id}`} x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={colors.green} stopOpacity={0.2} />
                <stop offset="95%" stopColor={colors.green} stopOpacity={0.02} />
              </linearGradient>
            </defs>
            {/* By default, reChart positions tooltip relative to mouse cursor or relative to chart container, it can't be positioned relative to chart dot.
                To overcome that problem we are calling Tooltip component here only to enable active dot on Line chart and we are hiding default tooltip with inline css.
                Further on, since active dot can be react component that has acces to chart data via props we are using it's mount/unmount lifecycle methods 
                to control rendering, positioning and data of our custom tooltip.
            */}

            {/* Since we are using this chart on SingleArticle page now, we need to swithch from absolutly positioned tooltip to floating tooltip so it can fit the screen.
              This works for now but in case we need further customisation, we should rethink the tooltip logic and probably implement something like we did with GeneralTooltipCaller and TooltipRenderer.
            */}

            {floatingTooltip && window.innerWidth < 1460 ? <Tooltip content={<WeekChartTooltip data={tooltipData} posTop={tooltipPos.top} posLeft={tooltipPos.left} isFloating={true} />} /> : <Tooltip wrapperStyle={{ display: 'none' }} cursor={false} />}

            <Line
              type='linear'
              dataKey="engagement"
              isAnimationActive={false}
              dot={<CustomizedDot />}
              stroke={colors.green}
              fill={colors.green}
              fillOpacity={1}
              activeDot={<ActiveDot onChartMouseOver={handleMouseOver} onChartMouseLeave={handleMouseLeave} />}
            />
            <Area dataKey="area" isAnimationActive={false} fill={`url(#${id})`} fillOpacity={1} stroke="" dot={false} />
          </ComposedChart>
        </ResponsiveContainer>


        {floatingTooltip ? (
          window.innerWidth < 1460 ? null : showTooltip && <WeekChartTooltip data={tooltipData} posTop={tooltipPos.top} posLeft={tooltipPos.left} />
        ) : (
          showTooltip && <WeekChartTooltip data={tooltipData} posTop={tooltipPos.top} posLeft={tooltipPos.left} />
        )}
      </ChartWrapper>
    )
  }

  return null

}
