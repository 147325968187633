import { Dispatch } from "@reduxjs/toolkit"
import { showPopup } from '../state/slices/popupSlice'

type event = 'inform' | 'success' | 'warning' | 'error';
type popupType = 'alert' | 'info' | 'additionalInfo';
type direction = 'top' | 'bottom' | 'left' | 'right';

export function showErrorOrSuccessPopup(
  dispatch: Dispatch,
  message: string,
  direction: direction,
  event: event,
  popupType: popupType
) {
  return dispatch(showPopup({
    event: event,
    type: popupType,
    direction: direction,
    props: {
      popupTitle: message,
      additionalComponentName: 'NewsCoverageInfo'
    }
  }))
}