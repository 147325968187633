import styled from "styled-components";
import { device } from "../../global-styles/mediaQueries";

export const OverviewTopWrapper = styled.div`
  display: flex;
  flex-flow: column;
  section {
    margin-bottom: 16px;
  }

  @media ${device.tabletL} {
    width: 100%;
    max-width: 100vw;
    margin-bottom: 20px;
    
    display: grid;
    grid-template-columns: 229px calc(100% - 245px);
    grid-template-rows: 148px 480px;
    column-gap: 16px;
    row-gap: 16px;
    grid-template-areas: 
      "engagement  engagement"
      "heatstatus  distribution";
    section {
      margin-bottom: 0px;
    }
  }
  @media ${device.desktopS} {
    max-width: 1100px;
    margin: 0 auto 20px;
    grid-template-columns: 210px calc(100% - 230px);
    grid-template-rows: 148px 470px;
    column-gap: 20px;
  }
  @media ${device.desktop} {
    margin: 0 auto 20px;
    grid-template-columns: 260px calc(100% - 280px);
    grid-template-rows: 148px 490px;
    column-gap: 20px;
  }
`