import styled from 'styled-components'
import { device } from '../../global-styles/mediaQueries'

import { ConfirmButton } from '../global-components/ModalButton'

export const SettingsNavigationComponent = styled.div`
  position: absolute;
  width: calc(100% - 32px);
  left: 16px;
  z-index: 100;
  border-radius: 6px;
  
  @media ${device.mobileL} {
    left: 24px;
    width: calc(100% - 48px);
  }
  @media ${device.tablet} {
    position: relative;
    left: 0;
    width: 100%;
    border-radius: 6px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderPrimary};
  }
  @media ${device.desktopS} {
    max-width: 860px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: ${({ theme }) => theme.colors.white};
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  @media ${device.desktopS} {
    max-width: 1100px;
  }
`

export const SettingsMainContainer = styled.div`
  padding: 76px 16px 16px;

  @media ${device.mobileL} {
    padding: 80px 24px 20px;
  }
  @media ${device.tablet} {
    padding: 80px 24px 30px;
  }
  @media ${device.desktopS} {
    margin: 0 auto;
    max-width: 1100px;
    padding: 80px 20px 30px;
  }
  @media ${device.desktop} {
    padding: 80px 0 40px;
  }
  @media ${device.desktopL} {
    padding: 80px 0 60px;
  }
`
export const SettingsMainWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  
  @media ${device.desktopS} {
    padding-left: 240px;
  }
`

export const SettingsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  h3 {
    width: 50%;
    margin: 0;
  }
  @media ${device.mobileL} {
    margin-bottom: 20px;
  }
  @media ${device.desktopS} {
    max-width: 1100px;
  }
`

export const SettingsComponentWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.highlight};
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px;
`

export const SectionTitle = styled.p`
  margin-bottom: 30px;
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primary};

  @media ${device.tablet} {
    width: 160px;
    margin-right: 36px;
  }
  @media ${device.tabletL} {
    width: 200px;
    margin-right: 56px;
  }
  @media ${device.desktop} {
    width: 200px;
    margin-right: 96px;
  }
`

export const SettingsSectionWrapper = styled.div<{ isInEditMode?: boolean, hasBorderRadius?: boolean, hasBoxShadow?: boolean }>`
  position: relative;
  padding: 16px 16px 40px;
  background-color: ${({ isInEditMode, theme }) => isInEditMode ? theme.colors.highlight_500 : theme.colors.white};
  border-bottom-left-radius: ${({ hasBorderRadius }) => hasBorderRadius ? '6px' : 'none'};
  border-bottom-right-radius: ${({ hasBorderRadius }) => hasBorderRadius ? '6px' : 'none'};
  box-shadow: ${({ hasBoxShadow }) => hasBoxShadow ? '0 5px 5px -4px rgba(49, 65, 97, 0.08);' : 'none'};
  &:after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 16px;
    width: calc(100% - 32px);
    height: 1px;
    background-color: ${({ theme }) => theme.colors.borderPrimary};
  }
  :last-of-type {
    &:after {
      display: none;
    }
  }
  @media ${device.mobileL} {
    padding: 20px  24px 40px;
  }
  @media ${device.tablet} {
    display: flex;
    padding: 50px 34px;
    &:after {
      width: calc(100% - 68px);
      left: 34px;
    }

  }
`

export const SettingsConfirmButton = styled(ConfirmButton) <{ disabledPointerOnLoad: boolean, width: string }>`
  pointer-events: ${({ disabledPointerOnLoad }) => disabledPointerOnLoad ? 'none' : 'auto'};
  min-width: ${({ width }) => width};
`

export const PasswordResetInputWrapper = styled.div<{ isFirstInput?: boolean }>`
  display: flex;
  flex-flow: column;
  margin-bottom: ${({ isFirstInput }) => isFirstInput ? "60px" : "24px"};
`

export const PasswordResetButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 6px;
`