import styled, { css } from 'styled-components'
import { addOpacityToHex } from '../../utils'
import { device } from '../../global-styles/mediaQueries'
import { globalFocusStyles } from '../../global-styles/globalStyles'

import {
  Info,
  Sentiment
} from '../dashboard/SingleNews.styled'



export const SocialStats = styled.div`
  position: relative;
  margin-bottom: 70px;
  padding: 20px 16px 28px;
  border-radius: 6px;
  box-shadow: 0 5px 5px -4px rgba(49, 65, 97, 0.08);
  background-color: ${({ theme }) => theme.colors.white};

  @media ${device.mobileL} {
    padding: 28px 20px;
  }

  @media ${device.tablet} {
    padding: 28px 24px 50px;
  }

  @media ${device.tabletL} {
    grid-column: 1/2;
    grid-row: 2/3;
    flex-direction: column;
    height: fit-content;
  }
`

export const SingleArticleWrapper = styled.div`
  @media ${device.tabletL} {
    display: grid;
    grid-template-columns: 326px 20px 1fr;
    grid-template-rows: auto auto;
  }
`

export const EngagementWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: flex-start;
  }

  @media ${device.tabletL} {
    flex-direction: column;
  }
`

export const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 76px;

  @media ${device.tabletL} {
    grid-column: 1/4;
    grid-row: 1/2;
  }
`

export const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  min-width: 0;
`

export const BackButton = styled.button`
  display: flex;
  width: 32px;
  height: 32px;
  margin: 0 14px 0 0;
  padding: 7px;
  border-radius: 4px;
  box-shadow: 0 5px 5px -4px rgba(49, 65, 97, 0.08);
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  outline: none;
  cursor: pointer;

  // Custom focus styles
  ${globalFocusStyles}
`

export const Engagement = styled.div`
 display: flex;
 margin-bottom: 30px;

  @media ${device.tablet} {
    margin-right: 92px;
    margin-bottom: 0;
  }
  @media ${device.tabletL} {
    margin-bottom: 30px;
  }
`

export const ChartWrapper = styled.div<{ showChart: boolean }>`
  position: relative;
  display: ${({ showChart }) => showChart ? 'block' : 'none'};
  height: 85px; 
  width: 100%;
  z-index: 2;

  @media ${device.tablet} {
    display: block;
    max-width: 300px;
  }
`

export const StatIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 68px;
  width: 68px;
  margin-right: 24px;
  background-color: ${({ theme }) => addOpacityToHex(theme.colors.green, 0.1)};
  border-radius: 100%;
`

export const StatInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

export const StatTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primary};
`

export const StatInfoValue = styled.span`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.extraDarkBlue};
`

export const SocialMedia = styled.div`
  position: relative;
  margin: 30px 0 16px;
  padding: 24px 12px 18px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.highlight};
`

export const TitleWithIcon = styled.span`
 display: flex;
 justify-content: flex-start;
 align-items: center;
 margin-bottom: 24px;

 svg {
   margin-right: 10px;
 }

`

export const ArticleDistribution = styled.div`
  padding-bottom: 24px;
  
`

export const ArticleDistributionInfo = styled.div`
 display: flex;
 justify-content: space-between;
 align-items: center;
 margin-bottom: 12px;
`

export const StatSubTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.extraDarkBlue};
`

export const StatValue = styled.div`
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: right;
  color: ${({ theme }) => theme.colors.primary};

  span {
    font-size: 12px;
    font-weight: normal;
    line-height: 1.33;
    color: ${({ theme }) => theme.colors.secondary};
  }
`

export const DistributionBarChart = styled.div<{ distribution: number }>`
  position: relative;
  height: 6px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.white};
  border: solid 1px ${({ theme }) => theme.colors.borderPrimary};
  overflow: hidden;

  &::before {
    display: block;
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: ${({ distribution }) => 100 - distribution}%;
    background-color: ${({ theme }) => theme.colors.accent};
  }
`
export const WhiteBox = styled.div`
  margin: 12px 0 0;
  padding: 15px 14px;
  border-radius: 6px;
  border: solid 1px ${({ theme }) => theme.colors.borderPrimary};
  background-color: ${({ theme }) => theme.colors.white};
`

export const WhiteBoxRow = styled.div<{ hasSeparator?: boolean, isFirst?: boolean, isLast?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  ${({ hasSeparator }) => hasSeparator && css`
    border-bottom: solid 1px ${({ theme }) => theme.colors.borderPrimary};
  `}
  ${({ isFirst }) => isFirst && css`
    padding-top: 5px;
  `}
  ${({ isLast }) => isLast && css`
    padding-bottom: 5px;
  `}
`

export const MostRead = styled.div`
  position: relative;
  padding: 24px 12px 18px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.highlight};
`

export const ShowMore = styled.button`
  position: absolute;
  bottom: -42px;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
  border: none;
  outline: none;

  @media ${device.tabletL} {
    display: none;
  }
`

export const ShowMoreArrow = styled.span<{ open: boolean }>`
  position: absolute;
  top: 0;
  right: -10px;
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  ${({ open }) => open ? css`
    top: 8px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  ` : css`
  top: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  `
  }
`

export const ArticleContentWrapper = styled.div`
  position: relative;
  height: fit-content;
  border-radius: 6px;
  box-shadow: 0 5px 5px -4px rgba(49, 65, 97, 0.08);
  background-color: ${({ theme }) => theme.colors.white};

  @media ${device.tabletL} {
    grid-column: 3/4;
    grid-row: 2/3;
  }
  
`

export const ArticleContent = styled.div`
  padding: 16px 16px 40px;

  @media ${device.mobileL} {
    padding: 20px 20px 40px;
  }

  @media ${device.tablet} {
    padding: 26px 46px 52px;

  }

`

export const ArticleTitle = styled.h1`
  margin: 16px 0 14px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primary};
`

export const ArticleTitleLink = styled.a`
  position: relative;
  display: inline-block;
  height: 18px;
  width: 18px;

  svg {
    position: absolute;
    top: 2px;
    left: 0px;

    &:hover {
      fill: ${({ theme }) => theme.colors.accent};
    }
  }
`

export const ArticleText = styled.div`
  padding-bottom: 30px;
  border-bottom: solid 1px #e5eaf1;

  p {
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: ${({ theme }) => theme.colors.secondary};

    &:last-child {
      margin: 0;
    }
  }

  @media ${device.mobileL} {
    padding-bottom: 40px;
  }
  @media ${device.tablet} {
    padding-bottom: 50px;
  }
`

export const NotificationIcon = styled.div`
    position: absolute;
    top: 27px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    width: 22px;
    background-color: ${({ theme }) => theme.colors.accent};
    border-radius: 4px;
    z-index: 1;

    @media ${device.tablet} {
      top: 30px;
      right: 46px;
    }
`

export const SpinnerWrapper = styled.div<{ location: 'stats' | 'content' }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: ${({ location }) => location === 'stats' ? '68px' : '400px'};

  @media ${device.tabletL} {
    min-height: 300px;
  }
`

export const StatsWrapper = styled.div<{ showStats: boolean }>`
  display: ${({ showStats }) => showStats ? 'block' : 'none'};

  @media ${device.tabletL} {
    display: block;
  }
`

export const StatTooltipWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 22px;
`

export const TagsAndSentiment = styled.div`
  @media ${device.tablet} {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
`
export const SingleArticleInfo = styled(Info)`
  span {
    max-width: unset;
  }
`

export const NewsInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  a {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary}
  }
`

export const SingleArticleSentiment = styled(Sentiment)`
  position: relative;
  width: fit-content;
  margin-bottom: 0px;

  @media ${device.tablet} {
    margin-top: 24px;
  }

  @media ${device.desktop} {
    margin-top: 0px;
  }
  
`

export const PageLoader = styled.div`
  position: absolute;
  top: 0;
  left:0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  @media ${device.desktopS} {
    left: 240px;
    width: calc(100% - 240px);
  }
`

export const NarrowWrapper = styled.div`
  padding: 0 12px 0;
`


export const SentimentTooltipWrapper = styled.div`
  position: absolute;
  top: -2px;
  right: -2px;


  @media ${device.tabletL} {
    top: 13px;
  }

`

export const BreadCrumbs = styled.span`
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.primary};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const BreadCrumbsWrapper = styled.div`
  display: flex;
  flex: 1 1 100%;
  min-width: 0;
  max-width: 100%;
  margin-right: 14px;
`