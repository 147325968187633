import styled from 'styled-components'
import { device } from '../../global-styles/mediaQueries'
import { globalFocusStyles } from '../../global-styles/globalStyles';

export const AdvancedSearchSelect = styled.div`
  display: inline-block;
  width: auto;
  cursor: pointer;

  @media ${device.tablet} {
    position: relative;
  }
`;

export const AdvancedSearchHeader = styled.div<{ isActive: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  height: 28px;
  width: 100%;
  padding: 7px 12px 5px 9px;
  font-family: ${({ theme }) => theme.fonts};
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  color: ${({ isActive, theme }) => isActive ? theme.colors.primary : theme.colors.secondary};
  background-color: ${({ isActive, theme }) => isActive ? theme.colors.highlight : theme.colors.white};
  border: ${({ isActive, theme }) => `1px solid ${isActive ? 'transparent' : theme.colors.borderSecondary}`};
  border-radius: 4px;
  outline: none;

  span { 
    margin-left: 5px;
    line-height: 1.29;
    color: ${({ isActive, theme }) => isActive ? theme.colors.primary : theme.colors.secondary};

    font-size: 12px;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
  }

  // Custom focus styles
  ${globalFocusStyles}

  &:hover {
    border-color: transparent;
    background-color: ${({ theme }) => theme.colors.highlight};

    span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  @media ${device.tablet} {
    padding: 7px 12px 5px 9px;

    span {
      display: block;
      font-size: 12px;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: right;
    }
  }
`;

export const DropdownContainer = styled.div`
  position: absolute;
  top: 82px;
  left: 0;
  right: 0;
  width: auto;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 4px 16px -2px rgba(49, 65, 97, 0.15);
  border: solid 1px #e5eaf1;
  background-color: #fff;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: auto;
  z-index: 1;

  h4 {
    margin-top: 0;
  }

  @media ${device.mobileL} {
    top: 90px;
  }

  @media ${device.tablet} {
    left: unset;
    top: 35px;
    right: 0;
    bottom: unset;
    width: 412px;
  }
`