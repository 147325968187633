import styled from 'styled-components'
import { globalFocusStyles } from '../../global-styles/globalStyles';

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`

export const ItemsInfo = styled.div`
  span { 
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
`

export const PageControls = styled.div`
  display: flex;
  align-items: center;
`

export const PageInfo = styled.div`
  margin-right: 18px;

  input {
    height: 32px;
    width: 43px;
    margin-right: 8px;
    text-align: center;
    border: 1px solid ${({ theme }) => theme.colors.borderSecondary};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 13px;
    font-weight: bold;
    outline: none;

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.accent};
    }

    &:focus {
      border: 2px solid ${({ theme }) => theme.colors.accent};
    }
  }

  span { 
    color: ${({ theme }) => theme.colors.primary};
    font-size: 13px;
    font-weight: bold;

  }
`

export const ControlButtons = styled.div`
  display: flex;
  button {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    margin-right: 5px;
    border-radius: 4px;
    border: none;
    outline: none;
    background-color: ${({ theme }) => theme.colors.accent};
    cursor: pointer;
    z-index: 2;

    // Custom focus styles
    ${globalFocusStyles}

    &:last-of-type {
      margin-right: 0;
    }

    div {
      :after {
        border-bottom: 2px solid ${({ theme }) => theme.colors.white};
        border-right: 2px solid ${({ theme }) => theme.colors.white};
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.accent_900};
    }
    &:disabled {
      background-color: ${({ theme }) => theme.colors.default_200};
      cursor: default;

      div {
        :after {
          border-bottom: 2px solid ${({ theme }) => theme.colors.default_600};
          border-right: 2px solid ${({ theme }) => theme.colors.default_600};
        }
      }
    }
  }
`

export const ControlButtonDirection = styled.div<{ isRight?: boolean }>`
  position: relative;
  width: 18px;
  height: 18px;

  :after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    transform: ${({ isRight }) => isRight ? 'translate(-5px, -4px)' : 'translate(-3px, -4px)'} ${({ isRight }) => isRight ? 'rotate(-45deg)' : 'rotate(135deg)'};
    content: "";
  }
`