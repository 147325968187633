import styled from 'styled-components'

const setEventColor = (eventType: string, theme: any) => {
  switch (eventType) {
    case 'inform':
      return theme.colors.accent
    case 'success':
      return theme.colors.green
    case 'warning':
      return theme.colors.neutral
    case 'error':
      return theme.colors.redAlert
  }
}

const setTranslatePosition = (direction: "top" | "bottom" | "left" | "right" | undefined) => {
  switch (direction) {
    case 'top':
      return 'translateY(calc(100% + 50px))'
    case 'bottom':
      return 'translateY(calc(-100% - 50px))'
    case 'left':
      return 'translateX(calc(100% + 50px))'
    case 'right':
      return 'translateX(calc(-100% - 50px))'
  }
}

export const PopupBox = styled.div<{ eventType: string, shouldShow: boolean, direction: "top" | "bottom" | "left" | "right" | undefined }>`
  position: relative;
  width: auto;
  max-width: 400px;
  padding: 24px 60px 22px 55px;
  background-color: ${({ theme }) => theme.colors.white};
  border-left: ${({ theme, eventType }) => `6px solid ${setEventColor(eventType, theme)}`};
  border-radius: 6px;
  box-shadow: 0 26px 24px 0 rgba(49, 65, 97, 0.12);
  opacity: ${({ shouldShow }) => shouldShow ? 1 : 0};
  transform: ${({ shouldShow, direction }) => shouldShow ? setTranslatePosition(direction) : 'translateY(0)'};
  transition: all .3s ease-in-out;
`

export const Header = styled.div<{ popupType: string }>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  p {
    font-weight: ${({ popupType }) => popupType === 'info' ? 'normal' : 'bold'};
    font-size: 14px;
    line-height: ${({ popupType }) => popupType === 'info' ? 1.71 : 1.29};
    color: ${({ theme, popupType }) => popupType === 'info' ? theme.colors.secondary : theme.colors.primary};

  }

  svg { 
    position: absolute;
    top: ${({popupType}) => popupType === 'info' ? '23px' : '20px'};
    left: 22px;
  }
`

export const CloseButton = styled.button`
  height: 28px;
  width: 28px;
  background: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: 16px;
  right: 16px;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;

  &::before, &::after {
    position: absolute;
    left: 13px;
    top: 8px;
    content: ' ';
    height: 12px;
    width: 2px;
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.highlight};
  }
`

