import React from 'react'
import { ComposedChart, XAxis, YAxis, CartesianGrid, Tooltip, Area, ResponsiveContainer, Line } from 'recharts'
import { ChartWrapper } from './NewsDistribution.styled'
import ChartTooltip from '../global-components/ChartTooltip'

type DistributionChartItem = {
  name: string;
  engagement: number;
  articles: number;
}

interface DistibutionChartData {
  chartData: DistributionChartItem[]
}

export default function NewsDistributionChart({ chartData }: DistibutionChartData) {

  function chartNumberFormater(number: number) {
    if (number > 1000000000) {
      return (number / 1000000000).toString() + 'B';
    } else if (number > 1000000) {
      return (number / 1000000).toString() + 'M';
    } else if (number > 1000) {
      return (number / 1000).toString() + 'K';
    } else {
      return number.toString();
    }
  }
  return (
    <ChartWrapper>

      <ResponsiveContainer width="100%" height="100%">

        <ComposedChart
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#4ac380" stopOpacity={0.1} />
              <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3" vertical={false} />
          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false} // Small - sing next to number 
            tickMargin={10}
            tick={{ fontSize: '12px' }}
          />

          <YAxis
            yAxisId="left"
            axisLine={false} // Hide side line 
            tickLine={false} // Small - sing next to number e
            tickMargin={4}
            tickFormatter={chartNumberFormater}
            tick={{ fontSize: '12px' }}

          />
          <YAxis
            yAxisId="right"
            axisLine={false}
            tickLine={false} // Small - sing next to number 
            orientation="right"
            tickMargin={4} // Margin-bottom
            tickFormatter={chartNumberFormater}
            tick={{ fontSize: '12px' }}

          />

          <Tooltip content={<ChartTooltip isDoubleChart={true} />} />

          <Line
            yAxisId='left'
            type="monotone"
            dataKey="engagement"
            stroke="#4ac380"
            strokeWidth={3}
            dot={false}
            activeDot={{ r: 5, strokeWidth: 2, fill: '#fff', stroke: '#4ac380' }}
          />
          <Line
            yAxisId='right'
            type="monotone"
            dataKey="articles"
            stroke="#0284fe"
            strokeWidth={3}
            dot={false}
            activeDot={{ r: 5, strokeWidth: 2, fill: '#fff', stroke: '#0284fe' }}
          />
          <Area
            yAxisId='right'
            type="monotone"
            dataKey="articles"
            stroke={'#0284fe'}
            activeDot={false}
            strokeWidth={0}
            fillOpacity={1}
            fill="url(#colorUv)"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </ChartWrapper>
  )
}
