import React, { useState, useEffect } from 'react'
import OutsideClickWrapper from './OutsideClickWrapper'
import { v4 as uuidv4 } from 'uuid'
import MaterialIcon from './MaterialIcon'
import DateRangeCalendar from './DateRangeCalendar'
import {
  format
} from 'date-fns';

import { useAppDispatch, useAppSelector } from '../../state/hooks/hooks'
import { openModal } from '../../state/slices/modalsSlice'


import * as Locales from 'date-fns/locale'

import { calculateDayBeforeXDays } from '../../utils'

import {
  DropDownListContainer,
  DropDownList,
} from './CustomDropdown.styled'

import {
  FormSelectContainer,
  SelectFormHeader,
  FormSelectListItems
} from './DateRangeSelect.styled'

export interface DateRange {
  startDate: string | null;
  endDate: string | null;
}

export interface Option {
  name: string;
  text: string;
}

type DateRangeOptionName = 'day' | 'week' | 'month' | 'half-year' | 'year'

interface DateRangeSelectProps {
  handleSelect: (option: DateRange, optionName?: string) => void;
  disabled?: boolean;
  optionName?: string;
  dateRange?: DateRange;
  usedInModal?: boolean;
  type?: 'expiration' | 'date-range' | 'date-range-filter';
  location: string;
  dropdownPosition?: 'left' | 'center' | 'right' | 'fit';
}

export default function DateRangeSelect({
  handleSelect, disabled, optionName, dateRange, usedInModal = false, type, location, dropdownPosition
}: DateRangeSelectProps) {

  const options = [
    {
      name: "anytime",
      text: 'Anytime',
    },
    {
      name: 'day',
      text: 'Past 24 hours',
    },
    {
      name: 'week',
      text: 'Past week',
    },
    {
      name: 'month',
      text: 'Past month',
    },
    {
      name: "half-year",
      text: 'Past 6 months',
    },
    {
      name: "year",
      text: 'Past year',
    },
    {
      name: "custom",
      text: 'Custom...',
    },
  ]

  const dispatch = useAppDispatch()
  const sliceState = useAppSelector(state => {
    if (!usedInModal) {
      return state.dateRange
    }
    return null
  })

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(optionName ? optionName : options[0].text)
  const [showCustomDateModal, setShowCustomDateModal] = useState(false)
  const [customRangeSelected, setCustomRangeSelected] = useState(false)

  const toggling = () => !disabled && setIsOpen(!isOpen);

  const calculateDateBeforeXDaysISO = (xDays: number): string => calculateDayBeforeXDays(xDays).toISOString()

  const getPastDate = (timeAgo: DateRangeOptionName): string => {
    switch (timeAgo) {
      case 'day':
        return calculateDateBeforeXDaysISO(1)
      case 'week':
        return calculateDateBeforeXDaysISO(7)
      case 'month':
        return calculateDateBeforeXDaysISO(30)
      case 'half-year':
        return calculateDateBeforeXDaysISO(180)
      case 'year':
        return calculateDateBeforeXDaysISO(365)
    }
  }

  const onChange = (option: Option) => () => {
    //Set option
    if (option.name === 'anytime') {
      handleSelect({
        startDate: null,
        endDate: null
      }, option.text)
      setSelectedOption(option.text)
      if (customRangeSelected) setCustomRangeSelected(false)
    } else if (option.name === 'custom') {
      if (usedInModal) {
        setShowCustomDateModal(true)
      } else {
        dispatch(openModal({
          name: 'dateRangePicker',
          props: {
            location: location
          }
        }))
      }
      if (!customRangeSelected) setCustomRangeSelected(true)
    } else {
      handleSelect({
        startDate: getPastDate(option.name as DateRangeOptionName),
        endDate: calculateDateBeforeXDaysISO(0)
      }, option.text)
      setSelectedOption(option.text)
      if (customRangeSelected) setCustomRangeSelected(false)
    }
    setIsOpen(false);
  };

  const onSelectCustom = (startDate: Date, endDate: Date) => {
    setSelectedOption(`
    ${format(startDate, 'dd. MMM yyyy', { locale: Locales.is })} - 
    ${format(endDate, 'dd. MMM yyyy', { locale: Locales.is })}
    `)
    handleSelect({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    }, `${format(startDate, 'dd. MMM yyyy', { locale: Locales.is })} - ${format(endDate, 'dd. MMM yyyy', { locale: Locales.is })}`)
    setShowCustomDateModal(false)
  }

  const closeModal = () => setShowCustomDateModal(false)

  const closeOnClickOutside = () => setIsOpen(false)

  useEffect(() => {
    if (!usedInModal) {
      if (sliceState?.location === location && sliceState?.startDate && sliceState?.endDate) {
        const startDate = new Date(JSON.parse(sliceState.startDate))
        const endDate = new Date(JSON.parse(sliceState.endDate))
        onSelectCustom(startDate, endDate)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliceState])

  useEffect(() => {
    if (!optionName) {
      setSelectedOption('Anytime')
    } else {
      setSelectedOption(optionName)
    }
  }, [optionName])

  return (
    <FormSelectContainer type={type}>
      <OutsideClickWrapper handlerFunc={closeOnClickOutside} enabled={isOpen}>
        <SelectFormHeader
          onClick={toggling}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              toggling()
            }
          }}
          isActive={isOpen}
          tabIndex={0}
          disabled={disabled}
          type={type}
        >
          <MaterialIcon iconName="calendar_today" additionalClassName="calendar-icon" />
          <span>{selectedOption || options[0].text}</span>
          <MaterialIcon iconName="arrow_drop_down" additionalClassName="dropdown-icon" />
        </SelectFormHeader>
        {isOpen && (
          <DropDownListContainer
            position={dropdownPosition ? dropdownPosition : 'fit'}
          >
            <DropDownList>
              {options && options.map(option => (
                <FormSelectListItems
                  onClick={onChange(option)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.keyCode === 13) {
                      onChange(option)()
                    }
                  }}
                  key={uuidv4()}
                  isSelected={customRangeSelected && option.name === 'custom' ? true : selectedOption === option.text}
                  type={type}
                  tabIndex={0}
                >
                  {(selectedOption === option.text || (option.name === 'custom' && customRangeSelected)) && <MaterialIcon iconName="done" />}
                  <span>{option.text}</span>
                </FormSelectListItems>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </OutsideClickWrapper>
      {showCustomDateModal && <DateRangeCalendar onSelectCustom={onSelectCustom} closeModal={closeModal} />}

    </FormSelectContainer>
  )
}