import React, { useState, useRef, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { ThemeContext } from 'styled-components';

// Components
import OutsideClickWrapper from '../global-components/OutsideClickWrapper'
import MaterialIcon from '../global-components/MaterialIcon'
import { CancelButton } from '../global-components/ModalButton'
import Spinner from '../global-components/ButtonSpinner'

// Styles
import { CancelSaveComponent } from './SearchTerms.styled'
import { SubtermWrapper, SingleTag, TransparentTextInput, CancelIconWrapper, SubtermsConfirmButton, SubtermsHoverComponent } from '../global-components/SubTermInput.styled'
import { ButtonWrapper } from '../modals/DeleteArticle.styled'

// Utils
import { handleInputSanitize, limitCharacters } from '../../utils'

// Redux
import { showPopup } from '../../state/slices/popupSlice'
import { useEditSearchSubTermsMutation } from '../../state/query/queryApi'
import { updateEditMode, cleanEditMode } from '../../state/slices/appState'
import { useAppDispatch } from '../../state/hooks/hooks'


interface EventCodeExtended extends React.KeyboardEvent<HTMLInputElement> {
  code: string;
}

export type InputStyle = 'dark' | 'light';
export type SubTermInputType = 'email' | 'text'
export type TagStyle = 'primary' | 'secondary' | 'secondary-dark' | 'error';


export interface SubTermInputInterface {
  id: number;
  tags: string[];
  type: SubTermInputType;
  inputName: string;
  inputStyle: InputStyle;
  tagStyle?: TagStyle;
  isDisabled?: boolean;
  placeholder?: string;
  maxLength?: number;
  setShowSavedMessage: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SearchTermsSubTermInput({
  id,
  tags,
  type,
  inputName,
  tagStyle = 'primary',
  inputStyle = 'dark',
  isDisabled = false,
  setShowSavedMessage,
  maxLength = 40,
  placeholder = 'Add item' }: SubTermInputInterface) {

  const [editSearchSubTerms, { isLoading, isSuccess }] = useEditSearchSubTermsMutation()

  const themeContext = React.useContext(ThemeContext)
  const dispatch = useAppDispatch()

  const [inputValue, setInputValue] = useState('')
  const [subtermsTags, setSubtermsTags] = useState(tags)
  const [isBoxOpen, setIsBoxOpen] = useState<boolean>(false)

  const inputField = useRef<HTMLInputElement>(null)
  const startValue = useRef(tags)
  const saveButtonRef = useRef(null)
  const cancelButtonRef = useRef(null)

  function setChangesInEditModeGlobalState(startFieldValue: string, fieldValue: string, fieldName: string) {
    dispatch(updateEditMode({ startFieldValue: startFieldValue, fieldValue: fieldValue, fieldName: fieldName }))
  }

  // Open Cancel/Save box
  const handleBoxOpen = () => {
    setIsBoxOpen(true)
  }

  // On cancel, we return subterms on the initial state and close the save/cancel component
  const handleOnCancelChanges = () => {
    dispatch(cleanEditMode('link'))
    setSubtermsTags(tags)
    setInputValue('')
    setIsBoxOpen(false)
  }

  const updateSubtermsOnSave = (value: string) => {
    return editSearchSubTerms({
      term_id: id,
      subterms: value ? [...subtermsTags, value] : subtermsTags,
    }).unwrap().catch(() => dispatch(showPopup({
      event: 'error',
      type: 'alert',
      direction: 'bottom',
      props: {
        popupTitle: `Adding or removing subterms failed. Check internet connection and try again.`
      }
    })))
  }

  // On save, we update subterms, show a success message, and close the save/cancel component
  const handleOnSaveChanges = (value: string) => {
    if (value.trim().length !== 0 && value !== '') {
      setSubtermsTags((subtermsTags: string[]): string[] => [...subtermsTags, value])
      setInputValue('')
    }
    updateSubtermsOnSave(value)
    dispatch(cleanEditMode('link'))
  }

  function setTagsStat(e: EventCodeExtended) {
    const event = e.target as HTMLTextAreaElement;
    if (event.value.trim().length !== 0) {
      if (e.code === 'Enter' || e.key === 'Enter') {
        setSubtermsTags([...subtermsTags, event.value])
        setInputValue('')
      }
    } else {
      if ((e.code === 'Backspace' || e.key === 'Backspace') && subtermsTags.length > 0) {
        const tagsWithoutLast = [...subtermsTags]
        tagsWithoutLast.pop()
        setSubtermsTags(tagsWithoutLast)
      }
    }
    setChangesInEditModeGlobalState(JSON.stringify(startValue.current), JSON.stringify([...startValue.current, inputValue]), inputName)
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value.includes(',')) {
      if (value.indexOf(',') > 0) {
        setSubtermsTags([...subtermsTags, value.slice(0, value.length - 1)])
        setInputValue('')
      } else {
        handleInputSanitize(e, setInputValue)
      }
    } else {
      handleInputSanitize(e, setInputValue)
    }
  }

  const handleClickOutside = (value: string) => {
    if (value.trim().length !== 0) {
      setSubtermsTags((subtermsTags: string[]): string[] => [...subtermsTags, value])
    }
    setInputValue('')
  }

  const removeTag = (dropIndex: number) => setSubtermsTags((subtermsTags: string[]) => subtermsTags.filter((_: string, index: number) => index !== dropIndex))

  // After a successful response closes the save/cancel box and show a success message
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>

    if (isSuccess) {
      setIsBoxOpen(false)
      setShowSavedMessage(true)

      // Removing success message after 4 sec
      timer = setTimeout(() => {
        setShowSavedMessage(false)
      }, 4000)
    }
    return () => clearTimeout(timer)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  useEffect(() => {
    if (JSON.stringify(tags) !== JSON.stringify(subtermsTags)) {
      setSubtermsTags(tags)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags])

  return (
    <>
      <div style={{ position: 'relative' }}>
        <SubtermWrapper
          tabIndex={0}
          inputStyle={inputStyle}
          tagStyle={tagStyle}
          isDisabled={isDisabled}
          onClick={() => {
            inputField.current && inputField.current.focus()
          }}
        >
          {/* Sinle tags in component */}
          {subtermsTags && subtermsTags.map((tag: string, index: number) =>
            <SingleTag key={uuidv4()} tagStyle={tagStyle} isDisabled={isDisabled} type={type}>
              {limitCharacters(tag, 20)}
              <CancelIconWrapper onClick={() => {
                setChangesInEditModeGlobalState(JSON.stringify(startValue.current), JSON.stringify([...startValue.current, inputValue]), inputName)
                removeTag(index)
              }}>
                <MaterialIcon size={14} iconName='close' color={tagStyle === 'primary' ? themeContext.colors.white : themeContext.colors.primary} />
              </CancelIconWrapper>
              {tag.length > 20 && <SubtermsHoverComponent>{tag}</SubtermsHoverComponent>}
            </SingleTag>)

          }
          {/* Input field */}
          <OutsideClickWrapper
            handlerFunc={() => handleClickOutside(inputValue)}
            enabled={isBoxOpen && inputValue !== ''}
            refsToIgnore={[cancelButtonRef, saveButtonRef]}
          >
            <form onSubmit={e => e.preventDefault()}>
              <TransparentTextInput
                autoComplete="on"
                ref={inputField}
                placeholder={placeholder}
                onChange={handleOnChange}
                maxLength={maxLength}
                value={inputValue}
                onKeyDown={(e: EventCodeExtended): void => setTagsStat(e)}
                name={inputName}
                onFocus={handleBoxOpen}
              />
            </form>
          </OutsideClickWrapper>
        </SubtermWrapper >
      </div>
      {/* Save/Cancel component */}
      <CancelSaveComponent isOpen={isBoxOpen}>
        <ButtonWrapper>
          <CancelButton
            ref={cancelButtonRef}
            isDisabled={isLoading}
            onClick={() => handleOnCancelChanges()}
          >Cancel</CancelButton>
          <SubtermsConfirmButton
            ref={saveButtonRef}
            disabledPointerOnLoad={(inputValue === '' && JSON.stringify(tags) === JSON.stringify(subtermsTags)) || isLoading}
            isDisabled={inputValue === '' && JSON.stringify(tags) === JSON.stringify(subtermsTags) && !isLoading}
            onClick={() => handleOnSaveChanges(inputValue)}
            bgColorActiveState="blue"
          >{isLoading ? <Spinner size={18} /> : 'Save'}</SubtermsConfirmButton>
        </ButtonWrapper>
      </CancelSaveComponent>
    </>
  )
}
