import styled from 'styled-components'
import { centerFlex } from '../../global-styles/mixins'
import { device } from '../../global-styles/mediaQueries'
import { globalFocusStyles } from '../../global-styles/globalStyles'

//Load more button 
export const LoadMore = styled.div`
  height: 64px;
  ${centerFlex};
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.accent};
  p {
    padding-left: 9px;
  }
  p, svg {
    cursor: pointer;
  }
`

//This can be container for all dashboard routes
export const DashboardMainContainer = styled.div`
  padding: 76px 16px 16px;
  min-height: 100vh;
  @media ${device.mobileL} {
    padding: 80px 24px 20px;
  }
  @media ${device.tablet} {
    padding: 80px 24px 30px;
  }
  @media ${device.desktopS} {
    margin: 0 auto;
    max-width: 1100px;
    padding: 80px 20px 30px;
  }
  @media ${device.desktop} {
    padding: 80px 0 40px;
  }
  @media ${device.desktopL} {
    padding: 80px 0 60px;
  }
`
export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  h3 {
    width: 50%;
    margin: 0;
  }
  @media ${device.mobileL} {
    margin-bottom: 20px;
  }
  @media ${device.desktopS} {
    max-width: 1100px;
  }
`

export const SingleSocialMediaCard = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 24px 16px 28px;
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  .see-more-dots {
    border-radius: 4px;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 16px;
    height: 1px;
    width: calc(100% - 32px);
    background-color: ${({ theme }) => theme.colors.borderPrimary};
  }
  &:last-child {
    &:after {
      content: none;
    }
  }
  @media ${device.mobileL} {
    padding: 24px 20px 28px;
  }
  @media ${device.tablet} {
    padding: 28px 98px 32px 24px;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  @media ${device.desktopS} {
    padding: 28px 118px 32px 24px;
  }
  @media ${device.desktop} {
    padding: 28px 120px 32px 24px;
  }
`

export const SingleNewsSearchTableRowWrapper = styled.div`
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  a {
    text-decoration: none;
  }
`

export const ItemRank = styled.div`
  position: relative;
  width: 34px;
  height: 34px;
  margin: 0 26px 0px 0;
  padding: 6px 3px 6px;
  ${centerFlex};
  background-color: ${({ theme }) => theme.colors.highlight};
  border-radius: 50%;
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  svg {
    position: absolute;
    right: -19px;
  }
  @media ${device.tablet} {
    margin-right: 52px;
  }
  @media ${device.tabletL} {
    margin-right: 93px;
  }
  @media ${device.desktopS} {
    margin-right: 63px;
  }
  @media ${device.desktop} {
    margin-right: 113px;
  }
`

export const IconWrapper = styled.div`
  display: flex; 
  align-items: center;
  p {
    margin: 0 0 0 14px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: ${({ theme }) => theme.colors.primary}
  }
  @media ${device.tablet} {
    .hide-tablet {
      display: none;
    }
  }
`

export const IconImageWrapper = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.colors.borderSecondary};
  overflow: hidden;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 32px;
    width: auto;
  }
`

export const ItemContent = styled.div`
  padding-left: 60px;
  margin-top: 14px;
  width: 100%;
  a {
    display: block;
    width: fit-content;
    text-decoration: none;

    // Custom focus styles
    ${globalFocusStyles}

    // Override globalFocusStyles
    &:focus-visible {
      outline: 4px solid ${({ theme }) => theme.colors.white};
    }

    &:hover,
    &:active {
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: underline;
    }
  }
  h5 {
    width: fit-content;
    margin: 0 0 8px 0;
  }
  @media ${device.tablet} {
    width: 344px;
    margin: 0 54px 0 24px;
    padding-left: 0;
  }
  @media ${device.tabletL} {
    width: 444px;
    margin: 0 111px 0 24px;
    padding-left: 0;
  }
  @media ${device.desktopS} {
    width: 444px;
    margin: 0 65px 0 24px;
    padding-left: 0;
  }
  @media ${device.desktop} {
    width: 444px;
    margin: 0 183px 0 24px;
    padding-left: 0;
  }
`

export const DistributionWrapper = styled.div`
  display: none;
  @media ${device.tablet} {
    display: block;
  }
`

export const HeatWrapper = styled.div`
  display: none;
  @media ${device.tablet} {
    display: block;
    padding-top: 14px;
    flex-shrink: 0;
  }
`
// HEADER
export const TopNewsTableHeader = styled.div`
  display: flex;
  padding: 10px 16px;
  background-color: ${({ theme }) => theme.colors.highlight};
  border-top: 1px solid ${({ theme }) => theme.colors.borderPrimary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderPrimary};
  p {
    margin: 0 30px 0 0;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: ${({ theme }) => theme.colors.primary};
  }
  .hide-mobile { 
    display: none;
  }
  @media ${device.mobileL} {
    p {
      margin: 0 35px 0 0;
    }
  }
  @media ${device.tablet} {
    padding: 10px 24px;

    .first {
      margin-right: 111px;
    }
    .second {
      margin-right: 370px;
      span {
        display: none;
      }
    }
    .third {
      margin: 0;
    }
    .hide-mobile {
      display: flex;
      align-items: center;
      svg {
        margin-left: 4px;
      }

    }
  }
  @media ${device.tabletL} {
    .first {
      margin-right: 153px;
    }
    .second {
      margin-right: 522px;
    }
   
  }
  @media ${device.desktopS} {
    .first {
      margin-right: 122px;
    }
    .second {
      margin-right: 460px;
    }
  }
  @media ${device.desktop} {
    .first {
      margin-right: 173px;
    }
    .second {
      margin-right: 582px;
    }
  }
`

export const SocialMediaTableWrapper = styled.div`
  background: white;
  border-radius: 6px;
  box-shadow: 0 5px 5px -4px rgba(49, 65, 97, 0.08);
`

export const DropDownWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 16px;
  @media ${device.mobileL} {
    right: 20px;
  }
  @media ${device.tablet} {
    top: 28px;
    right: 24px;
  }
`

export const ToolTipWrapper = styled.div`
  display: none;
  @media ${device.tablet} {
    display: block;
  }
`