import styled, { css } from 'styled-components'
import { device } from '../../global-styles/mediaQueries'

export const ActiveButtonBox = styled.div<{ isActive: boolean }>`
  display: flex;
  position: absolute;
  right: 0;
  bottom: 11px;
  transition: 0.1s;
  opacity: 1;
  z-index: ${({ isActive }) => isActive ? 10 : 1};
  button {
    margin-left: 6px;
  }
`

export const InactiveButtonBox = styled(ActiveButtonBox) <{ isActive: boolean, isEditModeAllowed: boolean }>`
  display: ${({isEditModeAllowed}) => isEditModeAllowed ? 'flex' : 'none'};
  opacity: 1;
  z-index: ${({ isActive }) => isActive ? 1 : 10};
  svg {
    &:hover {
      fill: ${({ theme: { colors } }) => colors.accent};
    }
  }
  button {
    margin-left: 0px;
  }
  @media ${device.desktopS} {
    opacity: 0;
  }
`

export const TransparentInputField = styled.input<{ isActive: boolean }>`
  border: none;
  width: 80%;
  color: ${({theme}) => theme.colors.primary};
  pointer-events: ${({ isActive }) => isActive ? 'auto' : 'none'};
  background-color: transparent;
  &:focus,
  &:active {
    outline: none;
  }
`

export const LineInputWrapper = styled.div<{ isActive: boolean, isValidEmail: boolean, isLoading: boolean }>`
  position: relative;
  display: flex;
  padding: 16px 0;
  border-bottom: solid 1px ${({ isActive, theme }) => isActive ? theme.colors.accent : theme.colors.borderPrimary};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primary};
  ::placeholder {
    color: ${({ theme: { colors } }) => colors.inputPlaceholderGray};
  }
  ${({ isValidEmail, theme }) => !isValidEmail && css`
    border-bottom: 1px solid ${theme.colors.redAlert};
    margin-bottom: 14px;
  `}
  @media ${device.desktopS} {
    ${InactiveButtonBox} {
      opacity: ${({isLoading}) => isLoading ? 1 : 0};
    }
    &:hover {
      ${InactiveButtonBox} {
        opacity: 1;
      }
    }
  }
`;

export const ButtonSpinnerWrapper = styled.div<{isActiveButton: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-left: ${({isActiveButton}) => isActiveButton ? '6px' : '0px'};
  opacity: 1;
  background-color: ${({theme, isActiveButton}) => isActiveButton ? theme.colors.highlight : theme.colors.white};
  z-index: 200;
`