import React, { useState } from 'react';
import LatestNewsTableWrapper from './LatestNewsTableWrapper'
import LatestNewsTableColumns from './LatestNewsTableColumns'
import NewArticlesInfo from './NewArticlesInfo'

/**
 * LatestNews is the parent component which contains all components for the latest news inside LatestNewsTableWrapper
 * 
 */
const LatestNews = () => {

  const [resetFilters, setResetFilters] = useState(false)

  return (
    <LatestNewsTableWrapper title="Latest News" resetFilters={resetFilters} setResetFilters={setResetFilters}>
      <LatestNewsTableColumns />
      <NewArticlesInfo setResetFilters={setResetFilters} />
    </LatestNewsTableWrapper>
  );
}

export default LatestNews;
