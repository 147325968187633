import React from 'react'
import styled from 'styled-components'

import TopNavBar from '../top-nav-bar/TopNavBar'
import SEO from './SEO'

import { device } from '../../global-styles/mediaQueries'


const StyledLayout = styled.div<{ hasMargin?: boolean, disablePadding?: boolean }>`
  margin-top: ${({ hasMargin }) => hasMargin ? '95px' : '0'};
  @media ${device.tablet} {
    padding: 0px;

  }
  @media ${device.tablet} {
    margin-top: 0;
  }
  @media ${device.desktopS} {
    padding: ${({ disablePadding }) => disablePadding ? '0px' : '0px 0 0 240px'} ;

  }
`;

type LayoutProps = {
  children: React.ReactNode;
  hasMargin?: boolean;
  disablePadding?: boolean;
  title?: string;
}

export default function Layout({ children, hasMargin, disablePadding = false, title }: LayoutProps) {
  return (
    <StyledLayout hasMargin={hasMargin} disablePadding={disablePadding} className="general-layout">
      <SEO title={title ? title : ""} />
      <TopNavBar />
      {children}
    </StyledLayout>
  )
}
