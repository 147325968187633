import React, { useState, useRef, useContext, useEffect } from 'react'
//Components
import MaterialIcon from '../global-components/MaterialIcon';
import OutsideClickWrapper from './OutsideClickWrapper'
//Styles
import { SearchWrapperBox, SmallSearchBox, SearchTransparentInput, CloseIconWrapper, BackIconWrapper } from './SmallSearchInput.styled'
import { ThemeContext } from 'styled-components'
import { handleInputSanitize } from '../../utils'


interface EventCodeExtended extends React.ChangeEvent<HTMLInputElement> {
  code: string;
  key: string;
}

interface SmallSearchInputProps {
  handleSearchAction: (searchTerm: string) => any // Return is void, but I am using it in debounce fn so it requires to return is any
  placeholder?: string
  isActive: boolean
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>
}


//Small input search is used for searching data in the tables
export default function SmallSearchInput({ handleSearchAction, placeholder = 'Search', isActive, setIsActive }: SmallSearchInputProps) {

  const [inputVal, setInputVal] = useState('')

  const inputField = useRef<HTMLInputElement>(null)

  const themeContext = useContext(ThemeContext)

  const deactivateOnClickOutside = () => setIsActive(false)

  function onActiveSearch() {
    setIsActive(true)
    inputField.current && inputField.current.focus()
  }

  useEffect(() => {
    handleSearchAction(inputVal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputVal])

  return (
    <OutsideClickWrapper
      enabled={isActive}
      handlerFunc={deactivateOnClickOutside}
    >
      <SearchWrapperBox>

        <SmallSearchBox
          className="ssb -----"
          onClick={() => onActiveSearch()}
          isActive={isActive}
        >
          <MaterialIcon iconName="search" color={themeContext.colors.secondary} />
          <SearchTransparentInput
            value={inputVal}
            onChange={(e: EventCodeExtended) => {
              handleInputSanitize(e, setInputVal)
            }}
            ref={inputField}
            isActive={isActive}
            placeholder={placeholder}
          />
          <CloseIconWrapper
            isActive={isActive && inputVal !== ''}
            onClick={() => {
              setInputVal('')
            }}
          >
            <MaterialIcon
              color={themeContext.colors.secondary}
              iconName="close"
              additionalClassName="close-icon"
            />
          </CloseIconWrapper>
        </SmallSearchBox>

        <BackIconWrapper
          isActive={isActive}
          onClick={() => setIsActive(false)}
        >
          <MaterialIcon
            iconName="arrow_back"
            color={themeContext.colors.secondary}
            additionalClassName="back-icon"
          />

        </BackIconWrapper>

      </SearchWrapperBox>
    </OutsideClickWrapper>
  )
}
