import styled from 'styled-components'
import { device } from '../../global-styles/mediaQueries'

export const NotificationsMailWrapper = styled.div`
  padding: 16px;
  h5 {
    font-size: 14px;
  }
  @media ${device.mobileL} {
    padding: 24px 20px;
  }
  @media ${device.tablet} {
    display: flex;
    padding: 50px 34px 50px 34px;
  }
`

export const EmailListWrapper = styled.div`
  padding: 20px 0 40px 0;
  @media ${device.tablet} {
    width: 360px;
    padding: 20px 0 0 0;
  }
  @media ${device.tabletL} {
    width: 512px;
  }
  @media ${device.desktopS} {
    width: 410px;
  }
  @media ${device.desktop} {
    width: 512px;
  }
`

export const AddEmailButton = styled.div<{ isDisabled: boolean }>`
  position: relative;
  width: fit-content;
  padding-left: 24px;
  margin-top: 24px;
  font-size: 14px;
  line-height: 1.29;
  color: ${({ theme, isDisabled }) => isDisabled ? theme.colors.secondary : theme.colors.accent};
  cursor: ${({ isDisabled }) => isDisabled ? 'default' : 'pointer'};
  :after {
    position: absolute;
    top: 8px;
    left: 0;
    width: 11px;
    height: 2px;
    background-color: ${({ theme, isDisabled }) => isDisabled ? theme.colors.secondary : theme.colors.accent};
    content: "";
  }
  :before {
    position: absolute;
    top: 8px;
    left: 0;
    width: 11px;
    height: 2px;
    transform: rotate(90deg);
    background-color: ${({ theme, isDisabled }) => isDisabled ? theme.colors.secondary : theme.colors.accent};
    content: "";
  }
  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.accent};
    border-radius: 4px;
  }
  &:active {
    color: ${({ theme }) => theme.colors.accentDark};

    &::after,
    &::before {
      background-color: ${({ theme }) => theme.colors.accentDark};
    }
  }
`