import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'


interface LatestNews {
  lastPublishedNewsKey: number;
}

const initialState: LatestNews = {
  lastPublishedNewsKey: 0,
}

const slice = createSlice({
  name: 'latestNews',
  initialState: initialState,
  reducers: {
    setLastPublishedNewsKey: (state, action: PayloadAction<number>) => {
      state.lastPublishedNewsKey = action.payload
    }
  }
})

export const { setLastPublishedNewsKey } = slice.actions

export const latestNewsReducer = (state: RootState) => state

export default slice.reducer