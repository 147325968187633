import { device } from './../../global-styles/mediaQueries';
import styled, { css } from 'styled-components'
import { addOpacityToHex } from '../../utils'
import { globalFocusStyles } from '../../global-styles/globalStyles';

/** @component */
export const SingleNewsWrapper = styled.div<{ isOpen: boolean, location?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px 16px 28px 68px;
  ${({ location }) => location === 'news-search' && css`
    padding: 24px 16px 28px;
  `}
  height: absolute;
  background-color:  ${({ isOpen, theme }) => isOpen ? addOpacityToHex(theme.colors.highlight, 0.5) : theme.colors.white};

  &:after {
    position: absolute;
    bottom: 0;
    left: 16px;
    display: block;
    content: '';
    width: calc(100% - 32px);
    height: 1px;
    background-color: ${({ theme }) => theme.colors.borderPrimary};

    @media ${device.tablet} {
      left: 24px;
      width: calc(100% - 48px);
    }
  }

  &:last-child {
    &:after {
      display: none;
    }
  }

  @media ${device.mobileL} {
    padding: 24px 20px 28px 68px;
    ${({ location }) => location === 'news-search' && css`
      padding: 24px 20px 28px;
    `}
  }

  @media ${device.tablet} {
    display: grid;
    grid-template-columns: 62px 150px 1fr 66px;
    ${({ location }) => location === 'news-search' && css`
      grid-template-columns: 150px 1fr 66px;
    `}
    grid-template-rows: auto auto auto auto auto auto auto;
    padding: 28px 24px 32px;
  }

  @media ${device.tabletL} {
    grid-template-columns: 62px 186px 524px 88px 1fr;
    ${({ location }) => location === 'news-search' && css`
      grid-template-columns: 186px 1fr 66px;
    `}
    grid-template-rows: auto auto auto auto auto auto;
  }

  @media ${device.desktopS} {
    grid-template-columns: 152px 373px 150px 150px;
    ${({ location }) => location === 'news-search' && css`
      grid-template-columns: 152px 373 180px;
    `}
    grid-template-rows: auto auto auto auto;
    justify-content: space-between;

    input {
        display: flex;
        &:placeholder-shown {
          opacity: 0;
        }
      }

    .dropdown-arrow {
      display: ${({ isOpen }) => isOpen ? 'flex' : 'none'}
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.highlight_500};
      input {
        display: inline-block;
        opacity: 1;
      }
      .dropdown-arrow {
        display: flex;
      }
    }
  }

  @media ${device.desktop} {
    grid-template-columns: 180px 500px 180px 180px;
    justify-content: space-between;
  }
`

export const NotificationIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: auto;
  width: 4px;
  background-color: ${({ theme }) => theme.colors.accent};

  svg { 
    position: absolute;
    top: 2px;
    right: -16px;
    z-index: 2;
  }

  &::before {
    position: absolute;
    display: block;
    content: '';
    height: 22px;
    width: 22px;
    background-color: ${({ theme }) => theme.colors.accent};
    border-radius: 4px;
    z-index: 1;

  }
`

export const DropdownArrow = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 24px;
  left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  background-color: ${({ theme }) => addOpacityToHex(theme.colors.accent, 0.1)};
  border-radius:  100%;
  cursor: pointer;

  // Custom focus styles
  ${globalFocusStyles}
  position: absolute;

  svg {
    transform: ${({ isOpen }) => isOpen ? 'rotate(180deg)' : 'none'};
  }

  @media ${device.tablet} {
    top: unset;
    left: unset;
    grid-column: 1/2;
    grid-row: 1/2;
  } 

  @media ${device.desktopS} {
    display: none;
    position: absolute;
    bottom: 0;
    right: 32px;
    grid-column: unset;
    grid-row: unset;
    height: 24px;
    width: 24px;
    border-radius: 0;
    border: none;
    background-color: ${({ theme }) => theme.colors.accent};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    svg { 
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`


export const Info = styled.div<{ location?: string }>`
  display: flex;
  height: fit-content;
  align-items: center;

  span {
    display: inline-block;
    max-width: 98px;
    font-size: 12px;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.primary};
  }

  span::first-letter {
    text-transform: uppercase;
  }

  @media ${device.tablet} {
    grid-column: 2/3;
    ${({ location }) => location === 'news-search' && css`
      grid-column: unset;
    `}
    grid-row: 1/2;
  } 

  @media ${device.desktopS} {
    grid-column: 1/2;
    height: fit-content;
    width: fit-content;
  }
`
export const NewsSourceIconWrapper = styled.div`
  position: relative;
  height: 32px;
  width: 32px;
  margin-right: 10px;
  border-radius: 100%;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.borderSecondary};
  overflow: hidden;

  img { 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 32px;
    width: auto;
  }
`

export const TextWrapper = styled.div<{ location?: string }>`
  a { 
    display: block;
    width: fit-content;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};

    h3 {
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 1.38;
    }

    // Custom focus styles
    ${globalFocusStyles}

    &:focus-visible {
      outline: 4px solid ${({ theme }) => theme.colors.white};
    }

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  p { 
    font-size: 13px;
    line-height: 1.54;
    color: ${({ theme }) => theme.colors.secondary};
  }
  @media ${device.tablet} {
    grid-column: 3/4;
    ${({ location }) => location === 'news-search' && css`
      grid-column: unset;
    `}
    grid-row: 1/2;
    max-width: 400px;

    h3 { 
      margin-top: 0;
    }
  }

  @media ${device.tabletL} {
    max-width: 440px;
  }

  @media ${device.desktopS} {
    grid-column: 2/3;
    max-width: 340px;
    ${({ location }) => location === 'news-search' && css`
      grid-column: unset;
      max-width: 440px;
    `}
    height: fit-content;
  }

  @media ${device.desktop} {
    max-width: 440px;
  }
`
export const LableText = styled.span<{ isSingleArticlePage?: boolean }>`
  margin-bottom: 14px;
  font-size: 13px;
  line-height: 1.38;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;

  @media ${device.tablet} {
    min-width: ${({ isSingleArticlePage }) => isSingleArticlePage ? 'unset' : '216px'};

  }

  @media ${device.tabletL} {
    min-width: ${({ isSingleArticlePage }) => isSingleArticlePage ? 'unset' : '246px'};

  }

  @media ${device.desktopS} {
    display: ${({ isSingleArticlePage }) => isSingleArticlePage ? 'block' : 'none'};
  }

`

export const Tags = styled.div<{ isOpen: boolean, isSingleArticlePage?: boolean }>`
  display: ${({ isOpen }) => isOpen ? 'flex' : 'none'};
  flex-direction: column;
  margin-top: 32px;

  @media ${device.tablet} {
    grid-column: 1/4;
    grid-row: 2/3;
    flex-direction: ${({ isSingleArticlePage }) => isSingleArticlePage ? 'column' : 'row'} ;
    margin-top: ${({ isSingleArticlePage }) => isSingleArticlePage ? '24px' : '32px'} ;
  }
  

  @media ${device.desktopS} {
    grid-column: 2/3;
    display: flex;
    width: fit-content;
    margin-top: ${({ isSingleArticlePage }) => isSingleArticlePage ? '16px' : '24px'} ;
    margin-bottom: 0;
  }

`

export const Sentiment = styled.div<{ isOpen: boolean, isSingleArticlePage?: boolean }>`
  display: ${({ isOpen }) => isOpen ? 'flex' : 'none'};
  flex-direction: column;
  margin-bottom: 32px;

  div {
    display: flex;
  }

  @media ${device.tablet} {
    grid-column: 1/4;
    grid-row: 3/4;
    flex-direction: ${({ isSingleArticlePage }) => isSingleArticlePage ? 'column' : 'row'} ;
  }

  @media ${device.tabletL} {
    display: flex;
    grid-column: 4/5;
    grid-row: 1/2;
    margin-bottom: 0;
    padding-top: 16px;

    span { 
      display: ${({ isSingleArticlePage }) => isSingleArticlePage ? 'block' : 'none'};
    }

    div { 
      justify-content: center;
    }
  }

  @media ${device.desktopS} {
    height: fit-content;
    width: fit-content;
  }
`

export const Mentions = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => isOpen ? 'flex' : 'none'};
  flex-direction: column;
  margin-bottom: 32px;

  div {
    span {
      font-size: 13px;
      line-height: 1.54;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  @media ${device.tablet} {
    grid-column: 1/4;
    grid-row: 4/5;
    flex-direction: row;

    div { 
      max-width: 400px;
    }
  }

  @media ${device.tabletL} {
    grid-row: 3/4;
  }

  @media ${device.desktopS} {
    grid-column: 2/3;
    grid-row: 3/4;
    position: relative;
    flex-direction: column;
    width: fit-content;
    margin-top: 48px;
    padding-left: 60px;

    div {
      max-width: 340px;
      white-space: pre-wrap;
      span { 
        display: inline-block;
      }
    }

    span { 
      display: block;
      min-width: unset;
      margin-top: 4px;
      margin-bottom: 3px;
    }
  }

  @media ${device.desktop} {
    max-width: 440px;
  }
`

export const MentionsIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  background-color: ${({ theme }) => theme.colors.body};
  border-radius: 100%;

  @media ${device.desktopS} {
    display: flex;
  }
`

export const Engagements = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => isOpen ? 'flex' : 'none'};
  flex-direction: column;
  margin-bottom: 32px;

  @media ${device.tablet} {
    grid-column: 1/4;
    grid-row: 5/6;
    flex-direction: row;
  }

  @media ${device.tabletL} {
    grid-row: 4/5;
    
  }

  @media ${device.desktopS} {
    display: flex;
    grid-column: 3/4;
    grid-row: 1/2;
    width: fit-content;
  }
`

export const MediaStats = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => isOpen ? 'flex' : 'none'};
  flex-direction: column;

  @media ${device.tablet} {
    grid-column: 1/4;
    grid-row: 6/7;
    flex-direction: row;
  }

  @media ${device.tabletL} {
    grid-row: 5/6;
    
  }

  @media ${device.desktopS} {
    grid-column: 2/4;
    grid-row: 4/5;
    width: fit-content;
  }

`

export const StatsWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: fit-content;
  padding: 24px 24px 24px 24px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.borderSecondary};
  border-radius: 28px;

  & > div {
    &:first-child {
      margin-bottom: 24px;
    }
  }

  @media ${device.tabletL} {
    grid-column: 1/4;
    grid-row: 6/7;
    flex-direction: row;
    padding: 14px 30px 14px 13px;
    border-radius: 37px;

    & > div {
      &:first-child {
        margin-bottom: 0;
        margin-right: 16px;
      }
    }
  }
`

export const SingleStat = styled.div`
  display: flex;
`

export const StatIcon = styled.div<{ color: string }>`
  display: none;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  margin-right: 10px;
  background-color: ${({ color }) => addOpacityToHex(color, 0.1)};
  border-radius: 100%;

  @media ${device.tablet} {
    display: flex;
    flex-shrink: 0;
  }
`

export const SingleStatInfo = styled.div`
  display: flex;
  flex-direction: column;

  div {
    .total_engagement {
      margin-right: 4px;
      font-size: 20px;
      line-height: 1.4;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`

export const SingleStatInfoTitle = styled.p`
  font-size: 12px;
  line-height: 1.33;
  color: ${({ theme }) => theme.colors.primary};
`

export const EngagementChartWrapper = styled.div`
  width: 170px;
  height: 50px;
`

export const SingleStatInfoWrapper = styled.div`
  position: relative;
  display: flex;
  p {
    margin-right: 2px;
  }
  div {
    margin-top: -1px;
  }
`