import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface SetPickerRange {
  startDate: string,
  endDate: string,
  optionName: string,
  location: string
}


const initialState: SetPickerRange = {
  startDate: '',
  endDate: '',
  optionName: '',
  location: ''
}


const dateRangeSlice = createSlice({
  name: 'dateRange',
  initialState,
  reducers: {
    setPickedRage: (state, action: PayloadAction<SetPickerRange>) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.location = action.payload.location;
    },
    resetRange: (state) => {
      state.startDate = '';
      state.endDate = '';
      state.location = '';
    }
  }
})

export const {
  setPickedRage,
  resetRange
} = dateRangeSlice.actions


export default dateRangeSlice.reducer