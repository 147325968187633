import { Middleware } from 'redux';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { removeLocalStorageItems } from '../../utils/handleLogout';

const generalRequestErrorHandler: Middleware = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {

    if (action.payload.status === 401) {
      // Clear storage data
      removeLocalStorageItems(['name', 'email', 'image', 'token']);

      // Refresh the page to flush state and trigger automatic redirection to the login page
      window.location.reload();

      // Stop the rejected action from being processed further
      return;
    }
  }

  return next(action);
};

export default generalRequestErrorHandler