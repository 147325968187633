import LatestNews from './LatestNews'
import OverviewDistribution from './OverviewDistribution'
import TodaysCalendar from '../global-components/TodaysCalendar'

import DashboardPageWrapper from './DashboardPageWrapper'

import { PageHeader } from './SocialMediaTab.styled'



const Overview = () => {

  return (
    <DashboardPageWrapper title='Overview'>
      <PageHeader>
        <h3>Overview</h3>
        <TodaysCalendar />
      </PageHeader>

      {/* Chart & Social stats components/ */}
      <OverviewDistribution />

      <LatestNews />
    </DashboardPageWrapper>
  );
}

export default Overview;
