import React from 'react'
import { ThemeContext } from 'styled-components';

// Redux
import { useAppDispatch } from '../../state/hooks/hooks'
import { fregnirApi } from '../../state/query/queryApi'

// Styles
import { ConnectionLostWrapper } from './ConnectionLostComponent.styled'
import { DefaultButton } from './Button.styled'

// Svg
import { ReactComponent as ConnectionLostImage } from '../../assets/icons/no-internet.svg'


type ConnectionLostComponentProps = {
  isCard?: boolean;
  invalidateTags?: 'SocialAlerts' | 'MostRead' | 'SocialMedia' | 'gNews' | 'gNewsSingle' | 'Dashboard' | 'Reports' | 'Invoices' | 'SavedSearch' | 'Settings' | 'GlobalSearch';
  error?: string | number;
}

export default function ConnectionLostComponent({ isCard, invalidateTags, error }: ConnectionLostComponentProps) {

  const theme = React.useContext(ThemeContext)

  const dispatch = useAppDispatch()

  return (
    <ConnectionLostWrapper isCard={!!isCard}>
      <ConnectionLostImage />
      <h3>
        {
          error === 'FETCH_ERROR'
            ? 'You are offline!'
            : 'Server not responding!'
        }
      </h3>
      <p>
        {
          error === 'FETCH_ERROR'
            ? 'Your network is unavailable. Check your data or wifi connection.'
            : 'We are having difficulty connecting to the server. Please try again later.'
        }
      </p>
      {
        invalidateTags &&
        <DefaultButton
          bgColor={theme.colors.accent}
          size={"small"}
          onClick={() => dispatch(fregnirApi.util.invalidateTags([invalidateTags]))}
        >Retry</DefaultButton>
      }
    </ConnectionLostWrapper>
  )
}
