import React, { useContext } from 'react'

import MaterialIcon from '../global-components/MaterialIcon'
import { ThemeContext } from 'styled-components'

import { SearchTermsPharagraph, SearchTermsBoxResults, SearchTermsBoxSingleResult, SearchTermsQuoteTitle } from './SearchTerms.styled'

type SearchTermsTerm = { 
  term: string;
}

interface SearchTermsTermProps {
  onboardTerms: SearchTermsTerm[];
}

export default function SearchTermsBox({ onboardTerms }: SearchTermsTermProps) {

  const themeContext = useContext(ThemeContext)

  return (
    <>
      <h4>Search Terms</h4>
      <SearchTermsPharagraph>This is a list of queries established during onboarding session which will help collect accurate data relevant to your company</SearchTermsPharagraph>
      <SearchTermsBoxResults>
        {
          onboardTerms.map((item: SearchTermsTerm, index: number) => {
            
            return (
              <SearchTermsBoxSingleResult key={index}>
                <MaterialIcon iconName="check_circle_filled" color={themeContext.colors.green} size={18} />
                <SearchTermsQuoteTitle hasMarginLeft={true}>{item.term}</SearchTermsQuoteTitle>
              </SearchTermsBoxSingleResult>
            )
          })
        }
      </SearchTermsBoxResults>
    </>
  )
}
