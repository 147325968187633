import { DefaultTheme } from "styled-components";


export const theme: DefaultTheme = {
  colors: {
    primary: '#314161',
    primary_700: '#6E7A90',
    default_100: '#EFF0F3',
    default_200: '#E1E3E8',
    default_300: '#D1D4DC',
    default_400: '#C3C7D1',
    default_500: '#B3B8C5',
    default_600: '#A4AABA',
    default_700: '#959CAE',
    primaryDark: '#27344E',
    positive_100: '#ECF9F2',
    secondary: '#68728C',
    accent: '#0284FE',
    accent05: '#0284FE0D',
    accent_50: '#F2F9FF',
    accentDark: '#026ACB',
    accent_900: "#1A90FD",
    neutral: '#FFB457',
    neutral10: '#FEC95E1A',
    neutralDark: '#D8AB50',
    green: '#4AC380',
    greenDark: '#3B9C66',
    redAlert: '#F86262',
    redAlertDark: '#BA4A4A',
    body: '#EEF1F9',
    highlight: '#F6F8FB',
    highlight_500: '#FAFBFD',
    highlight_800: '#F8F9FC',
    blueDots: '#359DFE',
    inputPlaceholderGray: '#959CAE',
    borderPrimary: '#E5EAF1',
    borderSecondary: '#DBE3EA',
    borderTertiary: '#BFCAD4',
    tagBorder: '#D9DDE4',
    white: '#ffffff',
    black: '#000',
    orange: '#C29152',
    extraDarkBlue: '#314161'

  },
  fonts: "Arial, Roboto",
  defaultGradients: {
    heat: 'linear-gradient(to right, #fec95e -1%, #f86262 101%)',
    illustrations: 'linear-gradient(91deg, #377ade 18%, #7cb0ff 91%)',
    chart: 'linear-gradient(to bottom, rgba(2, 132, 254, 0.12), rgba(2, 132, 254, 0))'
  },
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em"
  }
}
