import React, { useState, useContext, useRef, useEffect, Dispatch, SetStateAction } from 'react'
import { ThemeContext } from 'styled-components';

// Components
import IconButtonComponent from './IconButtonComponent'
import ButtonSpinner from './ButtonSpinner'

// Styles
import { LineInputWrapper, ActiveButtonBox, InactiveButtonBox, TransparentInputField, ButtonSpinnerWrapper } from './LineInput.styled'
import { ErrorMessage } from './CustomInput.styled'

// Redux
import { useAppDispatch } from '../../state/hooks/hooks'
import { updateEditMode, cleanValuesInEditMode } from '../../state/slices/appState'

// Utils
import { validateEmail } from '../../utils'


interface LineInputInterface {
  value: string; //If we get alredy values from DB
  onDeleteAction: (item: string) => void; //Action when user press delete icon
  placeholder?: string; // Input placeholder
  errorMessage?: string; // Custom error message if needed
  onSaveAction: (item: string, previousValue: string) => void;
  isActiveOnMount?: boolean;
  hasGlobalEditMode?: boolean;
  currentEditFiledIndex?: number | null; // This is for multiple email usage(from array), so only one can be in edit mode at the time
  setCurrentEditFieldIndex?: Dispatch<SetStateAction<number | null>>;
  index?: number;
  isLoading?: boolean; // displays loader while waiting from response from server
  hasAdditionalError?: boolean;
  setHasAdditionalError?: Dispatch<SetStateAction<boolean>>;
}

//Line input component has width 100%, so idea is that parrent has to have width
export default function LineInput({
  value,
  onSaveAction,
  onDeleteAction,
  placeholder = 'Insert Email',
  errorMessage = 'Please insert valid email',
  isActiveOnMount = false,
  hasGlobalEditMode = false,
  currentEditFiledIndex = null,
  setCurrentEditFieldIndex,
  index,
  isLoading,
  hasAdditionalError,
  setHasAdditionalError
}: LineInputInterface) {

  const themeContext = useContext(ThemeContext)
  const { colors } = themeContext

  const [inputValue, setInputValue] = useState(value)
  const [isActive, setIsActive] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState(true)

  const inputRef = useRef<HTMLInputElement>(null)

  const onStartValue = useRef<string>(value)

  const dispatch = useAppDispatch()

  function startEditMode() {
    setIsActive(true)
    inputRef.current && inputRef.current.focus()
  }


  function doneWithEdit() {
    setIsActive(false)
    setCurrentEditFieldIndex && setCurrentEditFieldIndex(null)
    hasGlobalEditMode && dispatch(cleanValuesInEditMode([`mailInput${index}`]))
    inputRef.current && inputRef.current.blur()
    setIsValidEmail(true)
  }


  //When user is done with edit check is email valid
  function endEditMode(action: string) {
    if (action === 'save') {

      if (!validateEmail(inputValue)) {

        setIsValidEmail(validateEmail(inputValue))

      }
      else {
        onSaveAction(inputValue, onStartValue.current)

        // This is for cases when there is no need to wait for response from server and display loader.
        if (isLoading === undefined) {
          doneWithEdit()
        }
      }

      if (onStartValue.current === inputValue && inputValue !== '') {
        doneWithEdit()
      }
    }

    else if (action === 'cancel') {

      if (onStartValue.current === '') {
        onDeleteAction(onStartValue.current)
      }
      setInputValue(onStartValue.current)
      doneWithEdit()
    }
  }


  useEffect(() => {
    if (isActiveOnMount) {
      startEditMode()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    // Reseting current edit field index when loading is finished.
    if (!isLoading && currentEditFiledIndex === index && onStartValue.current !== "") {
      setCurrentEditFieldIndex && setCurrentEditFieldIndex(null)
      doneWithEdit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])


  /**
   * Reverting back to initial email if input is not in edit mode.
   * This is needed for password managers that are overwriting value of email, while input is not editable.
   */
  useEffect(() => {
    if (inputValue !== value && !isActive) {
      setInputValue(value)
      hasGlobalEditMode && dispatch(cleanValuesInEditMode([`mailInput${index}`]))
      inputRef.current && inputRef.current.blur()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])


  return (
    <>
      <LineInputWrapper isLoading={!!isLoading && currentEditFiledIndex === index} isActive={isActive} isValidEmail={isValidEmail}>

        <TransparentInputField
          placeholder={placeholder}
          isActive={isActive}
          ref={inputRef}
          value={inputValue}
          onChange={(e) => {
            if (!isLoading) {
              setInputValue((e.target as HTMLInputElement).value)
              hasGlobalEditMode && dispatch(updateEditMode({ startFieldValue: onStartValue.current, fieldValue: (e.target as HTMLInputElement).value, fieldName: `mailInput${index}` }))

              // Removing the error when user starts typing.
              !isValidEmail && setIsValidEmail(true)
              hasAdditionalError && setHasAdditionalError && setHasAdditionalError(false)
            }
          }}
          name={`mailInput${index}`}
        />
        {/**
         * Buttons which are visible with default state 
         * 
         * isEditModeAllowed is displaying buttons. Buttons are active when there is no filed in edit mode or currently updating.
         * Also, buttons of filed that is being updated or is in edit mode are visible.
         */}
        <InactiveButtonBox isActive={isActive} isEditModeAllowed={currentEditFiledIndex === null || (!!isLoading && currentEditFiledIndex === index)}>
          <IconButtonComponent
            size="medium"
            bgColor={colors.white}
            iconName="mode_edit"
            onClick={() => {
              if (currentEditFiledIndex === null) {
                setCurrentEditFieldIndex && typeof index === 'number' && setCurrentEditFieldIndex(index)
                startEditMode()
              }
            }}
            setType={6}
          />
          {
            isLoading
              ? <ButtonSpinnerWrapper isActiveButton={false}><ButtonSpinner size={15} color={colors.accent} /></ButtonSpinnerWrapper>
              : <IconButtonComponent
                size="medium"
                bgColor={colors.white}
                iconName="delete"
                onClick={() => {
                  onDeleteAction(inputValue)
                  setCurrentEditFieldIndex && typeof index === 'number' && setCurrentEditFieldIndex(index)
                }}
                setType={6}
              />
          }
        </InactiveButtonBox>

        {/* Buttons which are visible when user press some of default state buttons */}
        {isActive &&
          <ActiveButtonBox isActive={isActive}>
            <IconButtonComponent
              size="medium"
              bgColor={colors.highlight}
              iconName="close"
              onClick={() => !isLoading && endEditMode('cancel')}
              setType={3}

            />
            {
              isLoading && currentEditFiledIndex === index
                ? <ButtonSpinnerWrapper isActiveButton={true}><ButtonSpinner size={15} color={colors.accent} /></ButtonSpinnerWrapper>
                : <IconButtonComponent
                  size="medium"
                  bgColor={colors.highlight}
                  iconName="done"
                  onClick={() => endEditMode('save')}
                  setType={3}
                />
            }
          </ActiveButtonBox>
        }

        {/* Error message - displays when email format is invalid or additional error prop is passed(when woriking with list) */}
        {(!isValidEmail || (hasAdditionalError && currentEditFiledIndex === index)) && <ErrorMessage >{errorMessage}</ErrorMessage>}
      </LineInputWrapper>
    </>
  )
}
