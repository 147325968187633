import styled from 'styled-components'
import { capitalizeFirstLetter } from '../../utils'
import { device } from "../../global-styles/mediaQueries";


const StatusTag = styled.div<{ status: 'Pending' | 'Paid' | 'Unpaid' }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  grid-column: 4/5;
  grid-row: 1/2;
  align-self: center;
  justify-self: flex-start;
  padding: 5px 14px;
  border-radius: 14px;

  background-color: ${({ status, theme }) => (() => {
    switch (status) {
      case "Pending":
        return 'rgba(255, 180, 87, 0.1);';
      case "Paid":
        return 'rgba(74, 195, 128, 0.1);';
      case "Unpaid":
        return 'rgba(248, 98, 98, 0.1);';
    }
  })()};

  @media ${device.tablet} {
    grid-column: 6/7;
  }

  @media ${device.tabletL} {
    grid-column: 7/8;
  }

`

const StatusText = styled.div<{ status: 'Pending' | 'Paid' | 'Unpaid' }>`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: ${({ status, theme }) => (() => {
    switch (status) {
      case "Pending":
        return theme.colors.orange;
      case "Paid":
        return theme.colors.green;
      case "Unpaid":
        return theme.colors.redAlertDark;
    }
  })()};
`

export default function InvoiceStatus({ status }: { status: 'Pending' | 'Paid' | 'Unpaid' }) {
  return (
    <StatusTag status={status}>
      <StatusText status={status}>{capitalizeFirstLetter(status)}</StatusText>
    </StatusTag>
  )
}
