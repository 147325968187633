import styled, { css } from 'styled-components'
import { addOpacityToHex } from '../../utils'
import { device } from '../../global-styles/mediaQueries'

export const ChartWrapper = styled.div<{ floatingTooltip?: boolean }>`
  position: relative;
  height: ${({ floatingTooltip }) => floatingTooltip ? '100%' : '50px'} ;
  width: ${({ floatingTooltip }) => floatingTooltip ? '100%' : '160px'} ;

  @media ${device.tabletL} {
    ${({ floatingTooltip }) => floatingTooltip && css`
      width: 100%;
    `}
  }

  @media ${device.desktopS} {
    width: ${({ floatingTooltip }) => floatingTooltip ? '100%' : '112px'}
  }

  @media ${device.desktop} {
    width: ${({ floatingTooltip }) => floatingTooltip ? '100%' : '130px'}
  }
`

export const CustomTooltip = styled.div<{ posTop: number, posLeft: number, isFloating?: boolean }>`
  position: relative;
  z-index: 99999999999;

  ${({ isFloating, posTop, posLeft }) => !isFloating && css`
  position: absolute;
  top: ${posTop - 13}px;
  left: ${posLeft}px;
  transform: translate(-50%, -100%);

  &::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: #314161 transparent transparent transparent;
  }
  `}

  min-width: 170px;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.primaryDark};
  border-radius: 4px;
  pointer-events: none;  
`

export const NoDataChartWrapper = styled.div<{ isInSingleArticle?: boolean }>`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: ${({ isInSingleArticle }) => isInSingleArticle ? '85px' : '50px'};
  width: 100%;
  svg {
    width: ${({ isInSingleArticle }) => isInSingleArticle ? '100%' : '130px'};
    height: 16px;
  } 
`

export const Label = styled.div<{ separator: boolean, type: 'date' | 'engagement', backgroundColor?: string }>`
  display: flex;
  justify-content: space-between;
  padding: ${({ type }) => type === 'date' ? '8px 12px' : '10px 12px 14px'};
  border-bottom: ${({ separator, theme }) => separator ? `1px solid ${addOpacityToHex(theme.colors.borderPrimary, 0.1)}` : 'none'};
  background: ${({ theme }) => theme.colors.primary};


  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`

export const LabelText = styled.p<{ firstPoint?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: max-width;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${({ theme, firstPoint }) => firstPoint ? theme.colors.white : addOpacityToHex(theme.colors.white, 0.6)};
  white-space: nowrap;

  svg { 
    margin-bottom: 2px;
    margin-right: 8px;
  }
`

export const LabelCount = styled.p`
  padding-left: 30px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.white};
`