import styled from 'styled-components'
import { device } from '../../global-styles/mediaQueries'

export const AlertTableHeader = styled.div<{ isSearchActive: boolean }>`
  display: flex; 
  align-items: center;
  padding: 16px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-shadow: 0 5px 5px -4px rgba(49, 65, 97, 0.08);
  background-color: white;
  p {
    min-width: fit-content;
    width: 100%;
    flex-shrink: 2;
    flex-grow: 4;
  }
  .outside-wrapper {
    width: ${({ isSearchActive }) => isSearchActive ? '100%' : 'auto !important'}; 
    margin-left: 10px;
  }
  @media ${device.mobileL} {
    .outside-wrapper {
    margin-left: 12px;

    }
  }
  @media ${device.tablet} {
    .outside-wrapper {
      width: auto; 
    }
  }
`

export const FilterWrapper = styled.div<{ isSearchActive: boolean }>`
  display: ${({ isSearchActive }) => isSearchActive ? 'none' : 'block'};
  margin-left: 28px;
  div {
    margin-bottom: 0px;
  }
  @media ${device.tablet} {
    display: block;
  }
`

export const AlertTableSubHeader = styled.div`
  height: 38px;
  padding: 10px 0 10px 70px;
  background-color: ${({ theme }) => theme.colors.highlight};
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primary};
  border-top:  solid 1px ${({ theme }) => theme.colors.borderPrimary};
  border-bottom: solid 1px ${({ theme }) => theme.colors.borderPrimary};
  @media ${device.mobileL} {
    padding: 10px 0 10px 74px;
  }

  @media ${device.tablet} {
    padding: 10px 72px 10px 86px;
    p {
      &:first-of-type {
        margin-right: 58px;
      }
      &:last-of-type {
        margin-left: 318px;
      } 
    }
  }

  @media ${device.tabletL} {
    padding: 10px 112px 10px 24px;
    p {
      &:first-of-type {
        margin-right: 83px;
      }
      &:last-of-type {
        margin-left: 83px;
      } 
    }
  }

  @media ${device.desktopS} {
    padding: 10px 83px 10px 24px;
    p {
      &:first-of-type {
        margin-right: 67px;
      }
      &:last-of-type {
        margin-left: 46px;
      } 
    }
  }
  @media ${device.desktop} {
    padding: 10px 133px 10px 24px;
    p {
      &:first-of-type {
        margin-right: 104px;
      }
      &:last-of-type {
        margin-left: 93px;
      } 
    }
  }
`

export const MobileContent = styled.div`
  @media ${device.tablet} {
    display: none;
  }
`

export const DesktopContent = styled.div`
  display: none;

  @media ${device.tablet} {
    display: flex;
    .hide-tablet {
      display: none;
    }
  }

  @media ${device.tabletL} {
    .hide-tablet {
      display: inline-block;
      margin-left: 381px;
    }
  }

  @media ${device.desktopS} {
    .hide-tablet {
      margin-left: 347px;
    }
  }
  @media ${device.desktop} {
    .hide-tablet {
      margin-left: 453px;
    }
  }

`
