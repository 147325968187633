import React from 'react';
import styled from 'styled-components';
import ExampleOne from './additionalInfoComponents/ExampleOne'
import NewsCoverageInfo from './additionalInfoComponents/NewsCoverageInfo'
import SimpleTextInfo from './additionalInfoComponents/SimpleTextInfo'

const Wrapper = styled.div`
  font-size: 14px;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.secondary};

  a {
    color: ${({ theme }) => theme.colors.accent};
    text-decoration: none;
  }
`

const EventPopupAdditional = ({ popupText, component, onClose }: { popupText?: string, component: string, onClose: () => void }): JSX.Element => {
  return (
    <Wrapper>
      {(() => {
        switch (component) {
          case 'ExampleOne':
            return <ExampleOne sideEffect={onClose} />
          case 'NewsCoverageInfo':
            return <NewsCoverageInfo sideEffect={onClose} />
          case 'SimpleTextInfo':
            return popupText && <SimpleTextInfo text={popupText} />
        }
      })()}
    </Wrapper>
  );
}

export default EventPopupAdditional;
