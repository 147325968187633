import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components'
import MaterialIcon from '../MaterialIcon';


const EventPopupIcon = ({ type }: { type: 'inform' | 'success' | 'warning' | 'error' }): JSX.Element => {

  const { colors } = useContext(ThemeContext)

  return (
    <>
      {((popupType) => {
        switch (popupType) {
          case 'inform':
            return <MaterialIcon iconName="info_filled" size={24} color={colors.accent} />
          case 'success':
            return <MaterialIcon iconName="check_circle_filled" size={24} color={colors.green} />
          case 'warning':
            return <MaterialIcon iconName="warning_filled" size={24} color={colors.neutral} />
          case 'error':
            return <MaterialIcon iconName="error_filled" size={24} color={colors.redAlert} />
        }
      })(type)}
    </>
  );
}

export default EventPopupIcon;
