import styled from "styled-components"

export const TooltipBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 280px;
  padding: 4px 12px 6px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.primaryDark};
`

export const TooltipRow = styled.div<{ hasDivider: boolean }>`
  display: block;
  padding: 12px 0 10px;
  border-bottom: ${({ hasDivider, theme }) => hasDivider ? `2px solid ${theme.colors.primaryDark}` : 'none'};

  h6 {
    margin: 0 0 10px;
    font-size: 14px;
    line-height: 1.29;
    color: ${({ theme }) => theme.colors.white};
  }
  p {
    font-size: 13px;
    line-height: 1.54;
    color: ${({ theme }) => theme.colors.default_400};
  }
  span {
    display: inline-block;
    padding: 0px 6px;
    margin-right: 8px;
    border-radius: 2px;
    font-size: 13px;
    line-height: 1.38;
    background-color: ${({ theme }) => theme.colors.accent};
  }
`