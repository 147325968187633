import React, { useState, useContext, ReactElement } from 'react'
import { ThemeContext } from 'styled-components'
//Redux
import { useAppDispatch } from '../../state/hooks/hooks'
import { closeModal } from '../../state/slices/modalsSlice'
import { SingleSavedSearch } from '../../state/query/types/SavedSearchTypes'

//Components
import CustomInput from '../../components/global-components/CustomInput'
import CustomButton from '../global-components/CustomButton'
import MaterialIcon from '../global-components/MaterialIcon';
import DateRangeSelect, { DateRange } from '../global-components/DateRangeSelect'
import Spinner from '../global-components/ButtonSpinner'

//Styles
import { NewsCoverageModalWrapper, TitleWrapper, InputWrapper, InputLabel, CancelButton } from './NewsCoverageModal.styled'
import { DeleteButton, SavedSearchButtonWrapper } from './EditSavedSearch.styled'

//Utils
import { showErrorOrSuccessPopup } from '../../utils/showPopups'

//Query 
import { useDeleteNewsSearchMutation } from '../../state/query/queryApi'
import { useSaveNewsSearchMutation } from '../../state/query/queryApi'
import { openModal } from '../../state/slices/modalsSlice';

export default function EditSavedSearch({ payload }: { payload: SingleSavedSearch }): ReactElement {

  const { id, search_name, date, search_term } = payload
  const [inputName, setInputName] = useState(search_name)
  const [inputQuery, setInputQuery] = useState(search_term)
  const [simulateLoading, setSimulateLoading] = useState(false)


  const [expirationOption, setExpirationOption] = useState<DateRange>(date)

  const contextTheme = useContext(ThemeContext)
  const { colors } = contextTheme

  const dispatch = useAppDispatch()
  const handleExpiredOption = (option: DateRange) => setExpirationOption(option)

  const handleCloseModal = () => {
    dispatch({ type: closeModal.type })
  }

  //Mutations
  const [deleteSavedSearchById] = useDeleteNewsSearchMutation()
  const [saveNewsSearchMutation] = useSaveNewsSearchMutation()


  function openDeleteModal() {
    return dispatch({
      type: openModal.type,
      payload: {
        name: 'deleteSavedSearch',
        props: {
          id: id, //This will be for now we will find out how we will handle this
          articleTitle: search_name,
          modalType: 'deleteSavedSearch',
          modalContent: "Once you delete this search, it will no longer be visible in the saved search box",
        }
      }
    })
  }


  const handleDeleteSavedSearch = () => openDeleteModal()

  //No API for edit so when user edit search we delete original one and create new one 
  const editNewSearch = () => {
    setSimulateLoading(true)
    deleteSavedSearchById(id)  // Use mutation to delete current search
      .then(deleteResponse => Object.keys(deleteResponse)[0] === 'data'
        ? saveNewsSearchMutation({ // IF we got success response for delete news then create new one 
          id: id + 10000, //in cache store unique ID. This id is just temporary cache ID  
          search_name: inputName,
          search_term: inputQuery,
          date: expirationOption,
        })
          .then((saveResponse) => saveResponse?.hasOwnProperty('data') //Check is saved response ok 
            ? showErrorOrSuccessPopup(dispatch, 'Search is Edited', 'bottom', 'success', 'additionalInfo')
            : showErrorOrSuccessPopup(dispatch, 'Error please try again', 'bottom', 'error', 'additionalInfo'))
        : showErrorOrSuccessPopup(dispatch, Object.values(deleteResponse)[0].error, 'bottom', 'error', 'additionalInfo'))
      .then(() => {
        setSimulateLoading(false)
        handleCloseModal()
      })

  }


  return (
    <NewsCoverageModalWrapper>
      <TitleWrapper>
        <MaterialIcon iconName="mode_edit" color={contextTheme.colors.accent} size={24} />
        <h4>Edit saved search</h4>
      </TitleWrapper>
      <InputWrapper>
        <CustomInput
          label="Search Name"
          inputVal={inputName}
          setInputVal={setInputName}
          isGray={true}
          placeholder={search_name}
          name="TBDsearchName"
        />
      </InputWrapper>
      <InputWrapper>
        <CustomInput
          label="Query"
          inputVal={inputQuery}
          setInputVal={setInputQuery}
          isGray={true}
          placeholder={search_term}
          name="TBDsearchName2"
        />
      </InputWrapper>
      <InputWrapper>
        <InputLabel>Date Range</InputLabel>
        <DateRangeSelect handleSelect={handleExpiredOption} location="global-search" />
      </InputWrapper>

      <SavedSearchButtonWrapper>
        <DeleteButton
          onClick={handleDeleteSavedSearch}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              handleDeleteSavedSearch()
            }
          }}
          tabIndex={0}
        >
          <MaterialIcon iconName="delete" color={colors.redAlert} />
          <p>Delete this search</p>
        </DeleteButton>
        {/* This is auto value in grid columns so this div is space between columns */}
        <CancelButton
          onClick={() => dispatch({ type: closeModal.type })}
        >Cancel</CancelButton>
        <CustomButton bgColor={colors.accent} size="medium"
          onClick={editNewSearch}
          isDisabled={search_name === inputName && search_term === inputQuery && JSON.stringify(date) === JSON.stringify(expirationOption)}
        >
          {simulateLoading ? <Spinner /> : 'Save'}
        </CustomButton>
      </SavedSearchButtonWrapper>
    </NewsCoverageModalWrapper>
  )
}
