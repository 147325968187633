import styled from 'styled-components'
import { device } from "../../global-styles/mediaQueries";

export const ModalBox = styled.div`
  width: 100%;
  max-width: 420px;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 5px 5px -4px rgba(49, 65, 97, 0.08);
  background-color: ${({ theme }) => theme.colors.white};

  h3 { 
    margin: 0 0 12px;
  }

  @media ${device.tablet} {
    padding: 24px;
  }
`

export const FileName = styled.span`
  display: inline-block;
  margin-bottom: 22px;
  padding: 2px 8px;
  font-size: 13px;
  line-height: 1.38;
  font-weight: bold;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.body};
`
export const Labbel = styled.p`
  margin-bottom: 12px;
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primary};
`

export const AdditionalInfo = styled.p`
  margin: 5px 0 30px;
  font-family: Arial;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.secondary};

  a {
    color: ${({ theme }) => theme.colors.accent};
    text-decoration: none;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  svg {
    margin-right: 4px;
    fill: ${({ theme }) => theme.colors.white};
  }
`

export const GeneratingProcessMessage = styled.p`
  padding-top: 8px;
  font-family: Arial;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: ${({ theme }) => theme.colors.secondary};

`