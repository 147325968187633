import React, { useState, MouseEvent, useEffect } from 'react'
//Components
import { useHistory, useLocation } from 'react-router-dom';
// import NotificationsButton from './NotificationsButton'
import DropDownMenu from './DropDownMenu'
import MainNavigation from './MainNavigation'
import Notifications from './Notifications'

//Hooks
import useMediaQuery from '../../hooks/useMediaQuery'

//Styles
import { Container, MenuAndNotificationsContainer, NavButtonWrapper, NavButton, AppLogoLink, LogoLG, LogoSM } from './TopNavBar.styled'

//Redux
import { useAppDispatch } from '../../state/hooks/hooks'
import { openModal } from '../../state/slices/modalsSlice'
import { useAppSelector } from '../../state/hooks/hooks';

// Should be removed once notifications logic is in place
// const fakeNotificationsStatus = {
//   hasNotifications: true
// }

export default function TopNavBar() {

  const isDesktop = useMediaQuery('desktopS')

  const history = useHistory()

  const { pathname } = useLocation()

  const [mainNavOpen, setMainNavOpen] = useState(false)
  const [notificationsOpen, setNotificationsOpen] = useState(false)

  const isInEditMode = useAppSelector(state => state.appState.isEditModeActive)

  const dispatch = useAppDispatch()

  const toggleMainNav = () => {
    setMainNavOpen(mainNavOpen => !mainNavOpen)
  }

  const toggleNotifications = () => {
    setNotificationsOpen(notificationsOpen => !notificationsOpen)
    if (mainNavOpen) setMainNavOpen(false)
  }

  const handleLogoClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (isInEditMode) {
      dispatch(openModal({
        name: 'unsavedChanges',
        props: { fieldName: '', pathTo: '/dashboard' }
      })
      )
    } else {
      if (mainNavOpen) {
        toggleMainNav();
      }
      history.push('/dashboard');
    }
  }

  useEffect(() => {
    if (isDesktop && document.body.classList.contains('no-scroll')) {
      document.body.classList.remove('no-scroll')
      mainNavOpen && setMainNavOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop])


  useEffect(() => {
    mainNavOpen && setMainNavOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {
    if (mainNavOpen) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [mainNavOpen])

  return (
    <Container>
      <NavButtonWrapper tabIndex={0} onKeyDown={(e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
          toggleMainNav()
        }
      }}>
        <NavButton isOpen={mainNavOpen} onClick={toggleMainNav} className='focus-border' />
      </NavButtonWrapper>
      <AppLogoLink href="#" onClick={handleLogoClick}>
        <LogoLG />
        <LogoSM />
      </AppLogoLink>
      <MenuAndNotificationsContainer>
        {/* Currently removed until logic for notifications is made. */}
        {/* <NotificationsButton hasNew={fakeNotificationsStatus.hasNotifications} onClick={toggleNotifications} /> */}
        <DropDownMenu />
      </MenuAndNotificationsContainer>
      <MainNavigation shouldShow={mainNavOpen} setMainNavOpen={setMainNavOpen} />
      <Notifications shouldShow={notificationsOpen} handleClose={toggleNotifications} />
    </Container>
  )
}
