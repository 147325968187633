import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { formatThousandNumbers, addOpacityToHex } from '../../utils'

//From toolitp file
declare type TooltipType = 'none';

interface TToltip {
  active?: boolean;
  payload?: ChartItem[];
  label?: string;
  isDoubleChart?: boolean;
}

type TooltipPayload = {
  name: string;
  engagement: number;
  articles: number;
  labelName?: string;
}

interface ChartItem {
  color: string,
  dataKey: string | number,
  fill: string,
  formatter?: undefined | string,
  name?: string,
  payload: TooltipPayload,
  points: any[],
  stroke: string,
  strokeWidth: number,
  type?: undefined | TooltipType,
  unit?: undefined | ReactNode,
  value?: number,
}

//Styled components
const TooltipWrapper = styled.div`
  display: flex;
  flex-flow: column;
  min-width: 110px;
  width: fit-content;
  padding: 8px 12px 14px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
`

const TooltipLabel = styled.div`
    margin: -8px -12px;
    padding: 8px 16px;
    font-size: 12px;
    border-bottom: solid 1px ${({ theme }) => addOpacityToHex(theme.colors.borderPrimary, 0.2)};
    background-color: ${({ theme }) => theme.colors.primaryDark};
    color: ${({ theme }) => theme.colors.default_400};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
`
const SingleTooltipLabel = styled.div<{ isDoubleChart: boolean }>`
  display: flex;
  margin-top: 14px;
  font-size: 12px;
  label {
    margin-right: 5px;
    color: ${({ theme }) => theme.colors.default_400};
  }
  span {
    font-weight: bold;
    color: white;
    text-transform: 'capitalize' !important;
    margin-left: auto;

  }
  svg {
    margin-right: 8px;
  }
  ${({ isDoubleChart }) => isDoubleChart && css`
    &:last-child {
      display: none;
    }
  `}
`


//Double chart
export default function ChartTooltip({ active, payload, label, isDoubleChart = false }: TToltip) {

  if (active && payload && payload.length) {
    return (
      <TooltipWrapper>

        {!!payload[0].payload.labelName && <TooltipLabel>{payload[0].payload.labelName}</TooltipLabel>}

        {payload.map((item: ChartItem, index: number) => {
          return <SingleTooltipLabel key={index} isDoubleChart={isDoubleChart}>
            <svg width="10" height="10">
              <circle cx="5" cy="5" r="4" strokeWidth="1" stroke={item.fill} fill={item.stroke} />
            </svg>
            <label style={{ textTransform: 'capitalize' }}>{item?.dataKey}:</label>
            <span> {formatThousandNumbers(item.value as number)}</span>
          </SingleTooltipLabel>
        })}

      </TooltipWrapper >
    );
  }
  return null
}