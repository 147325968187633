import React, { useState, useRef, useEffect } from 'react'
import TableHeader from './CheckboxTableHeader'
import ListPagination from '../ListPagination'
import { ListFooter } from '../../dashboard/LatestNewsTableWrapper.styled'
import TableMasterCheckbox from '../../global-components/TableMasterCheckbox'
import TableCheckboxList from './ChecboxTableList'
import CheckboxTableColumn from './CheckboxTableColumns'
import { useAppDispatch } from '../../../state/hooks/hooks'
import { openModal } from '../../../state/slices/modalsSlice'
import { isEmpty } from '../../../utils'
import { LoaderAndMessageWrapper } from '../../dashboard/LatestNewsList.styled'
import Spinner from '../../global-components/ButtonSpinner'
import { ThemeContext } from 'styled-components';
import { ReactComponent as NoResultImage } from '../../../assets/icons/no-results.svg'
import NoItems from './NoItems'
import { ReportData, InvoiceData } from '../../../state/query/types/ReportsAndInvoicesTypes'
import ConnectionLostComponent from '../ConnectionLostComponent'
import { Error, QueryError } from '../../../state/query/types/globalQueryTypes'


export interface CheckedItems {
  [key: number]: number[]
}

interface CheckboxTableProps {
  tableType: 'report' | 'invoice';
  tableColumns: string[];
  tableData: ReportData[] | InvoiceData[] | undefined;
  isLoading: boolean;
  isError: boolean;
  error: QueryError | undefined | Error;
}

export default function CheckboxTable({ tableType, tableColumns, tableData, isLoading, isError, error }: CheckboxTableProps) {

  const theme = React.useContext(ThemeContext)
  const dispatch = useAppDispatch()
  const tableRef = useRef<HTMLDivElement>(null)

  const [listData, setListData] = useState<ReportData[] | InvoiceData[] | []>(tableData ? [...tableData] : [])
  const [pageNum, setPageNum] = useState(1)
  const [filterBy, setFilterBy] = useState<string>('newest')
  const [searchKeywords, setSearchKeywords] = useState<string>('')
  const [checkedItems, setCheckedItems] = useState<CheckedItems>({})


  const handleSearchTerms = (keywords: string): void => {
    setSearchKeywords(keywords)
  }

  const sortData = (data: ReportData[] | InvoiceData[], filter: string) => {
    let sortedArray = [...data] as ReportData[] | InvoiceData[];

    sortedArray.sort((a: ReportData | InvoiceData, b: ReportData | InvoiceData) => {
      const createdAtA = new Date(a.created_at).getTime()
      const createdAtB = new Date(b.created_at).getTime()

      if (createdAtA < createdAtB) {
        return filter === 'newest' ? 1 : -1;
      }
      if (createdAtA > createdAtB) {
        return filter === 'newest' ? -1 : 1;
      }
      // a must be equal to b
      return 0;
    })

    return sortedArray
  }

  const filterByKeyword = (data: ReportData[] | InvoiceData[] | undefined, input: string, type: 'report' | 'invoice'): ReportData[] | InvoiceData[] => {
    if (input === "") {
      return (data as ReportData[] | InvoiceData[])
    }

    if (type === 'report') {
      return (data as ReportData[]).filter((item: ReportData) => item.title.toLowerCase().includes(input.toLowerCase()))
    } else {
      return (data as InvoiceData[]).filter((item: InvoiceData) => {
        const dateVariants = {
          long: new Date(item.created_at).toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' }),
          numeric: new Date(item.created_at).toLocaleDateString('en-GB', { year: 'numeric', month: 'numeric', day: 'numeric' })
        }

        return (dateVariants.long.toLowerCase().includes(input.toLowerCase())
          || dateVariants.numeric.includes(input)
          || item.invoice_id.toLowerCase().includes(input.toLowerCase())
          || item.billed_to.toLowerCase().includes(input.toLowerCase()))
      })
    }
  }

  const handleSelectFilter = (option: string) => setFilterBy(option)
  const changePage = (page: number): void => setPageNum(page)
  const resetCheckbox = () => setCheckedItems({})



  const calculateItemsOnPage = (data: ReportData[] | InvoiceData[] | undefined, pageNum: number): number => {
    if (data) {
      return data.slice((pageNum - 1) * 10, pageNum * 10).length
    }
    return 0
  }


  const handleCheckedItems = (e: React.ChangeEvent<HTMLInputElement>, id: number, checkedItems: CheckedItems, pageNumber: number): void => {
    let checked = e.target.checked

    if (checked) {
      // check if array that matches current page exists
      if (checkedItems[pageNum]) {
        // if it does check if id is inside array
        if (checkedItems[pageNum].indexOf(id) === -1) {
          setCheckedItems({ ...checkedItems, [pageNum]: [...checkedItems[pageNum], id] })
        }
      } else {
        // if array does not exist, create array and add id
        if (!checkedItems[pageNum]) {
          setCheckedItems({ ...checkedItems, [pageNum]: [id] })
        }
      }

    } else {
      if (checkedItems[pageNum]) {
        let index = checkedItems[pageNum].indexOf(id)
        if (index !== -1) {
          let preparedArray = [...checkedItems[pageNum]]
          preparedArray.splice(index, 1)

          setCheckedItems({ ...checkedItems, [pageNum]: [...preparedArray] })
        }
      }
    }
  }

  const handleSelectAll = () => {
    const tempChecked = checkedItems[pageNum] ? [...checkedItems[pageNum]] : []

    sortData(listData, filterBy).slice((pageNum - 1) * 10, pageNum * 10).forEach((item: ReportData | InvoiceData) => {
      if (tempChecked.indexOf(item.id) === -1) {
        tempChecked.push(item.id)
      }
    })
    setCheckedItems({ ...checkedItems, [pageNum]: [...tempChecked] })
  }

  const handleDeselectAll = () => {
    setCheckedItems({ ...checkedItems, [pageNum]: [] })
  }

  function openDeleteModal(checked: number[], title?: string | null) {
    dispatch({
      type: openModal.type,
      payload: {
        name: 'deleteCheckedFromTable',
        props: {
          id: checked,
          title: title ? title : null,
          modalType: tableType
        }
      }
    })
  }


  const handleDeleteFromMainButton = (type: 'report' | 'invoice') => {
    let checkedItemsArr = [] as number[]
    let title = null;

    for (const key in checkedItems) {
      if (checkedItems.hasOwnProperty(key) && key !== 'lastSelected') {
        checkedItemsArr.push(...checkedItems[key])
      }
    }

    if (checkedItemsArr.length === 1) {
      if (type === 'report') {
        const obj = (listData as ReportData[]).find((item: ReportData) => item.id === checkedItemsArr[0])
        if (obj) title = obj.title
      } else {
        const obj = (listData as InvoiceData[]).find((item: InvoiceData) => item.id === checkedItemsArr[0])
        if (obj) title = obj.invoice_id.toString()
      }
    }

    openDeleteModal(checkedItemsArr, title)
  }

  const checkIfSelectedOnPage = (page: number): boolean => checkedItems[page] && checkedItems[page].length > 0 ? true : false


  useEffect(() => {
    tableData && setListData(tableData)
    if (searchKeywords) setListData(filterByKeyword(tableData, searchKeywords, tableType))
    if (!isEmpty(checkedItems)) resetCheckbox()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData])

  useEffect(() => {
    setPageNum(1)
    if (!isEmpty(checkedItems)) resetCheckbox()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBy])

  useEffect(() => {
    tableData && setListData(filterByKeyword(tableData, searchKeywords, tableType))

    setPageNum(1)
    if (!isEmpty(checkedItems)) resetCheckbox()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeywords])

  return (
    <div style={{ backgroundColor: "white", borderRadius: '6px', overflow: 'hidden' }}>
      <TableHeader
        filterBy={filterBy}
        handleSelectFilter={handleSelectFilter}
        handleSearchTerms={handleSearchTerms}
        headerTitle={tableType === 'report' ? 'Report List' : 'Invoice List'}
        handleDelete={() => handleDeleteFromMainButton(tableType)}
        handleDownload={() => console.log("Download logic not implemented yet.")}
        itemsChecked={checkIfSelectedOnPage(pageNum)}
      />
      <CheckboxTableColumn type={tableType} columns={tableColumns}>
        <TableMasterCheckbox
          itemsOnPage={calculateItemsOnPage(tableData, pageNum)}
          numberOfSelected={checkedItems[pageNum] ? checkedItems[pageNum].length : 0}
          handleDeselectAll={handleDeselectAll}
          handleSelectAll={handleSelectAll}
        />
      </CheckboxTableColumn>

      {isError ? (
        <ConnectionLostComponent error={(error as Error)?.status} invalidateTags={tableType === 'report' ? "Reports" : "Invoices"} />
      ) : (
        isLoading ? (
          <LoaderAndMessageWrapper type="loader">
            <Spinner size={40} color={theme.colors.accent} />
          </LoaderAndMessageWrapper>
        ) : (
          <div ref={tableRef}>
            {
              tableData && tableData.length > 0 ? (

                listData?.length > 0 ? (
                  <TableCheckboxList
                    data={sortData(listData, filterBy)}
                    page={pageNum}
                    checkedItems={checkedItems}
                    handleCheck={(id: number, page: number, checked: CheckedItems) => (e: React.ChangeEvent<HTMLInputElement>) =>
                      handleCheckedItems(e, id, checked, page)
                    }
                    listType={tableType}
                    handleDelete={openDeleteModal}
                  />
                ) : (searchKeywords !== '' && (
                  <LoaderAndMessageWrapper type="no-results">
                    <NoResultImage />
                    <h3>Nothing is matching your search</h3>
                    <p>Please try again with a different term</p>
                  </LoaderAndMessageWrapper>
                )
                )

              ) : (
                <NoItems type={tableType} />
              )

            }
          </div>
        )
      )}
      {
        tableData && tableData.length > 0 &&
        <ListFooter>
          <ListPagination
            page={pageNum}
            pageLength={10}
            totalRecords={listData ? listData.length : 0}
            pageNum={pageNum}
            changePageNum={changePage}
            ref={tableRef}
          />
        </ListFooter>
      }
    </div>
  )
}
