import React, { KeyboardEvent } from 'react'
import styled from 'styled-components'
import { globalFocusStyles } from '../../global-styles/globalStyles'

interface Colors {
  [color: string]: string
}

const getCheckboxBackgroundColor = (checked: boolean, colors: Colors, isEnabled?: boolean) => {
  if (isEnabled) {
    if (checked) return colors.accent
    else return colors.white
  }
  else {
    if (checked) return colors.default_300
    else return colors.highlight
  }
}

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  height: 0px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 0px;
  opacity: 0;
`
export const Icon = styled.svg`
  fill: none;
  stroke: ${props => props.theme.colors.white};
  stroke-width: 2px;
`
export const CheckboxLabel = styled.label<{ isEnabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ isEnabled }) => isEnabled ? 'pointer' : 'arrow'};
`
export const CheckboxText = styled.span<{ isEnabled?: boolean }>`
  margin-left: 10px;
  color: ${({ theme, isEnabled }) => isEnabled ? theme.colors.primary : theme.colors.secondary};
  font-size: 14px;
  line-height: 24px;
`

export const StyledCheckbox = styled.div<{ checked: boolean, isEnabled?: boolean }>`
  display: inline-block;
  width: 20px;
  height: 20px;
  background: ${({ checked, isEnabled, theme }) => getCheckboxBackgroundColor(checked, theme.colors, isEnabled)};
  border: ${props => props.checked ? 'none' : `solid 1px ${props.theme.colors.borderSecondary}`};
  border-radius: 3px;
  transition: all 150ms;

  ${Icon} {
    visibility: ${props => props.checked ? 'visible' : 'hidden'};
  }
`

export const CheckboxContainer = styled.div`
  position: relative;
  display: flex;
  vertical-align: middle;

  // Custom focus styles
  ${globalFocusStyles}

`

interface CustomCheckboxProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void;
  checked: boolean;
  className?: string;
  labelText?: string;
  isEnabled?: boolean;
  name?: string;
}


export default function CustomCheckbox({ onChange, onKeyDown, checked, className, labelText, name, isEnabled = true }: CustomCheckboxProps) {

  return (

    <div>
      <CheckboxLabel isEnabled={isEnabled}>
        <CheckboxContainer className={className} tabIndex={0} onKeyDown={(e) => isEnabled && onKeyDown && onKeyDown(e)}>
          <HiddenCheckbox
            name={name}
            checked={checked}
            onChange={(e) => isEnabled && onChange(e)}
            id={name}
            tabIndex={0}
          />
          <StyledCheckbox checked={checked} isEnabled={isEnabled}>
            <Icon viewBox="0 0 24 24">
              <polyline points="20 7 10 17 5 12" />
            </Icon>
          </StyledCheckbox>
        </CheckboxContainer>
        {labelText && <CheckboxText isEnabled={isEnabled}>{labelText}</CheckboxText>}
      </CheckboxLabel>
    </div>

  )
}
