import styled, { keyframes } from 'styled-components'
import { ReactComponent as HeatBar } from '../../assets/svg/heatBar.svg'
import { centerFlex } from '../../global-styles/mixins'
import { device } from '../../global-styles/mediaQueries'

const pathKeyFrames = (value: number) => keyframes` 

0% {opacity: 0}
50% {opacity: 0}
80% {opacity: 0}
95% {opacity: 1}
100% { stroke-dashoffset: ${value}; opacity: 1;}`;

export const HeatStatusContainer = styled.section`
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 5px 5px -4px rgba(49, 65, 97, 0.08);
  @media ${device.tablet} {
    flex-flow: row;
  }
  @media ${device.tabletL} {
    flex-flow: column;
    grid-area: heatstatus;
  }
`

export const HeatStatusBox = styled.div`
  position: relative;
  height: 215px;
  ${centerFlex};
  flex-flow: column;
  padding: 34px;
  width: 100%;

  &:first-child {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 16px;
      width: calc(100% - 32px);
      height: 1px;
      background-color: ${({ theme: { colors } }) => colors.borderPrimary};
    }
  }

  @media ${device.tablet} {
  padding: 34px 0 28px;

    &:first-child {
      &:after {
        bottom: 36px;
        left: 100%;
        width: 1px;
        height: 138px;
      }
    }
  }

  @media ${device.tabletL} {
    padding: 48px 0 44px;
    &:first-child {
      &:after {
        bottom: 0;
        left: 20%;
        width: 60%;
        height: 1px;
      }
    }
  }

  @media ${device.desktopS} {
    padding: 34px 0;
  }
  @media ${device.desktop} {
    padding: 46px 0;
    height: 240px;

  }
`
export const AnimatedHeatBar = styled(HeatBar) <{ heat: number }>`
position: absolute;
left: 0;
  #color-path {
    opacity: 0;
    animation: ${({ heat }) => heat !== 0 && pathKeyFrames(heat)}  1.4s linear forwards;
  }

`
export const HeatIconWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  @media ${device.mobileL} {
    left: 22px;
    top: 22px;
  }
  @media ${device.tablet} {
    left: 24px;
    top: 24px;
  }
  @media ${device.tabletL} {
    left: 26px;
    top: 26px;
  }
  @media ${device.desktopS} {
    left: 20px;
    top: 20px;
  }
  @media ${device.desktop} {
    left: 26px;
    top: 26px;
  }
`

export const HeatInfoBox = styled.div`
  position: relative;
  
  p {
    margin: 0;
    text-align: center;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary};

    &:first-child {
      margin-bottom: 2px;
      font-size: 26px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      text-align: center;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`

export const HeatSatWrapper = styled.div`
  position: relative;
  width: 116px;
  min-height: 80px;
  height: 80px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  
`

export const HeatStatInfo = styled.div`
  position: relative;
  margin-top: 30px;
  p {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary};
    &:first-child {
      margin-bottom: 4px;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: center;
      color: ${({ theme }) => theme.colors.primary};

    }
  }
`