import styled from 'styled-components'
import { TitleWrapper } from './DeleteArticle.styled'
import { globalFocusStyles } from '../../global-styles/globalStyles'

export const DeleteButton = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${({ theme }) => theme.colors.redAlert};
  font-size: 14px;
  cursor: pointer;
  margin-right: auto;
  svg {
    margin-right: 6px;
  }
  
  // Custom focus styles
  ${globalFocusStyles}
`
export const ModalTitleWrapper = styled(TitleWrapper)`
  h3 {
    margin: 0 0 24px;
  }
`
export const SavedSearchButtonWrapper = styled.div`
  display: flex;
  /* display: grid; */
  /* grid-template-columns: [delete] auto [space] 1fr [cancel] 80px [save] 82px; */
`