import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

//Components
import ModalButton from '../global-components/ModalButton'
import MaterialIcon from '../global-components/MaterialIcon'
import StopAlertsButton from '../global-components/StopAlertsButton'

// Hooks
import { useAppDispatch } from '../../state/hooks/hooks'

// Slices
import { closeModal } from '../../state/slices/modalsSlice'

//Styles
import { ModalBox, TitleWrapper, DisclaimerText, ButtonWrapper, NewsTitleExcerpt } from './DeleteArticle.styled'

// Types
import { RemoveSocialAlertProp } from '../../state/query/types/TopNewsAndSocialAlertsTypes'
import { RemoveSocialAlerts } from '../../state/query/types/LatestNewsTypes'

// Utils
import { prepareTitleExcerpt } from '../../utils'

export interface RemoveNewsSocialAlertsWithLabelText extends RemoveSocialAlertProp {
  labelTitle?: string
}
export interface RemoveLatestNewsSocialAlertsWithLabelText extends RemoveSocialAlerts {
  labelTitle?: string
}
export type StopAlertsPayloadProps = RemoveNewsSocialAlertsWithLabelText | RemoveLatestNewsSocialAlertsWithLabelText


export default function StopAlertsModal({ payload }: { payload: StopAlertsPayloadProps}): JSX.Element {

  const dispatch = useAppDispatch();
  const themeColors = useContext(ThemeContext)
  const handleCloseModal = () => dispatch({ type: closeModal.type })

  return (
    <ModalBox>
      <TitleWrapper>
        <MaterialIcon iconName="notifications_off" color={themeColors.colors.accent} size={24} />
        <h3>Stop alerts?</h3>
      </TitleWrapper>
      {payload.labelTitle && <NewsTitleExcerpt>{prepareTitleExcerpt(payload.labelTitle as string)}</NewsTitleExcerpt>}
      <DisclaimerText>Stopping alerts will prevent you from receiving notifications for this article. You can resume alerts at any time.</DisclaimerText>
      <ButtonWrapper>
        <ModalButton type="cancel" onClick={handleCloseModal}>Cancel</ModalButton>
        <StopAlertsButton payload={payload} handleCloseModal={handleCloseModal} color={themeColors.colors.accent}/>
      </ButtonWrapper>
    </ModalBox>
  )
}
