import React from 'react'
import CustomDropdownList from '../global-components/CustomDropdownList'
import { IconName } from '../global-components/MaterialIcon'
import { useHistory } from "react-router-dom";

//Redux
import { useAppDispatch, useAppSelector } from '../../state/hooks/hooks'
import { openModal } from '../../state/slices/modalsSlice'

//Utils
import { handleLogout } from '../../utils/handleLogout';

export interface DropdownListOptionsTypes {
  text: string;
  icon: IconName;
  onClick: () => void
}

export default function DropDownMenu() {

  const dispatch = useAppDispatch()
  const isInEditMode = useAppSelector(state => state.appState.isEditModeActive)
  const { auth_token } = useAppSelector(state => state.user)

  function changeRouteOrStayInEditMode(
    path: string,
  ) {
    if (isInEditMode) {
      dispatch(openModal({ name: 'unsavedChanges', props: { fieldName: 'logout', pathTo: path } }))
    } else {
      history && history.push(path)
    }

  }

  const history = useHistory()

  const dropdownListOptionsOne = [
    {
      text: "Account",
      icon: "manage_accounts",
      onClick: () => changeRouteOrStayInEditMode("/settings")
    }, {
      text: "Invoices",
      icon: "receipt_long",
      onClick: () => changeRouteOrStayInEditMode("/invoices")
    },
    // Commented out since we don't currently have a Help page
    // {
    //   text: "Help",
    //   icon: "help_outline",
    //   onClick: () => changeRouteOrStayInEditMode("/help")
    // },
    {
      text: "Log out",
      icon: "logout",
      onClick: function () {
        if (isInEditMode) {
          dispatch(openModal({ name: 'unsavedChanges', props: { fieldName: 'logout' } }))
        } else {
          handleLogout(auth_token, dispatch)
        }
      }
    }
  ] as DropdownListOptionsTypes[]

  return (
    <div>
      <CustomDropdownList
        options={dropdownListOptionsOne}
        dropdownPosition="right"
        hasUserImage={true}
        hasLastChildLikeBtn={true}
      />
    </div>
  )
}
