import React from 'react';
import DateRangeCalendar from '../global-components/DateRangeCalendar'

import { useAppDispatch } from '../../state/hooks/hooks'
import { closeModal } from '../../state/slices/modalsSlice'
import { setPickedRage } from '../../state/slices/dateRangePickerSlice'

interface DateRangePickerProps {
  payload: {
    location: string
  }
}
const DateRangePicker = ({ payload }: DateRangePickerProps) => {

  const { location } = payload;

  const dispatch = useAppDispatch();

  const onSelectCustom = (startDate: Date, endDate: Date) => {
    dispatch({
      type: setPickedRage.type, payload: {
        startDate: JSON.stringify(startDate),
        endDate: JSON.stringify(endDate),
        location: location
      }
    })
    dispatch({ type: closeModal.type })
  }

  const close = () => {
    dispatch({ type: closeModal.type })
  }

  return (
    <div>
      <DateRangeCalendar onSelectCustom={onSelectCustom} closeModal={close} />
    </div>
  );
}

export default DateRangePicker;
