import React from 'react'

import NotificationBar from '../global-components/NotificationBar'

import { useAppDispatch } from '../../state/hooks/hooks'
import { openModal } from '../../state/slices/modalsSlice'
import { DateRange } from '../global-components/DateRangeSelect'
import { handleOnErrorImage } from '../../utils'

//Styles
import {
  DropDownWrapper,
} from './SocialMediaTab.styled'
import { calculatedDurationFromCreationTime } from '../../utils'
import {
  SingleNewsWrapper,
  Info,
  TextWrapper,
  NewsSourceIconWrapper
} from './SingleNews.styled'
import CustomDropdownList from '../global-components/CustomDropdownList'

import { FilterOptions } from './CustomNewsSearchBar'


export interface SingleNews {
  full_text: string
  full_url: string
  media_logo: string
  title: string
  created_at: string
  current_news_key: string;
  alert_id: number | null;
}

export interface SingleNewsSearchTablProps {
  singleNews: SingleNews;
  searchPageNum: number;
  query: {
    search_keyword: string;
    search_range: DateRange;
  };
  filter: FilterOptions;
}



export default function SingleNewsSearchTableRow({ singleNews, query, searchPageNum, filter }: SingleNewsSearchTablProps) {

  const dispatch = useAppDispatch()

  const handleGetAlerts = () => {
    dispatch({
      type: openModal.type,
      payload: {
        name: 'newsCoverage',
        props: {
          id: parseInt(singleNews.current_news_key),
          newsTitle: singleNews.title,
          newsURL: singleNews.full_url,
          newsType: 'existing',
          cacheType: 'GlobalSearch',
          globalNewsFetchParams: {
            ...query,
            filter: filter,
            page: searchPageNum
          },
          post: singleNews,
        }
      }
    })
  }

  // Stopping alerts
  const handleStopAlerts = () => {
    dispatch({
      type: openModal.type,
      payload: {
        name: 'stopAlertsModal',
        props: {
          id: singleNews.alert_id as number,
          cacheType: 'GlobalSearch',
          isTopNewsQuery: true,
          post: singleNews,
          globalNewsFetchParams: {
            ...query,
            filter: filter,
            page: searchPageNum
          },
          labelTitle: singleNews.title
        }
      }
    })
  }
  
  return (
    <SingleNewsWrapper isOpen={false} location='news-search'>
      {singleNews.alert_id && <NotificationBar />}
      <Info location='news-search'>
        <NewsSourceIconWrapper>
          <img src={singleNews.media_logo} alt="News Logo" onError={handleOnErrorImage} />
        </NewsSourceIconWrapper>
        <span>{calculatedDurationFromCreationTime(singleNews.created_at)}</span>
      </Info>

      <TextWrapper location='news-search'>
        <h3><a href={singleNews.full_url} target="_blank" rel="noreferrer">{singleNews.title}</a></h3>
        <p>{singleNews.full_text.slice(0, 100)}</p>
      </TextWrapper>
      <DropDownWrapper>
        <CustomDropdownList
          options={[{
            text: singleNews.alert_id ? "Stop Alerts" : "Get Alerts",
            icon: "notifications",
            onClick: function () {
              if (singleNews.alert_id) {
                handleStopAlerts()
              }
              else {
                handleGetAlerts()
              }
            }
          }]}
          dropdownPosition="right"
          icon="more_vert"
          lastItemSeparator={false}
        />
      </DropDownWrapper>



    </SingleNewsWrapper>
  )
}