import { useContext } from 'react';
import { useAppDispatch } from '../../state/hooks/hooks'
import { closeModal } from '../../state/slices/modalsSlice'
import { showPopup } from '../../state/slices/popupSlice'
import ModalButton from '../global-components/ModalButton'
import MaterialIcon from '../global-components/MaterialIcon'
import { ThemeContext } from 'styled-components'
import { ModalBox, TitleWrapper, NewsTitleExcerpt, DisclaimerText, ButtonWrapper } from './DeleteArticle.styled'
import { useDeleteReportsMutation, useDeleteInvoicesMutation } from '../../state/query/queryApi'

interface DeleteCheckedFromTableProps {
  payload: {
    id: number[];
    modalType: 'report' | 'invoice';
    title: string;
  }
}

export default function DeleteCheckedFromTable({ payload }: DeleteCheckedFromTableProps) {

  const dispatch = useAppDispatch();
  const { colors } = useContext(ThemeContext)

  const [deleteReports, { isLoading: reportsIsLoading }] = useDeleteReportsMutation()
  const [deleteInvoices, { isLoading: invoicesIsLoading }] = useDeleteInvoicesMutation()

  const handleDelete = (type: string, ids: number[]): void => {
    if (type === 'report') {
      deleteReports({ reports: ids }).unwrap()
        .then(() => {
          handleCloseModal()
          dispatch(showPopup({
            event: 'success',
            type: 'alert',
            direction: 'bottom',
            props: {
              popupTitle: 'Report has been deleted'
            }
          }))
        })
        .catch((rejected) => {
          handleCloseModal()
          dispatch(showPopup({
            event: 'error',
            type: 'alert',
            direction: 'bottom',
            props: {
              popupTitle: `${rejected.status !== 'FETCH_ERROR' ? rejected.data.Message : 'Check your network connection!'}`
            }
          }))
        })
    } else {
      deleteInvoices({ invoices: ids }).unwrap()
        .then(() => {
          handleCloseModal()
          dispatch(showPopup({
            event: 'success',
            type: 'alert',
            direction: 'bottom',
            props: {
              popupTitle: 'Invoice has been deleted'
            }
          }))
        })
        .catch((rejected) => {
          handleCloseModal()
          dispatch(showPopup({
            event: 'error',
            type: 'alert',
            direction: 'bottom',
            props: {
              popupTitle: `${rejected.status !== 'FETCH_ERROR' ? rejected.data.Message : 'Check your network connection!'}`
            }
          }))
        })
    }
  }

  const handleCloseModal = () => dispatch({ type: closeModal.type })

  const prepareModalText = (numberOfItems: number, type: string, location: 'header' | 'disclaimer'): string => {
    let text

    if (location === 'header') {
      if (numberOfItems < 2) {
        text = `Delete this ${type}?`
      } else {
        text = `Delete ${numberOfItems} ${type}s?`
      }
    } else {
      if (numberOfItems < 2) {
        text = `Once you delete this ${type}, it will no longer be available in the ${type} list.`
      } else {
        text = `Once you delete ${type}s, they will no longer be available in the ${type} list.`
      }
    }
    return text
  }

  return (
    <ModalBox>
      <TitleWrapper>
        <MaterialIcon iconName="warning_filled" color={colors.redAlert} size={24} />
        <h3>{prepareModalText(payload.id.length, payload.modalType, 'header')}</h3>
      </TitleWrapper>
      {payload.title && <NewsTitleExcerpt>{payload.title}</NewsTitleExcerpt>}
      <DisclaimerText>{prepareModalText(payload.id.length, payload.modalType, 'disclaimer')}</DisclaimerText>
      <ButtonWrapper>
        <ModalButton type="cancel" onClick={handleCloseModal}>Cancel</ModalButton>
        <ModalButton type="confirm" color={colors.redAlert} isLoading={payload.modalType === 'report' ? reportsIsLoading : invoicesIsLoading} onClick={() => handleDelete(payload.modalType, payload.id)}>Delete</ModalButton>
      </ButtonWrapper>
    </ModalBox>
  );
}
