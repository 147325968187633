import styled from 'styled-components'
import { centerFlex } from '../../global-styles/mixins'
import { device } from '../../global-styles/mediaQueries'

export const SocialStatsWrapper = styled.section`
  min-height: 296px;
  position: relative;
  display: flex;
  flex-flow: column;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 5px 5px -4px rgba(49, 65, 97, 0.08);
  z-index: 25;
  @media ${device.tablet} {
    flex-flow: row;
    flex-wrap: wrap;
    min-height: 120px;
    
  }
  @media ${device.tabletL} {
    grid-area: engagement;

  }
  @media ${device.desktopS} {
    max-width: 1100px;
  }

`
//Single social main wrapper 
export const SingleSocialStatWrapp = styled.div`
  position: relative;
  display: flex;
  padding: 40px 0px 40px 16px;                       
  justify-content: flex-start;
  &:after {
    content: '';
    position: absolute;
    left: 16px;
    bottom: 0;
    width: calc(100% - 32px);
    height: 1px;
    background-color: ${({ theme }) => theme.colors.borderPrimary};
  }
  &:last-of-type {
    &:after {
      display: none;
    }
  }

  @media ${device.mobileL} {
    padding: 40px 0px 40px 20px;                       

  }  
  @media ${device.tablet} {
    width: 50%;
    padding: 40px 0px 40px 24px;                       
    &:after {
      display: none;
    }
    &:nth-of-type(even) {
      &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 76px;
        top: calc(50% - 38px);
        right: 0;
        background-color: ${({ theme }) => theme.colors.borderPrimary};
      }
    }
  
  }
`

export const SingleSocialStatIcon = styled.div`
  align-self: end;
  display: flex;
  justify-content: flex-start;
  padding: 0px 24px 0px 0px;
`

export const SingleSocialStatInfoNumber = styled.p`
  display: flex;
  align-items: flex-end;
  margin: 6px 0 0px 0;
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primary};
`

export const SingleSocialStatInfoTrend = styled.p<{ isRising?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 0 7px 12px;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${({ isRising, theme }) => isRising ? theme.colors.green : theme.colors.redAlert};
  svg {
    margin-bottom: 1px;
  }
`


export const SingleSocialStatIconWrapp = styled.div<{ bgColor: string }>`
  width: 68px;
  height: 68px;
  ${centerFlex};
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor};
`

export const SocialStatFilterWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 12px;
  right: 16px;
  z-index: 10;
  @media ${device.tablet} {
    top: 14px;
    right: 24px;
  }
`