import styled, { css } from 'styled-components'
//Interface
import { InputField } from './GlobalInterfaces'
import { centerFlex } from '../../global-styles/mixins'
import { globalFocusStyles } from '../../global-styles/globalStyles';

export type ButtonSize = 'small' | 'medium' | 'large'

export interface DefaultButtonProps extends InputField {
  size: ButtonSize,
  hoverColor?: string,
  onClick?: () => void,
}

const handlePadding = (size: ButtonSize) => {
  switch (size) {
    case 'small':
      return '7px 18px'
    case 'medium':
      return '11px 24px'
    case 'large':
      return '15px 30px'
  }
}

export const Icons = styled.span`
  font-family: 'Material Icons O';
  font-weight: normal;
  font-style: normal;
  font-size: 30px;
`
export const FillIcons = styled.span`
  font-family: 'Material Icons';
  font-weight: 'normal';
  font-style: 'normal';
  font-size: '30px';
`

export const DefaultButton = styled.button<DefaultButtonProps>`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${({ size }) => handlePadding(size)};
  justify-content: center;
  font-size: ${({ size }) => size === 'small' ? '13px' : '14px'};
  font-weight: bold;
  color: ${({ isDisabled, theme, color, }) => isDisabled ? theme.colors.default_600 : color ? color : theme.colors.white};
  background-color: ${({ isDisabled, theme, bgColor }) => isDisabled ? theme.colors.default_200 : bgColor};
  border-radius: 4px;
  border: none;
  line-height: 1.29;
  cursor: pointer;
  transition: 0.3s;
  pointer-events: ${props => props.isDisabled ? "none" : "auto"};
  > svg {
    position: relative;
    margin-right: 8px;
  }

  // Custom focus styles
  ${(props) => !props.isDisabled && css`
    &:focus-visible {
      ${globalFocusStyles}
    }
  `}
  
  
  &:hover {
    ${(props) => !props.isDisabled && css`
      background-color: ${() => props.hoverColor ? props.hoverColor : props.theme.colors.accent};
    `}
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.accentDark};
  }
`;

const calcIconButtonSize = (size: ButtonSize) => {
  switch (size) {
    case 'small':
      return '28px';
    case 'medium':
      return '32px';
    case 'large':
      return '36px';
  }

}

export const IconButton = styled.button<{ size: ButtonSize, bgColor: string, isDisabled: boolean, autoFocus?: boolean, tabIndex?: string }>`
  position: relative;
  ${centerFlex};
  background-color: ${({ bgColor }) => bgColor};
  width: ${({ size }) => calcIconButtonSize(size)};
  height: ${({ size }) => calcIconButtonSize(size)};
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
  border: none;
  ${({ theme, isDisabled }) => isDisabled && css`
    pointer-events: none;
    background-color: ${theme.colors.secondary}20;
  `}

`

export const IconButtonSetOne = styled(IconButton)`
  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.accent};
  }
  &:focus, 
  &:focus-within {
    background-color: ${({ theme }) => theme.colors.accentDark};
  }
`


export const IconButtonSetTwo = styled(IconButtonSetOne)`
  svg {
    fill: ${({ theme }) => theme.colors.secondary};
  }
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
  &:focus,
  &:focus-within {
    background-color: ${({ theme }) => theme.colors.accentDark};
    svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`


export const IconButtonSetThree = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.highlight};
  svg {
    fill: ${({ theme }) => theme.colors.secondary};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.body};
  }

  // Custom focus styles
  ${globalFocusStyles}
`

export const IconButtonSetFour = styled(IconButton)`
  svg {
    fill: ${({ theme }) => theme.colors.secondary};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.highlight};
    svg {
      fill: ${({ theme }) => theme.colors.accent};
    }
  }
  &:focus,
  &:focus-within {
    background-color: ${({ theme }) => theme.colors.highlight};
    svg {
      fill: ${({ theme }) => theme.colors.accent};
    }
  }
`

export const IconButtonSetFive = styled(IconButton)`
  svg {
    fill: ${({ theme }) => theme.colors.secondary};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.body};
    svg {
      fill: ${({ theme }) => theme.colors.primaryDark};
    }
  }
  &:focus,
  &:focus-within {
    background-color: ${({ theme }) => theme.colors.body};
    svg {
      fill: ${({ theme }) => theme.colors.primaryDark};
    }
  }
`

export const IconButtonSetSix = styled(IconButton)`
svg {
    fill: ${({ theme }) => theme.colors.secondary};
  }
  // Custom focus styles
  ${globalFocusStyles}
`