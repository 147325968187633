import React from 'react'
import CustomDropdownList from '../global-components/CustomDropdownList'
import { IconName } from '../global-components/MaterialIcon'
import styled, { css } from 'styled-components'
import { device } from '../../global-styles/mediaQueries'

interface DropdownListOptionsTypes {
  text: string;
  icon: IconName;
  onClick: () => void
}

interface SingleNewsDropdownProps {
  newsId: number;
  notification?: number | boolean;
  hasAlertOptions?: boolean;
  isSocialAlert?: boolean;
  isSingleArticle?: boolean;
  handleDeleteNews?: () => void;
  handleStopAlerts?: () => void;
  handleGetAlerts?: () => void;
}


export const StyledWrapper = styled.div<{ isSingleArticle: boolean }>`
  position: absolute;
  top: 24px;
  right: 16px;

  ${({ isSingleArticle }) => isSingleArticle && css`
    position: relative;
    top: 0;
    right: 0;
  `}

  @media ${device.tablet} {
    top: 28px;
    right: 24px;

    ${({ isSingleArticle }) => isSingleArticle && css`
    top: 0;
    right: 0;
  `}
  }
`

export default function SingleNewsDropdown({
  newsId,
  notification = 0,
  hasAlertOptions = true,
  isSocialAlert = false,
  isSingleArticle = false,
  handleDeleteNews,
  handleStopAlerts,
  handleGetAlerts
}: SingleNewsDropdownProps) {


  function prepareDropdownList(): DropdownListOptionsTypes[] {

    const stopAlertOption: DropdownListOptionsTypes = {
      text: "Stop Alerts",
      icon: "notifications_off",
      onClick: function () {
        handleStopAlerts && handleStopAlerts()
      }
    }

    const getAlertOption: DropdownListOptionsTypes = {
      text: "Get Alerts",
      icon: "notifications",
      onClick: function () {
        handleGetAlerts && handleGetAlerts()
      }
    }

    const deleteOption: DropdownListOptionsTypes = {
      text: "Delete",
      icon: "delete",
      onClick: function () {
        handleDeleteNews && handleDeleteNews()
      }
    }

    const optionArray = []
    const alertOption = notification ? stopAlertOption : getAlertOption

    if (isSocialAlert || isSingleArticle) {
      optionArray.push(alertOption)
    } else {
      optionArray.push(alertOption, deleteOption)
    }
    return optionArray

  }

  return (
    <StyledWrapper isSingleArticle={isSingleArticle}>
      <CustomDropdownList
        options={prepareDropdownList()}
        dropdownPosition="right"
        icon="more_vert"
        whiteBackground={isSingleArticle}
        lastItemSeparator={!isSingleArticle && !isSocialAlert}
      />
    </StyledWrapper>
  )
}
