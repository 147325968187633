import { useState } from 'react'
import { useHistory } from "react-router-dom";

type HistoryLocationState<T> = {
  [key: string]: unknown
  states?: {
    [key: string]: T;
  }
}

/**
 * useHistoryState hook keeps track of a state and keeps it in react-router history object.
 * 
 * @param key - Key value for keeping state in react-router history object
 * @param initialValue - Inital value of the state(number, string...)
 * @returns state and setHistoryState tuple
 */
function useHistoryState<T>(key: string, initialValue: T): [T, (t: T) => void] {
  const history = useHistory();
  const getHistoryState = () => history.location?.state as HistoryLocationState<T>;

  const [rawState, rawSetState] = useState<T>(() => {
    const value = getHistoryState()?.states?.[key];
    return value || initialValue;
  });

  function setHistoryState(value: T) {

    const historyState = getHistoryState();

    // replace state in states object, without overwriting existing data.
    history.replace({
      ...history.location,
      state: {
        ...historyState,
        states: {
          ...historyState?.states,
          [key]: value
        }
      }
    });
    // set state localy
    rawSetState(value);
  }

  return [rawState, setHistoryState];
}

export default useHistoryState