import styled from 'styled-components'
import { device } from "../../global-styles/mediaQueries";
import { addOpacityToHex } from '../../utils'

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  background-color: ${({ theme }) => addOpacityToHex(theme.colors.primary, 0.5)};
  z-index: 100;

  @media ${device.mobileL} {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media ${device.tablet} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`
export const HiddenDivForInitialFocus = styled.div`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  outline: none;
  border: 0;
  transform: translateX(-1000px); 
`