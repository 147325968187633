import React from 'react';
import { Link } from 'react-router-dom'

const NewsCoverageInfo = ({ sideEffect }: { sideEffect: () => void }) => {

  return (
    <>
      <p>Monitor article stats in <Link to="/dashboard/social-alerts" onClick={sideEffect}>Social alerts</Link> page.</p>
    </>
  );

}

export default NewsCoverageInfo;
