import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Tooltip {
  name: string;
  props: any;
  open: boolean;
  isInNoDataChart?: boolean;
}

const initialState = {
  name: '',
  props: {},
  open: false,
  isInNoDataChart: false
} as Tooltip


const tooltipSlice = createSlice({
  name: 'tooltips',
  initialState,
  reducers: {

    showTooltip: (state, action: PayloadAction<{ name: string, props?: any }>) => {
      state.name = action.payload.name;
      if (action.payload.props) state.props = action.payload.props;
      state.open = true;
    },
    hideTooltip: (state) => {
      state.name = '';
      state.props = {};
      state.open = false;
    },
  },
})


export const {
  showTooltip,
  hideTooltip
} = tooltipSlice.actions

export default tooltipSlice.reducer