import React from 'react';
import styled, { css } from 'styled-components'
import { device } from "../../global-styles/mediaQueries";

import Spinner from './ButtonSpinner'
import { globalFocusStyles } from '../../global-styles/globalStyles'


type ActiveBgColorsProps = 'blue' | 'green' | 'yellow' | 'red'

// Background color for the active state
const getBgColorActiveState = (bgColorActiveState: string | undefined, theme: any) => {
  switch (bgColorActiveState) {
    case 'blue':
      return theme.colors.accentDark;
    case 'green':
      return theme.colors.greenDark;
    case 'yellow':
      return theme.colors.orange;
    case 'red':
      return theme.colors.redAlertDark;
  }
}

export const CancelButton = styled.button<{ isDisabled?: boolean }>`
  margin-right: 18px;
  //I have margin top set up on first-of-type buttons in this section, but this one should avoid that prop so that is reason for using !important
  margin-top: 0px !important; 
  padding: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.secondary};
  opacity: ${({ isDisabled }) => isDisabled ? '0.5' : 'unset'};
  background-color: unset;
  border: none;
  outline: none;
  cursor: pointer;
  pointer-events: ${({ isDisabled }) => isDisabled ? "none" : "auto"};
  // Custom focus styles
  ${globalFocusStyles}
  
  @media ${device.tablet} {
    margin-right: 22px;
  }
`

export const ConfirmButton = styled.button<{ color?: string, isDisabled: boolean, bgColorActiveState?: ActiveBgColorsProps }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  min-width: 92px;
  padding: 4px 20px;
  color: ${({ theme, isDisabled }) => isDisabled ? theme.colors.default_600 : theme.colors.white};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  border-radius: 4px;
  background-color: ${({ isDisabled, color, theme }) => isDisabled ? theme.colors.default_200 : color === undefined ? theme.colors.accent : color};
  border: none;
  outline: none;
  cursor: pointer;
  pointer-events: ${({ isDisabled }) => isDisabled ? "none" : "auto"};

  // Custom focus styles
  ${({ isDisabled }) => !isDisabled && css`
    ${globalFocusStyles}
  `}

  &:active {
    background-color: ${({ bgColorActiveState, theme }) => getBgColorActiveState(bgColorActiveState, theme)};
  }
  
  &:focus:active {
    outline: 1px solid ${({ bgColorActiveState, theme }) => getBgColorActiveState(bgColorActiveState, theme)};
  }
`

export const DownloadLink = styled.a<{ color?: string, isDisabled?: boolean, minWidth?: number }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  min-width: ${({ minWidth }) => minWidth ? `${minWidth}px` : '92px'};
  padding: 4px 20px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ color }) => color};
  border: none;
  outline: none;
  cursor: pointer;
  pointer-events: ${({ isDisabled }) => isDisabled ? "none" : "auto"};
  text-decoration: none;
  overflow: hidden;
`

export const SpinnerWrapper = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.accent};
`



interface ModalButtonProps {
  type: 'confirm' | 'download' | 'cancel';
  disabled?: boolean;
  isLoading?: boolean;
  color?: string;
  children: string | React.ReactElement[];
  onClick?: () => void;
  downloadURL?: string;
  fileName?: string;
  minWidth?: number;
  bgColorActiveState?: ActiveBgColorsProps;
}



const ModalButton = ({ type, disabled = false, isLoading, color = "#0284FE", bgColorActiveState, children, onClick, downloadURL, fileName, minWidth }: ModalButtonProps) => {

  switch (type) {
    case 'confirm':
      return (
        <ConfirmButton bgColorActiveState={bgColorActiveState} isDisabled={disabled} color={color} onClick={onClick} tabIndex={0}>
          {isLoading ? <Spinner size={18} /> : children}
        </ConfirmButton>
      )
    case 'download':
      return (
        <DownloadLink
          href={downloadURL}
          isDisabled={isLoading}
          color={color}
          download={fileName}
          minWidth={minWidth}
          tabIndex={0}
        >
          {children}
          {isLoading && <SpinnerWrapper><Spinner size={18} /></SpinnerWrapper>}
        </DownloadLink>
      )
    case 'cancel':
      return <CancelButton onClick={onClick} isDisabled={disabled} tabIndex={0}>{children}</CancelButton>
  }
}

export default ModalButton;
