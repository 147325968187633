import { Helmet } from 'react-helmet-async'

type SeoProps = {
  title?: string;
}

export default function SEO({ title }: SeoProps) {

  return (
    <Helmet>
      <title>{`Ratsjá ${title ? "|" : ""} ${title}`}</title>
    </Helmet>
  )
}