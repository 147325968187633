import React, { useContext, useRef, useEffect } from 'react'

//Components
import { InputField } from './GlobalInterfaces'
import MaterialIcon, { IconName } from '../global-components/MaterialIcon'
import GeneralTooltipCaller from './tooltips/GeneralTooltipCaller';

//Styles
import { ThemeContext } from 'styled-components';
import { TriangleBox, CustomInputLabel, LabelTooltipWrapper, StyledCustomInput, StyledInputWrapper, CancelIcon, IconInBox, ErrorMessage, HelperBox } from './CustomInput.styled'

//Redux
import { useAppDispatch } from '../../state/hooks/hooks'
import { updateEditMode } from '../../state/slices/appState'

import { trimInput } from '../../utils'

export interface EventCodeExtended extends React.KeyboardEvent<HTMLInputElement> {
  code: string;
}

interface CustomInputProps extends InputField {
  inputVal: string;
  setInputVal: React.Dispatch<React.SetStateAction<any>>;
  isGray: boolean;
  name: string;
  color?: string;
  borderColor?: string;
  placeholder: string;
  isSubmited?: boolean;
  iconName?: IconName;
  iconColor?: string;
  hasIcon?: boolean;
  hasCancelIcon?: boolean;
  hasIconBackground?: boolean;
  customErrorMessage?: string;
  hasDropdown?: boolean;
  handleOnTriangleClick?: () => void
  id?: string;
  hasEditMode?: boolean;
  focusOnMount?: boolean;
  label?: string;
  hasLableTooltip?: boolean,
  tooltipText?: string;
  labelHtmlFor?: string;
  hasHelperText?: boolean;
  helperText?: string;
  hasHelperTextIcon?: boolean;
  helperTextIconName?: IconName;
  helperTextIconColor?: string;
  onKeyDown?: (e: EventCodeExtended) => void;
}

function CustomInput({
  inputVal, setInputVal, isGray, label, hasLableTooltip, tooltipText, labelHtmlFor, name, placeholder, iconName, hasHelperText, helperText, hasHelperTextIcon, helperTextIconColor, helperTextIconName,
  hasIcon, hasCancelIcon, iconColor, hasIconBackground = true, isError = false, isDisabled = false, isSubmited = false, customErrorMessage = 'Test Error for now',
  id, hasEditMode = false, hasDropdown, handleOnTriangleClick, focusOnMount, onKeyDown }: CustomInputProps) {

  const dispatch = useAppDispatch()

  const themeContext = useContext(ThemeContext)
  const startValue = useRef(inputVal)
  const input = useRef<HTMLInputElement>(null)

  function setChangesInEditModeGlobalState(startFieldValue: string, fieldValue: string, fieldName: string) {
    dispatch(updateEditMode({ startFieldValue: startFieldValue, fieldValue: fieldValue, fieldName: fieldName }))
  }

  useEffect(() => {
    if (focusOnMount) {
      input.current?.focus()
    }
  }, [focusOnMount])

  useEffect(() => {
    if (isSubmited) {
      startValue.current = inputVal
    }
    //eslint-disable-next-line
  }, [isSubmited])

  return (
    <>
      {label && <CustomInputLabel htmlFor={labelHtmlFor} isDisabled={isDisabled}>
        {label}
        {hasLableTooltip && <LabelTooltipWrapper isDisabled={isDisabled}>
          <GeneralTooltipCaller name="basic" content={tooltipText} />
        </LabelTooltipWrapper>}
      </CustomInputLabel>}
      <StyledInputWrapper>

        <StyledCustomInput
          value={inputVal}
          onChange={(e) => {
            setInputVal(trimInput(e.target.value))
            hasEditMode && setChangesInEditModeGlobalState(startValue.current, e.target.value, name)
          }}
          hasIcon={hasIcon}
          isError={isError}
          isDisabled={isDisabled}
          placeholder={placeholder}
          isGrayBg={isGray}
          isCloseIconHidden={!!inputVal}
          hasIconBackground={hasIconBackground}
          hasCancelIcon={hasCancelIcon}
          hasDropdown={hasDropdown}
          id={id}
          disabled={isDisabled}
          name={name}
          ref={input}
          onKeyDown={onKeyDown}
        />
        {hasIcon && iconName &&
          <IconInBox isDisabled={isDisabled} isError={isError} hasIconBackground={hasIconBackground}>
            <MaterialIcon iconName={iconName} color={iconColor} />
          </IconInBox>}
        {hasDropdown &&
          <TriangleBox
            isDisabled={isDisabled}
            onClick={() => !isDisabled && handleOnTriangleClick && handleOnTriangleClick()}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.keyCode === 13) {
                !isDisabled && handleOnTriangleClick && handleOnTriangleClick()
              }
            }}
            tabIndex={0}
          >
            <svg viewBox="0 0 34 44" width="34" xmlns="http://www.w3.org/2000/svg">
              <polygon points="14,20 18,24 22,20" fill='#68728c' />
              <line x1="0" x2="1" y1="10" y2="34" stroke="#dbe3ea" />
            </svg>
          </TriangleBox>
        }
        {/* Cancel icon  */}
        <CancelIcon hasDropdown={hasDropdown} isDisabled={isDisabled} isShown={!!inputVal} onClick={() => {
          setInputVal('')
          hasEditMode && setChangesInEditModeGlobalState(startValue.current, '', name)
        }}>
          <MaterialIcon iconName="close" color='#000' />
        </CancelIcon>

        {/**Error message */}
        {isError &&
          <ErrorMessage>
            <div><MaterialIcon iconName="error_filled" color={themeContext.colors.redAlert} /></div>
            {customErrorMessage}
          </ErrorMessage>}

      </StyledInputWrapper>
      {/* Helper text with or without icon which we can use below the input field */}
      {hasHelperText &&
        <HelperBox isError={isError} isDisabled={isDisabled}>
          {hasHelperTextIcon && helperTextIconName && <div><MaterialIcon iconName={helperTextIconName} color={helperTextIconColor} /></div>}
          {helperText}
        </HelperBox>
      }
    </>
  )
}

export default React.memo(CustomInput)
// export default CustomInput