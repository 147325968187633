import React from 'react'
import NewsTermInput from './NewsTermInput'
import { useAppDispatch } from '../../state/hooks/hooks'
import { useAddOrRemoveTagsMutation } from '../../state/query/queryApi'
import { LatestNewsFetchParams } from '../../state/query/types/LatestNewsTypes'

import { showPopup } from '../../state/slices/popupSlice'

interface SingleNewsTagsProps {
  tags: string[];
  tags_company?: string[];
  newsId: number;
  newsKey: number;
  searchParams?: LatestNewsFetchParams
}


export default function SingleNewsTags({ tags, tags_company, newsKey, searchParams }: SingleNewsTagsProps) {
  const dispatch = useAppDispatch()
  const [triggerTagsUpdate] = useAddOrRemoveTagsMutation()

  const updateCustomTags = (tags: any) => {
    return triggerTagsUpdate({
      params: searchParams,
      newsKey: newsKey,
      requestBody: {
        tags_json: tags
      }
    }).unwrap().catch(() => dispatch(showPopup({
      event: 'error',
      type: 'alert',
      direction: 'bottom',
      props: {
        popupTitle: `Addig/removing tag failed. Check internet connection and try again.`
      }
    })))
  }


  return (
    <div>
      <NewsTermInput
        tags_custom={tags}
        tags_company={tags_company}
        inputName="text"
        tagStyle="primary"
        type='text'
        updateCustomTags={updateCustomTags}
      />
    </div>
  )
}
