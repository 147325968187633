import React, { useState, useEffect, useContext } from 'react'
import { ThemeContext } from 'styled-components'

//Styles 
import { DropdownArrow, LableText } from './SingleNews.styled'
import { TextWrapper, SocialAlertRow, SingleContentBox, ExpiresDate, RowText, RowTitle, TotalEngagements, DalilyEngagements } from './SingleSocialAlertRow.styled';

//Components 
import MaterialIcon from '../global-components/MaterialIcon';
import DailyChart from './DailyChart'
import SingleNewsDropdown from './SingleNewsDropdown'

//RTK & Query
import { useAppDispatch } from '../../state/hooks/hooks'

//Utils
import { formatThousandNumbers } from '../../utils'

import { openModal } from '../../state/slices/modalsSlice'

//Types 
import { SocialAlertRowProps, TimeObj } from '../../types/TopNewsTypes'

export default function SingleSocialAlertRow({ post }: SocialAlertRowProps) {

  const themeContext = useContext(ThemeContext)
  const dispatch = useAppDispatch()

  const [isOpen, setIsOpen] = useState(false)
  const toggleAccordion = () => setIsOpen(isOpen => !isOpen)

  //How far my number is from 60
  const upToSixty = (n: number) => 60 - n

  //How far my number is from 24
  const upTo24 = (n: number) => 24 - n

  const getTodaysDay = () => new Date()

  //Calc distance betwen todays time & expiration data. Uses as reduce callback
  const calcDiferenceBetweenTime = (acc: TimeObj, curr: number, index: number, expirationTime: number[]) =>
    index === 0
      ? acc.hour = upTo24(curr) + expirationTime[index]
      : acc.min = upToSixty(expirationTime[index]) - upToSixty(curr)

  //GO through number[], subtract currTime - expirationTime and return object with strings
  const calcHoursAndMinutes = (todayTime: number[], expirationTime: number[]): TimeObj =>
    todayTime.reduce((acc: TimeObj, curr: number, index: number): TimeObj => {
      calcDiferenceBetweenTime(acc, curr, index, expirationTime)
      return acc
    }, { hour: 0, min: 0 })

  function trimWords(string: string, type: 'title' | 'text', endNum: number, titleUrl?: string) {

    if (type === 'text') {
      if (string.length < endNum) {
        return <RowText>{string}</RowText>
      } else if (string.length >= endNum) {
        return <RowText>{string.substring(0, endNum)}...</RowText>
      }
    } else {
      if (string.length < endNum) {
        return <RowTitle target="_blank" href={titleUrl}>{string}</RowTitle>
      } else if (string.length >= endNum) {
        return <RowTitle target="_blank" href={titleUrl}>{string.substring(0, endNum)}...</RowTitle>
      }
    }
  }

  //Convert date & time to string
  function returnStringForHoursAndMins({ hour, min }: TimeObj): string {
    if (hour >= 24) {
      return '1 day'
    } else if (hour > 1) {
      return hour > 1 ? `${hour} hours` : `${hour} hour`
    } else {
      return min > 1 ? `${min} minutes` : `${min} minute`
    }
  }

  //Calculate and return expiration date in string type => from UTC date
  function calcExpirationDate(): string {

    const MS_PER_DAY = 1000 * 60 * 60 * 24;

    const today = getTodaysDay()
    const expirationDate = post.alert_expiration.split(' ')
    const exppirationDay = new Date(expirationDate[0])
    const todayDate = new Date(today)

    const utc1 = Date.UTC(exppirationDay.getFullYear(), exppirationDay.getMonth(), exppirationDay.getDate());
    const utc2 = Date.UTC(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate());

    const calcDays = Math.floor((utc1 - utc2) / MS_PER_DAY);

    if (calcDays === 1) {
      let currHours = [todayDate.getHours(), todayDate.getMinutes()]

      const expirationHours: number[] | undefined = expirationDate[1]
        .split(':')
        .map((time: string) => parseInt(time))
        .slice(0, -1)

      return returnStringForHoursAndMins(calcHoursAndMinutes(currHours, expirationHours))

    } else if (calcDays > 1) {

      return `${calcDays} days`

    } else if (calcDays === 0) {

      const currHours = [todayDate.getHours(), todayDate.getMinutes()]

      const expirationHours: number[] | undefined = expirationDate[1]
        .split(':')
        .map((time: string) => parseInt(time))
        .slice(0, -1)

      return returnStringForHoursAndMins(calcHoursAndMinutes(currHours, expirationHours))

    } else {
      return `Expired` // This is the case only if something went wrong from server 
    }

  }

  // Removing alert from list
  const handleStopAlerts = () => {
    dispatch({
      type: openModal.type,
      payload: {
        name: 'stopAlertsModal',
        props: {
          id: post.id,
          cacheType: 'SocialAlerts',
          isTopNewsQuery: false,
          post: post,
          labelTitle: post.title
        }
      }
    })
  }

  useEffect(() => {
    calcExpirationDate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SocialAlertRow isOpen={isOpen}>
      <DropdownArrow isOpen={isOpen} onClick={toggleAccordion} className="dropdown-arrow social-alert-dropdown">
        <MaterialIcon iconName="expand_more" color={themeContext.colors.accent} />
      </DropdownArrow>

      <SingleNewsDropdown
        newsId={parseInt(post.current_news_key)}
        handleStopAlerts={handleStopAlerts}
        notification={true}
        hasAlertOptions={true}
        isSocialAlert={true}
      />

      <SingleContentBox>

        <ExpiresDate>
          <MaterialIcon iconName="timer" color={themeContext.colors.primary} />
          {calcExpirationDate()}
        </ExpiresDate>
        <TextWrapper>
          {trimWords(post.title, 'title', 35, post.source_url)}
          {trimWords(post.message, 'text', 100)}
        </TextWrapper>

        <TotalEngagements isOpen={isOpen}>
          <LableText className="tablet-hidden">Total Engagement</LableText>
          <p>{formatThousandNumbers(post.total_engagement)}</p>
        </TotalEngagements>

        <DalilyEngagements isOpen={isOpen}>
          <LableText className="desktop-hidden">Daily Engagement</LableText>
          <DailyChart size="small" engagenmentData={post.engagements} />
        </DalilyEngagements>

      </SingleContentBox>

    </SocialAlertRow>
  )
}
