import styled, { css } from 'styled-components'

export const ButtonWrapper = styled.div`
  width: 88px;
  height: 28px;
  position: relative;
  display: flex;
  justify-content: space-between;
`

export const SentimentButton = styled.div<{ type: string, selected: number | null }>`
  position: relative;
  width: 28px !important;
  height: 28px;
  background-color: ${({ theme }) => theme.colors.body};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.secondary};
  display:flex;
  justify-content: center;
  align-items: center;
  transition: all ease 200ms;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    opacity: 0;
    transition: all cubic-bezier(.28,.67,.17,1.49) 400ms;
  }

  &::after {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    display: none;
    border: 2px solid ${({ theme }) => theme.colors.accent};
    z-index: 1;
  }

  &:focus-visible {
    outline: 4px solid ${({ theme }) => theme.colors.white};
    
    &::after {
      display: block;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.redAlert};
    color: ${({ theme }) => theme.colors.white};;
    z-index: 1;
    svg { 
      fill: ${({ theme }) => theme.colors.white};;
    }

    &:before {
      width: 130%;
      height: 130%;
      opacity: 0.3;
    }
  }


  ${({ selected }) => selected !== null && css`
    opacity: 0;
    pointer-events: none;
    border-radius: 4px;

    svg { 
      fill: #fff;
      transform: rotate(-90deg)
    }

    &:before,
    &:after {
      border-radius: 4px;
    }
  `}
  
  // NEGATIVE SENTIMENT

  ${({ type, selected }) => type === 'negative' && css`
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;


    &:before {
      background-color: ${({ theme }) => theme.colors.redAlert};
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:focus-visible {
      &:after {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.redAlert};
    }

    ${selected === -1 && css`
      opacity: 1;
      pointer-events: all;
      background-color: ${({ theme }) => theme.colors.redAlert};
      color: ${({ theme }) => theme.colors.white};
      transform: translate(30px, 0%) rotate(90deg);

      &::before {
        border-radius: 4px
      }
      &::after {
        border-radius: 8px;
      }
    `}
  `}


  //NEUTRAL SENTIMENT

  ${({ type, selected }) => type === 'neutral' && css`
    margin: 0 3px;


    &:before {
      background-color: ${({ theme }) => theme.colors.neutral};
      border-radius: 0;
      z-index: -1;
    }

    
    &:hover {
      background-color: ${({ theme }) => theme.colors.neutral};
    }

    ${selected === 0 && css`
      opacity: 1;
      pointer-events: all;
      background-color: ${({ theme }) => theme.colors.neutral};
      color: ${({ theme }) => theme.colors.white};
      transform: translate(0%, 0%) rotate(90deg);
      border-radius: 4px;

      &:before {
        border-radius: 4px;
      }
      &:after {
        border-radius: 8px;
      }
    `}
  `}


  //POSITIVE SENTIMENT

  ${({ type, selected }) => type === 'positive' && css`
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    &:before {
      background-color: ${({ theme }) => theme.colors.green};
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:focus-visible {
      &:after {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.green};
    }

    ${selected === 1 && css`
      opacity: 1;
      pointer-events: all;
      background-color: ${({ theme }) => theme.colors.green};
      transform: translate(-30px, 0%) rotate(-90deg);
      border-radius: 4px;

      &:before {
        border-radius: 4px;
      }
      &:after {
        border-radius: 8px;
      }
    `}
  `}
`