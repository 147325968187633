import React, { useState, useEffect, KeyboardEvent } from 'react';
import OutsideClickWrapper from './OutsideClickWrapper'
import { v4 as uuidv4 } from 'uuid'
import MaterialIcon, { IconName } from './MaterialIcon'
import UserImage from './UserImage'

import {
  DropDownContainer,
  OptionsListHeader,
  DropDownListContainer,
  DropDownList,
  ListItem,
  FileExportListItem
} from './CustomDropdown.styled'

export interface Options {
  text: string;
  icon: IconName;
  onClick: () => void
}


interface DropdownListProps {
  options?: Options[];
  dropdownPosition?: 'left' | 'center' | 'right';
  icon?: IconName;
  lastItemSeparator?: boolean;
  hasUserImage?: boolean;
  children?: React.ReactElement | React.ReactElement[];
  passedElementIcon?: IconName;
  openCloseSideeffect?: (open: boolean) => void;
  whiteBackground?: boolean;
  hasLastChildLikeBtn?: boolean;
}

export default function CustomDropdownList({
  options,
  dropdownPosition = 'right',
  icon,
  lastItemSeparator,
  hasUserImage = false,
  children,
  passedElementIcon,
  openCloseSideeffect,
  whiteBackground = false,
  hasLastChildLikeBtn = false
}: DropdownListProps) {

  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);
  const closeOnClickOutside = () => setIsOpen(false)

  const handleOnKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      toggling();
    }
  };

  const onOptionClicked = (callback: () => void) => () => {
    callback()
    setIsOpen(false);
  };

  const handleListItemKeyDown = (callback: () => void) => (e: KeyboardEvent<HTMLLIElement>) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      onOptionClicked(callback)();
    }
  };

  useEffect(() => {
    openCloseSideeffect && openCloseSideeffect(isOpen)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <DropDownContainer className="dropdown-container">
      <OutsideClickWrapper handlerFunc={closeOnClickOutside} enabled={isOpen}>
        <OptionsListHeader
          onClick={toggling}
          onKeyDown={handleOnKeyDown}
          isActive={isOpen}
          hasImage={hasUserImage}
          tabIndex={0}
          whiteBackground={whiteBackground}
        >
          {hasUserImage ? (
            <>
              <UserImage hasBorder={false} size="small" />
              <MaterialIcon iconName={'arrow_drop_down'} />
            </>
          ) : (
            icon && <MaterialIcon iconName={icon as IconName} />
          )}
        </OptionsListHeader>
        {isOpen && (
          <DropDownListContainer position={dropdownPosition} >
            <DropDownList>
              {options && options.map(option => (
                <ListItem
                  onClick={onOptionClicked(option.onClick)}
                  onKeyDown={handleListItemKeyDown(option.onClick)}
                  key={uuidv4()}
                  lastItemSeparator={lastItemSeparator} hasLastChildLikeBtn={hasLastChildLikeBtn} tabIndex={0}>
                  <MaterialIcon iconName={option.icon} />
                  <span>{option.text}</span>
                </ListItem>
              ))}
              {!options && children && React.Children.map(children,
                (child: React.ReactElement) => (
                  <FileExportListItem onClick={() => setIsOpen(false)} lastItemSeparator={lastItemSeparator}>
                    {passedElementIcon && <MaterialIcon iconName={passedElementIcon} />}
                    {child}
                  </FileExportListItem>
                ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </OutsideClickWrapper>
    </DropDownContainer>
  )
}
