import React, { useContext } from 'react'

import NewsIconComponentWrapper from './NewsIconComponentWrapper'
import MaterialIcon from '../global-components/MaterialIcon'

import styled, { ThemeContext } from 'styled-components'
import { device } from '../../global-styles/mediaQueries'

interface TabHaderProps {
  setisTabActive: (activeTab: number) => void;
  isTabActive: number;
}

//Styles
const TabHeaderItem = styled.div<{ active?: boolean }>`
  width: 50%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 12px 0 15px;
  border-radius: 6px;
  box-shadow: 0 5px 8px -4px rgba(49, 65, 97, 0.08);
  font-family: Arial, Helvetica, sans-serif;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;

  &:focus-visible {
    outline: none;
    border: 2px solid ${({ theme }) => theme.colors.accent};
  }

  svg {
    margin-bottom: 8px;
  }
  @media ${device.mobileL} {
    flex-flow: row;
    justify-content: flex-start;
    padding: 22px;
    svg {
      margin-bottom: 0px;
      margin-right: 18px;
      width: 30px;
      height: 30px;
    }
  }
  @media ${device.tablet} {
    padding: 30px 28px;
    svg {
      margin-right: 17px;
    }
  }

`
const ActiveTabHeaderItem = styled(TabHeaderItem)`
  height: 76px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  @media ${device.tablet} {
    height: 90px;
  }
`

const InactiveTabHeaderItem = styled(TabHeaderItem)`
  height: 74px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  background-color: ${({ theme }) => theme.colors.highlight};
  svg {
    fill: ${({ theme }) => theme.colors.secondary};
  }
  @media ${device.tablet} {
    height: 88px;
  }
`

const TabContent = styled.div`
  display: flex;
  flex-flow: column;
  p {
    margin: 0;
    &:last-child{
      display: none;
    }
  }
  @media ${device.tablet} {
    justify-content: flex-start;
    p {
      text-align: left;
      &:last-child {
        display: block;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        /* color: ${({ theme }) => theme.colors.secondary}; */
      }
    }
  }
`
const TabHeaderWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  margin-bottom: 10px;
`

const TextIconWrapp = styled.div`
    display: none;
    @media ${device.tablet} {
      display: flex;
      .text {
        margin-right: 12px;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
`
const BottomBorder = styled.div`
  position: absolute;
  left: 0;
  bottom: -12px;
  height: 12px;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
`

export default function TabHeader({ isTabActive, setisTabActive }: TabHaderProps) {


  const themeContext = useContext(ThemeContext);

  //Depeands of prop value render active & inactive tab 
  const RenderActiveTabItems = () => {
    if (isTabActive === 0) {
      return (<>
        <ActiveTabHeaderItem
          onClick={() => setisTabActive(0)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              setisTabActive(0)
            }
          }}
          tabIndex={0}
        >

          <MaterialIcon iconName="local_fire" size={24} color={themeContext.colors.accent} />
          <TabContent>
            <p>Most read</p>
            <TextIconWrapp>
              <p className="text">Most read articles on</p>
              <NewsIconComponentWrapper iconName={['frettabladid', 'bbl', 'mbl.is', 'dv']} />
            </TextIconWrapp>
          </TabContent>
        </ActiveTabHeaderItem>
        <InactiveTabHeaderItem
          onClick={() => setisTabActive(1)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              setisTabActive(1)
            }
          }}
          tabIndex={0}
        >
          <MaterialIcon iconName="facebook_filled" size={24} color={themeContext.colors.accent} />
          <TabContent>
            <p>social media</p>
            <p>Top news on social media today</p>
          </TabContent>
        </InactiveTabHeaderItem>
      </>
      )
    } else {
      return (
        <>
          <InactiveTabHeaderItem onClick={() => setisTabActive(0)}>
            <MaterialIcon iconName="local_fire" size={24} color={themeContext.colors.accent} />
            <TabContent>
              <p>Most read</p>
              <TextIconWrapp>
                <p className="text">Most read articles on</p>
                <NewsIconComponentWrapper iconName={['frettabladid', 'bbl', 'mbl.is', 'dv']} />
              </TextIconWrapp>
            </TabContent>
          </InactiveTabHeaderItem>

          <ActiveTabHeaderItem onClick={() => setisTabActive(1)}>
            <MaterialIcon iconName="facebook_filled" size={24} color={themeContext.colors.accent} />
            <TabContent>
              <p>social media</p>
              <p>Top news on social media today</p>
            </TabContent>
          </ActiveTabHeaderItem>
        </>
      )

    }
  }

  return (
    <TabHeaderWrapper>
      <RenderActiveTabItems />
      <BottomBorder />
    </TabHeaderWrapper>
  )
}
