import styled, { css } from 'styled-components'
import { device } from '../../global-styles/mediaQueries'

export const LoaderAndMessageWrapper = styled.div<{ type: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  padding: 0px 16px;
  text-align: center;
  
  ${({ type }) => type === 'no-results' && css`
      h3 {
        margin-top: 14px;
        margin-bottom: 8px;
        font-size: 18px;
        line-height: 1.33;

        @media ${device.mobileL} {
          max-width: 426px;
        }

        @media ${device.desktopS} {
          max-width: 375px;
        }

         @media ${device.desktop} {
          max-width: 480px;
        }
      }

      p {
        font-size: 14px;
        line-height: 1.71;
        color: ${({ theme }) => theme.colors.secondary};

        @media ${device.mobileL} {
          max-width: 426px;
        }

        @media ${device.desktopS} {
          max-width: 375px;
        }

         @media ${device.desktop} {
          max-width: 480px;
        }
      }
      svg {
        width: 102px;
      }
      button {
        margin-top: 25px;
      }
  `}
  
  @media ${device.mobileL} {
    min-height: 360px;
    padding: 0px 24px;
    ${({ type }) => type === 'no-results' && css`
      button {
        margin-top: 30px;
      }
  `}
  }

  @media ${device.tablet} {
    min-height: 400px;
  }
`