import React, { useState, useEffect } from 'react';

//Types
import { SingleSocialAlert } from '../../types/TopNewsTypes'

//Redux 
import { openModal } from '../../state/slices/modalsSlice'
import { useGetSocialAlertsQuery } from '../../state/query/queryApi'
import { useAppDispatch } from '../../state/hooks/hooks'
import { Error } from '../../state/query/types/globalQueryTypes'

//Components
import GetAlertsTable from './GetAlertsTable'
import NoAlertsScreen from './NoAlertsScreen'
import SingleSocialAlertRow from './SingleSocialAlertRow'
import SocialAlertsHeader from './SocialAlertsHeader';
import NoSearchResult from './NoSearchResult';
import LoadSpinner from '../global-components/LoadSpinner'
import ConnectionLostComponent from '../global-components/ConnectionLostComponent'
import DashboardPageWrapper from './DashboardPageWrapper';


//Hooks
import useStorageListener from '../../hooks/useStorageListener'


function SocialAlerts() {


  const { data: alerts, refetch, requestId, status, isLoading, isError, error } = useGetSocialAlertsQuery('', {
    refetchOnMountOrArgChange: true
  })

  const fitlerOptions = ["Oldest", 'Newest', "Engagement"]

  const [selectedFilter, setSelectedFilter] = useState(fitlerOptions[0])
  const [searchState, setSearchState] = useState<SingleSocialAlert[] | undefined>()

  const dispatch = useAppDispatch();

  function openDeleteModal(id: number, title: string) {
    dispatch({
      type: openModal.type,
      payload: {
        name: 'deleteArticle',
        props: {
          id: id,
          articleTitle: title,
          modalType: 'socialAlerts',
          modalContent: "Once you delete this alert, it will no longer be visible in the social news feed",
        }
      }
    })
  }

  //Still no idea what will happen after user add new alert, so for now there is no logic for it
  function handleSocialAlertModal() {
    dispatch({
      type: openModal.type,
      payload: {
        name: 'newsCoverage',
        props: {
          cacheType: "SocialAlerts"
        }
      }
    })
  }

  function searchSocialAlerts<Type extends SingleSocialAlert>(searchTerm: string, state: Type[]): Type[] {
    return state.filter((item: Type) => {
      if (searchTerm === '') {
        return item
      } else {
        return item.title.toLowerCase().includes(searchTerm.toLowerCase())
      }
    })
  }

  function filterAlerts(filterType: string, state: SingleSocialAlert[] | undefined): SingleSocialAlert[] | undefined {
    if (state !== undefined) {

      let arrayForSort = [...state]

      switch (filterType) {
        case 'Newest':
          return arrayForSort.sort((a, b) => new Date(b.alert_date).getTime() - new Date(a.alert_date).getTime())
        case 'Oldest':
          return arrayForSort.sort((a, b) => new Date(a.alert_date).getTime() - new Date(b.alert_date).getTime())
        case 'Engagement':
          return arrayForSort.sort((a, b) => b.total_engagement - a.total_engagement)
        default:
          return arrayForSort
      }
    } else {
      return undefined
    }
  }

  const handleSearchAction = (searchTerm: string) => {
    if (alerts?.data) {
      return setSearchState(searchSocialAlerts(searchTerm, alerts.data))
    }
  }
  useEffect(() => {
    !isError &&
      !isLoading && setSearchState(alerts.data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, alerts])

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId])


  useEffect(() => setSearchState(filterAlerts(selectedFilter, searchState)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedFilter])


  useStorageListener('updated_article_action', (action) => {
    if (action === "STOP_ALERT" || action === "SET_ALERT") {
      refetch()
    }
  });

  return (
    <DashboardPageWrapper title='Social alerts'>
      <SocialAlertsHeader handleSocialAlertModal={handleSocialAlertModal} />
      {/* Check global state */}
      {isError
        ? <ConnectionLostComponent error={(error as Error)?.status} isCard={true} />
        : !isLoading && alerts?.data.length === 0
          ? <NoAlertsScreen />
          : <GetAlertsTable
            handleSearchAction={handleSearchAction}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            fitlerOptions={fitlerOptions}
          >
            {!isLoading && searchState !== undefined
              ? searchState?.map((item: SingleSocialAlert) => {
                return (<SingleSocialAlertRow
                  key={item.alert_date}
                  post={item}
                  handleDelete={openDeleteModal}
                />)
              }
              ) : <div style={{ background: '#fff' }}><LoadSpinner size="large" /></div>
            }
            {!isLoading && searchState?.length === 0 && <NoSearchResult />}

          </GetAlertsTable>
      }
    </DashboardPageWrapper>
  );
}

export default SocialAlerts;