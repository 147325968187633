import styled from 'styled-components'
import { device } from "../../global-styles/mediaQueries";

export const ModalBox = styled.div`
  max-width: 448px;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 5px 5px -4px rgba(49, 65, 97, 0.08);
  background-color: ${({ theme }) => theme.colors.white};
  
  @media ${device.mobileL} {
    padding: 20px;
  }
  @media ${device.tablet} {
    padding: 24px;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;

  svg { 
    margin-right: 14px;
  }

  h3 { 
    margin: 0;
    font-size: 18px;
    line-height: 1.33;
  }
`

export const NewsTitleExcerpt = styled.span`
  display: inline-block;
  margin-bottom: 22px;
  padding: 2px 8px;
  font-size: 13px;
  line-height: 1.38;
  font-weight: bold;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.body};
`

export const DisclaimerText = styled.p`
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.primary};

  @media ${device.tablet} {
    margin-bottom: 30px;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`