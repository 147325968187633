import React, { useRef, useEffect, Dispatch, SetStateAction } from 'react'

import CustomSelectButton from '../global-components/CustomSelectButton'
import CustomCheckbox from '../global-components/CustomCheckbox'
import CustomRadioButton from '../global-components/CustomRadioButton'

// Styles
import {
  SelectionsDropDownWrapper,
  DropDownWrapper,
  DropDownContainer,
  SelectionsLabel,
  SelectionsBoxText
} from './NotificationsSelections.styled'

// Types
import { NotificationDataSelections } from './Notifications'
import { NotificationsSelectionsProps } from './NotificationsSelections'

import { useAppDispatch } from '../../state/hooks/hooks'
import { updateEditMode } from '../../state/slices/appState'


interface Props {
  item: NotificationDataSelections;
  isEditMode: boolean;
  type: NotificationsSelectionsProps['type'];
  setNotificationsData: Dispatch<SetStateAction<NotificationDataSelections[]>>;
  notificationsData: NotificationDataSelections[];
  postRoute: string;
}


export default function NotificationsInputElements({ item, isEditMode, type, notificationsData, setNotificationsData, postRoute }: Props) {

  const prevSelectValue = useRef<string>(item.extension ? item.extension : '')

  const prevSelectionButtonValue = useRef<boolean>(item.checked);

  const dispatch = useAppDispatch()


  /**
   * convertExtensionsToString
   * 
   * @returns string[] or empty string[]
   */
  const convertExtensionsToString = (extensions: NotificationDataSelections['extensions']) => {
    if (extensions && extensions[0] !== typeof String) {
      return extensions.map((extension: number | string) => `${extension}`)
    }
    else if (extensions) {
      return extensions
    }
    else {
      return ['']
    }
  }

  /**
   * updateEditModeGlobalState
   * 
   * @param prevDataValue - value on start of edit mode
   * @param currDataValue - changed value after interaction
   * @param fieldName - name of the field that is being interacted with
   */
  const updateEditModeGlobalState = (prevDataValue: string | boolean, currDataValue: string | boolean, fieldName: string) => {
    dispatch(updateEditMode({ startFieldValue: prevDataValue, fieldValue: currDataValue, fieldName: fieldName }))
  }


  /**
   * handleSelectionsChangeOnDropDownClick
   * 
   * Used for updating checkbox and radio button when clicked on dropdown if it has it. On first click on drop down checked vale is being updated.
   * 
   * @param id - ID of element that is being updated
   */
  const handleSelectionsChangeOnDropDownClick = (id: number) => {
    // Creating a deep copy of state, since shallow copy will reference object properties to state, and state is immutable
    const tempNotificationsData: NotificationDataSelections[] = JSON.parse(JSON.stringify(notificationsData));

    tempNotificationsData.forEach((item: NotificationDataSelections) => {
      if (type === 'checkbox') {
        if (item.id === id) {
          item.checked = true
        }
      }
      else {
        if (item.id === id) {
          item.checked = true
        }
        else if (item.checked === true && item.id !== id) {
          item.checked = false
          updateEditModeGlobalState(false, item.checked, `${postRoute} ${item.name}`)
        }
      }
    })

    setNotificationsData(tempNotificationsData);
  }


  /**
   * handleCheckboxChange
   * 
   * Used for updating data. Creates new array, update checkbox check property, and sets new array as data state.
   */
  const handleCheckboxChange = (checked: boolean, id: number) => {
    const tempNotificationsData: NotificationDataSelections[] = JSON.parse(JSON.stringify(notificationsData));

    tempNotificationsData.forEach((item: NotificationDataSelections) => {
      if (item.id === id) {
        item.checked = checked;
      }
    });

    setNotificationsData(tempNotificationsData);
  };


  /**
   * handleRadioChange
   * 
   * Used for updating data. Creates new array, update radiobutton check property, and 'unchecks' the one selected before, and sets new array as data state.
   */
  const handleRadioChange = (id: number, checked: boolean) => {
    // Creating a deep copy of state, since shallow copy will reference object properties to state, and state is immutable
    const tempNotificationsData: NotificationDataSelections[] = JSON.parse(JSON.stringify(notificationsData));

    tempNotificationsData.forEach((item: NotificationDataSelections) => {

      if (item.id === id) {
        item.checked = checked
      }
      else if (item.checked === true && item.id !== id) {
        item.checked = false
        updateEditModeGlobalState(false, item.checked, `${postRoute} ${item.name}`)
      }
    })

    setNotificationsData(tempNotificationsData);
  }


  /**
   * handleOnSelect
   * 
   * Used for updating data. Creates new array, updates extension property to selected item, and updates data state.
   */
  const handleOnSelect = (value: string, id: number) => {

    const tempNotificationsData: NotificationDataSelections[] = JSON.parse(JSON.stringify(notificationsData));

    tempNotificationsData.forEach((item: NotificationDataSelections) => {
      if (item.id === id) {
        item.extension = value
      }
    })

    setNotificationsData(tempNotificationsData);
  }

  useEffect(() => {
    if (isEditMode) {
      prevSelectionButtonValue.current = item.checked
      prevSelectValue.current = item.extension ? item.extension : ''
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode])

  return (
    <SelectionsDropDownWrapper>
      {
        type === 'checkbox'
          ? <CustomCheckbox
            checked={item.checked}
            isEnabled={isEditMode}
            name={`${postRoute} ${item.name}`}
            onChange={(e) => {
              handleCheckboxChange(e.target.checked, item.id)
              updateEditModeGlobalState(prevSelectionButtonValue.current, e.target.checked, `${postRoute} ${item.name}`)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && isEditMode) {
                // Toggle the checked state
                handleCheckboxChange(!item.checked, item.id)
                updateEditModeGlobalState(prevSelectionButtonValue.current, !item.checked, `${postRoute} ${item.name}`)
              }
            }}
          />
          : <CustomRadioButton
            isChecked={item.checked}
            isEnabled={isEditMode}
            name={`${postRoute} ${item.name}`}
            onChange={(e) => {
              handleRadioChange(item.id, e.target.checked)
              updateEditModeGlobalState(prevSelectionButtonValue.current, e.target.checked, `${postRoute} ${item.name}`)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && isEditMode) {
                handleRadioChange(item.id, !item.checked)
                updateEditModeGlobalState(prevSelectionButtonValue.current, !item.checked, `${postRoute} ${item.name}`)
              }

            }}
          />
      }

      <span>
        <SelectionsBoxText>
          <SelectionsLabel
            onClick={e => !isEditMode && e.preventDefault()}
            htmlFor={`${postRoute} ${item.name}`}
            isEditMode={isEditMode}
          >
            {item.name}
          </SelectionsLabel>

          {
            item.extension !== null && item.extensions &&
            <DropDownContainer
              enablePointer={isEditMode}
              onClick={() => {
                if (!item.checked && isEditMode) {
                  handleSelectionsChangeOnDropDownClick(item.id)
                  updateEditModeGlobalState(prevSelectionButtonValue.current, true, `${postRoute} ${item.name}`)
                }
              }}
            >
              <DropDownWrapper enableClick={isEditMode && item.checked} enableColor={isEditMode}>
                <CustomSelectButton
                  options={convertExtensionsToString(item.extensions)}
                  type="select-link"
                  value={item.extension === typeof String ? item.extension : `${item.extension}`}
                  visibleItems={4}
                  onSelect={(value) => {
                    handleOnSelect(value, item.id)
                    updateEditModeGlobalState(prevSelectValue.current, value, `${postRoute} select ${item.name}`);
                  }}
                />
              </DropDownWrapper>
            </DropDownContainer>
          }
        </SelectionsBoxText>
      </span>
    </SelectionsDropDownWrapper>
  )
}
