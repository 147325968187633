import React, { ReactElement } from 'react'
import styled from 'styled-components'

// type TooltipPosition = 
interface SimpleTooltipProps {
  text: string;
  hasIcon?: boolean;
  icon?: ReactElement;
  hasArrow?: boolean;
  position?: 'top' | 'right' | 'bottom' | 'left';
}


export const TooltipBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 6px 10px;
  max-width: 200px;
  width: fit-content;
  height: fit-content;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary};
  p {
    margin: 0 auto;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: ${({ theme }) => theme.colors.white};
  }
  div {
    height: fit-content;
    display: flex;
     margin-right: 5px;
    svg {
      position: relative;
      width: 18px;
      max-height: 18px;
      height: fit-content;
    }
  }
  svg {
    position: absolute;
  }
`

export default function SimpleTooltip({ text, hasIcon = false, hasArrow, icon, position }: SimpleTooltipProps) {

  // Don't know d we need this somewhere in the future, if not feel free to remove
  // function setTooltipPosition() {
  //   switch (position) {
  //     case "top":
  //       return { top: '-40px' }
  //     case "right":
  //       return { right: '-100%' }
  //     case "bottom":
  //       return { bottom: '-40px' }
  //     case 'left':
  //       return { left: '-100%' }
  //   }
  // }

  function setTooltipTriangle() {
    switch (position) {
      case "top":
        return {
          bottom: "-6px",
          transform: 'rotate(-60deg)',
          left: 'calc(50% - 4px)'
        }
      case "right":
        return {
          top: 'calc(50% - 6px)',
          left: '-5px',
          transform: 'rotate(25deg)'
        }
      case "bottom":
        return {
          top: '-7px',
          left: 'calc(50% - 4px)'

        }
      case 'left':
        return {
          top: 'calc(50% - 6px)',
          right: '-5px',
          transform: 'rotate(-27deg)'

        }
    }
  }
  return (

    <TooltipBox aria-label={text}>
      {hasIcon &&
        <div>
          {icon}
        </div>
      }
      <p>{text}</p>
      {
        hasArrow &&
        <svg height="10" width="10" style={setTooltipTriangle()} >
          {/* <polygon points="5,0 0,10 10,10" stroke-width="3" fill="#314161" /> */}
          <path d="M5 0 L0 10 L10 10 Z" fill="#314161" />
        </svg>
      }
    </TooltipBox>
  )
}
