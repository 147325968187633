import React, { useEffect, useState } from 'react';
import Layout from '../global-components/GeneralLayout'
import SettingsNavigation from './SettingsNavigation';
import {
  Route,
  Switch,
  useRouteMatch,
  Redirect
} from "react-router-dom";

import { NewsSearchSpinnerWrapper } from '../dashboard/CustomNewsSearchBar.styled'

import Account, { AccountDataProps } from "./Account"
import Notifications, { NotificationsData } from "./Notifications"
// import RSSFeed from './RSSFeed'
import SearchTerms, { SearchTermsDataProp } from './SearchTerms'
import SignIn from './SignIn'
import TodaysCalendar from '../global-components/TodaysCalendar';
import LoadSpinner from '../global-components/LoadSpinner'
import ConnectionLostComponent from '../global-components/ConnectionLostComponent'

//Styles 
import { SettingsHeader, SettingsMainWrapper, SettingsMainContainer, SettingsComponentWrapper } from './Settings.styled'

//Queries
import { useGetSettingsDataQuery } from '../../state/query/queryApi'
import { Error } from '../../state/query/types/globalQueryTypes'

/**
 * 
 * Settings is the main component that renders the whole page. It also contains routing logic for sub-navigation (SettingsNavigation), which includes other child components like Account, Sign in & Security ect.
 */
const Settings = () => {

  let { path, url } = useRouteMatch();

  const { data, isLoading, isError, error } = useGetSettingsDataQuery()

  const [accData, setAccData] = useState<AccountDataProps>()
  const [searchTermsData, setSearchTermsData] = useState<SearchTermsDataProp>()

  const [notificationsData, setNotificationsData] = useState<NotificationsData>();

  useEffect(() => {
    if (data) {
      const { search_terms, notification_and_alerts, ...rest } = data
      setAccData(rest)
      setNotificationsData(notification_and_alerts)
      setSearchTermsData(search_terms)
    }
  }, [data])

  return (
    <SettingsMainWrapper>
      <SettingsMainContainer>
        <SettingsHeader>
          <h3>Settings</h3>
          <TodaysCalendar />
        </SettingsHeader>

        <SettingsNavigation url={url} />

        <Layout hasMargin={true} disablePadding={true} title='Settings'>
          {
            isError
              ? <ConnectionLostComponent error={(error as Error)?.status} invalidateTags="Settings" isCard={true} />
              : isLoading && !data
                ? <NewsSearchSpinnerWrapper> <LoadSpinner size="large" /></NewsSearchSpinnerWrapper>
                : <SettingsComponentWrapper>
                  <Switch>
                    <Route path={`${path}`}
                      render={() => <Account accountData={accData} />} exact />
                    <Route path={`${path}/sign-in-security`} component={SignIn} exact />
                    <Route path={`${path}/notifications-alerts`}
                      render={() => <Notifications notificationsData={notificationsData} />} exact />
                    <Route path={`${path}/search-terms`} render={() => <SearchTerms termsData={searchTermsData} />} exact />
                    {/* <Route path={`${path}/rss-feed`} component={RSSFeed} exact /> */}
                    <Redirect to={`${path}`} />

                  </Switch>
                </SettingsComponentWrapper>
          }
        </Layout>
      </SettingsMainContainer>
    </SettingsMainWrapper >
  );
}

export default Settings;
