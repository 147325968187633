import styled from 'styled-components'
import { device } from '../../global-styles/mediaQueries'
import { globalFocusStyles } from '../../global-styles/globalStyles'

export const SavedSearchBoxWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-flow: column;
  border-radius: 6px;
  width: 100%;
  top: 74px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 16px -2px rgba(49, 65, 97, 0.15);
  border: solid 1px ${({ theme }) => theme.colors.borderPrimary};
  z-index: 10;
  @media ${device.desktopS} {
    max-width: 360px;
    right: 0;
  }
`

export const SavedSearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 14px;
`

export const SavedSearchTitle = styled.p`
  font-family: Arial;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primary};
`

export const SavedSearchInput = styled.input`
  width: 100%;
  margin-top: 14px;
  margin-bottom: 21px;
  position: relative;
  padding: 5px 8px;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.colors.borderSecondary};
  background-color: ${({ theme }) => theme.colors.highlight};

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.accent};
  }
`

export const SavedSearchResults = styled.div`
  display: flex;
  flex-flow: column;
  max-height: 217px;
  overflow: scroll;
  padding: 2px; // need this because there is no enough space for the focus state
`
export const IconWrapper = styled.div`
  position: absolute;
  right: 14px;
  display: none;

  @media ${device.tablet} {
    /* display: flex; maybe we will ned this*/
  }
`

export const SingleSavedRow = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 14px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.highlight};
  }
  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.accent};
  }
  @media ${device.desktopS} {
    &:hover, &:focus-within {
      ${IconWrapper} {
        display: flex;
      }
    }
  }
`

export const SingleSavedRowEdit = styled.p`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: right;
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;

  // Custom focus styles
  ${globalFocusStyles}
  
  @media ${device.desktopS} {
    display: none;
  }
`

export const SingleSavedRowIcons = styled.div`
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }

 // Custom focus styles
 ${globalFocusStyles}

  &:hover{
    svg {
      fill: ${({ theme }) => theme.colors.accent};
    }
  }
`
