import React from 'react'

//Components
import { NewsIconWrapp, NewsImageFromArray, SingleNewsIcon, TooltipWrapper } from './NewsIconComponentWrapper.styled'
import SimpleTooltip from '../global-components/SimpleTooltip'
//Images
import frettabladid from '../../assets/icons/news-frettabladid.png'
import grapevine from '../../assets/icons/news-grapevine.png'
import bbl from '../../assets/icons/news-bbl.png'
import dv from '../../assets/icons/news-dv.png'
import kjarninn from '../../assets/icons/news-kjarninn.png'
import mbl from '../../assets/icons/news-mbl.png'
import reykjavik from '../../assets/icons/news-reykjavik.png'
import ruv from '../../assets/icons/news-ruv.png'
import stundin from '../../assets/icons/news-stundin.png'
import turisti from '../../assets/icons/news-turisti.png'
import vb from '../../assets/icons/news-vb.png'
import visir from '../../assets/icons/news-visir.png'

export type NewsIconName =
  'frettabladid' | 'grapevine' | 'bbl' | 'dv' | 'kjarninn' | 'mbl.is' | 'reykjavik' | 'ruv' |
  'stundin' | 'turisti' | 'vb' | 'visir.is'

type IconNameProp = { iconName: NewsIconName | NewsIconName[] }

export default function NewsIconComponentWrapper({ iconName }: IconNameProp) {

  const returnPropperImage = (name: NewsIconName) => {
    switch (name) {
      case 'frettabladid':
        return frettabladid
      case 'grapevine':
        return grapevine
      case 'bbl':
        return bbl
      case 'dv':
        return dv
      case 'kjarninn':
        return kjarninn
      case 'mbl.is':
        return mbl
      case 'reykjavik':
        return reykjavik
      case 'ruv':
        return ruv
      case 'stundin':
        return stundin
      case 'turisti':
        return turisti
      case 'vb':
        return vb
      case 'visir.is':
        return visir
    }
  }

  function displaySingleImageOrArrayOfImages() {
    if (typeof iconName === 'string') {
      return <img src={returnPropperImage(iconName)} alt="top news article" />
    } else {
      return <NewsIconWrapp>
        {iconName.map((singleImageName, index) =>
          <SingleNewsIcon key={index}>
            <NewsImageFromArray zIndex={99 - index} src={returnPropperImage(singleImageName)} alt="top news article" />
            <TooltipWrapper><SimpleTooltip text={singleImageName} position="top" /></TooltipWrapper>
          </SingleNewsIcon>)}
      </NewsIconWrapp>
    }
  }

  return (
    <div>
      {displaySingleImageOrArrayOfImages()}
    </div>
  )
}
