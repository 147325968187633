import styled from 'styled-components'
import { device } from '../../global-styles/mediaQueries'

export const NotificationsWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0 5px 5px -4px rgba(49, 65, 97, 0.08);
`

export const TitleContainer = styled.div`
  display: inline-block;
  width: calc(100% - 16px);
  padding: 8px;
  margin: 16px 8px 0;
  background-color: ${({ theme }) => theme.colors.highlight};
  @media ${device.mobileL} {
    padding: 10px;
    margin: 20px 10px 0;
    width: calc(100% - 20px);
  }
  @media ${device.tablet} {
    margin: 24px 24px 0;
    width: calc(100% - 48px);
  }
`

export const Title = styled.h4`
  margin: 0;
`

export const BorderElement = styled.div`
  height: 1px;
  margin: 0 16px;
  background-color: ${({theme}) => theme.colors.borderPrimary};
  @media ${device.mobileL} {
    margin: 0 24px;
  }
  @media ${device.tablet} {
    margin: 0 34px;
  }
`