import React from 'react'
import { ButtonSize, IconButtonSetOne, IconButtonSetTwo, IconButtonSetThree, IconButtonSetFour, IconButtonSetFive, IconButtonSetSix } from './Button.styled'
import MaterialIcon, { IconName } from './MaterialIcon'

interface IconButtonComponentInterface {
  size: ButtonSize;
  bgColor: string;
  setType: number;
  iconName: IconName;
  // iconColor?: string; // We will add this if we 
  onClick?: () => void;
  isDisabled?: boolean;
}


export default function IconButtonComponent({ size, bgColor, onClick, setType = 1, iconName, isDisabled = false }: IconButtonComponentInterface) {

  /**
   * There are 5 different sets ( setType ), all sets has default hover, focus & color. 
   * User can only chose bg color of button. There is idea to chose icon color, but we will add that option layter if we need it. 
   * @returns JSX element
   */
  const renderSetTypeButton = () => {
    switch (setType) {
      case 1:
        return <IconButtonSetOne autoFocus={true} size={size} bgColor={bgColor} onClick={onClick} isDisabled={isDisabled}>
          <MaterialIcon size={18} iconName={iconName} />
        </IconButtonSetOne>
      case 2:
        return <IconButtonSetTwo size={size} bgColor={bgColor} onClick={onClick} isDisabled={isDisabled}>
          <MaterialIcon size={18} iconName={iconName} />
        </IconButtonSetTwo>
      case 3:
        return <IconButtonSetThree size={size} bgColor={bgColor} onClick={onClick} isDisabled={isDisabled}>
          <MaterialIcon size={18} iconName={iconName} />
        </IconButtonSetThree>
      case 4:
        return <IconButtonSetFour size={size} bgColor={bgColor} onClick={onClick} isDisabled={isDisabled}>
          <MaterialIcon size={18} iconName={iconName} />
        </IconButtonSetFour>
      case 5:
        return <IconButtonSetFive size={size} bgColor={bgColor} onClick={onClick} isDisabled={isDisabled}>
          <MaterialIcon size={18} iconName={iconName} />
        </IconButtonSetFive>
      case 6:
        return <IconButtonSetSix size={size} bgColor={bgColor} onClick={onClick} isDisabled={isDisabled}>
          <MaterialIcon size={18} iconName={iconName} />
        </IconButtonSetSix>
    }
  }

  return (
    <>
      {renderSetTypeButton()}
    </>
  )
}
