import React from 'react';
//Styles
import { SearchInputWrapper } from './NewsSearch.styled'
import { PageHeader } from './SocialMediaTab.styled'
//Components
import TodaysCalendar from '../global-components/TodaysCalendar'
import CustomNewsSearchBar from './CustomNewsSearchBar'
import DashboardPageWrapper from './DashboardPageWrapper';

//News search is component which will be rendered on news-search route
const NewsSearch = () => {

  return (
    <DashboardPageWrapper title='News search'>
      <PageHeader>
        <h3>News Search</h3>
        <TodaysCalendar />
      </PageHeader>


      <SearchInputWrapper>
        <CustomNewsSearchBar />
      </SearchInputWrapper>

    </DashboardPageWrapper>
  );
}

export default NewsSearch;
