import React, { useEffect, useState, useRef } from 'react';
import DateRangeSelect from '../global-components/DateRangeSelect'
import { DateRange } from '../global-components/DateRangeSelect'
import ModalButton from '../global-components/ModalButton'
import MaterialIcon from '../global-components/MaterialIcon'
import { useAppDispatch } from '../../state/hooks/hooks'
import { closeModal } from '../../state/slices/modalsSlice'
import { showPopup } from '../../state/slices/popupSlice'
import DownloadNewsButton from './DownloadNewsButton'
import { useAppSelector } from '../../state/hooks/hooks'

import {
  ModalBox,
  FileName,
  Labbel,
  ButtonsWrapper,
  GeneratingProcessMessage
} from './DownloadNews.styled'


interface DownloadNewsProps {
  payload: {
    fileType: 'excel' | 'csv' | 'pdf'
  }
}


const DownloadNews = ({ payload: { fileType } }: DownloadNewsProps) => {
  const dispatch = useAppDispatch()
  const token = useAppSelector(state => state.user.auth_token)

  // eslint-disable-next-line
  const [dateRangeOption, setDateRangeOption] = useState<DateRange>({ startDate: null, endDate: null })
  const [fileUrl, setFileUrl] = useState<string>('')
  const [preparingFile, setPreparingFile] = useState<boolean>(false)
  const [triggerDownload, setTriggerDownload] = useState<boolean>(false)

  const linkEl = useRef<HTMLAnchorElement>(null)

  const handleCloseModal = () => dispatch({ type: closeModal.type })

  const handleDateRangeOption = (option: DateRange): void => {
    setFileUrl("")
    setDateRangeOption(option)
  }

  const handleFileDownload = () => {
    if (linkEl.current) {
      linkEl.current.click()
    }
  }

  const prepareFileName = (fileType: string) => {
    switch (fileType) {
      case 'pdf':
        return `${`Latest-News-${new Date().toJSON().slice(0, 10)}`}.pdf`
      case 'csv':
        return `${`Latest-News-${new Date().toJSON().slice(0, 10)}`}.csv`
      case 'excel':
        return `${`Latest-News-${new Date().toJSON().slice(0, 10)}`}.xlsx`
    }
  }

  const prepareButtonText = (fileType: string) => {
    switch (fileType) {
      case 'pdf':
        return 'Download PDF'
      case 'csv':
        return 'Download CSV'
      case 'excel':
        return 'Download Excel'
    }
  }

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault()
    setPreparingFile(true)
  }

  useEffect(() => {
    if (preparingFile) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "file_name": prepareFileName(fileType),
        "search_keyword": "",
        "search_range": {
          "from": dateRangeOption.startDate,
          "to": dateRangeOption.endDate
        }
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
      };

      fetch("https://api.fregnir.is/api/gnews/downloadcsv", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          setFileUrl(result.file)
          setTriggerDownload(true)
          setPreparingFile(false)
        })
        .catch(error => {
          setTriggerDownload(false)
          setPreparingFile(false)
          dispatch(showPopup({
            event: 'error',
            type: 'alert',
            direction: 'bottom',
            props: {
              popupTitle: `${error.message}`
            }
          }))
          console.error(error)
        });
    }
    // eslint-disable-next-line
  }, [preparingFile])

  useEffect(() => {
    if (triggerDownload && !!fileUrl) {
      handleFileDownload()
      setTriggerDownload(false)
      handleCloseModal()
    }
    // eslint-disable-next-line
  }, [triggerDownload])

  return (
    <ModalBox>
      <h3>Download News List</h3>
      <FileName>“{prepareFileName(fileType)}”</FileName>
      <Labbel>Date range</Labbel>
      <DateRangeSelect handleSelect={handleDateRangeOption} disabled={preparingFile} usedInModal={true} location="download-news-modal" />
      <ButtonsWrapper>
        <ModalButton type="cancel" onClick={handleCloseModal}>Cancel</ModalButton>
        <DownloadNewsButton
          isLoading={preparingFile}
          downloadURL={fileUrl}
          fileName={prepareFileName(fileType)}
          onClick={onClick}
          ref={linkEl}
        >
          <MaterialIcon iconName="file_download" />
          <span>{prepareButtonText(fileType)}</span>
        </DownloadNewsButton>
      </ButtonsWrapper>
      {preparingFile && <GeneratingProcessMessage>Generating file. Please wait...</GeneratingProcessMessage>}
    </ModalBox>
  );
}

export default DownloadNews;
