import styled, { css } from 'styled-components'
import { addOpacityToHex } from '../../utils'
import { InputField } from './GlobalInterfaces'

export interface StyledCustomInputInterface extends InputField {
  isGrayBg?: boolean; //Background can bee white or gray
  borderColor?: string;
  isInFocus?: boolean;
  tabIndex?: number; //Need for focus & blur elements which are not inputs
  isCloseIconHidden: boolean;
  hasIconBackground?: boolean;
  hasIconRight?: boolean;
  hasDropdown?: boolean;
  hasCancelIcon?: boolean;
  type?: string;
  name?: string;
}

const calcPadding = (hasIcon: boolean | undefined, hasIconBackground: boolean | undefined): string => hasIcon ? hasIconBackground ? '60px' : '42px' : '15px';

const calcRightPadding = (hasDropdown: boolean | undefined, hasCancelIcon: boolean | undefined): string => {
  let totalPadding = 0

  if (hasDropdown && hasCancelIcon) {
    totalPadding = 68
  } else if (hasCancelIcon) {
    totalPadding = 39
  } else {
    totalPadding = 14
  }

  return `${totalPadding}px`
}

export const TriangleBox = styled.div<{ isDisabled: boolean }>`
  position: absolute;
  width: 35px;
  height: 42px;
  top: 2px;
  right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: ${({ isDisabled }) => isDisabled ? 'default' : 'pointer'};
  z-index: 2;

  svg {
    polygon {
      fill: ${({ isDisabled, theme }) => isDisabled ? theme.colors.default_400 : theme.colors.secondary};
    }
  }

   &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.accent};
    border-radius: 4px;
  }
`
export const CustomInputLabel = styled.label<{ isDisabled?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${({ theme, isDisabled }) => isDisabled ? theme.colors.primary_700 : theme.colors.primary};
  text-transform: capitalize;
`
export const LabelTooltipWrapper = styled.div<{ isDisabled?: boolean }>`
  position: relative;

  &:hover {
    svg {
      g {
        fill: ${({ theme }) => theme.colors.secondary};
      }
    }
  }
  ${({ isDisabled }) => isDisabled && css`
    svg {
      g {
        fill: ${({ theme }) => theme.colors.default_700};
      }
    }
    &:hover {
      svg {
        g {
          fill: ${({ theme }) => theme.colors.default_700};
        }
      }
    }
  `}
`
export const HelperBox = styled.div<{ isError?: boolean, isDisabled?: boolean }>`
  display: flex;
  margin-top: 10px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${({ theme, isDisabled }) => isDisabled ? theme.colors.default_700 : theme.colors.secondary};

  div {
    width: 15px;
    height: 15px;
    margin-right: 7px;

    svg {
      fill: ${({ theme, isDisabled }) => isDisabled && theme.colors.default_700};
    }
  }
  ${({ isError }) => isError && css`
    margin-top: 25px;
  `}
`
export const StyledInputWrapper = styled.div`
  position: relative;
  display: flex;
  height: 48px;
  width: 100%;
`;

export const CancelIcon = styled.div<{ isShown: boolean, hasDropdown?: boolean, isDisabled?: boolean }>`
  position: absolute;
  right:${({ hasDropdown }) => hasDropdown ? '44px' : '14px'};
  width: 18px;
  height: 18px;
  opacity: 0;
  transition: 0.3s;
  top: calc(50% - 9px);
  pointer-events: ${({ isDisabled }) => isDisabled ? 'none' : 'auto'};
  cursor: ${({ isShown }) => isShown ? 'pointer' : 'auto'};
  z-index: 1;
`;

export const ErrorMessage = styled.div`
  position: absolute;
  left: -2px;
  bottom: -22px;
  display: flex;
  padding-left: 2px;
  margin: 0;
  color: ${({ theme: { colors } }) => colors.redAlert};
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  div {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }
`;

export const IconInBox = styled.div<{ isDisabled: boolean, isError?: boolean, hasIconBackground?: boolean }>`
  position: absolute;
  top: 1px;
  left: 1px;
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Material Icons';
  font-size: 14px;

  background-color: ${(props) => props.theme.colors.default_100};
  color: ${(props) =>
    props.theme.colors.secondary};

  border-right: ${({ theme }) => theme.colors.borderSecondary};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  transition: fill 0.3s;
  box-sizing: border-box;
  z-index: 10;
  transition: 0.3s;

  svg {
    fill: ${({ theme }) => theme.colors.secondary};
  }

  ${({ isError, theme }) => isError && css`
    background-color: ${theme.colors.redAlert};

    svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  `}

  ${({ hasIconBackground }) => !hasIconBackground && css`
    background-color: transparent;
    width: 46px;

  `}

    ${({ isDisabled, theme }) => isDisabled && css`
      svg {
        path {
          &:nth-of-type(2) {
            fill: ${addOpacityToHex(theme.colors.secondary, 0.5)};
          }
        }
      }
    `}
`;


export const StyledCustomInput = styled.input<StyledCustomInputInterface>`
  position: absolute;
  width: 100%;
  height: 48px;
  padding: 15px 14px;
  padding-left: ${({ hasIcon, hasIconBackground }) => calcPadding(hasIcon, hasIconBackground)};
  padding-right: ${({ hasDropdown, hasCancelIcon }) => calcRightPadding(hasDropdown, hasCancelIcon)};
  border-radius: 4px;
  background-color: ${props => props.isGrayBg ? props.theme.colors.highlight : props.theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
  transition: 0.1s;
  border: solid 1px ${({ theme }) => theme.colors.borderSecondary};
  box-sizing: border-box;
  font-family: 'Arial';
  font-size: 14px;
  line-height: 1.29;
  //Need for safari
  outline: none;
  z-index: 2;

  &:hover {
    border: ${({ borderColor, theme }) => !borderColor ? `solid 1px ${theme.colors.accent}` : `1px solid ${borderColor}`};

  }
  ::placeholder {
    color: ${({ theme, isDisabled }) => !isDisabled ? theme.colors.default_700 : theme.colors.default_400};
  }
  &:focus-within {
    background-color: ${({ theme: { colors } }) => colors.white};
    border: ${({ borderColor, theme }) => !borderColor ? `solid 2px ${theme.colors.accent}` : `1px solid ${borderColor}`};
    padding-left: ${({ hasIcon, hasIconBackground }) => hasIcon ? hasIconBackground ? '59px' : '41px' : '14px'};
    ${TriangleBox} {
      background-color: red;
    }
    & ~ ${CancelIcon} {
      opacity: ${({ isCloseIconHidden }) => isCloseIconHidden ? '1' : '0'};
      z-index: 10;
    }
    + ${IconInBox} {
      background-color: ${({ hasIconBackground, theme: { colors } }) => hasIconBackground ? colors.accent : 'transparent'};

      svg {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
  
  }
  ${({ isError, theme }) => isError && css`
    border: solid 2px ${theme.colors.redAlert};

    &:hover {
      border: solid 2px ${theme.colors.redAlert};
    }
    &:focus-within {
      border: solid 2px ${theme.colors.redAlert};
      + ${IconInBox} {
        background-color: ${({ theme: { colors } }) => colors.redAlert};
      }
    }
  `}
  ${({ isDisabled }) => isDisabled && css`
    pointer-events: none;
    background-color: ${({ theme }) => theme.colors.highlight_800};
    border: 1px solid ${({ theme }) => theme.colors.borderSecondary};
    color: ${({ theme }) => addOpacityToHex(theme.colors.primary, 0.5)};

    + ${IconInBox} {
      background-color: ${({ theme }) => theme.colors.default_100};

      svg {
        fill: ${({ theme }) => theme.colors.default_400};
      }
    }
  `}
  ${({ name }) => name === 'searchNews' && css`
    + ${IconInBox} {
      background-color: transparent;    
    }
    &:focus-within {
    + ${IconInBox} {
          svg {
            fill: ${({ theme }) => theme.colors.secondary};
          }
        }
      }
    `
  }
`;

