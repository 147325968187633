import styled from 'styled-components'
import { device } from '../../../global-styles/mediaQueries'
import { DropdownArrow } from '../../dashboard/SingleNews.styled'
import { addOpacityToHex } from '../../../utils'
import { textOverfllowEllipsis } from '../../../global-styles/mixins'


export const PropertyName = styled.p<{ isOpen: boolean }>`
  display: ${({ isOpen }) => isOpen ? 'block' : 'none'};
  grid-column: 1/2;
  padding-top: 32px;
  font-family: Arial;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primary};
`


export const PropertyValue = styled.p<{ isOpen: boolean }>`
  display: ${({ isOpen }) => isOpen ? 'block' : 'none'};
  grid-column: 3/4;
  padding-top: 32px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primary};

  &:last-child {
    padding-bottom: 14px;
  }
`


export const RowWrapper = styled.div`
  position: relative;

  &:not(:first-child) {
    &::before {
      content:"";
      position: absolute;
      top: 0;
      left: 16px;
      right: 16px;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.borderPrimary};
      z-index: 2;
    }
  }
`

export const ListSeparator = styled.p`
  padding: 25px 16px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.highlight};
`

export const SingleItemWrapper = styled.div<{ type: 'report' | 'invoice', isOpen: boolean, isChecked: boolean }>`
  position: relative;
  display: grid;
  padding: ${({ isOpen }) => isOpen ? '16px 16px 30px' : '16px'};
  grid-template-columns: ${({ type }) => type === 'report' ? '72px 28px 1fr auto 28px' : '72px 28px 1fr 1fr 28px'};
  grid-template-rows: repeat(auto-fill, auto);
  background-color:${({ isOpen, isChecked, theme }) => isChecked ? addOpacityToHex(theme.colors.accent, 0.05) : isOpen ? theme.colors.highlight : theme.colors.white};
  z-index: 1;

  @media ${device.tablet} {
    grid-template-columns: ${({ type }) => type === 'report' ? '82px 38px 137px 1fr auto 28px' : '82px 38px 1fr 1fr 1fr 1fr 28px'};
    padding: ${({ isOpen }) => isOpen ? '16px 24px 50px' : '16px 24px'};
  }
  @media ${device.tabletL} {
    grid-template-columns: ${({ type }) => type === 'report' ? '62px 38px 137px 1fr 1fr 28px' : '62px 38px 136px 1fr 1fr 1fr 1fr 28px'};
    padding: 24px;
    background-color:${({ isChecked, theme }) => isChecked ? addOpacityToHex(theme.colors.accent, 0.05) : theme.colors.white};
  }
`

export const ArrowAndCheckbowWrapper = styled.div`
  grid-column: 1/2;
  grid-row: 1/2;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${DropdownArrow} {
    position: relative;
    top: 0;
    left: 0;
    margin-right: 16px;

    @media ${device.tablet} {
      margin-right: 26px;
    }

    @media ${device.tabletL} {
      display: none;
    }
  }
`

export const RowTitle = styled.a`
  grid-column: 3/4;
  grid-row: 1/2;
  align-self: center;
  font-family: Arial;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  ${textOverfllowEllipsis}

  &:hover,
  &:active {
    text-decoration: underline;
  }

  @media ${device.tablet} {
    grid-column: 4/5;
  }


`

