import React from 'react';
import styled from 'styled-components'

const AnimatedSpiner = styled.div`
  animation: rotating 1.2s linear infinite;

  svg {
    display: block;
    margin: 0 !important;
  }
  
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

const ButtonSpinner = ({ color = "#ffffff", size = 22 }: { color?: string, size?: number }) => {
  return (
    <AnimatedSpiner>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 22 22">
        <g fill="none" fillRule="evenodd">
          <g stroke={color} strokeWidth="2">
            <g>
              <path d="M53 14c-5.523 0-10 4.477-10 10s4.477 10 10 10 10-4.477 10-10" transform="translate(-454 -7567) translate(412 7554)" />
            </g>
          </g>
        </g>
      </svg>
    </AnimatedSpiner>
  );
}

export default ButtonSpinner;
