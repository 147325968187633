import styled from 'styled-components'
import { device } from '../../global-styles/mediaQueries'

export const NewsSearchListWrapper = styled.div`
  margin-top: 16px;
`

export const NewsSearchTableTitle = styled.div`
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: bold;
  @media ${device.mobileL} {
    margin-bottom: 20px;
  }
  @media ${device.tablet} {
    margin-bottom: 0;
  }
`

export const NewsSearchTableColumns = styled.div`
  height: 38px;
  padding: 10px 10px 10px 20px;
  background-color: ${({ theme }) => theme.colors.highlight};
  border-top: 1px solid ${({ theme }) => theme.colors.borderPrimary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderPrimary};
  display: flex;

  @media ${device.tablet} {
    display: grid;
    padding-left: 24px;
    grid-template-columns: 150px 1fr;
    justify-content: space-between;
  }
  @media ${device.tabletL} {
    grid-template-columns: 182px 1fr;
  }
  @media ${device.desktopS} {
    grid-template-columns: 152px 373px 150px 160px;
  } 
  @media ${device.desktop} {
    grid-template-columns: 180px 500px 180px 200px;;
  }
`
export const ColumnText = styled.span`
  display: none;
  @media ${device.tablet} {
    display: block;
    width: fit-content;
    font-size: 13px;
    line-height: 1.38;
    color: ${({ theme }) => theme.colors.primary}; 
  }
`

export const ColumnTextMobile = styled.span`
  font-size: 13px;
  line-height: 1.38;
  color: ${({ theme }) => theme.colors.primary};
  @media ${device.tablet} {
    display: none;
  }
`