import styled from 'styled-components'
import { device } from '../../global-styles/mediaQueries'

export const NewsDistributionSection = styled.section`
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  border-radius: 6px;
  box-shadow: 0 5px 5px -4px rgba(49, 65, 97, 0.08);
  background-color: white;
  font-family: Arial, Helvetica, sans-serif;
  grid-area: distribution;
`


export const NewsDistributionTopBar = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderPrimary};
  z-index: 20;
  @media ${device.mobileL} {
    padding: 20px 18px;
  }
  @media ${device.tablet} {
    padding: 16px 24px;
  }

`

export const NewsDistributionFooter = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.borderPrimary};
  @media ${device.mobileL} {
    padding: 15px 20px;
  }
  @media ${device.tablet} {
    padding: 20px 24px;
  }
  @media ${device.desktopS} {
    padding: 18px 24px;
  }
`

export const NewsDistributionSideLabels = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  top: 0;
  padding: 0 16px;
  z-index: 1;
  p {
    right: -8px;
    margin: 0;
    position: relative;
    display: block;
    transform: rotate(-90deg);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary};
    &:first-child {
      left: -26px;
    } 
  }
`
export const NewsDistributionSideLabelsWrapp = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ChartWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 200px;
  margin: 16px 0 16px 12px;
  z-index: 10;
  @media ${device.tablet} {
    height: 294px;
    margin: 32px 0 32px 14px;
  }   
  @media ${device.desktopS} {
    margin: 28px 0 28px 14px;
  }
  @media ${device.desktop} {
    height: 315px;
  }

`

export const ChartLegend = styled.div<{ bgColor: string }>`
  position: relative;
  display: flex;
  align-items: baseline;
  margin-right: 20px;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primary};
  &:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    margin-right: 6px;
    border-radius: 100%;
    background-color: ${({ bgColor }) => bgColor};
  }
`