import styled from 'styled-components'
import { device } from '../../global-styles/mediaQueries'

export const List = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  overflow: visible;
`

export const ListHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 50px;
  padding: 11px 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  .action-bars {
    display: flex;
    flex-wrap: wrap;
  }

  @media ${device.mobileL} {
    padding: 15px 20px;
  }

  @media ${device.tablet} {
    flex-direction: row;
    align-items: center;
    max-height: 66px;
    padding: 19px 24px;
  }
  
`

export const TableTitle = styled.span`
  margin-bottom: 16px;
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${({ theme: { colors } }) => colors.primary};

  @media ${device.mobileL} {
    margin-bottom: 20px;
  }
  
  @media ${device.tablet} {
    margin-bottom: 0;
  }
`

export const ListFooter = styled.div`
  min-height: 64px;
  background-color: ${({ theme }) => theme.colors.highlight_500};
  border-top: 1px solid ${({ theme }) => theme.colors.borderPrimary};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`