import styled from 'styled-components'
import { device } from '../../global-styles/mediaQueries'
import { Link } from "react-router-dom";
import { globalFocusStyles } from '../../global-styles/globalStyles';

export const NewsCoverageModalWrapper = styled.div`
  width: 100%;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: 0 5px 5px -4px rgba(49, 65, 97, 0.08);

  @media ${device.mobileL} {
    padding: 20px;
  }
  @media ${device.tablet} {
    max-width: 640px;
    width: 100%;
    padding: 24px;
  }
`
export const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;

  h4 {
    margin: 0;
  }
  svg {
    margin-right: 12px;
  }
`

export const InputLabel = styled.label<{ isDisabled?: boolean }>`
  margin-bottom: 12px;
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: normal;
  color: ${({ theme, isDisabled }) => isDisabled ? theme.colors.default_700 : theme.colors.primary};
  text-transform: capitalize;
`

export const InputWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 24px;
  &:last-of-type {
    margin-bottom: 10px;
  }
`

export const FlexRowWrapper = styled.div`
  display: flex;
  flex-flow: column;
  @media ${device.mobileL} {
    flex-flow: row;
    justify-content: space-between;
    &:first-of-type {
        width: 50%;
      }
      &:last-child {
        width: 35%;
      }
    ${InputWrapper} {
      &:first-of-type {
        width: 100%;
      }
      &:last-of-type {
        min-width: 168px;
        max-width: 168px;
        margin-left: 20px;
          div {
            min-width: 168px //In comopnent min width is set to 214
        }
      }
    }
  }

`
export const TextWraper = styled.div`
  margin-bottom: 24px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.secondary};
`

export const TextLink = styled(Link)`
  display: inline;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  color: ${({ theme }) => theme.colors.accent};
  text-decoration: none;

  // Custom focus styles
  ${globalFocusStyles}
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const CancelButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: fit-content;
  margin-right: 22px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: right;
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  border: none;
  background-color: transparent;

  // Custom focus styles
  ${globalFocusStyles}
`