import React from 'react';
import styled from 'styled-components'
import Spinner from '../global-components/ButtonSpinner'


export const DownloadButton = styled.a<{ color?: string, isDisabled?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  padding: 4px 20px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, color }) => color ? color : theme.colors.accent};
  border: none;
  outline: none;
  cursor: pointer;
  pointer-events: ${({ isDisabled }) => isDisabled ? "none" : "auto"};
  text-decoration: none;
  overflow: hidden;
`

export const SpinnerWrapper = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.accent};
  border-radius: 4px;
`

const HiddenDownloadLink = styled.a`
  display: none;
`

interface ModalButtonProps {
  isLoading?: boolean;
  color?: string;
  children: string | React.ReactElement[];
  onClick?: (e: React.MouseEvent) => void;
  downloadURL?: string;
  fileName?: string;
}

const DownloadNewsButton = React.forwardRef<HTMLAnchorElement, ModalButtonProps>((
  {
    isLoading,
    color,
    children, onClick,
    downloadURL,
    fileName,
  },
  ref
) => {

  return (
    <>
      <DownloadButton
        isDisabled={isLoading}
        color={color}
        onClick={onClick}
      >
        {children}
        {isLoading && <SpinnerWrapper><Spinner size={18} /></SpinnerWrapper>}
      </DownloadButton>
      <HiddenDownloadLink ref={ref} download={fileName} href={downloadURL}></HiddenDownloadLink>
    </>
  );
})

export default DownloadNewsButton;
