import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'


interface SingleSavedSearch {
  id: number;
  search_name: string;
  search_term: string;
  date: {
    startDate: string
    endDate: string
  };
}
const initialState = {
  savedSearches: [] as SingleSavedSearch[],
  pagination: 1

}

const newsSearchSlice = createSlice({
  name: 'newsSearch',
  initialState,
  reducers: {
    getNewsSearches: (state, action) => {

    },
    addNewsSearch: (state, action) => {

    },
    deleteSavedSearch: (state, action) => {
      console.log('Delete saves search')
    },
    editSavedSearch: (state, action) => {

    }
  }
})

export const {
  getNewsSearches,
  addNewsSearch,
  deleteSavedSearch,
  editSavedSearch
} = newsSearchSlice.actions

export const newsSearchsReducer = (state: RootState) => state

export default newsSearchSlice.reducer