import React from 'react'
import { Helmet } from 'react-helmet-async'
import { isIOS } from '../../utils'

export default function PreventInputZoomIOS() {
  // On iOS devices browser will automaticaly zoom in on focused input fields that have font size set to less then 16px.
  // To prevent that we are adding maximum-scale=1 to viewport meta tag. 
  // The reason we are adding maximum-scale only on iOS devices is because it will prevent manual zooming on android devices.
  // Note that if Apple deside to change navigator object or userAgent property or they change max-scale to acctualy prevent user from zooming manualy, this solution will no longer work. 

  return isIOS() ? (
    <Helmet>
      <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1" />
    </Helmet>
  ) : null
}
