import React, { ReactElement } from 'react'
import { ReactComponent as NoResultImage } from '../../assets/icons/no-results.svg'
import styled from 'styled-components'
import { device } from '../../global-styles/mediaQueries'

const NoResultWrapper = styled.div<{ hasBgColor: boolean }>`
  padding: 40px 16px 60px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  background-color: ${({ theme, hasBgColor }) => hasBgColor ? theme.colors.white : 'transparent'};
  color: ${({ theme }) => theme.colors.secondary};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  text-align: center;
  h4 {
    margin: 14px 0 8px;
  }
  svg {
    height: 80px;
  }
  @media ${device.mobileL} {
    padding: 40px 24px 80px;
  }
  @media ${device.tablet} {
    padding: 60px 24px 100px;
    h4, p {
      max-width: 55%;
    }
  }
  @media ${device.tabletL} {
    padding: 70px 24px 120px;
  }
  @media ${device.desktopS} {
    padding: 60px 24px 120px;
    h4, p {
      max-width: 35%;
    }
  }
  @media ${device.desktop} {
    padding: 80px 24px 120px;
  }
`

export default function NoSearchResult({ hasBgColor = true }: { hasBgColor?: boolean }): ReactElement {
  return (
    <NoResultWrapper hasBgColor={hasBgColor}>
      <NoResultImage />
      <h4>Nothing is matching your search</h4>
      <p>Please try again with a different term</p>
    </NoResultWrapper>
  )
}
