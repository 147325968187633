import React, { useState, useContext, useEffect } from 'react';
import { ThemeContext } from 'styled-components'

//Styled components
import { NewsCoverageModalWrapper, TitleWrapper, InputWrapper, InputLabel, FlexRowWrapper, TextWraper, TextLink, ButtonWrapper, CancelButton } from './NewsCoverageModal.styled'

//Components 
import CustomInput from '../global-components/CustomInput'
import CustomButton from '../global-components/CustomButton'
import ExpirationSelect from '../global-components/ExpirationDateSelect'
import SubTermInput from '../global-components/SubTermInput'
import { SpinnerWrapper } from './DownloadNewsButton'
import Spinner from '../global-components/ButtonSpinner'
import MaterialIcon from '../global-components/MaterialIcon';

//Redux
import { useAppDispatch } from '../../state/hooks/hooks'
import { closeModal } from '../../state/slices/modalsSlice'
import { useSetSocialAlertMutation } from '../../state/query/queryApi'
import { GlobalNewsFetchParams } from '../../state/query/types/LatestNewsTypes';

//Utils
import { showErrorOrSuccessPopup } from '../../utils/showPopups'
import { validURL, validateEmail, convertArrayToCsv } from '../../utils'

interface NewsCoverageProps {
  payload: {
    newsType?: 'new' | 'existing';
    newsURL?: string;
    newsTitle?: string;
    id?: number;
    message?: string
    cacheType: 'SocialMedia' | 'MostRead' | 'gNews' | 'GlobalSearch' | "SocialAlerts"
    post: any;
    globalNewsFetchParams?: GlobalNewsFetchParams; // used for news search
  }
}

interface ExtendedFullfiledResponse extends Object {
  Message: string
}
const NewsCoverageModal = ({ payload: { newsType = 'new', newsURL = '', newsTitle = '', id, cacheType, post, globalNewsFetchParams } }: NewsCoverageProps) => {
  const [name, setName] = useState(newsTitle)
  const [url, setUrl] = useState(newsURL)
  const [email, setEmail] = useState<string[]>([])
  const [expirationOption, setExpirationOption] = useState<string>("1 day")
  const [isError, setIsError] = useState<boolean>(false)

  const [setSocialAlerts, { isLoading }] = useSetSocialAlertMutation()

  const themeContext = useContext(ThemeContext)

  const dispatch = useAppDispatch()

  const checkAndSetUrl = (url: string) => validURL(url)

  const checkEmail = (emails: string[]) => {
    let validEmail = true;
    if (emails.length > 0) {
      email.forEach((email) => {
        if (!validateEmail(email)) {
          validEmail = false
        }
      })
    }
    else {
      // Emails are required so array must have at least one email.
      validEmail = false
    }
    return validEmail
  }

  const dispatchCloseModal = () => dispatch({ type: closeModal.type })

  function handleSubmit() {
    if (checkAndSetUrl(url) && name.length !== 0 && checkEmail(email)) {
      const body = {
        expires_at: expirationOption,
        alert_emails_csv: convertArrayToCsv(email),
        title: name,
        source_url: url
      };
      if (newsType === 'existing' && id !== undefined) {
        setSocialAlerts({ id, body, cacheType, post, globalNewsFetchParams }).unwrap()
          .then((fullfiled: ExtendedFullfiledResponse) => {
            showErrorOrSuccessPopup(dispatch, fullfiled.Message, 'bottom', 'success', 'additionalInfo');
            dispatchCloseModal()
          })
          .catch(rejected => {
            showErrorOrSuccessPopup(dispatch, rejected.data.Message, 'bottom', 'error', 'additionalInfo')
            dispatchCloseModal()
          })
      } else {
        // For adding custom alerts we are passing 0 for id
        setSocialAlerts({ id: 0, body, cacheType, post }).unwrap()
          .then((fullfiled: ExtendedFullfiledResponse) => {
            showErrorOrSuccessPopup(dispatch, fullfiled.Message, 'bottom', 'success', 'additionalInfo');
            dispatchCloseModal()
          })
          .catch(rejected => {
            showErrorOrSuccessPopup(dispatch, rejected.data.Message, 'bottom', 'error', 'additionalInfo')
            dispatchCloseModal()
          })
      }
    } else {
      setIsError(true)
    }

  }

  useEffect(() => {
    const userEmail = localStorage.getItem('email')
    userEmail && setEmail(prevState => [...prevState, userEmail])
  }, [])

  return (
    <NewsCoverageModalWrapper>
      <TitleWrapper>
        <MaterialIcon iconName="notifications_active" color={themeContext.colors.accent} size={24} />
        <h4>Set Up Alerts</h4>
      </TitleWrapper>
      <InputWrapper>
        <CustomInput
          label="URL"
          labelHtmlFor="URL"
          inputVal={url}
          setInputVal={setUrl}
          isGray={true}
          isError={isError && !checkAndSetUrl(url)}
          customErrorMessage="Please insert valid url"
          placeholder="Paste URL address here…"
          id="URL"
          iconName="link"
          hasIconBackground={true}
          hasIcon={true}
          hasCancelIcon={true}
          isDisabled={newsType === 'existing'}
          name={'URL'}
        />
      </InputWrapper>

      <FlexRowWrapper>
        <InputWrapper>
          <CustomInput
            label="Coverage Name"
            labelHtmlFor="name"
            inputVal={name}
            setInputVal={setName}
            isGray={true}
            isError={isError && name.length === 0}
            hasCancelIcon={true}
            customErrorMessage="You must enter the name"
            placeholder="Type name here…"
            id="name"
            name="NewsName"
          />
        </InputWrapper>

        <InputWrapper>
          <InputLabel>
            Expiration
          </InputLabel>
          <ExpirationSelect handleSelect={setExpirationOption} />
        </InputWrapper>
      </FlexRowWrapper>
      <InputWrapper>
        <InputLabel htmlFor="email">
          Email for Alerts
        </InputLabel>
        <SubTermInput
          tags={email}
          setTags={setEmail}
          isError={isError && !checkEmail(email)}
          inputName="email"
          tagStyle="secondary"
          inputStyle='dark'
          placeholder={'Add email...'}
        />
      </InputWrapper>

      <TextWraper>
        You can add multiple emails. After typing an email, hit tab, spacebar or comma to add new one. To set default emails go to
        <TextLink onClick={dispatchCloseModal} to="/settings/notifications-alerts"> Default email settings</TextLink>
      </TextWraper>

      <ButtonWrapper>
        <CancelButton
          onClick={dispatchCloseModal}
        >
          Cancel
        </CancelButton>
        <CustomButton
          size="medium"
          bgColor={themeContext.colors.accent}
          onClick={handleSubmit}
        >
          Start Alerts
          {isLoading && <SpinnerWrapper><Spinner size={18} /></SpinnerWrapper>}
        </CustomButton>

      </ButtonWrapper>

    </NewsCoverageModalWrapper>
  );
}

export default NewsCoverageModal;
