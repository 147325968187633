import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom"
import { SingleNewsProps } from '../dashboard/SingleNews'
import moment from 'moment'
import SimpleWeekChart, { NoDataChart } from '../dashboard/SimpleWeekChart'
import SingleNewsSocialMediaRank from '../dashboard/SingleNewsSocialMediaRank'
import { DashboardMainContainer } from '../dashboard/SocialMediaTab.styled'
import { ThemeContext } from 'styled-components';
import MaterialIcon from '../global-components/MaterialIcon'
import SingleNewsTags from '../dashboard/SingleNewsTags'
import { useGetSingleArticleQuery } from '../../state/query/queryApi'
import SentimentButtons from '../dashboard/SentimentButtons'
import Spinner from '../global-components/ButtonSpinner'
import { LoaderAndMessageWrapper } from '../dashboard/LatestNewsList.styled'
import SingleNewsDropdown from '../dashboard/SingleNewsDropdown'
import { useAppDispatch } from '../../state/hooks/hooks'
import { openModal } from '../../state/slices/modalsSlice'
import { ReactComponent as ConnectionLostImage } from '../../assets/icons/no-internet.svg'
import { ReactComponent as MissingPage } from '../../assets/icons/missing_page.svg'
import { DefaultButton } from '../global-components/Button.styled'
import GeneralTooltipCaller from '../global-components/tooltips/GeneralTooltipCaller'
import { Error } from '../../state/query/types/globalQueryTypes'
import { handleOnErrorImage, limitCharacters } from '../../utils'
import useStorageListener from '../../hooks/useStorageListener'

// Utils
import { differenceInMinutesFromNow } from '../../utils'


import {
  SingleArticleWrapper,
  SocialStats,
  Engagement,
  StatIconWrapper,
  StatInfo, StatTitle,
  StatInfoValue,
  SocialMedia,
  TitleWithIcon,
  ArticleDistribution,
  ArticleDistributionInfo,
  StatSubTitle,
  StatValue,
  DistributionBarChart,
  WhiteBox,
  MostRead,
  WhiteBoxRow,
  ShowMore,
  ShowMoreArrow,
  ArticleContentWrapper,
  ArticleContent,
  ArticleTitle,
  ArticleTitleLink,
  ArticleText,
  NotificationIcon,
  SpinnerWrapper,
  ChartWrapper,
  StatsWrapper,
  EngagementWrapper,
  Header,
  BackButton,
  BackButtonWrapper,
  TagsAndSentiment,
  SingleArticleInfo,
  NewsInfoWrapper,
  PageLoader,
  SingleArticleSentiment,
  StatTooltipWrapper,
  NarrowWrapper,
  SentimentTooltipWrapper,
  BreadCrumbs,
  BreadCrumbsWrapper
} from './SingleArticle.styled'

import {
  Tags,
  LableText,
  NewsSourceIconWrapper,
} from '../dashboard/SingleNews.styled'
import SEO from '../global-components/SEO'

interface UrlParams {
  current_news_key: string
}

export default function SingleArticle() {
  const history = useHistory()
  const historyData = history.location.state as SingleNewsProps

  const dispatch = useAppDispatch()

  const { current_news_key }: UrlParams = useParams();

  const { data, isLoading, refetch, error } = useGetSingleArticleQuery(parseInt(current_news_key))

  const themeContext = React.useContext(ThemeContext);
  const [showMore, setShowMore] = useState(false)

  const isPublishedInLastHour = differenceInMinutesFromNow(data?.published_date as string) <= 60;

  const formateDate = (date: string): string => {
    const d = moment(date)
    const now = moment()
    const today = now.clone().startOf('day');
    const yesterday = now.clone().subtract(1, 'days').startOf('day');

    if (d.isSame(today, 'd')) {
      return d.fromNow()
    } else if (d.isSame(yesterday, 'd')) {
      return `Yesterday at ${d.format("HH:mm")}`
    } else {
      return d.format("Do MMMM YYYY, HH:mm")
    }
  }

  const toggleAccordion = () => setShowMore(showMore => !showMore)

  const handleGetAlerts = () => {
    if (data) {
      dispatch(openModal({
        name: 'latestNewsCoverage',
        props: {
          id: data.id,
          newsKey: data.current_news_key,
          newsTitle: data.title,
          newsURL: data.full_url,
          newsType: 'existing',
          searchParams: historyData?.searchParams
        }
      }))
    }
  }

  // Stopping alerts
  const handleStopAlerts = () => {
    if (data) {
      dispatch({
        type: openModal.type,
        payload: {
          name: 'stopAlertsModal',
          props: {
            alert_id: data.alert_id,
            newsKey: data.current_news_key,
            params: historyData?.searchParams,
            labelTitle: data.title
          }
        }
      })
    }
  }

  const createNewsLink = (url: string) => {
    const urlObj = new URL(url)
    return <a href={urlObj.origin} target="_blank" rel="noreferrer">{urlObj.hostname.replace(/www./, '')}</a>
  }

  const handleGoBack = (): void => {
    if (historyData) {
      history.goBack()
    } else {
      history.push('/dashboard')
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useStorageListener('updated_article_action', (action) => {
    if (action === 'DELETE_ARTICLE') {
      window.location.reload()
    } else {
      refetch()
    }
  });




  return (
    <DashboardMainContainer>
      {!isLoading ? (
        data ? (
          <SingleArticleWrapper>
            <SEO title={data.title} />

            <Header>
              <BackButtonWrapper>
                <BackButton onClick={handleGoBack}>
                  <MaterialIcon iconName='arrow_back' color="#68728c" />
                </BackButton>

                <BreadCrumbsWrapper>
                  <BreadCrumbs>Overview / {limitCharacters(data.title, 60)}</BreadCrumbs>
                </BreadCrumbsWrapper>
              </BackButtonWrapper>

              <SingleNewsDropdown
                newsId={data.id}
                notification={data?.alert_id !== null}
                handleStopAlerts={handleStopAlerts}
                handleGetAlerts={handleGetAlerts}
                isSingleArticle={true}
              />
            </Header>

            <SocialStats className='social-stats'>
              <EngagementWrapper className='engagement-wrapper'>
                <Engagement>
                  <StatIconWrapper>
                    <MaterialIcon iconName="groups" color={themeContext.colors.green} size={24} />
                  </StatIconWrapper>
                  <StatInfo>
                    <StatTitle>Total Engagement:</StatTitle>
                    <StatInfoValue>{isPublishedInLastHour ? '0' : data.total_engagement}</StatInfoValue>
                  </StatInfo>
                </Engagement>


                {data.social_engagement.length > 0 && (
                  <ChartWrapper showChart={showMore}>
                    {isPublishedInLastHour ? <NoDataChart isInSingleArticle={true} /> : <SimpleWeekChart data={data.social_engagement} published={data.published_date} floatingTooltip={true} />}
                  </ChartWrapper>
                )}
              </EngagementWrapper>

              <StatsWrapper showStats={showMore}>
                <SocialMedia>
                  <StatTooltipWrapper>
                    <GeneralTooltipCaller name="basic" content="This field shows the interactions this has gotten on Facebook. “Article distribution” shows the share of interactions compared to the article with the interactions of the same day. “Rank” shows the how high this article reach on the day of publication." />
                  </StatTooltipWrapper>
                  <NarrowWrapper>

                    <TitleWithIcon>
                      <MaterialIcon iconName="facebook_filled" color={themeContext.colors.accent} size={24} />
                      <StatTitle>Social media</StatTitle>
                    </TitleWithIcon>

                    <ArticleDistribution>
                      <ArticleDistributionInfo>
                        <StatSubTitle>Article distribution:</StatSubTitle>
                        <StatValue>
                          {data.article_distribution === null ? 0 : data.article_distribution}%
                        </StatValue>
                      </ArticleDistributionInfo>
                      <DistributionBarChart distribution={
                        data.article_distribution === null ? 0 : data.article_distribution
                      }></DistributionBarChart>
                    </ArticleDistribution>
                    <StatSubTitle>Rank: </StatSubTitle>
                  </NarrowWrapper>

                  <WhiteBox>
                    {data && <SingleNewsSocialMediaRank rankStats={data.rank} publishedDate={data.published_date} />}
                  </WhiteBox>
                </SocialMedia>

                <MostRead>

                  <NarrowWrapper>
                    <TitleWithIcon>
                      <MaterialIcon iconName="whatshot_filled" color={themeContext.colors.accent} size={24} />
                      <StatTitle>Most Read</StatTitle>
                    </TitleWithIcon>
                  </NarrowWrapper>

                  <StatTooltipWrapper>
                    <GeneralTooltipCaller name="basic" content="This field shows data from all publications’ most read lists. “Rank” shows the highest rank the article reached on the publishers website. “Time spent on top 10“ shows how long it has spent on the top 10 list of the publication." />
                  </StatTooltipWrapper>

                  <WhiteBox>
                    <WhiteBoxRow hasSeparator={true} isFirst={true}>
                      <StatSubTitle>Rank</StatSubTitle>
                      <StatValue>{data.top_ten_rank ? `# ${data.top_ten_rank}` : <span>Not Ranked</span>}</StatValue>
                    </WhiteBoxRow>
                    <WhiteBoxRow isLast={true}>
                      <StatSubTitle>Time spent in top 10</StatSubTitle>
                      <StatValue>{moment.utc(data.time_in_top_ten * 1000).format('H:mm')}h</StatValue>
                    </WhiteBoxRow>
                  </WhiteBox>
                </MostRead>
              </StatsWrapper>

              <ShowMore onClick={toggleAccordion}>
                {showMore ? <span>Show Less</span> : <span>Show More</span>}
                <ShowMoreArrow open={showMore} />
              </ShowMore>
            </SocialStats>

            <ArticleContentWrapper >
              <ArticleContent>
                {(
                  data &&
                  <>
                    {data?.alert_id !== null && (
                      <NotificationIcon>
                        <MaterialIcon iconName="notifications_filled_active" color={themeContext.colors.white} size={18} />
                      </NotificationIcon>
                    )}
                    <SingleArticleInfo>
                      <NewsSourceIconWrapper>
                        <img src={data.media_logo} alt="news logo" onError={handleOnErrorImage} />
                      </NewsSourceIconWrapper>
                      <NewsInfoWrapper>
                        {createNewsLink(data.full_url)}
                        <span>{formateDate(data.published_date)}</span>
                      </NewsInfoWrapper>

                    </SingleArticleInfo>
                    <ArticleTitle>
                      {data.title} <ArticleTitleLink href={data.full_url} target="_blank" rel="noreferrer">
                        <MaterialIcon iconName="launch" color={themeContext.colors.primary} size={18} />
                      </ArticleTitleLink>
                    </ArticleTitle>
                    <ArticleText dangerouslySetInnerHTML={{ __html: `<p>${data.full_text_raw.replace(/<br \/>\\*/g, "</p><p>")}</p>` }} />

                    <TagsAndSentiment>
                      <Tags isOpen={true} isSingleArticlePage={true}>
                        <LableText isSingleArticlePage={true}>Tags</LableText>
                        <SingleNewsTags tags={data.tags_custom} tags_company={data.tags_company} newsId={data.id} newsKey={data.current_news_key}
                          searchParams={historyData?.searchParams}
                        />
                      </Tags>

                      <SingleArticleSentiment isOpen={true} isSingleArticlePage={true}>
                        <LableText isSingleArticlePage={true}>Sentiment</LableText>
                        <SentimentButtons sentiment={data.sentiment} newsId={data.id} newsKey={data.current_news_key} searchParams={historyData?.searchParams} />
                        <SentimentTooltipWrapper>
                          <GeneralTooltipCaller name="basic" content="Select the overall sentiment for the article" />
                        </SentimentTooltipWrapper>
                      </SingleArticleSentiment>
                    </TagsAndSentiment>
                  </>
                )}

              </ArticleContent>
            </ArticleContentWrapper>

          </SingleArticleWrapper>
        ) : (
          <LoaderAndMessageWrapper type="no-results">
            {(error as Error)?.status === 404 ? <MissingPage /> : <ConnectionLostImage />}
            <h3>
              {
                ((status) => {
                  switch (status) {
                    case 404:
                      return 'The page seems to be missing'
                    case 'FETCH_ERROR':
                      return 'You are offline!'
                    default:
                      return 'Server not responding!'
                  }
                })((error as Error)?.status)
              }
            </h3>
            <p>
              {
                ((status) => {
                  switch (status) {
                    case 404:
                      return 'We\'re sorry, but it seems that the page you\'re searching for has been misplaced or is no longer available.'
                    case 'FETCH_ERROR':
                      return 'Your network is unavailable. Check your data or wifi connection.'
                    default:
                      return 'We are having difficulty connecting to the server. Please try again later.'
                  }
                })((error as Error)?.status)
              }
            </p>
            <DefaultButton
              bgColor={themeContext.colors.accent}
              size={"small"}
              onClick={(error as Error)?.status === 404 ? () => history.push('/dashboard') : () => refetch()}
            >
              {(error as Error)?.status === 404 ? 'Go to Homepage' : 'Retry'}
            </DefaultButton>
          </LoaderAndMessageWrapper>
        )
      ) : (
        <PageLoader>
          <SpinnerWrapper location="content">
            <Spinner size={40} color={themeContext.colors.accent} />
          </SpinnerWrapper>
        </PageLoader>
      )}

    </DashboardMainContainer>
  )
}
