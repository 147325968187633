import styled from 'styled-components'

export const SearchInputWrapper = styled.div`
  position: relative;
`

export const TriangleBox = styled.div`
  position: relative;
  width: 35px;
  height: 46px;
  left: -35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.body};
  border: solid 1px ${({ theme }) => theme.colors.borderSecondary};
`