import React, { useState, useEffect } from 'react'
import OutsideClickWrapper from './OutsideClickWrapper'
import { v4 as uuidv4 } from 'uuid'
import MaterialIcon from './MaterialIcon'

import {
  DropDownListContainer,
  DropDownList,
} from './CustomDropdown.styled'

import {
  FormSelectContainer,
  SelectFormHeader,
  FormSelectListItems
} from './DateRangeSelect.styled'

export interface DateRange {
  startDate: string | null;
  endDate: string | null;
}

export interface Option {
  dayNum: number;
  text: string;
}

interface CustomFormSelect {
  handleSelect: (option: string) => void
}

export default function ExpirationDateSelect({
  handleSelect,
}: CustomFormSelect) {

  const options = [
    {
      dayNum: 1,
      text: 'After 1 day',
    },
    {
      dayNum: 2,
      text: 'After 2 days',
    },
    {
      dayNum: 3,
      text: 'After 3 days',
    }
  ]

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0].text)

  const toggling = () => setIsOpen(!isOpen);

  const calculateDateAfterXDaysISO = (xDays: number): string => new Date(Date.now() + xDays * 24 * 60 * 60 * 1000).toISOString()
  const prepareExpiresAtString = (option: number): string => {
    if (option === 1) return '1 day'
    return `${option} days`
  }

  const onChange = (option: Option) => () => {
    handleSelect(prepareExpiresAtString(option.dayNum))
    setSelectedOption(option.text)
    setIsOpen(false)
  };

  const closeOnClickOutside = () => setIsOpen(false)

  //If onChange is not executed
  useEffect(() => handleSelect(calculateDateAfterXDaysISO(options[0].dayNum)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  return (
    <FormSelectContainer type={'expiration'}>
      <OutsideClickWrapper handlerFunc={closeOnClickOutside} enabled={isOpen}>
        <SelectFormHeader
          onClick={toggling}
          isActive={isOpen}
          tabIndex={0}
        >
          <span>{selectedOption || options[0].text}</span>
          <MaterialIcon iconName="arrow_drop_down" additionalClassName="dropdown-icon" />
        </SelectFormHeader>
        {isOpen && (
          <DropDownListContainer position={'fit'}>
            <DropDownList>
              {options && options.map(option => (
                <FormSelectListItems
                  onClick={onChange(option)}
                  key={uuidv4()}
                  isSelected={selectedOption === option.text}
                  type={'expiration'}
                >
                  {option.text}
                </FormSelectListItems>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </OutsideClickWrapper>
    </FormSelectContainer>
  )
}