import React, { useState, useContext, useRef, useCallback, useEffect } from 'react'
import { ThemeContext } from 'styled-components'

//Components
import CustomInput from '../global-components/CustomInput'
import SavedSearchDropdown from '../global-components/SavedSearchDropdown'
import { InputWrapper, InputLabel, NewsSearchFormWrapper } from './CustomNewsSearchBar.styled'
import DateRangeSelect, { DateRange } from '../global-components/DateRangeSelect'
import OutsideClickWrapper from '../global-components/OutsideClickWrapper'

//Components
import CustomButton from '../global-components/CustomButton'
import SavedSearch from './SavedSearch'

//query
import { useGetSavedSearchQuery, useSaveNewsSearchMutation } from '../../state/query/queryApi'

//Types
interface NewsSearchFormProps {
  handleSearchQueryChange: (inputValue: string, date: DateRange) => void;
  isFetchingNewSearchData: boolean;
}

//Hack for old version of React, after some version React contain this, but istead of update version I will just modify event
interface ExtendedKeyboardEvent extends React.KeyboardEvent<HTMLDivElement> {
  code: string
}

export default function NewsSearchForm({ handleSearchQueryChange, isFetchingNewSearchData }: NewsSearchFormProps) {

  const [isSearchHIstoryActive, setIsSearchHIstoryActive] = useState(false)
  const [setDateRangeForSavedSearch, setSetDateRangeForSavedSearch] = useState<string | undefined>(undefined)
  const [showSavedSearch, setShowSavedSearch] = useState(false)
  //Local state input
  const [inputVal, setInputVal] = useState('')
  const context = useContext(ThemeContext)

  const closeOnClickOutside = () => setIsSearchHIstoryActive(false)

  const { colors } = context

  //Query
  const { data: savedSearches, isLoading, isFetching, isSuccess } = useGetSavedSearchQuery('', {
    refetchOnReconnect: true,
  })

  //Mutations
  const [saveNewsSearchMutation, results] = useSaveNewsSearchMutation()

  //Local states
  const [expirationOption, setExpirationOption] = useState<DateRange>({ startDate: null, endDate: null })

  //update local state
  const handleExpiredOption = (option: DateRange) => setExpirationOption(option)


  //When user create new search in cache
  const latestID = useRef(savedSearches?.length !== 0 && savedSearches !== undefined
    ? savedSearches
      ? savedSearches[0].id
      : 10000
    : 0)

  const handleSearchHistory = () => setIsSearchHIstoryActive(isSearchHIstoryActive => !isSearchHIstoryActive)

  useEffect(() => {
    latestID.current = savedSearches?.length !== 0 && savedSearches !== undefined ? savedSearches ? savedSearches[0].id : 10000 : 0
  }, [isFetching, isSuccess, savedSearches])


  function saveNewsSearch(search_name: string): Promise<any> {

    latestID.current = latestID.current + 1

    return saveNewsSearchMutation({
      id: latestID.current,
      search_name: search_name,
      search_term: inputVal,
      date: expirationOption,
    })
  }

  const memoSavedNewsSearch = useCallback(saveNewsSearch,
    //eslint-disable-next-line
    [inputVal, expirationOption])

  return (
    <>
      <NewsSearchFormWrapper
        onKeyDown={(e: ExtendedKeyboardEvent) => e.code === 'Enter' && handleSearchQueryChange(inputVal, expirationOption)}
      >

        <InputWrapper>
          <CustomInput
            label="Search"
            inputVal={inputVal}
            setInputVal={setInputVal}
            isGray={true}
            placeholder={"Search For Articles"}
            iconColor={colors.secondary}
            hasIcon={true}
            iconName="search"
            hasIconBackground={false}
            hasDropdown={true}
            hasCancelIcon={true}
            handleOnTriangleClick={handleSearchHistory}
            name="searchNews"
            isDisabled={showSavedSearch}
          />
          {isSearchHIstoryActive
            && <OutsideClickWrapper handlerFunc={closeOnClickOutside} enabled={isSearchHIstoryActive}>
              <SavedSearchDropdown
                isLoading={isLoading}
                data={savedSearches}
                setValuesToQuery={(search_term, date) => handleSearchQueryChange(search_term, date)}
                setSearchInputVal={setInputVal}
                setExpirationOption={setExpirationOption}
                setIsBoxVisible={handleSearchHistory}
                setSetDateRangeForSavedSearch={setSetDateRangeForSavedSearch}
              />
            </OutsideClickWrapper>}
        </InputWrapper>
        <InputWrapper className="expiration-wrapper">
          <InputLabel isDisabled={showSavedSearch}>
            Date Range
          </InputLabel>
          <DateRangeSelect handleSelect={handleExpiredOption} location="global-search" optionName={setDateRangeForSavedSearch} disabled={showSavedSearch || isFetchingNewSearchData} />
        </InputWrapper>

        <CustomButton
          size="large"
          onClick={() => handleSearchQueryChange(inputVal, expirationOption)}
          bgColor={colors.accent}
          isDisabled={inputVal.length < 3 || showSavedSearch || isFetchingNewSearchData}
        >
          Search
        </CustomButton>


      </NewsSearchFormWrapper>

      <SavedSearch savedSearches={savedSearches} NewsSearchInputVal={inputVal} showSavedSearch={showSavedSearch} setShowSavedSearch={setShowSavedSearch} isDisabled={inputVal.length < 3} isLoading={results.isLoading} saveNewsSearch={memoSavedNewsSearch} />
    </>
  )
}
