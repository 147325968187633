import { useEffect, useRef } from "react";

const usePreviousState = <T>(value: T, deps: any[]): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);
  return ref.current;
};

export default usePreviousState