import React from 'react'
import { ThemeContext } from 'styled-components'

type SubtitleText = { text: string, font?: 'primary' | 'secondary', type?: 'small' | 'large' | 'caption' }

//This component can render Subtitle 1 | Subtitle 2 | Caption C1 depeands of type
export default function SubtitleComponent({ text, font = 'primary', type = 'large' }: SubtitleText) {

  const theme = React.useContext(ThemeContext)

  const calcFontSize = () => {
    switch (type) {
      case 'caption': return '12px'
      case 'small': return '13px'
      case 'large': return '14px'
    }
  }

  return (
    <p style={{
      color: theme.colors.primary,
      fontSize: calcFontSize(),
      fontWeight: 'bold',
      lineHeight: '18px',
      fontFamily: `${font === 'primary' ? 'Arial' : 'Helvetica'}`,
      textTransform: 'capitalize'
    }}
    >
      {text}
    </p>
  )
}
