import React, { ReactElement, useContext } from 'react'
import { ComposedChart, Area, Line, ResponsiveContainer } from 'recharts'
import { nanoid } from '@reduxjs/toolkit'
import { ThemeContext } from 'styled-components'
import { CustomizedDot } from './SimpleWeekChart'

import styled from 'styled-components'
import { device } from '../../global-styles/mediaQueries'

type ChartSize = 'small' | 'large'

const DailyChartWrapper = styled.div<{ size?: ChartSize }>`
 position: relative;
  height: ${({ size }) => size === 'small' ? '50px' : '70px'}; 
  width: ${({ size }) => size === 'small' ? '130px' : '170px'};

  @media ${device.tabletL} {
    width: 160px;
    height: 60px;
  }
`

export default function DailyChart({ engagenmentData, size = 'large' }: { engagenmentData: string, size?: ChartSize }): ReactElement {

  const preparedDataForChart = engagenmentData.split(',').map(item => { return { engagement: parseInt(item) } })
  const { colors } = useContext(ThemeContext)
  const id = nanoid()

  return (
    <DailyChartWrapper size={size}>
      <ResponsiveContainer width="100%" height="100%">

        <ComposedChart data={preparedDataForChart} margin={{ top: 11, right: 15, bottom: 11, left: 15 }} >
          <defs>
            <linearGradient id={`${id}`} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={colors.green} stopOpacity={0.2} />
              <stop offset="95%" stopColor={colors.green} stopOpacity={0.02} />
            </linearGradient>
          </defs>

          <Line
            type='linear'
            dataKey="engagement"
            isAnimationActive={false}
            dot={<CustomizedDot />}
            stroke={colors.green}
            fill={colors.green}
            fillOpacity={1}
          // activeDot={<ActiveDot onChartMouseOver={handleMouseOver} onChartMouseLeave={handleMouseLeave} />}
          />
          <Area dataKey="engagement" isAnimationActive={false} fill={`url(#${id})`} fillOpacity={1} stroke="" dot={false} />
        </ComposedChart>
      </ResponsiveContainer>

    </DailyChartWrapper>
  )
}
