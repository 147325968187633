import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//Tranlsation files
import enTranslations from './en/translations.json'
import isTranslations from './is/translations.json'

i18n
  .use(initReactI18next)// passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: enTranslations
      },
      is: {
        translation: isTranslations
      }
    },
    lng: "en", // if you're using a language detector, do not define the lng option. For now we are not using it, but we will see with client should we use it
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // https://www.i18next.com/translation-function/interpolation#unescape
    }

  })