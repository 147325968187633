import styled from 'styled-components'
import { device } from '../../global-styles/mediaQueries'
import { globalFocusStyles } from '../../global-styles/globalStyles'

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 20px 16px;
  background-color: #0284fe;
  background-color: ${({ theme }) => theme.colors.accent_50};

  @media ${device.mobileL} {
    padding: 20px;
  }

  @media ${device.tablet} {
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    padding: 22px 20px;
  }
`

export const TermsAndRangeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const SearchTerm = styled.div`
  display: flex;
  height: 26px;
  justify-content: space-between;
  align-items: center;
  margin: 8px 8px 8px 0;
  padding: 0px 8px 0px 10px;
  border-radius: 13px;
  border: solid 1px ${({ theme }) => theme.colors.tagBorder};
  background-color: ${({ theme }) => theme.colors.highlight};

  p {
    padding-top: 2px;
    font-family: Arial;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: ${({ theme }) => theme.colors.primary};
  }

  button {
    display: inline-flex;
    margin-left: 6px;
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
`

export const ClearAllButton = styled.button`
  display: flex;
  justify-content: center;
  margin: 8px 0;
  padding: 0;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  
  span {
    padding-top: 2px;
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 13px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
  }

  // Custom focus styles
  ${globalFocusStyles}
`
