import { useState, useRef, useEffect } from 'react'
import { showTooltip, hideTooltip } from '../../../state/slices/tooltipSlice'
import { useAppDispatch } from '../../../state/hooks/hooks'
import { ReactComponent as QuestionMark } from '../../../assets/icons/QuestionMark.svg'

import {
  TooltipButton,
  TooltipCallerWrapper
} from './GeneralTooltipCaller.styled'



export default function GeneralTooltipCaller({ content, name, children, isInNoDataChart }: { content?: string, name: 'basic' | 'socMediaRank', children?: any, isInNoDataChart?: boolean }) {

  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false)
  const tooltipCaller = useRef<HTMLDivElement>(null)


  const handleShowTooltip = () => {
    const rect = tooltipCaller.current?.getClientRects()[0]

    if (rect) {
      setOpen(true)
      dispatch(showTooltip({
        name: name,
        props: {
          callerTop: rect.top,
          callerLeft: rect.left,
          callerWidth: rect.width,
          callerHeight: rect.height,
          content: content,
          isInNoDataChart: isInNoDataChart
        }
      }))
    }
  }

  const handleCloseTooltip = () => {
    setOpen(false)
    dispatch({ type: hideTooltip.type })
  }

  const handleTooltipOnScroll = () => {
    if (open) {
      setOpen(false)
      dispatch({ type: hideTooltip.type })
    }
  }


  useEffect(() => {
    window.addEventListener('scroll', handleTooltipOnScroll)
    return () => window.removeEventListener('scroll', handleTooltipOnScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])


  return (
    <TooltipCallerWrapper ref={tooltipCaller}>
      <TooltipButton
        onMouseOver={() => handleShowTooltip()}
        onMouseOut={() => handleCloseTooltip()}
        onClick={() => handleShowTooltip()}
      >
        {children ? children : <QuestionMark />}
      </TooltipButton>
    </TooltipCallerWrapper>
  )
}
