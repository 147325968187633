import React, { useEffect, useRef } from 'react'
import styled from "styled-components"

const CircleGraphWrapper = styled.div`
  position: relative;
  text {
    font-family: Arial;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary};
  }
`
export default function CircleGraph({ percentage }: { percentage: number }) {

  const circle = useRef<SVGCircleElement | null>(null)

  function calculateSvgStrokePercentage() {
    let percentDashOffset = 1.65;
    let calcDashOffset = (200 - (percentDashOffset * percentage)).toString()
    return calcDashOffset
  }

  useEffect(() => {
    circle.current?.setAttribute('stroke-dashoffset', calculateSvgStrokePercentage())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <CircleGraphWrapper>
      <svg width="60" height="60" xmlns="http://www.w3.org/2000/svg">
        <circle
          cx="30" cy="30" r="27"
          stroke="#eef1f9" strokeWidth="5"
          fill="transparent"
        />
        <circle
          ref={circle}
          cx="30" cy="30" r="27"
          stroke="#0284fe" strokeWidth="5" strokeDasharray="200" strokeDashoffset="200"
          fill="transparent" style={{ transition: '0.4s', transformOrigin: 'center', transform: 'rotate(-90deg)' }}
          strokeLinecap="round"
        />
        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">{percentage}%</text>
      </svg>
    </CircleGraphWrapper>
  )
}
