import React from 'react'
import styled from 'styled-components'

import { Icon, StyledCheckbox } from './CustomCheckbox'

const MasterCheckboxContainer = styled.div`
  display: flex;
  vertical-align: middle;
  cursor: pointer;
`

interface TableMasterCheckboxProps {
  numberOfSelected: number;
  itemsOnPage: number;
  handleDeselectAll: () => void;
  handleSelectAll: () => void;
}

export default function TableMasterCheckbox(props: TableMasterCheckboxProps) {
  
  const handleClick = () => {
    if (!props.numberOfSelected) {

      props.handleSelectAll()
    } else {
      props.handleDeselectAll()
    }
  }

  return (

    <div >
      <label>
        <MasterCheckboxContainer onClick={handleClick}>
          <StyledCheckbox isEnabled={true} checked={props.numberOfSelected ? true : false}>
            {
              !!props.numberOfSelected && (
                <Icon viewBox="0 0 24 24">
                  {props.numberOfSelected < props.itemsOnPage ? (
                    <line x1="5" y1="12" x2="19" y2="12" />
                  ) : (
                    <polyline points="20 7 10 17 5 12" />
                  )}

                </Icon>
              )
            }
          </StyledCheckbox>
        </MasterCheckboxContainer>
      </label>
    </div>

  )
}
