import React from 'react'
import CustomDropdownList from '../global-components/CustomDropdownList'
import { IconName } from '../global-components/MaterialIcon'
import { openModal } from '../../state/slices/modalsSlice'
import { useAppDispatch } from '../../state/hooks/hooks'
import styled from 'styled-components'
import { device } from '../../global-styles/mediaQueries'


const DropdownWrapper = styled.div`
  position: absolute;
  top: 11px;
  right: 16px; 

  @media ${device.mobileL} {
    top: 15px;
    right: 20px;
  }

  @media ${device.tablet} {
    position: relative;
    top: unset;
    right: unset;
    margin-left: 10px;
  }
`

interface DropdownListOptionsTypes {
  text: string;
  icon: IconName;
  onClick: () => void
}

export default function LatestNewsDownload() {

  const dispatch = useAppDispatch()

  const downloadListOptions = [
    {
      text: "Download news list",
      icon: "file_download",
      onClick: function () {
        dispatch(openModal({
          name: 'downloadNews',
          props: {
            fileType: 'csv'
          }
        }))
      }
    }
  ] as DropdownListOptionsTypes[]

  return (
    <DropdownWrapper>
      <CustomDropdownList
        options={downloadListOptions}
        dropdownPosition="right"
        icon="more_vert"
        passedElementIcon="file_download"
      />
    </DropdownWrapper>
  )
}
