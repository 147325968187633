import { createSlice, PayloadAction } from '@reduxjs/toolkit'


interface SendAt {
  date: string;
  time: null | number;
}

interface SendAction {
  action: string;
  checked: boolean;
  threshold?: number;
}

interface SendActions extends Array<SendAction> { }

interface EmailNotification {
  send_at: SendAt;
  send_when: SendActions;
}

interface SearchTerm {
  term: string;
  sub_terms: string[];
  id: number;
}

interface SearchTerms extends Array<SearchTerm> { }

export interface Settings {
  default_emails: string[];
  alert_expiration: number;
  email_notifications: EmailNotification;
  search_terms: SearchTerms;
}

const initialState: Settings = {
  default_emails: [],
  alert_expiration: 0,
  email_notifications: {
    send_at: {
      date: "",
      time: null
    },
    send_when: [],
  },
  search_terms: []
}

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    addSettings: (state, action: PayloadAction<Settings>) => {
      return { ...state, ...action.payload }
    },
    changeSendAt: (state, action: PayloadAction<SendAt>) => {
      if (action.payload.time) {
        state.email_notifications.send_at.date = action.payload.date
        state.email_notifications.send_at.time = action.payload.time
      } else {
        state.email_notifications.send_at.date = action.payload.date
        state.email_notifications.send_at.time = null
      }
    },
    changeSendOn: (state, action: PayloadAction<SendActions>) => {
      state.email_notifications.send_when = action.payload
    },
    addSubTerm: (state, action: PayloadAction<{ id: number, sub_term: string }>) => {
      const index = state.search_terms.findIndex(term => term.id === action.payload.id)
      if (index !== -1) state.search_terms[index].sub_terms.push(action.payload.sub_term)
    },
    removeSubTerm: (state, action: PayloadAction<{ id: number, sub_term: string }>) => {
      const index = state.search_terms.findIndex(term => term.id === action.payload.id)
      if (index !== -1) {
        const subTermIndex = state.search_terms[index].sub_terms.indexOf(action.payload.sub_term)
        state.search_terms[index].sub_terms.splice(subTermIndex, 1)
      }
    },
    addDefaultEmail: (state, action: PayloadAction<string>) => {
      state.default_emails.push(action.payload)
    },
    removeDefaultEmail: (state, action: PayloadAction<string>) => {
      const index = state.default_emails.indexOf(action.payload)
      state.default_emails.splice(index, 1)
    }
  }
})

export const {
  addSettings,
  changeSendAt,
  changeSendOn,
  addSubTerm,
  removeSubTerm,
  addDefaultEmail,
  removeDefaultEmail
} = slice.actions

export default slice.reducer