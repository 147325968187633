import styled, { css } from 'styled-components'
import { device } from '../../global-styles/mediaQueries'

export const NavigationWrapper = styled.div<{ shouldShow: boolean, hideWrapper: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  min-height: ${({ hideWrapper }) => hideWrapper ? '0px' : '100vh'};
  width: 100%;
  background-color: transparent;
  opacity: 1;
  overflow: hidden;
  transition: background-color .3s ease;
  overflow: hidden;
  z-index: 2;
  
  ${({ shouldShow }) => shouldShow && css`
    background-color: ${({ theme }) => `${theme.colors.primary}80`};
  `}
`

export const Container = styled.div<{ shouldShow: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100vh;
  width: 100%;
  background: white;
  animation-fill-mode: forwards;
  animation-duration: .3s;
  overflow: hidden;
  transform: translateX(100%);
  transition: transform .3s ease;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: auto;

  ${({ shouldShow }) => shouldShow && css`
    transform: translateX(0);
  `}

  @media ${device.tablet} {
    max-width: 440px;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
`

export const CloseButton = styled.button`
  display: inline-block;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`

export const Body = styled.div`
  padding: 16px;
`