import styled, { css } from 'styled-components'
import { device } from '../../global-styles/mediaQueries'
import { globalFocusStyles } from '../../global-styles/globalStyles'

export const NavigationWrapper = styled.div<{ shouldShow: boolean, hideWrapper: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  min-height: ${({ hideWrapper }) => hideWrapper ? '0px' : '100vh'};
  width: 100%;
  background-color: transparent;
  opacity: 1;
  overflow: hidden;
  transition: background-color .3s ease;
  overflow: hidden;
  z-index: 1;
  
  ${({ shouldShow }) => shouldShow && css`
    background-color: ${({ theme }) => `${theme.colors.extraDarkBlue}50`};
  `}

  @media ${device.desktopS} {
    top: 60px;
    min-height: calc(100vh - 60px);
    width: auto;
    overflow: visible;
  }
`

export const Container = styled.div<{ shouldShow: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background: white;
  animation-fill-mode: forwards;
  animation-duration: .3s;
  transform: translateX(-100%);
  background: green;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 8px 0 rgba(49, 65, 97, 0.05);
  transition: transform .3s ease;
  overflow-y: auto;

  ${({ shouldShow }) => shouldShow && css`
    transform: translateX(0);
  `}

  @media ${device.tablet} {
    width: 300px;
  }
  @media ${device.desktopS} {
    position: relative;
    width: 240px;
    height: calc(100vh - 60px);
    transform: unset;
  }
`
export const NavHeaderWrapp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 18px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderPrimary};

  svg {
    cursor: pointer;
  }

  @media ${device.desktopS} {
    display: none;
  }
`
export const LogoWrapper = styled.div`
  svg {
    height: 24px;
    width: auto;
    cursor: auto;
  }
`
export const CompanyInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 30px;

  .company-name {
    margin-left: 12px;
    font-size: 13px;
    font-weight: bold;
    line-height: 1.38;
    color: ${({ theme }) => theme.colors.primary};
  }

  @media ${device.desktopS} {
    padding: 22px 30px 30px;
  }
`

export const Navigation = styled.nav`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-right: 12px;
  padding-bottom: 40px;

  a { 
    position: relative;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-width: 250px;
    height: 54px;
    padding: 0 30px;
    text-decoration: none;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.secondary};
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    svg {
      display: inline-block;
      fill: ${({ theme }) => theme.colors.secondary};
      margin-right: 12px;
    }

    span {
      font-size: 14px;
      line-height: 1.29;
    }
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
      background-color: ${({ theme }) => theme.colors.highlight};

      svg {
        fill: ${({ theme }) => theme.colors.accent};
      }
    }

    &:focus-visible {
      outline: 2px solid ${({ theme }) => theme.colors.accent};
    }
  
    &.with-separator {
      position: relative;
      margin-top: auto;
      margin-right: -20px;

      &::after {
        position: absolute;
        top: 0;
        left: -10px;
        display: block;
        content: '';
        height: 1px;
        width: calc(100% + 10px);
        background-color: ${({ theme }) => theme.colors.borderPrimary};
      }
    }
  }

  .active {
    color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.highlight};

    svg {
      fill: ${({ theme }) => theme.colors.accent};
    }

    &::before {
      position: absolute;
      display: block;
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      width: 3px;
      background-color: ${({ theme }) => theme.colors.accent}
    }
    &.with-separator {
      &::before {
        left: -10px;
      }
    }
  }
  @media ${device.desktopS} {
    a {
      min-width: 230px;
    }
  }
`

export const NavigationLabel = styled.span`
  display: block;
  margin: 0px 0px 14px 30px;
  font-size: 12px;
  line-height: 1.33;
  color: ${({ theme }) => theme.colors.secondary};
`

export const NavigationSection = styled.div`
  margin-bottom: 20px;
`
export const CloseBtnWrapper = styled.div`
  ${globalFocusStyles}
`