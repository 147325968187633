export const centerFlex = `
  display: flex;
  justify-content: center;
  align-items: center;
`

export const textOverfllowEllipsis = `
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`