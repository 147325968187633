import React from 'react'
import styled, { css } from 'styled-components'
import { device } from '../../../global-styles/mediaQueries'
import { nanoid } from '@reduxjs/toolkit'



const setStyles = (colNumber: number, type: 'report' | 'invoice') => {
  if (type === 'report') {
    switch (colNumber) {
      case 0:
        return css`
          display: none;
          @media ${device.tablet} {
            display: block;
            grid-column: 3/4;
          }
        `
      case 1:
        return css`
          display: block;
          grid-column: 3/4;
          @media ${device.tablet} {
            grid-column: 4/5;

          }
        `
      case 2:
        return css`
          display: none;
          @media ${device.tabletL} {
            display: block;
            grid-column: 5/6;
          }
        `
    }
  } else {
    switch (colNumber) {
      case 0:
        return css`
          display: none;
          @media ${device.tablet} {
            display: block;
            grid-column: 3/4;
          }
        `
      case 1:
        return css`
          display: block;
          grid-column: 3/4;
          @media ${device.tablet} {
            grid-column: 4/5;

          }
        `
      case 2:
        return css`
          display: none;
          @media ${device.tabletL} {
            display: block;
            grid-column: 5/6;
          }
        `
      case 3:
        return css`
          display: none;
          @media ${device.tablet} {
            display: block;
            grid-column: 5/6;
          }
          @media ${device.tabletL} {
            grid-column: 6/7;
          }
        `
      case 4:
        return css`
          display: block;
          grid-column: 4/5;
          @media ${device.tablet} {
            display: block;
            grid-column: 6/7;
          }
          @media ${device.tabletL} {
            display: block;
            grid-column: 7/8;
          }
        `
    }
  }
}

const TableColumnWrapper = styled.div<{ type: 'report' | 'invoice' }>`
  display: grid;
  grid-template-columns: ${({ type }) => type === 'report' ? '72px 28px 1fr' : '72px 28px 1fr 1fr 28px'};
  height: 38px;
  padding: 9px 16px;
  background-color: ${({ theme }) => theme.colors.highlight};
  border-top: 1px solid ${({ theme }) => theme.colors.borderPrimary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderPrimary};

  @media ${device.tablet} {
    grid-template-columns: ${({ type }) => type === 'report' ? '82px 38px 137px 1fr' : '82px 38px 1fr 1fr 1fr 1fr 28px'};
    padding: 10px 24px;
  }
  @media ${device.tabletL} {
    grid-template-columns: ${({ type }) => type === 'report' ? '62px 38px 137px 1fr 1fr 28px' : '62px 38px 136px 1fr 1fr 1fr 1fr 28px'};
  }
`

const ColumnText = styled.span<{ col: number, type: 'report' | 'invoice' }>`
  font-size: 13px;
  line-height: 1.38;
  color: ${({ theme }) => theme.colors.primary};
  grid-row: 1/2;
  ${({ col, type }) => setStyles(col, type)}
`

const ChildrenWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-column: 1/2;

  @media ${device.tabletL} {
    justify-content: flex-start;
  }
`

export default function CheckboxTableColumns({ children, columns, type }: { children: React.ReactChild, columns: string[], type: 'report' | 'invoice' }) {

  return (
    <TableColumnWrapper type={type}>
      <ChildrenWrapper>
        {children}
      </ChildrenWrapper>
      {columns.map((item, index) => <ColumnText col={index} type={type} key={nanoid()}>{item}</ColumnText>)}
    </TableColumnWrapper>
  )
}
