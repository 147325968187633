import styled from 'styled-components'
import { device } from '../../global-styles/mediaQueries'
import { centerFlex } from '../../global-styles/mixins'

export const AddNewBox = styled.div`
  display: flex;
  flex-flow: column;
  padding: 16px;
  margin-bottom: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: 0 5px 5px -4px rgba(49, 65, 97, 0.08);
  h5 {
    margin: 0 0 2px 0;
  }
  @media ${device.mobileL} {
    flex-flow: row;
    justify-content: space-between;
    padding: 20px;  
    button { height: 40px;}
  }
  @media ${device.tablet} {
    margin-bottom:20px;
    padding: 24px;
    justify-content: flex-start;
    button { justify-self: flex-end;}
  }
`

export const IconWrapper = styled.div`
  display: none;
  @media ${device.tablet} {
    display: flex;
    margin-right: 14px;
    ${centerFlex};
  }
`

export const AddNewTextBox = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 16px;
  @media ${device.mobileL} {
    margin-bottom: 0px;
  }
  @media ${device.tablet} {
    flex-grow: 2;
  }
`