import React, { useState, useContext } from 'react'
//Styles
import { ActiveTab, NavigationWrapper, SingleNavItem, DashboardNavigationContainer, NavigationContainer } from './DashboardNavigation.styled'
import { ThemeContext } from 'styled-components'
//Components
import MaterialIcon, { IconName } from '../global-components/MaterialIcon';
//Redux
import { useAppDispatch, useAppSelector } from '../../state/hooks/hooks'
import { nanoid } from "@reduxjs/toolkit"
import { openModal } from '../../state/slices/modalsSlice'

export interface NavigationItems {
  name: string;
  url: string;
  iconName?: IconName;
}

export interface DashboardNavigationProp {
  url: string;
  isSingleArticle: boolean;
}


export const navigationOpenIcon = (isActive: boolean) => {
  return (
    <svg className="openingSvg" viewBox="0 0 24 24" width="20" height="20px" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" stroke={isActive ? "#0284fe" : "#68728c"} strokeWidth={2} fill="transparent" />
      {isActive
        ? <g>
          <line x1="8" y1="8" x2="16" y2="16" stroke="#68728c" strokeWidth={2} />
          <line x1="8" y1="16" x2="16" y2="8" stroke="#0284fe" strokeWidth={2} />
        </g>
        : <polygon points="8,10 12,15 16,10" fill="#68728c" />
      }


    </svg>
  )
}

const navigationData: NavigationItems[] = [
  {
    name: 'Overview',
    url: '',
    iconName: 'bar_chart'
  },
  {
    name: 'Top News',
    url: '/top-news',
    iconName: 'grade'
  },
  {
    name: 'News Search',
    url: '/news-search',
    iconName: 'search'
  },
  {
    name: 'Social Alerts',
    url: '/social-alerts',
    iconName: 'notifications_active'
  },
]

export default function DashboardNavigation({ url, isSingleArticle }: DashboardNavigationProp) {
  const [activeTab, setActiveTab] = useState(0)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const themeContext = useContext(ThemeContext)

  const isInEditMode = useAppSelector(state => state.appState.isEditModeActive)
  const dispatch = useAppDispatch();

  function changeRouteOrStayInEditMode(
    e: React.MouseEvent<HTMLAnchorElement>,
    path: string,
    index: number
  ) {
    if (isInEditMode) {
      e.preventDefault()
      dispatch(openModal({ name: 'unsavedChanges', props: { fieldName: 'Edit Contact', pathTo: path } }))
    } else {
      setActiveTab(index)
      setIsMenuOpen(false)
    }
  }

  return (
    <DashboardNavigationContainer className="DashboardNavigationContainer">

      <ActiveTab onClick={() => setIsMenuOpen(isMenuOpen => !isMenuOpen)}>

        <MaterialIcon iconName={navigationData[activeTab].iconName as IconName} />

        <p>{navigationData[activeTab].name}</p>
        {navigationOpenIcon(isMenuOpen)}
      </ActiveTab>

      <NavigationWrapper>
        <span></span>
        <NavigationContainer isRound={true} isMenuOpen={isMenuOpen}>
          {navigationData.map((item, index) =>
            <SingleNavItem
              key={nanoid()}
              onClick={(e) => changeRouteOrStayInEditMode(e, `${url}${item.url}`, index)}
              exact
              to={`${url}${item.url}`}
              activeStyle={{
                color: themeContext.colors.primary
              }}
              className={item.name === 'Overview' && isSingleArticle ? 'active' : ''}
            >
              {item.iconName && <MaterialIcon iconName={item.iconName} />}
              {item.name}
            </SingleNavItem>)}
        </NavigationContainer>
      </NavigationWrapper>

    </DashboardNavigationContainer>
  )
}
