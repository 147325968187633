import Layout from '../global-components/GeneralLayout'
import TodaysCalendar from '../global-components/TodaysCalendar'
import { ReportsMainContainer, PageHeader } from '../reports/Reports.styled'
import TableTest from '../global-components/checkbox-table-components/CheckboxTable'
import { useGetInvoicesQuery } from '../../state/query/queryApi'

const Reports = () => {

  const { data, isLoading, isError, error } = useGetInvoicesQuery()


  return (
    <Layout title="Invoices">
      <ReportsMainContainer>
        <PageHeader>
          <h3>Invoices</h3>
          <TodaysCalendar />
        </PageHeader>

        <div>
          <TableTest
            tableType="invoice"
            tableColumns={['Date', 'Invoice ID', 'Billed To', 'Amount', 'Status']}
            tableData={data}
            isLoading={isLoading}
            isError={isError}
            error={error}
          />
        </div>
      </ReportsMainContainer>
    </Layout>

  );
}

export default Reports;
