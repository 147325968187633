import React, { useState, useLayoutEffect, useRef } from 'react'
import styled, { css } from 'styled-components'


const TooltipBody = styled.div<{
  overflowLeft: number | string,
  overflowRight: number | string,
  overflowTop: boolean,
  top: number,
  left: number,
  callerWidth: number,
  callerHeight: number
}>`
  position: fixed;  
    background-color: transparent;
    z-index: 9999;
    top: ${({ top }) => `${top}px`};
    left: ${({ left, callerWidth }) => `${left + callerWidth / 2}px`};
    transform: translate(-50%, calc(-100% - 10px));
    overflow: visible;

    display: flex;
    justify-content: center;
    align-items: center;

    ${({ overflowTop, top, callerHeight }) => overflowTop && css`
      top: ${top + callerHeight}px;
      transform: translate(-50%, 10px);
    `}

    ${({ overflowLeft, left, callerWidth }) => overflowLeft !== 'unset' && css`
      left: ${left + (typeof overflowLeft === 'number' ? overflowLeft + callerWidth / 2 : 0)}px;

    `}

    ${({ overflowRight, left, callerWidth }) => overflowRight !== 'unset' && css`
      left: ${left - (typeof overflowRight === 'number' ? overflowRight - callerWidth / 2 : 0)}px;
    `}
`

const Triangle = styled.span<{ isTop: boolean, callerTop: number, callerLeft: number, callerWidth: number, callerHeight: number }>`
  position: fixed;
  display: block;
  width: 10px;
  height: 10px;
  top: ${({ callerTop, isTop, callerHeight }) => isTop ? `${callerTop + callerHeight}px` : `${callerTop}px`};
  transform: ${({ isTop }) => isTop ? 'translateY(0)' : 'translateY(-100%)'};
  left: ${({ callerLeft, callerWidth }) => `${callerLeft + (callerWidth / 2) - 5}px`};
  z-index: 999;

  svg {
    display: block;
    transform: ${({ isTop }) => !isTop ? 'rotate(180deg)' : 'rotate(0)'};
  }

`

const TooltipRendBodyTest = ({ tooltipState, children }: { tooltipState: any, children: React.ReactNode; }) => {

  const tooltip = useRef<HTMLDivElement>(null)

  const [position, setPosition] = useState<{ left: number | string, right: number | string, top: boolean }>({
    left: 'unset',
    right: 'unset',
    top: false
  })

  useLayoutEffect(() => {
    const rect = tooltip.current && tooltip.current.getClientRects()[0]
    if (rect) {
      setPosition({
        left: rect.left < 0 ? Math.abs(rect.left) + 10 : 'unset',
        right: rect.right > window.innerWidth ? Math.abs(rect.right - window.innerWidth + 10) : 'unset',
        top: rect.top < 0 ? true : false
      })
    }

  }, [])

  return (
    <>
      <TooltipBody
        overflowLeft={position.left}
        overflowRight={position.right}
        overflowTop={position.top}
        top={tooltipState.props.callerTop}
        left={tooltipState.props.callerLeft}
        callerWidth={tooltipState.props.callerWidth}
        callerHeight={tooltipState.props.callerHeight}
        ref={tooltip}
      >
        {children}

      </TooltipBody >
      <Triangle
        isTop={position.top}
        callerTop={tooltipState.props.callerTop}
        callerLeft={tooltipState.props.callerLeft}
        callerWidth={tooltipState.props.callerWidth}
        callerHeight={tooltipState.props.callerHeight}
      >
        <svg height="10" width="10" >
          <path d="M5 0 L0 10 L10 10 Z" fill={'#27344E'} />
        </svg>
      </Triangle>
    </>
  )
}


export default React.memo(TooltipRendBodyTest)