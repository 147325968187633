import React from 'react'
import { LoaderAndMessageWrapper } from '../../dashboard/LatestNewsList.styled'
import { ReactComponent as NoFilesImage } from '../../../assets/icons/no-files.svg'


export default function NoItems({ type }: { type: 'report' | 'invoice' }) {
  return (
    <LoaderAndMessageWrapper type="no-results">
      <NoFilesImage />
      <h3>{`No ${type}s in the list`}</h3>
      <p>{type === 'report' ? 'Reports' : 'Invoices'} will show up here eventually</p>
    </LoaderAndMessageWrapper>
  )
}
