import styled, { css } from 'styled-components'
import { device } from '../../global-styles/mediaQueries'

export const CloseIconWrapper = styled.div<{ isActive?: boolean }>`
  position: absolute;
  top: 4px;
  right: 8px;
  opacity:${({ isActive }) => isActive ? 1 : 0};
  display: ${({ isActive }) => isActive ? 'block' : 'none'};
`

export const BackIconWrapper = styled.div<{ isActive?: boolean }>`
  position: absolute;
  top: 4px;
  left: 0px;
  opacity:${({ isActive }) => isActive ? 1 : 0};
  display: ${({ isActive }) => isActive ? 'block' : 'none'};
  cursor: pointer;
  @media ${device.tablet} {
    display: none;
  }
`

export const SmallSearchBox = styled.div<{ isActive?: boolean }>`
  position: relative;
  left: 0;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  width: 28px;
  height: 28px;
  padding: 5px;
  border-radius: 4px;
  box-sizing: border-box;
  border: solid 1px ${({ theme }) => theme.colors.borderSecondary};
  z-index: -1;
  svg {
    min-width: 18px;
  }
  ${({ isActive }) => isActive && css`
    right: 100%;
    width: calc(100% - 30px);
    height: 28px;
    padding: 4px 8px;
    justify-content: flex-start;
    border-radius: 4px;
    border: solid 2px ${({ theme }) => theme.colors.accent};
    background-color: ${({ theme }) => theme.colors.white};
    transition: width 0.3s;

  `}

  @media ${device.tablet} {
    width: 200px;
    padding: 5px 8px;
    background-color: ${({ theme }) => theme.colors.white};
    ${BackIconWrapper} {
      display: none;
    }
    .close-icon {
      opacity: 0;
    }
    &:hover {
      border: solid 1px ${({ theme }) => theme.colors.accent};
    }
    ${({ isActive }) => isActive && css`
      padding: 4px 7px;
      border: solid 2px ${({ theme }) => theme.colors.accent};
      .close-icon {
        opacity: 1;
      }
      &:hover {
        border: solid 2px ${({ theme }) => theme.colors.accent};
      }
    `}
  }
`

export const SearchTransparentInput = styled.input<{ isActive: boolean }>`
  width: 0px;
  border: none;
  background-color: transparent;
  padding-right: 28px;
  font-size: 13px;
  z-index: -1;
  ${({ isActive }) => isActive && css`
    width: 100%;
  `}
  &::placeholder {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: ${({ theme }) => theme.colors.inputPlaceholderGray};
  }
  &:focus-visible{
    outline: none;
  }
  @media ${device.tablet} {
    width: 100%;
  }
`


export const SearchWrapperBox = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: auto;
  cursor: pointer;
  z-index: 50;
  width: 100%;
`;