import React, { ReactElement } from 'react'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const SpinnerWrapper = styled.div`
position: relative;
margin: 0 auto;
padding: 40px 0;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
svg {
  animation: ${rotate} 1.2s infinite;
  animation-timing-function: linear;

}
`
type LoadSpinnerProp = 'normal' | 'large'

export default function LoadSpinner({ size }: { size: LoadSpinnerProp }): ReactElement {

  const spinnerSize = size === 'normal' ? 18 : 40
  return (
    <SpinnerWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" width={spinnerSize} height={spinnerSize} viewBox="0 0 18 18">
        <g fill="none" fillRule="evenodd">
          <g stroke="#0284FE" strokeWidth="2">
            <path d="M8 0C3.582 0 0 3.582 0 8s3.582 8 8 8 8-3.582 8-8" transform="translate(-1669 -7569) translate(1670 7570)" />
          </g>
        </g>
      </svg>

    </SpinnerWrapper>
  )
}
