import React, { useState } from 'react'
import MaterialIcon, { IconName } from './MaterialIcon'
import styled, { keyframes, ThemeContext, css } from 'styled-components'
import { addOpacityToHex } from '../../utils'

const TreningWrapper = styled.div<{ isActive: boolean, bgColor: string }>`
  position: relative;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  width: 42px;
  height: 28px;
  padding: 5px 12px;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 14px;
  cursor: pointer;
  transition: 0.3s;
  ${({ isActive }) => isActive && css`
    width: 102%;
  `}
  svg {
    min-width: 14px;
    min-height: 14px;
    flex-shrink: 0;
  }
`

const showText = keyframes`
  1% {
    width: 80%;
    opacity: 0;
  }
  50% {
    width: 100%;
    opacity: 0;
  }
  100% { 
    opacity: 1;
    width: 100%;
    margin-left: 6px;
  }
`

const TrendingSpan = styled.p<{ isActive?: boolean, color: string }>`
  position: relative;
  width: fit-content;
  flex-shrink: 0;
  font-size: 13px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38 !important;
  letter-spacing: normal;
  color: ${({ color }) => color} !important;
  width: 1px;
  opacity: 0;
  transition: 1s;
  ${({ isActive }) => isActive && css`
    animation: ${showText} 0.5s forwards; 
  `}
`

interface HeatIconProps {
  bgColor: string,
  iconColor: string,
  iconName: IconName,
  text: 'Weak' | 'Hot' | 'Very Hot'
}

export default function HeatTrendingComponent({ trending }: { trending: number }) {

  const [isActive, setIsActive] = useState(false)
  const themeContext = React.useContext(ThemeContext)

  const { colors } = themeContext

  function createHeatTrendingIcon(): HeatIconProps {
    if (trending === 0) {
      return {
        bgColor: addOpacityToHex(colors.accent, 0.1),
        iconColor: colors.accent,
        iconName: "weak",
        text: 'Weak'
      }
    } else if (trending > 3 && trending < 7) {
      return {
        bgColor: addOpacityToHex(colors.neutral, 0.1),
        iconColor: colors.neutral,
        iconName: "hot",
        text: "Hot"
      }
    } else {
      return {
        bgColor: addOpacityToHex(colors.redAlert, 0.1),
        iconColor: colors.redAlert,
        iconName: "very_hot",
        text: 'Very Hot'
      }
    }
  }

  return (
    <>
      <TreningWrapper
        isActive={isActive}
        bgColor={createHeatTrendingIcon().bgColor}
        onClick={() => setIsActive(isActive => !isActive)}
      >
        <MaterialIcon iconName={createHeatTrendingIcon().iconName} size={18} color={createHeatTrendingIcon().bgColor} />
        <TrendingSpan isActive={isActive} color={createHeatTrendingIcon().iconColor}>{createHeatTrendingIcon().text}</TrendingSpan>
      </TreningWrapper>
    </>
  )
}
