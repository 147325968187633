import React, { useState, useEffect, useRef, ReactElement } from 'react'

//Components
import ListPagination from '../global-components/ListPagination'
import LoadSpinner from '../global-components/LoadSpinner'
import NoSearchResult from './NoSearchResult';
import ConnectionLostComponent from '../global-components/ConnectionLostComponent'

import NewsSearchTable from './NewsSearchTable'
import SingleNewsSearchTableRow, { SingleNews } from './SingleNewsSearchTableRow'
import NewsSearchForm from './NewsSearchForm'
//Styles
import { NewsSearchWrapper, NewsSearchFormWrapper, NewsSearchSpinnerWrapper } from './CustomNewsSearchBar.styled'
import { ListFooter } from './LatestNewsTableWrapper.styled'

import { DateRange } from '../global-components/DateRangeSelect'
//Query 
import { GlobalNewsFetchParams } from '../../state/query/types/LatestNewsTypes'
import { fregnirApi } from '../../state/query/queryApi'
import { Error } from '../../state/query/types/globalQueryTypes'
import useStorageListener from '../../hooks/useStorageListener'
import { useAppDispatch } from '../../state/hooks/hooks'

import usePreviousStateValue from '../../hooks/usePreviousStateValue';


export type FilterOptions = 'newest' | 'oldest'

interface PaginationDataType {
  current_page: number
  last_page: number
  total: number
}

export default function CustomNewsSearchBar(): ReactElement {

  const dispatch = useAppDispatch()

  //I don't need to call query when component is ready, leazy query allows to triger it manualy 
  const [trigger, result] = fregnirApi.endpoints.getGlobalSearch.useLazyQuery()

  const tableRef = useRef<HTMLDivElement>(null);

  //Expiration will be added later
  const [paginationData, setPaginationData] = useState<PaginationDataType>()

  //Need for loader, if previus search is not equal as current show loader
  const [showLoader, setShowLoader] = useState(false)
  const [triggerPrevStateChange, setTriggerPrevStateChange] = useState(false);

  const [pageNum, setPageNum] = useState(1)
  const [filter, setFilter] = useState<FilterOptions>("newest")

  const [query, setQuery] = useState<{ search_keyword: string, search_range: DateRange }>({
    search_keyword: '',
    search_range: {
      startDate: null,
      endDate: null
    }
  })

  const prevState = usePreviousStateValue({ filter, query }, [triggerPrevStateChange])


  function handleSearchQueryChange(inputVal: string, date: DateRange): void {
    setQuery({
      search_keyword: inputVal,
      search_range: date,
    })
    setPageNum(1)
  }


  function handleFilterChange(value: FilterOptions): void {
    setFilter(value)
    setPageNum(1)
  }

  const createQueryParam = (queryParam: GlobalNewsFetchParams, filter: FilterOptions, pageNum: number) => {
    const queryParams = {
      filter: filter,
      search_keyword: queryParam.search_keyword,
      search_range: queryParam.search_range,
      page: pageNum
    }

    return queryParams
  }

  const submitSearch = (queryParam: GlobalNewsFetchParams, filter: FilterOptions, pageNum: number) => {
    trigger(createQueryParam(queryParam, filter, pageNum), true)
  }


  useEffect(() => {
    if (query.search_keyword.length > 2 && !result.isFetching && result.isSuccess) {
      setPaginationData(() => { return { current_page: result.data.current_page, last_page: result.data.last_page, total: result.data.total } })
    }
    //eslint-disable-next-line
  }, [result, result.isFetching, query.search_keyword, query.search_range])


  useEffect(() => {
    if (query.search_keyword.length > 2) {
      // show loader if query or filter has changed
      setShowLoader(JSON.stringify(query) !== JSON.stringify(prevState?.query) || prevState?.filter !== filter || result.isError)
      setTriggerPrevStateChange(prevState => !prevState)
      submitSearch(query, filter, pageNum)
    }
    //eslint-disable-next-line
  }, [query, pageNum, filter])


  useStorageListener('updated_article_action', (action) => {
    if (action === "STOP_ALERT") {
      dispatch(fregnirApi.util.invalidateTags(["GlobalSearch"]))
    }
  }, [query]);

  return (
    <>
      <NewsSearchWrapper ref={tableRef}>
        <NewsSearchFormWrapper>
          <NewsSearchForm
            handleSearchQueryChange={handleSearchQueryChange}
            isFetchingNewSearchData={result.isFetching}
          />
        </NewsSearchFormWrapper>
      </NewsSearchWrapper>

      {result.data?.data?.length === 0 && !result.isFetching && <NoSearchResult hasBgColor={false} />}

      {
        result.isError
          ? <ConnectionLostComponent error={(result.error as Error)?.status} isCard={true} />
          : result.isFetching && showLoader
            ?
            <NewsSearchTable filterBy={filter} handleSelectFilter={handleFilterChange} isFetching={result.isFetching}>
              <NewsSearchSpinnerWrapper>
                <LoadSpinner size="large" />
              </NewsSearchSpinnerWrapper>
            </NewsSearchTable>
            :
            result && result.data && prevState?.query.search_keyword === query.search_keyword && result.data?.data?.length !== 0 &&
            <>
              <NewsSearchTable filterBy={filter} handleSelectFilter={handleFilterChange} isFetching={result.isFetching}>
                {result.data.data?.map((item: SingleNews, index: number) =>
                  <SingleNewsSearchTableRow
                    key={index}
                    singleNews={item}
                    query={query}
                    searchPageNum={pageNum}
                    filter={filter}
                  />
                )}
              </NewsSearchTable>

              <ListFooter>
                <ListPagination
                  page={paginationData?.current_page || 0}
                  pageLength={20}
                  totalRecords={paginationData?.total}
                  ref={tableRef}
                  pageNum={paginationData?.current_page || 0}
                  changePageNum={(page: number): void => setPageNum(page)}
                />
              </ListFooter>
            </>
      }
    </>
  )
}
