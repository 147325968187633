import React, { useState } from 'react'

//Styled components
import {
  AlertTableHeader,
  AlertTableSubHeader,
  MobileContent,
  DesktopContent,
  FilterWrapper
} from './GetAlertsTable.styled'
import { SectionTitleBold } from '../global-components/TextAndLabelStyles.styled'

//Components
import CustomSelect from '../global-components/CustomSelectButton'
import SmallSearchInput from '../global-components/SmallSearchInput'

interface GetAlertProps {
  children: React.ReactNode;
  fitlerOptions: string[];
  selectedFilter: string;
  setSelectedFilter: React.Dispatch<React.SetStateAction<string>>
  handleSearchAction: (searchTerm: string) => void;
}

export default function GetAlertsTable({ children, fitlerOptions, selectedFilter, handleSearchAction, setSelectedFilter }: GetAlertProps) {

  const [isSearchActive, setIsSearchActive] = useState(false)

  return (
    <div>

      <AlertTableHeader isSearchActive={isSearchActive}>

        <SectionTitleBold isSearchActive={isSearchActive}>Coverage List</SectionTitleBold>
        <FilterWrapper isSearchActive={isSearchActive}>
          <CustomSelect
            type="select-button"
            options={fitlerOptions}
            value={selectedFilter}
            onSelect={setSelectedFilter}
            dropdownPosition="right"
            icon="filter_list"
          />
        </FilterWrapper>
        <SmallSearchInput handleSearchAction={handleSearchAction} isActive={isSearchActive} setIsActive={setIsSearchActive} />

      </AlertTableHeader>

      <AlertTableSubHeader>
        <MobileContent>
          Expires / Name
        </MobileContent>

        <DesktopContent>
          <p>Expires</p>
          <p>Name</p>
          <p className="hide-tablet">Daily Engagement</p>
          <p>Total Engagement</p>
        </DesktopContent>

      </AlertTableSubHeader>

      {children}
    </div>
  )
}

