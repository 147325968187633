import React from 'react'
import styled, { css } from 'styled-components'
import { device } from '../../global-styles/mediaQueries'
import { nanoid } from '@reduxjs/toolkit'
import GeneralTooltipCaller from '../global-components/tooltips/GeneralTooltipCaller'



const setStyles = (colNumber: number) => {
  switch (colNumber) {
    case 0:
      return css`
        display: block;
        @media ${device.tablet} {
          display: none;
        }
      `
    case 1:
      return css`
        display: none;
        @media ${device.tablet} {
          display: block;
          grid-column: 1/2;
        }
      `
    case 2:
      return css`
        display: none;
        @media ${device.tablet} {
          display: block;
          grid-column: 2/3;
        }
      `
    case 3:
      return css`
        display: none;
        @media ${device.desktopS} {
          display: block;
          grid-column: 3/4;
        }
      `
    case 4:
      return css`
        display: none;
        @media ${device.tabletL} {
          display: block;
          grid-column: 3/4;
        }
        @media ${device.desktopS} {
          grid-column: 4/5;
        }
      `
  }
}

const TableColumnWrapper = styled.div`
  height: 38px;
  padding: 10px 10px 10px 68px;
  background-color: ${({ theme }) => theme.colors.highlight};
  border-top: 1px solid ${({ theme }) => theme.colors.borderPrimary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderPrimary};

  @media ${device.tablet} {
    display: grid;
    padding-left: 90px;
    grid-template-columns: 145px 1fr;
  }
  @media ${device.tabletL} {
    display: grid;
    grid-template-columns: 182px 526px 1fr;
  }
  @media ${device.desktopS} {
    padding-left: 24px;
    grid-template-columns: 152px 375px 150px 160px;
    justify-content: space-between;
  }
  @media ${device.desktop} {
    padding-left: 24px;
    grid-template-columns: 186px 520px 180px 180px;
  }

`

const ColumnText = styled.span<{ col: number }>`
  font-size: 13px;
  line-height: 1.38;
  color: ${({ theme }) => theme.colors.primary};
  grid-row: 1/2;
  ${({ col }) => setStyles(col)}
  span {
    position: relative;
    display: inline-block;
  }
`

const TooltipWrapper = styled.div`
  position: absolute;
  top: -2px;
  right: -22px;
`

/**
 * LatestNewsTableColumns shows labels for the list of the latest news
 * 
 */
export default function LatestNewsTableColumns() {

  const columns = ['Published/News', 'Published', 'News', 'Engagement', 'Sentiment']

  return (
    <TableColumnWrapper>
      {columns.map((item, index) => {
        if (item === 'Sentiment') {
          return <ColumnText col={index} key={nanoid()}>
            <span>
              {item}
              <TooltipWrapper>
                <GeneralTooltipCaller name="basic" content="Select the overall sentiment in each article." />
              </TooltipWrapper>
            </span>
          </ColumnText>
        }
        return <ColumnText col={index} key={nanoid()}>{item}</ColumnText>
      })}
    </TableColumnWrapper>
  )
}
