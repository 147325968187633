import React, { ReactElement, useContext, KeyboardEvent, MouseEvent } from 'react'
import { ThemeContext } from 'styled-components'
//Components
import MaterialIcon from '../global-components/MaterialIcon';
//Styles
import { SingleSavedRowIcons, IconWrapper, SingleSavedRow, SingleSavedRowEdit } from './SavedSearchBox.styled'
//Slice
import { SingleSavedSearch } from '../../state/query/types/SavedSearchTypes'
import { openModal } from '../../state/slices/modalsSlice';
import { useAppDispatch } from '../../state/hooks/hooks'
//Handle date
import { convertDateToIcelandic } from '../../utils'
//Type
import { DateRange } from '../global-components/DateRangeSelect'

interface SingleSearchResultProp extends SingleSavedSearch {
  setSearchInputVal: React.Dispatch<React.SetStateAction<string>>
  setExpirationOption: React.Dispatch<React.SetStateAction<DateRange>>
  setValuesToQuery: (search_term: string, date: DateRange) => void
  setIsBoxVisible: () => void
  setSetDateRangeForSavedSearch: React.Dispatch<React.SetStateAction<string | undefined>>

}
export default function SingleSavedSearchResult({ id, date, search_name, search_term, setSearchInputVal, setExpirationOption, setValuesToQuery, setIsBoxVisible, setSetDateRangeForSavedSearch }: SingleSearchResultProp): ReactElement {

  const theme = useContext(ThemeContext)
  const { colors } = theme

  const dispatch = useAppDispatch()

  function openModalDialog(e: MouseEvent | KeyboardEvent) {
    e.stopPropagation()

    return dispatch({
      type: openModal.type,
      payload: {
        name: 'editSavedSearch',
        props: {
          id: id,
          search_name: search_name,
          date: date,
          search_term: search_term
        }
      }
    })
  }

  function openDeleteModal(e: MouseEvent | KeyboardEvent) {
    e.stopPropagation()

    return dispatch({
      type: openModal.type,
      payload: {
        name: 'deleteSavedSearch',
        props: {
          id: id, //This will be for now we will find out how we will handle this
          articleTitle: search_name,
          modalType: 'deleteSavedSearch',
          modalContent: "Once you delete this search, it will no longer be visible in the saved search box",
        }
      }
    })
  }

  const handleMouseClick = (e: MouseEvent) => {
    openModalDialog(e)
  }

  const handleOnKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      openModalDialog(e);
    }
  }

  const handleDeleteMouseClick = (e: MouseEvent) => {
    openDeleteModal(e)
  }

  const handleDeleteOnKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      openDeleteModal(e);
    }
  }

  function prepareExpirationName(): string | undefined {
    if (date.startDate !== null && date.endDate !== null) {
      return `${convertDateToIcelandic(date.startDate)} - ${convertDateToIcelandic(date.endDate)} `
    } else {
      return undefined
    }

  }

  function updateSearchStates() {

    setSearchInputVal(search_term)
    setExpirationOption(date)
    setSetDateRangeForSavedSearch(prepareExpirationName())
    setValuesToQuery(search_term, date)
    setIsBoxVisible()
  }

  return (
    <>
      <SingleSavedRow onClick={updateSearchStates} tabIndex={0}>
        <p>{search_name}</p>
        <SingleSavedRowEdit
          onClick={handleMouseClick}
          onKeyDown={handleOnKeyDown}
          tabIndex={0}
        >
          Edit
        </SingleSavedRowEdit>
        <IconWrapper>
          <SingleSavedRowIcons
            onClick={handleMouseClick}
            onKeyDown={handleOnKeyDown}
            tabIndex={0}
          >
            <MaterialIcon iconName="mode_edit" color={colors.secondary} />
          </SingleSavedRowIcons>
          <SingleSavedRowIcons
            onClick={handleDeleteMouseClick}
            onKeyDown={handleDeleteOnKeyDown}
            tabIndex={0}
          >
            <MaterialIcon iconName="delete" color={colors.secondary} />
          </SingleSavedRowIcons>
        </IconWrapper>


      </SingleSavedRow>
    </>
  )
}
