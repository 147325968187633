import styled, { css } from 'styled-components'


const handlePossition = (direction: string | undefined) => {
  switch (direction) {
    case 'top':
      return css`
        top: 0;
        left: 0;
        right: 0;
        transform: translateY(-100%);
    `
    case 'bottom':
      return css`
        bottom: 0;
        left: 0;
        right: 0;
        transform: translateY(100%);
      `
    case 'left':
      return css`
        top: 300px;
        left: 0;
        transform: translateX(-100%);
      `
    case 'right':
      return css`
      top: 300px;
      right: 0;
      transform: translateX(100%);
    `
  }
}

export const Renderer = styled.div<{ direction?: string }>`
    display: flex;
    justify-content: center;
    position: fixed;
    height: auto;
    width: auto;
    z-index: 101;

    ${({ direction }) => handlePossition(direction)}
`