import { createGlobalStyle, css } from 'styled-components';

// This is the reusable CSS for the button focus state(::before and :focus)
export const globalFocusStyles = css`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    display: none;
    border: 2px solid ${({ theme }) => theme.colors.accent};
    border-radius: 4px;
    z-index: 1;
  }

  &:focus-visible {
    outline: 4px solid transparent;

    &::before {
      display: block;
    }
  }
`;

export default createGlobalStyle`
  .scrollhost {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .scrollhost::-webkit-scrollbar {
    display: none;
  }
  * {
    box-sizing: border-box;
  }
  h1, h2 ,h3 ,h4 ,h5 ,h6 ,p ,a , span, button, input, input::placeholder, div {
    font-family: Arial, Helvetica, sans-serif;
  }
  .no-scroll {
    overflow: hidden;
  }

  // Just for Safari purposes (Safari adds its own icons for the input fields, and we want to hide them)
  input::-webkit-contacts-auto-fill-button,
  input::-webkit-credentials-auto-fill-button {
    position: absolute;
    right: 0;
    visibility: hidden;
    pointer-events: none;
  }
`;

