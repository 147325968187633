import React, { useState } from 'react'

// Components
import Spinner from '../global-components/ButtonSpinner'
import MaterialIcon from '../global-components/MaterialIcon';
import UserOrPasswordInput from './UserOrPasswordInput'

// Redux
import { useAppDispatch } from '../../state/hooks/hooks'
import { editUser } from '../../state/slices/userSlice'
import { fregnirApi } from '../../state/query/queryApi'
import { showPopup, forceClose } from '../../state/slices/popupSlice'

// Styles
import { InputWrapper, InputLabel } from '../modals/NewsCoverageModal.styled'
import {
  PageWrapper,
  LoginForm,
  FormHeader,
  Logo,
  WrongCredentials,
  HeaderText,
  LoginButton,
  ContactUsMessage,
  CopyRightLabel
} from './Login.styled'

// Utils
import { makeHash, fetchWithTimeout } from '../../utils'


interface ResponseData {
  token: string;
  user: {
    created_at: string;
    email: string;
    email_verified_at: string | null;
    group_id: number;
    id: number;
    image: string;
    name: string;
    updated_at: string;
  }
}

interface LoginResponse {
  token: string;
  user: {
    name: string;
    email: string;
    image: string;
    created_at: string;
    updated_at: string;
    email_verified_at: string;
    group_id: number;
    id: number;
  };
}

interface LoginCredentials {
  email: string;
  password: string;
}

interface RequestOptions {
  method: string;
  headers: Headers;
  body: string;
}

class UnauthorizedError extends Error { }
class ServerError extends Error { }


export default function Login() {

  const dispatch = useAppDispatch();

  const [userName, setUserName] = useState("")
  const [password, setPasword] = useState("")
  const [userNameError, setUserNameError] = useState("")
  const [paswordError, setPaswordError] = useState("")
  const [visible, setVisible] = useState(false)
  const [wrongCredentials, setWrongCredentials] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  /**
   * Handles changes to the username input field. It updates the username state
   * and clears any existing username error messages if the username is not an empty string.
   * @param {string} value - The current value of the username input field.
   * @returns {void}
   */
  const handleUserName = (value: string): void => {
    if (userName !== "") setUserNameError("")
    setUserName(value)
  }

  /**
   * Handles changes to the password input field. It updates the password state
   * and clears any existing password error messages if the password is not an empty string.
   * @param {string} value - The current value of the password input field.
   * @returns {void}
   */
  const handlePassword = (value: string) => {
    if (password !== "") setPaswordError("")
    setPasword(value)
  }

  /**
   * Handles the storage of session data in local storage. It stores the user's token,
   * name, email, and image after applying a hash function to the token.
   * @param {ResponseData} responseData - The data received from the login response.
   * @returns {void}
   */
  const handleLocalStorageData = (responseData: ResponseData): void => {
    localStorage.setItem("token", makeHash(responseData.token, process.env.REACT_APP_SECRET as string))
    localStorage.setItem("name", responseData.user.name)
    localStorage.setItem("email", responseData.user.email)
    localStorage.setItem("image", responseData.user.image)
  }

  /**
   * Handles a successful login event by updating the user's information
   * in the application's state and managing session storage data.
   * @param {LoginResponse} result - The login response object.
   * @returns {void}
   */
  const handleSuccessfulLogin = (result: LoginResponse): void => {
    // Updating the user's information in the application's state
    const user = {
      username: result.user.name,
      contactData: {
        company_name: "",
        ceo_name: "",
        email: result.user.email,
        phone: "",
        city: "",
        street_address: "",
      },
      account_type: "",
      image: result.user.image,
      created_at: result.user.created_at,
      updated_at: result.user.updated_at,
      email_verified_at: result.user.email_verified_at,
      group_id: result.user.group_id,
      id: result.user.id,
      auth_token: result.token,
    };

    dispatch(editUser(user));

    // Handling the session storage data
    if (process.env.REACT_APP_SECRET) {
      handleLocalStorageData(result);
    }
  }

  /**
   * Handles an error response from a fetch request.
   * Throws an error based on the HTTP status code of the response.
   * @param {Response} response - The response object from a fetch request.
   * @throws {UnauthorizedError} If the response status is 401.
   * @throws {ServerError} If the response status is 500 or greater.
   * @throws {Error} If the response status is unexpected.
   * @returns {Promise<void>}
   */
  const handleErrorResponse = (response: Response): Promise<void> => {
    if (response.status === 401) {
      throw new UnauthorizedError();
    } else if (response.status >= 500) {
      throw new ServerError();
    }
    throw new Error(`Unexpected status code: ${response.status}`);
  }

  /**
   * Handles login-related errors by displaying appropriate messages
   * to the user or logging the error message.
   * @param {Error} error - The error object.
   * @returns {void}
   */
  const handleLoginError = (error: Error): void => {
    if (error instanceof UnauthorizedError) {
      setWrongCredentials(true);
    } else if (error instanceof ServerError || error.message === 'network request timeout') {
      // Show server error popup
      dispatch(showPopup({
        event: 'error',
        type: 'additionalInfo',
        direction: 'top',
        props: {
          popupTitle: `Server not responding!`,
          popupText: 'We are having difficulty connecting to the server. Please try again later.',
          additionalComponentName: 'SimpleTextInfo'
        },
        dontHide: true
      }))
    } else if (error.message === 'Failed to fetch') {
      // Show network error popup
      dispatch(showPopup({
        event: 'error',
        type: 'additionalInfo',
        direction: 'top',
        props: {
          popupTitle: `You are offline!`,
          popupText: 'Your network is unavailable. Check your data or wifi connection.',
          additionalComponentName: 'SimpleTextInfo'
        },
        dontHide: true
      }))
    } else {
      console.error(error.message);
    }
  }

  /**
   * Handles the login process including sending the login request,
   * processing the response, and managing the application state.
   * @param {LoginCredentials} credentials - User's login credentials.
   * @throws {Error} If an error occurs during the login process.
   * @returns {Promise<void>}
   */
  const handleLogin = async (credentials: LoginCredentials): Promise<void> => {
    try {
      setIsLoading(true);

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "email": credentials.email,
        "password": credentials.password,
      });

      const requestOptions: RequestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
      };

      const response = await fetchWithTimeout(() => fetch("https://api.fregnir.is/api/login", requestOptions), 10000);

      if (!response.ok) {
        await handleErrorResponse(response);
        return;
      }

      const result = await response.json() as LoginResponse;

      if (result.token) {
        setIsLoading(false);
        dispatch(fregnirApi.util.invalidateTags(['Settings', 'SocialAlerts', 'Dashboard', 'Invoices', 'MostRead', 'Reports', 'SavedSearch', 'SocialMedia', 'gNews', 'gNewsSingle', 'GlobalSearch']));
        handleSuccessfulLogin(result);
      }
    } catch (error) {
      if (error instanceof Error) {
        setIsLoading(false);
        handleLoginError(error);
      }
    }
  }


  /**
   * Handles the form submission for login. It validates the username and password,
   * updates the error state if needed, and initiates the login process if
   * the username and password are provided. Also, it resets the wrong credentials
   * error state, force closes error popup and sets the loading state to true before attempting to log in.
   * @returns {void}
   */
  const handleSubmit = () => {
    if (wrongCredentials) setWrongCredentials(false)
    if (userName === "") setUserNameError("Please enter username")
    if (password === "") setPaswordError("Please enter password")
    if (!userName || !password) return
    setIsLoading(true)
    dispatch(forceClose())
    handleLogin({
      email: userName,
      password: password
    })
  }

  return (
    <PageWrapper>
      <LoginForm>
        <FormHeader>
          <Logo />
          <HeaderText>Log in to continue to Ratsjá</HeaderText>
        </FormHeader>

        {wrongCredentials && (
          <WrongCredentials>
            <MaterialIcon iconName="error_filled" color="#f86262" size={24} />
            <p>These credentials do not match our records</p>
          </WrongCredentials>
        )}

        <InputWrapper>
          <InputLabel>
            Username
          </InputLabel>

          <UserOrPasswordInput
            value={userName}
            placeholder="Type username here..."
            inputType="text"
            onChange={handleUserName}
            option="username"
            showError={userNameError !== ""}
            errorMessage={userNameError}
            name="usernamelogin"
            keyDownHandler={handleSubmit}
            autocomplete="username"
          />
        </InputWrapper>

        <InputWrapper>
          <InputLabel>
            Pasword
          </InputLabel>
          <UserOrPasswordInput
            value={password}
            placeholder="Type password here..."
            inputType={visible ? "text" : "password"}
            onChange={handlePassword}
            option="password"
            showError={paswordError !== ""}
            errorMessage={paswordError}
            visible={visible}
            toggleVisibility={() => setVisible(visible => !visible)}
            keyDownHandler={handleSubmit}
            autocomplete="current-password"

          />
        </InputWrapper>

        {/* "Remember me" checkbox can not be implemented without logic for setting cookes on the backed so we are skipping it for now.  */}
        {/* Forgot password link is commented out because backend logic is not done yet. For now we will reset forgoten passwords via email with ContactUsMessage link below. */}
        {/* <RememberForgotWrapper>
          <NavLink to="/register">Forgot password?</NavLink>
        </RememberForgotWrapper> */}

        <LoginButton onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? <Spinner size={18} /> : "Log In"}
        </LoginButton>


      </LoginForm>
      <ContactUsMessage>If you don’t have an account or if you forget the password get in touch with us at: <a href="mailto:ratsja@ratsja.is">ratsja@ratsja.is</a></ContactUsMessage>
      <CopyRightLabel>{(new Date()).getFullYear()} &copy; Ratsjá</CopyRightLabel>
    </PageWrapper>
  )
}
