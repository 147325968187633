import { configureStore } from '@reduxjs/toolkit'
import user from './slices/userSlice'
import settings from './slices/settingsSlice'
import appState from './slices/appState'
import latestNews from './slices/latestNewsSlice'
import popups from './slices/popupSlice'
import modals from './slices/modalsSlice'
import newsSearch from './slices/newsSearchSlice'
import dateRange from './slices/dateRangePickerSlice'
import generalRequestErrorHandler from './middlewares/generalRequestErrorHandler'
import tooltips from './slices/tooltipSlice'


import { fregnirApi } from './query/queryApi'

export const store = configureStore({
  reducer: {
    user,
    settings,
    appState,
    latestNews,
    dateRange,
    popups,
    modals,
    newsSearch,
    tooltips,
    [fregnirApi.reducerPath]: fregnirApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(fregnirApi.middleware)
      .concat(generalRequestErrorHandler)
})


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch