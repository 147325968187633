import React, { useContext } from 'react'
import MaterialIcon from './MaterialIcon'
import { convertDateToIcelandic } from '../../utils'
import styled, { ThemeContext } from 'styled-components'

const CalendarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
  svg {
    margin-right: 10px;
  }
`

const CalendarDate = styled.p`
 font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.secondary};
`

export default function TodaysCalendar() {

  const themeContext = useContext(ThemeContext)

  const today = new Date()
  return (
    <CalendarWrapper>
      <MaterialIcon iconName="calendar_today" size={18} color={themeContext.colors.secondary} />
      <CalendarDate>{convertDateToIcelandic(today, "DDMMYY")}</CalendarDate>
    </CalendarWrapper>
  )
}
