import React, { useState, useEffect, ReactElement } from 'react'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
//Components
import CustomInput from '../global-components/CustomInput'
import { CancelButton } from '../global-components/ModalButton';
import { SettingsConfirmButton } from '../settings/Settings.styled'
import MaterialIcon from '../global-components/MaterialIcon';
import Spinner from '../global-components/ButtonSpinner'

//Styles
import { SectionTitle, SettingsSectionWrapper } from './Settings.styled'
import { EditButton, AccountColumnWrapper } from './Account.styled'
import { InputWrapper } from '../modals//NewsCoverageModal.styled'
import { ButtonWrapper } from '../modals/DeleteArticle.styled'

//Redux
import { cleanEditMode } from '../../state/slices/appState'
import { useAppDispatch, useAppSelector } from '../../state/hooks/hooks'
import { useEditSettingsUserMutation } from '../../state/query/queryApi'
import { showPopup } from '../../state/slices/popupSlice'


//Utils
import { showErrorOrSuccessPopup } from '../../utils/showPopups'
import { validateEmail } from '../../utils'

//Array contains return value from useState hook where <S> === String
type setStateArray = [
  string,
  React.Dispatch<React.SetStateAction<string>>,
  string
]

type ContactData = {
  ceo_name: string
  city: string
  invoice_email: string
  name: string
  phone: string
  street: string
}

interface ContactInfoProp {
  isInputDisabled: boolean;
  setIsInputDisabled: React.Dispatch<React.SetStateAction<boolean>>
  contactData: ContactData
}
export default function ContactInfo({ isInputDisabled, setIsInputDisabled, contactData }: ContactInfoProp): ReactElement {

  const dispatch = useAppDispatch()

  const isInEditMode = useAppSelector(state => state.appState.isEditModeActive)

  const [saveUserData, { isLoading, isSuccess }] = useEditSettingsUserMutation()

  const { name, ceo_name, invoice_email, phone, city, street } = contactData;

  const globalUserDataArray = [name, ceo_name, invoice_email, phone, city, street];
  const [comapnyNameLocal, setComapnyNameLocal] = useState(name)
  const [ceoNameLocal, setCeoLocal] = useState(ceo_name)
  const [emailLocal, setEmailLocal] = useState(invoice_email)
  const [phoneLocal, setPhoneLocal] = useState(phone)
  const [cityLocal, setCityLocal] = useState(city)
  const [streetAddressLocal, setStreetAddressLocal] = useState(street)
  const [isSubmited, setIsSubmited] = useState(false)

  const [errorArray, setErrorArray] = useState(Array(globalUserDataArray.length).fill(undefined))

  //just for DRY purpose
  const inputFieldsDataActionArray: setStateArray[] = [
    [comapnyNameLocal, setComapnyNameLocal, 'name'],
    [ceoNameLocal, setCeoLocal, 'ceo'],
    [emailLocal, setEmailLocal, 'email'],
    [phoneLocal, setPhoneLocal, 'phone'],
    [cityLocal, setCityLocal, 'city'],
    [streetAddressLocal, setStreetAddressLocal, 'address']
  ];

  const sanitizeAllInputFields = (text: string, type: 'letters' | 'lettersAndNumber' | 'phone') => {
    const rgxText = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ÐðđĐÞþÆæ' ]+$/u
    const rgxTextNum = /^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ÐðđĐÞþÆæ# ]+$/u
    const rgxPhoneNum = /^\+?[0-9]{6,15}$/

    switch (type) {
      case 'letters':
        return rgxText.test(text.toLowerCase());
      case 'lettersAndNumber':
        return rgxTextNum.test(text.toLowerCase());
      case 'phone':
        return rgxPhoneNum.test(text);
      default:
        return null
    }
  }


  function checkingAllFieldsAreValid() {
    // eslint-disable-next-line 
    const errArr = inputFieldsDataActionArray.map((items) => {
      if (items[0] === '') {
        return {
          isValid: true,
          errMsg: 'This field must be filled'
        }
      }

      switch (items[2]) {
        case 'name':
        case 'ceo':
        case 'city':
          if (!sanitizeAllInputFields(items[0], 'letters')) {
            return {
              isValid: true,
              errMsg: 'You can use only letters'
            }
          }
          break;
        case 'email':
          if (!validateEmail(items[0])) {
            return {
              isValid: true,
              errMsg: 'Invalid email format'
            }
          }
          break;
        case 'phone':
          if (!sanitizeAllInputFields(items[0], 'phone')) {
            return {
              isValid: true,
              errMsg: 'You entered invalid phone number'
            }
          }
          break;
        case 'address':
          if (!sanitizeAllInputFields(items[0], 'lettersAndNumber')) {
            return {
              isValid: true,
              errMsg: 'You can use letters and number only'
            }
          }
          break;
        default:
          break;
      }
    })

    return errArr
  }

  const checkIsValueDifferent = (localStateItem: string, globalStateItem: string): boolean => localStateItem !== globalStateItem

  function onCancelHandler(): void {
    inputFieldsDataActionArray.forEach((item, index) =>
      checkIsValueDifferent(item[0], globalUserDataArray[index])
      && item[1](globalUserDataArray[index]))
    setErrorArray(Array(globalUserDataArray.length).fill(undefined))
  }

  function onSaveChanges() {

    const newErrorArray = checkingAllFieldsAreValid()
    setErrorArray(newErrorArray)

    let hasErrorInFields = false

    //Checking if all fields are valid on submit
    newErrorArray.forEach(item => {
      if (item !== undefined) {
        return hasErrorInFields = true
      }
    })

    if (!hasErrorInFields) {
      saveUserData({
        name: comapnyNameLocal,
        ceo_name: ceoNameLocal,
        invoice_email: emailLocal,
        phone: phoneLocal,
        city: cityLocal,
        street: streetAddressLocal
      }).unwrap()
        .then(() => {
          showErrorOrSuccessPopup(dispatch, 'Data saved successfuly', 'bottom', 'success', 'alert')
          setIsSubmited(true)
        })
        .catch((error) => {
          dispatch(showPopup({
            event: 'error',
            type: 'additionalInfo',
            direction: 'bottom',
            props: {
              popupTitle: (error as FetchBaseQueryError).status === 'FETCH_ERROR' ? 'You are offline!' : 'The server can’t be reached.',
              popupText: (error as FetchBaseQueryError).status === 'FETCH_ERROR' ? 'Your network is unavailable. Check your data or wifi connection.' : 'Please try again later.',
              additionalComponentName: 'SimpleTextInfo'
            }
          }))
        })
    }

    setIsSubmited(false)
  }

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setIsInputDisabled(true)
      dispatch(cleanEditMode('link'))
    }
    //eslint-disable-next-line
  }, [isSuccess])

  return (
    <SettingsSectionWrapper isInEditMode={!isInputDisabled}>
      <SectionTitle>Contact Information</SectionTitle>
      <EditButton
        isGrey={false}
        onClick={() => {
          setIsInputDisabled(false)
        }}
      >
        {isInputDisabled
          ? 'Edit'
          : <MaterialIcon iconName="mode_edit" />
        }
      </EditButton>
      <AccountColumnWrapper>
        {
          inputFieldsDataActionArray.map((item, index) => {

            return <InputWrapper key={index}>

              <CustomInput
                label={Object.entries(contactData)[index][0].split('_').join(' ')}
                inputVal={item[0]}
                setInputVal={item[1]}
                isGray={false}
                placeholder={item[0]}
                isDisabled={isInputDisabled}
                hasEditMode={true}
                hasCancelIcon={true}
                name={item[2]}
                isSubmited={isSubmited}
                isError={errorArray[index]?.isValid}
                customErrorMessage={errorArray[index]?.errMsg}
              />
            </InputWrapper>
          }
          )
        }
        {
          !isInputDisabled &&
          <ButtonWrapper>
            <CancelButton
              onClick={() => {
                dispatch(cleanEditMode('link'))
                setIsInputDisabled(true)
                onCancelHandler()
              }}
            >Cancel</CancelButton>

            <SettingsConfirmButton
              disabledPointerOnLoad={isLoading || !isInEditMode}
              isDisabled={!isInEditMode}
              width={"77px"}
              onClick={() => onSaveChanges()}
              bgColorActiveState="blue"
            >
              {isLoading
                ? <Spinner size={18} />
                : "Save"
              }
            </SettingsConfirmButton>
          </ButtonWrapper>
        }
      </AccountColumnWrapper>
    </SettingsSectionWrapper>
  )
}
