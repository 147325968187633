import React, { useState, useEffect, useContext } from 'react';
//Styled components
import { ThemeContext } from 'styled-components'
import { NewsCoverageModalWrapper, TitleWrapper, InputWrapper, InputLabel, FlexRowWrapper, TextWraper, TextLink, ButtonWrapper, CancelButton } from './NewsCoverageModal.styled'

//Components 
import CustomInput from '../global-components/CustomInput'
import CustomButton from '../global-components/CustomButton'
import ExpirationSelect from '../global-components/ExpirationDateSelect'
import SubTermInput from '../global-components/SubTermInput'
import MaterialIcon from '../global-components/MaterialIcon';


import { useAppDispatch } from '../../state/hooks/hooks'
import { showPopup } from '../../state/slices/popupSlice'
import { closeModal } from '../../state/slices/modalsSlice'

import { SpinnerWrapper } from './DownloadNewsButton';
import Spinner from '../global-components/ButtonSpinner'

import { useSetAlertLatestNewsMutation } from '../../state/query/queryApi'
import { LatestNewsFetchParams } from '../../state/query/types/LatestNewsTypes'
import { validateEmail, convertArrayToCsv, validURL } from '../../utils'


interface NewsCoverageProps {
  payload: {
    newsURL?: string;
    newsTitle: string;
    id?: number;
    newsKey: number;
    searchParams?: LatestNewsFetchParams
  }
}


const LatestNewsCoverage = ({ payload: { newsURL = '', newsTitle = '', newsKey, searchParams } }: NewsCoverageProps) => {
  const dispatch = useAppDispatch()

  const [name, setName] = useState(newsTitle)
  const [url, setUrl] = useState(newsURL)
  const [email, setEmail] = useState<string[]>([])
  const [expirationOption, setExpirationOption] = useState<string>("1 day")
  const [isError, setIsError] = useState<boolean>(false)

  const themeContext = useContext(ThemeContext)

  const checkAndSetUrl = (url: string) => validURL(url)

  const dispatchCloseModal = () => dispatch({ type: closeModal.type })

  const [setAlert, { isLoading }] = useSetAlertLatestNewsMutation()

  const checkEmail = (emails: string[]) => {
    let validEmail = true;
    if (emails.length > 0) {
      email.forEach((email) => {
        if (!validateEmail(email)) {
          validEmail = false
        }
      })
    }
    else {
      // Emails are required so array must have at least one email.
      validEmail = false
    }
    return validEmail
  }

  useEffect(() => {
    const userEmail = localStorage.getItem('email')
    userEmail && setEmail(prevState => [...prevState, userEmail])
  }, [])

  const handleSetAlert = () => {
    return setAlert({
      newsKey,
      params: searchParams,
      requestBody: {
        expires_at: expirationOption,
        alert_emails_csv: convertArrayToCsv(email),
        title: name
      }
    }).unwrap()
      .then((fullfiled) => {
        dispatch(showPopup({
          event: 'success',
          type: 'additionalInfo',
          direction: 'bottom',
          props: {
            popupTitle: 'Alerts for atricle are set',
            additionalComponentName: 'NewsCoverageInfo'
          }
        }))
        dispatchCloseModal()
      })
      .catch((rejected) => {
        dispatch(showPopup({
          event: 'error',
          type: 'alert',
          direction: 'bottom',
          props: {
            popupTitle: `${rejected.status !== 'FETCH_ERROR' ? rejected.data.Message : 'Check your network connection!'}`
          }
        }))
        dispatchCloseModal()
      })
  }

  function handleSubmit() {
    if (checkAndSetUrl(url) && name.length !== 0 && checkEmail(email)) {
      handleSetAlert()
    } else {
      setIsError(true)
    }
  }

  return (
    <NewsCoverageModalWrapper>
      <TitleWrapper>
        <MaterialIcon iconName="notifications_active" color={themeContext.colors.accent} size={24} />
        <h4>Set Up Alerts</h4>
      </TitleWrapper>
      <InputWrapper>
        <CustomInput
          label="URL"
          labelHtmlFor="URL"
          inputVal={url}
          setInputVal={setUrl}
          isGray={true}
          placeholder="Paste URL address here…"
          id="URL"
          iconName="link"
          hasIconBackground={true}
          hasIcon={true}
          iconColor={themeContext.colors.white}
          isDisabled={true}
          name="URL"
        />
      </InputWrapper>

      <FlexRowWrapper>
        <InputWrapper>
          <CustomInput inputVal={name}
            label="Coverage Name"
            labelHtmlFor="name"
            setInputVal={setName}
            isGray={true}
            hasCancelIcon={true}
            isError={isError && name.length === 0}
            customErrorMessage="You must enter the name"
            placeholder="Type name here…"
            id="name"
            name="name"
          />
        </InputWrapper>

        <InputWrapper>
          <InputLabel>
            Expiration
          </InputLabel>
          <ExpirationSelect handleSelect={setExpirationOption} />
        </InputWrapper>
      </FlexRowWrapper>
      <InputWrapper>
        <InputLabel htmlFor="email">
          Email for Alerts
        </InputLabel>
        <SubTermInput
          tags={email}
          setTags={setEmail}
          isError={isError && !checkEmail(email)}
          inputName="email"
          tagStyle="secondary"
          inputStyle='dark'
          placeholder={'Add email...'}
        />
      </InputWrapper>

      <TextWraper>
        You can add multiple emails. After typing an email, hit tab, spacebar or comma to add new one. To set default emails go to
        <TextLink to="/settings" onClick={dispatchCloseModal}> Default email settings</TextLink>
      </TextWraper>

      <ButtonWrapper>
        <CancelButton
          onClick={dispatchCloseModal}
        >
          Cancel
        </CancelButton>
        <CustomButton
          size="medium"
          bgColor={themeContext.colors.accent}
          onClick={handleSubmit}
        >
          Start Alerts
          {isLoading && <SpinnerWrapper><Spinner size={18} /></SpinnerWrapper>}
        </CustomButton>

      </ButtonWrapper>

    </NewsCoverageModalWrapper>
  );
}

export default LatestNewsCoverage;
