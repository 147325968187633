import { ReactElement, useState } from 'react'

//SVG Component
import { ReactComponent as PasswordProtect } from '../../assets/icons/password-protected.svg'

//Components 
import UserOrPasswordInput from '../login/UserOrPasswordInput'
import MaterialIcon from '../global-components/MaterialIcon';
import { CancelButton } from '../global-components/ModalButton';
import Spinner from '../global-components/ButtonSpinner'

//Redux
import { cleanValuesInEditMode } from '../../state/slices/appState';
import { fregnirApi, useEditPasswordMutation } from '../../state/query/queryApi';
import { showPopup } from '../../state/slices/popupSlice';
import { addToken } from '../../state/slices/userSlice';
import { makeHash } from '../../utils';
import { showErrorOrSuccessPopup } from '../../utils/showPopups';
import { AppDispatch } from '../../state/store';

//Styles
import { SectionTitle, SettingsSectionWrapper, SettingsConfirmButton, PasswordResetInputWrapper, PasswordResetButtonsWrapper } from './Settings.styled'
import { AccountGridWrapper, InfoWrapper, ResetPasswordBox, EditButton, AccountColumnWrapper, NewPasswordInfoLabel } from './Account.styled'
import { InputLabel } from '../modals//NewsCoverageModal.styled'



interface EditPasswordFieldsProp {
  onCancelHandler: () => void
  onSavePasswordHandler: (currPassword: string, newPassword: string, confirmedNewPassword: string) => void
  isLoadingOnSave: boolean
  newPasswordErrorText: string
  isNewPasswordIncorrect: boolean
  isCurrentPasswordIncorrect: boolean
  isConfirmNewPasswordIncorrect: boolean
  handleGlobalAndErrorStatesOnChange: (value: string, type: 'current' | 'new' | 'confirmNew') => void;
}

//EditPasswordFields will be rendered when user press on change/edit action
function EditPasswordFields({
  onCancelHandler,
  onSavePasswordHandler,
  isLoadingOnSave,
  newPasswordErrorText,
  isNewPasswordIncorrect,
  isCurrentPasswordIncorrect,
  isConfirmNewPasswordIncorrect,
  handleGlobalAndErrorStatesOnChange
}: EditPasswordFieldsProp): ReactElement {

  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmedNewPassword, setConfirmedNewPassword] = useState("")

  const [currPassVisible, setCurrPassVisible] = useState(false);
  const [newPassVisible, setNewPassVisible] = useState(false);
  const [confirmedNewPassVisible, setConfirmedNewPassVisible] = useState(false)

  return (
    <>
      <AccountColumnWrapper>
        <PasswordResetInputWrapper isFirstInput={true}>
          <InputLabel>
            Current Password
          </InputLabel>
          <UserOrPasswordInput
            value={currentPassword}
            inputType={currPassVisible ? "text" : "password"}
            onChange={(value) => {
              setCurrentPassword(value)
              handleGlobalAndErrorStatesOnChange(value, 'current')
            }}
            option="password"
            showError={isCurrentPasswordIncorrect}
            errorMessage={"Current password is incorrect"}
            visible={currPassVisible}
            name="CurrPassword"
            toggleVisibility={() => setCurrPassVisible(currPassVisible => !currPassVisible)}
            inEditableField={true}
          />
        </PasswordResetInputWrapper>
        <PasswordResetInputWrapper>
          <InputLabel>
            New Password
          </InputLabel>
          <UserOrPasswordInput
            value={newPassword}
            inputType={newPassVisible ? "text" : "password"}
            onChange={(value) => {
              setNewPassword(value)
              handleGlobalAndErrorStatesOnChange(value, 'new')
            }}
            option="password"
            showError={isNewPasswordIncorrect}
            errorMessage={newPasswordErrorText}
            visible={newPassVisible}
            name="NewPassword"
            toggleVisibility={() => setNewPassVisible(newPassVisible => !newPassVisible)}
            inEditableField={true}
          />
          <NewPasswordInfoLabel>Minimum 5 characters</NewPasswordInfoLabel>
        </PasswordResetInputWrapper>
        <PasswordResetInputWrapper>
          <InputLabel>
            Confirm New Password
          </InputLabel>
          <UserOrPasswordInput
            value={confirmedNewPassword}
            inputType={confirmedNewPassVisible ? "text" : "password"}
            onChange={(value) => {
              setConfirmedNewPassword(value)
              handleGlobalAndErrorStatesOnChange(value, 'confirmNew')
            }}
            option="password"
            showError={isConfirmNewPasswordIncorrect}
            errorMessage={"Passwords don't match"}
            visible={confirmedNewPassVisible}
            name="ConfirmNewPassword"
            toggleVisibility={() => setConfirmedNewPassVisible(newPassVisible => !newPassVisible)}
            inEditableField={true}
          />
        </PasswordResetInputWrapper>

        <PasswordResetButtonsWrapper>
          <CancelButton onClick={onCancelHandler}>Cancel</CancelButton>

          <SettingsConfirmButton
            disabledPointerOnLoad={isLoadingOnSave || (currentPassword === "" || newPassword === "" || confirmedNewPassword === "")}
            isDisabled={(currentPassword === "" || newPassword === "" || confirmedNewPassword === "") && !isLoadingOnSave}
            width={"161px"}
            onClick={() => {
              onSavePasswordHandler(currentPassword, newPassword, confirmedNewPassword)
            }}
          >
            {isLoadingOnSave
              ? <Spinner size={18} />
              : "Change Password"
            }
          </SettingsConfirmButton>
        </PasswordResetButtonsWrapper>

        <ResetPasswordBox>
          <p >Can’t remember your current password? </p>
          <a href="mailto:ratsja@ratsja.is"> Reset password via mail</a>
        </ResetPasswordBox>
      </AccountColumnWrapper>
    </>

  )
}


function PasswordMessageScreen() {
  return (
    <AccountGridWrapper>
      <PasswordProtect />
      <InfoWrapper hasDifferentPaddingRight={true}>
        <h5>Password is set</h5>
        <p>Always make a strong password that’s at least 5 characters long</p>
      </InfoWrapper>


    </AccountGridWrapper>
  )
}



export interface ResponseUserDataOnPasswordChange {
  data: {
    token: string
    user: {
      id: number;
      name: string;
      email: string;
      email_verified_at: null;
      group_id: number;
      created_at: string;
      updated_at: string;
      image: string;
    }
  }
  error: {
    data: {
      message: string
    }
  }
}


interface SetPasswordProps {
  setChangesInEditModeGlobalState: (startFieldValue: string, fieldValue: string, fieldName: string) => void;
  dispatch: AppDispatch;
}

export default function SetPassword({ setChangesInEditModeGlobalState, dispatch }: SetPasswordProps): ReactElement {

  const [editPassword, passwordResult] = useEditPasswordMutation()

  const [isSectionDisabled, setIsSectionDisabled] = useState<boolean>(true)
  const [isCurrentPasswordIncorrect, setIsCurrentPasswordIncorrect] = useState<boolean>(false)
  const [isNewPasswordIncorrect, setIsNewPasswordIncorrect] = useState<boolean>(false)
  const [newPasswordErrorText, setNewPasswordErrorText] = useState<string>('')
  const [isConfirmNewPasswordIncorrect, setIsConfirmNewPasswordIncorrect] = useState<boolean>(false)


  function onPasswordActiveMode() {
    setIsSectionDisabled(false)
  }


  function resetErrorSatesOnCancelAction() {
    isCurrentPasswordIncorrect && setIsCurrentPasswordIncorrect(false)
    isNewPasswordIncorrect && setIsNewPasswordIncorrect(false)
    isConfirmNewPasswordIncorrect && setIsConfirmNewPasswordIncorrect(false)
  }


  function onCancelHandler() {
    setIsSectionDisabled(true)
    dispatch(cleanValuesInEditMode(["CurrPassword", "NewPassword", "ConfirmNewPassword"]))
    resetErrorSatesOnCancelAction()
  }


  /**
   * Handles state updates on change in inputs for update password (new, current and confirmed new password).
   */
  function handleGlobalAndErrorStatesOnChange(value: string, type: 'current' | 'new' | 'confirmNew') {

    if (type === 'current') {
      setChangesInEditModeGlobalState('', value, "CurrPassword")
      isCurrentPasswordIncorrect && setIsCurrentPasswordIncorrect(false)
    }
    else if (type === "confirmNew") {
      setChangesInEditModeGlobalState('', value, "ConfirmNewPassword")
      isConfirmNewPasswordIncorrect && setIsConfirmNewPasswordIncorrect(false)
    }
    else {
      setChangesInEditModeGlobalState('', value, "NewPassword")
      isNewPasswordIncorrect && setIsNewPasswordIncorrect(false)
    }
  }


  /**
   * Handles update logic and errors for update password. 
   */
  async function onSavePasswordHandler(currPassword: string, newPassword: string, confirmedNewPassword: string) {

    if (newPassword.length < 5) {
      setIsNewPasswordIncorrect(true)
      setNewPasswordErrorText('Your new password must contain at least 5 characters')
    }
    else if (newPassword === currPassword) {
      setIsNewPasswordIncorrect(true)
      setNewPasswordErrorText('New password can’t be the same as current password')
    }
    else if (newPassword !== confirmedNewPassword) {
      setIsConfirmNewPasswordIncorrect(true)
    }
    else {

      const response = await editPassword({
        password: currPassword,
        new_password: newPassword
      })

      if (response.hasOwnProperty('error')) {

        // checking if error has a messega in reponse for incorrect password
        if ((response as ResponseUserDataOnPasswordChange).error?.data?.message) {
          setIsCurrentPasswordIncorrect(true)
        }
        else {
          dispatch(showPopup({
            event: 'error',
            type: 'additionalInfo',
            direction: 'bottom',
            props: {
              popupTitle: 'The server can’t be reached',
              popupText: 'Please try again later',
              additionalComponentName: 'SimpleTextInfo'
            }
          }))
        }

      }
      else {
        const token = (response as ResponseUserDataOnPasswordChange).data.token

        // reseting component states on succese and invalidating token
        if (process.env.REACT_APP_SECRET) {
          dispatch(fregnirApi.util.invalidateTags(['Settings', 'SocialAlerts', 'Dashboard', 'Invoices', 'MostRead', 'Reports', 'SavedSearch', 'SocialMedia', 'gNews', 'gNewsSingle', 'GlobalSearch']))
          dispatch(addToken({ auth_token: token }))
          localStorage.setItem("token", makeHash(token, process.env.REACT_APP_SECRET as string))

          setIsSectionDisabled(true)
          dispatch(cleanValuesInEditMode(["CurrPassword", "NewPassword", "ConfirmNewPassword"]))
          showErrorOrSuccessPopup(dispatch, 'Password saved', 'bottom', 'success', 'alert')
        }
      }
    }

  }


  return (
    <SettingsSectionWrapper
      isInEditMode={!isSectionDisabled}
      hasBorderRadius={true}
      hasBoxShadow={true}
    >
      <EditButton
        isGrey={false}
        onClick={onPasswordActiveMode}
      >
        {isSectionDisabled
          ? 'Change'
          : <MaterialIcon iconName="mode_edit" />
        }
      </EditButton>
      <SectionTitle>Password</SectionTitle>
      {
        isSectionDisabled
          ? <PasswordMessageScreen />
          : <EditPasswordFields
            onCancelHandler={onCancelHandler}
            onSavePasswordHandler={onSavePasswordHandler}
            isLoadingOnSave={passwordResult.isLoading}
            newPasswordErrorText={newPasswordErrorText}
            isNewPasswordIncorrect={isNewPasswordIncorrect}
            isCurrentPasswordIncorrect={isCurrentPasswordIncorrect}
            isConfirmNewPasswordIncorrect={isConfirmNewPasswordIncorrect}
            handleGlobalAndErrorStatesOnChange={handleGlobalAndErrorStatesOnChange}
          />
      }
    </SettingsSectionWrapper>
  )
}
