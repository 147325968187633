import styled, { keyframes } from 'styled-components'
import { device } from '../../global-styles/mediaQueries'
import { addOpacityToHex } from '../../utils';

import { globalFocusStyles } from '../../global-styles/globalStyles';

const fadeInFadeOut = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const SearchTermsSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 16px 40px;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0 5px 5px -4px rgba(49, 65, 97, 0.08);
  h4 {
    margin: 0;
    margin-bottom: 10px;
  }
  h5 {
    margin-bottom: 8px;
  }
  @media ${device.mobileL} {
    padding: 30px 24px 40px;
  }

  @media ${device.tablet} {
    padding: 34px;
  }

  @media ${device.tabletL} {
    flex-direction: row;
    justify-content: space-between;
  }
`
export const SearchTermsBoxes = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${device.tablet} {
    max-width: 540px;
  }
  @media ${device.tabletL} {
    max-width: 460px;
  }
  @media ${device.desktopS} {
    max-width: 540px;
  }
`

export const SearchTermsPharagraph = styled.p<{ hasMarginLeft?: boolean, isSmallFontSize?: boolean }>`
  margin-left: ${({ hasMarginLeft }) => hasMarginLeft ? '7px' : '0'};
  font-family: Arial;
  font-size: ${({ isSmallFontSize }) => isSmallFontSize ? '13px' : '14px'};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: ${({ isSmallFontSize }) => isSmallFontSize ? '1.54' : '1.71'};
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.secondary};

  @media ${device.tablet} {
    max-width: ${({ isSmallFontSize }) => isSmallFontSize ? '416px' : '440px'};
  }
`

export const SearchTermsBoxResults = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0 30px;
  padding: 20px 18px;
  background-color: ${({ theme }) => theme.colors.highlight};
  border-radius: 6px;

  @media ${device.mobileL} {
    margin: 30px 0 40px;
  }
  @media ${device.tablet} {
    margin: 30px 0 50px;
  }
`
export const SearchTermsBoxSingleResult = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.primary};

  &:last-child {
    margin-bottom: 0;
  }
`
export const SearchTermsQuoteTitle = styled.q<{ hasMarginLeft?: boolean, hasMarginBottom?: boolean }>`
  margin-left: ${({ hasMarginLeft }) => hasMarginLeft ? '8px' : '0'};
  margin-bottom: ${({ hasMarginBottom }) => hasMarginBottom ? '22px' : '0'};
  font-size: 14px;
  line-height: 1.29;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primary};
`

export const SubTermsBoxResults = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 40px;

  @media ${device.mobileL} {
    margin: 24px 0 40px;
  }
  @media ${device.tablet} {
    margin: 30px 0 50px;
  }
  @media ${device.tabletL} {
    margin: 30px 0 0;
  }
`
export const SubTermsBoxWrapper = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${device.tablet} {
    margin-bottom: 30px;
  }
`
export const SubTermsBoxSingleResult = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 14px;
  border-radius: 6px;
  border: solid 1px ${({ theme }) => theme.colors.borderSecondary};

  &:hover {
    box-shadow: 0 4px 16px -2px rgba(49, 65, 97, 0.15);
    transition: .4s ease;
  }

  @media ${device.mobileL} {
    padding: 16px;
  }
`

export const SubTermsSubTitle = styled.span`
  margin-bottom: 10px;
  font-size: 12px;  
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primary};
`
export const SubTermsSuccessMessage = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
  animation: ${fadeInFadeOut} 4s linear;

  p {
    margin-left: 7px;
    font-size: 14px;
    line-height: 1.29;
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const NeedHelpWrapper = styled.div`
  display: grid;
  grid-template-columns: 20px auto;

  @media ${device.tablet} {
    max-width: 540px;
  }
  @media ${device.tabletL} {
    max-width: 294px;
  }
  @media ${device.desktopS} {
    max-width: 244px;
  }
  @media ${device.desktop} {
    max-width: 294px;
    margin-right: 46px;
  }
`

export const NeedHelpContentBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 14px;
`

export const SearchTermsButtonLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin-top: 24px;
  padding: 7px 18px;
  background-color: ${({ theme }) => addOpacityToHex(theme.colors.accent, 0.1)};
  border-radius: 4px;
  text-decoration: none;

  // Custom focus styles
  ${globalFocusStyles}
  
  &:hover,
  &:active {
    transition: all .4s ease;
    p {
      color: ${({ theme }) => theme.colors.white};
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.accent};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.accentDark};
  }
`
export const NeedHelpButtonText = styled.p`
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.accent};
`
export const CancelSaveComponent = styled.div<{ isOpen: boolean }>`
  max-height: ${({ isOpen }) => isOpen ? '200px' : '0'};
  margin-top: ${({ isOpen }) => isOpen ? '24px' : ''};
  margin-bottom: ${({ isOpen }) => isOpen ? '2px' : ''};
  opacity: ${({ isOpen }) => isOpen ? '1' : '0'};
  /* overflow: hidden; */
  transition: all .4s ease-in-out;

  @media ${device.tablet} {
    margin-top: ${({ isOpen }) => isOpen ? '30px' : ''};
    margin-bottom: ${({ isOpen }) => isOpen ? '8px' : ''};
  }
`