import styled, { css } from 'styled-components'
import { centerFlex } from '../../global-styles/mixins'
import { InputStyle, TagStyle } from './SubTermInput'
import { ConfirmButton } from '../global-components/ModalButton'


const setTagBg = (tagStyle: string | undefined, theme: any) => {
  switch (tagStyle) {
    case 'primary':
      return theme.colors.accent
    case 'secondary':
      return theme.colors.white
    case 'secondary-dark':
      return theme.colors.highlight
    case 'error':
      return theme.colors.redAlert
    default:
      return theme.colors.accent
  }
}

const setTagColor = (tagStyle: string | undefined, theme: any) => {
  switch (tagStyle) {
    case 'primary':
      return theme.colors.white
    case 'secondary':
      return theme.colors.primary
    case 'secondary-dark':
      return theme.colors.primary
    case 'error':
      return theme.colors.white
    default:
      return theme.colors.white
  }
}

export const SubtermWrapper = styled.div < { inputStyle: InputStyle, isDisabled?: boolean, isError?: boolean, tagStyle?: TagStyle } > `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  min-height: 46px;
  height: 100%;
  padding: 6px 16px;
  border-radius: 4px;
  background-color: ${props => props.inputStyle === 'dark' ? props.theme.colors.highlight : props.theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
  transition: 0.3s;
  box-sizing: border-box;
  font-family: 'Arial';
  transition: 0.3s;
  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    border-radius: 4px;
    border: solid 1px ${({ theme }) => theme.colors.borderSecondary};
    box-sizing: border-box;
    transition: 0.15s;
  }
  &:hover {
    &:before {
      border: solid 1px #0284fe;
    }
  }
  &:focus-within {
    background-color: white;
    > div{
      background-color: ${(props) => props.tagStyle === 'secondary' && props.theme.colors.highlight};
    }
    &:before {
      border: solid 2px #0284fe;
    }
  }
  &:focus {
    outline: none;
  }
  ${({ isError, theme }) => isError && css`
      border: solid 2px ${theme.colors.redAlert};
      &:before {
        content: none;
      }
      &:hover {
        border: solid 2px ${theme.colors.redAlert};
      }
      &:focus-within {
        border: solid 2px ${theme.colors.redAlert};
      }
  `}
  ${({ isDisabled }) => isDisabled && css`
    pointer-events: none;
    background-color: rgba(246, 248, 251, 0.8);
  `}
`
export const CancelIconWrapper = styled.div`
  margin-left: 6px;
  ${centerFlex};
  cursor: pointer;
`

export const SingleTag = styled.div<{ tagStyle: TagStyle, isDisabled?: boolean, type?: string }>`
  position: relative;
  ${centerFlex}
  width: fit-content;
  height: 26px;
  margin: 4px 8px 4px 0;
  padding: 1px 6px 0px 10px;
  box-sizing: border-box;
  border-radius: 13px;
  font-size: 12px;
  font-weight: ${props => props.type === 'email' ? 'bold' : 'normal'};
  background-color: ${(props) => setTagBg(props.tagStyle, props.theme)};
  color: ${(props) => setTagColor(props.tagStyle, props.theme)};
  border: 1px solid ${({ theme }) => theme.colors.tagBorder};

  ${({ isDisabled }) => isDisabled && css`
    pointer-events: none;
    background-color: ${({ theme }) => `${theme.colors.secondary}20`};
    color: ${({ theme }) => theme.colors.secondary};
  `}
`
export const SubtermsHoverComponent = styled.div`
  position: absolute;
  top: -20px;
  left: 10px;
  display: none;
  height: 26px;
  width: max-content;
  padding: 1px 11px 0 10px;
  color: black;
  background-color: ${({ theme }) => theme.colors.highlight};
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.tagBorder};
  z-index: 0;

  ${SingleTag}:hover & {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
`

// Error input is created because single tag has to change BG color on specific case ( when type is dark & tagStyle is secondary) but error bg color can't be changed 
export const ErrorTag = styled(SingleTag)`
  background-color: ${(props) => props.theme.colors.redAlert} !important;
  border: 1px solid ${({ theme }) => theme.colors.redAlert};
`

export const TransparentTextInput = styled.input`
  border: none;
  background-color: transparent;
  width: 150px;
  height: 26px;
  font-size: 14px;
  outline: none;
  box-shadow: 0 0 0px 1000px transparent inset;
  ::placeholder {
    color: ${({ theme: { colors } }) => colors.inputPlaceholderGray};
  }

  &:focus {
    border-radius: 4px;
  }
`

export const SubtermsConfirmButton = styled(ConfirmButton) <{ disabledPointerOnLoad: boolean }>`
  pointer-events: ${({ disabledPointerOnLoad }) => disabledPointerOnLoad ? 'none' : 'auto'};
  width: 77px;
  min-width: fit-content;
`
