import React, { useEffect, useRef } from 'react'
import { Wrapper, HiddenDivForInitialFocus } from './ModalWrapper.styled'

interface ModalRendererProps {
  children: React.ReactElement;
}

const ModalWrapper = ({ children }: ModalRendererProps): JSX.Element => {

  const hiddenDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const htmlElementStyle = document.getElementsByTagName('html')[0].style;
    // on mount disable scroll
    if (htmlElementStyle.overflow !== 'hidden') htmlElementStyle.overflow = 'hidden';

    return () => {
      // on unmount enable scroll
      if (htmlElementStyle.overflow === 'hidden') htmlElementStyle.overflow = 'auto';
    }
  })

  useEffect(() => {
    if (hiddenDivRef.current) {
      hiddenDivRef.current.focus();
    }
  }, []);


  return <Wrapper>
    <HiddenDivForInitialFocus ref={hiddenDivRef} tabIndex={0} />
    {children}
  </Wrapper>
}

export default ModalWrapper;