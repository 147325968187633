import React, { ReactElement } from 'react'

//Styles
import {
  SingleSocialMediaCard, ItemRank, ItemContent, HeatWrapper, DropDownWrapper, IconWrapper, IconImageWrapper
} from './SocialMediaTab.styled'
import { GraySmallText } from '../global-components/TextAndLabelStyles.styled'

//Components
import CustomDropdownList from '../global-components/CustomDropdownList'
import HeatTrendingComponent from '../global-components/HeatTrendingComponent'
import Rank from '../global-components/Rank'
import NotificationBar from '../global-components/NotificationBar'

//Redux 
import { MostReadInterace } from '../../types/TopNewsTypes'
import { useAppDispatch } from '../../state/hooks/hooks'
import { openModal } from '../../state/slices/modalsSlice'

//Utils
import { handleOnErrorImage } from '../../utils'

export default function SingleMostReadItem({ post }: { post: MostReadInterace }): ReactElement {

  const dispatch = useAppDispatch()

  const isNotificationSet = post.alert_id !== null

  function openModalForSocialAlerts(id: number, newsTitle: string, newsUrl: string, newsType?: string): void {
    dispatch({
      type: openModal.type,
      payload: {
        name: 'newsCoverage',
        props: {
          id: id,
          newsTitle: newsTitle,
          newsURL: newsUrl,
          newsType: newsType,
          cacheType: 'MostRead',
          post: post
        }
      }
    })
  }

  // Stopping alerts
  const handleStopAlerts = () => {
    dispatch({
      type: openModal.type,
      payload: {
        name: 'stopAlertsModal',
        props: {
          id: post.alert_id,
          cacheType: 'MostRead',
          isTopNewsQuery: true,
          post: post,
          labelTitle: post.title
        }
      }
    })
  }

  return (
    <SingleSocialMediaCard key={post.id}>

      {isNotificationSet && <NotificationBar />}

      <ItemRank>
        {post.rank_number}
        <Rank isRising={parseInt(post.is_rising)} />
      </ItemRank>

      <IconWrapper>
        <IconImageWrapper>
          <img src={post.icon_name} alt="News Logo" onError={handleOnErrorImage} />
        </IconImageWrapper>
        
        <div className="hide-tablet" style={{ marginLeft: '14px' }}>
          <HeatTrendingComponent trending={post.hot_number} />
        </div>

      </IconWrapper>

      <ItemContent>
        <a href={post.source_url} target="_blank" rel="noreferrer"><h5>{post.title}</h5></a>
        <GraySmallText>{post.message.substr(0, 100)}</GraySmallText>
      </ItemContent>

      <HeatWrapper>
        <HeatTrendingComponent trending={post.hot_number} />
      </HeatWrapper>
      {/* Droprown for setting alert to Social Alerts */}
      <DropDownWrapper>
        <CustomDropdownList
          options={[{
            text: isNotificationSet ? "Stop Alerts" : "Get Alerts",
            icon: "notifications",
            onClick: function () {
              !isNotificationSet
                ? openModalForSocialAlerts(parseInt(post.current_news_key), post.title, post.source_url, 'existing')
                : handleStopAlerts()
            }
          }]}
          dropdownPosition="right"
          icon="more_vert"
          lastItemSeparator={false}
        />
      </DropDownWrapper>

    </SingleSocialMediaCard>
  )
}
