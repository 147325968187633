import styled from 'styled-components'
import { device } from '../../global-styles/mediaQueries'

export const StyledTagsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 32px;

  @media ${device.tablet} {
    max-width: 400px;
  }

  @media ${device.desktopS} {
    margin-bottom: 0;
  }

  @media ${device.desktop} {
    max-width: 440px;
  }
`

export const DefaultTag = styled.span`
  position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 26px;
    margin: 4px 8px 4px 0;
    padding: 1px 10px 0px;
    box-sizing: border-box;
    border-radius: 13px;
    font-size: 12px;
    font-weight: normal;
    background-color: ${({ theme }) => theme.colors.highlight};
    color: ${({ theme }) => theme.colors.default_600};
    border: 1px solid ${({ theme }) => theme.colors.tagBorder};
`