import styled from 'styled-components'
import { device } from '../../global-styles/mediaQueries'


export const ReportsMainContainer = styled.div`
  padding: 76px 16px 16px;
  min-height: 100vh;

@media ${device.mobileL} {
    padding: 80px 24px 20px;
  }
  @media ${device.tablet} {
    padding: 80px 24px 30px;
  }
  @media ${device.desktopS} {
    margin: 0 auto;
    max-width: 1100px;
    padding: 80px 20px 30px;
  }
  @media ${device.desktop} {
    padding: 80px 0 40px;
  }
  @media ${device.desktopL} {
    padding: 80px 0 60px;
  }
`

export const PageHeader = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-bottom: 16px;
  h3 {
    width: 50%;
    margin: 0;
  }
  @media ${device.mobileL} {
    margin-bottom: 20px;
  }
  @media ${device.desktopS} {
    margin: 0 auto 20px;
    max-width: 1100px;
  }
`