import React, { KeyboardEvent } from 'react'
import styled from 'styled-components'

import { globalFocusStyles } from '../../global-styles/globalStyles'

const HiddenRadioButton = styled.input.attrs({ type: 'radio' }) <{ isEnabled: boolean }>`
  border: 0;
  height: 20px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 20px;
  opacity: 0;
  z-index: 1;
  cursor: ${({ isEnabled }) => !isEnabled ? 'default' : 'pointer'};
`

const RadioButtonContainer = styled.div<{ isEnabled: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ isEnabled }) => !isEnabled ? 'auto' : 'pointer'};
  border-radius: 50%;

  // Custom focus styles
  ${globalFocusStyles}

  // Override globalFocusStyles
  &::before {
    border-radius: 50%;
  }
`

const StyledRadioButton = styled.div<{ isChecked: boolean, isEnabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.highlight_800};
  transition: border 0.15s;
  border: ${({ theme, isChecked, isEnabled }) => `1px solid ${(isChecked && isEnabled) ? theme.colors.accent : theme.colors.borderSecondary}`};
  border-radius: 50px;
`

const StyledRadioButtonCheck = styled.div<{ isChecked: boolean, isEnabled?: boolean }>`
  width: 12px;
  height: 12px;
  opacity: ${({ isChecked }) => isChecked ? '1' : '0'};
  border-radius: 50px;
  transition: opacity .15s, background-color 0.1s linear;
  background-color: ${({ theme, isEnabled }) => !isEnabled ? theme.colors.default_300 : theme.colors.accent};
`

const RadioButtonLabel = styled.label<{ isEnabled: boolean }>`
  margin-left: 10px;
  color: ${({ theme, isEnabled }) => isEnabled ? theme.colors.primary : theme.colors.secondary};
  cursor: ${({ isEnabled }) => !isEnabled ? 'auto' : 'pointer'};
`

interface CustomRadioButtonPorps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void;
  isChecked: boolean;
  className?: string;
  labelText?: string;
  isEnabled?: boolean;
  name: string;
}

export default function CustomRadioButton({ onChange, onKeyDown, isChecked, className, labelText, isEnabled = true, name }: CustomRadioButtonPorps) {

  return (
    <div>
      <label>
        <RadioButtonContainer tabIndex={0} isEnabled={isEnabled} onKeyDown={(e) => isEnabled && onKeyDown && onKeyDown(e)}>
          <HiddenRadioButton
            name={name}
            value={labelText}
            checked={isChecked}
            id={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => isEnabled && onChange(e)}
            isEnabled={isEnabled}
          />
          <StyledRadioButton
            className={className}
            isChecked={isChecked}
            isEnabled={isEnabled}
          >
            <StyledRadioButtonCheck isChecked={isChecked} isEnabled={isEnabled} />
          </StyledRadioButton>
          {
            labelText && <RadioButtonLabel isEnabled={isEnabled}>{labelText}</RadioButtonLabel>
          }
        </RadioButtonContainer>
      </label>
    </div>
  )
}
