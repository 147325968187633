import React from 'react'

//Components
import CustomSelect from '../global-components/CustomSelectButton'

//Styles
import { ListHeader } from './LatestNewsTableWrapper.styled'
import { NewsSearchTableColumns, ColumnText, ColumnTextMobile, NewsSearchListWrapper, NewsSearchTableTitle } from './NewsSearchTable.style'

import { FilterOptions } from './CustomNewsSearchBar'

interface NewsSearchTableProps {
  children: React.ReactNode;
  filterBy: FilterOptions;
  handleSelectFilter: (value: FilterOptions) => void;
  isFetching: boolean;
}

export default function NewsSearchTable({ children, filterBy, handleSelectFilter, isFetching }: NewsSearchTableProps) {

  return (
    <NewsSearchListWrapper >
      <ListHeader>
        <NewsSearchTableTitle>Results</NewsSearchTableTitle>
        <div className="action-bars">
          <CustomSelect
            type="select-button"
            options={["newest", "oldest"]}
            value={filterBy}
            onSelect={(value) => handleSelectFilter(value as FilterOptions)}
            dropdownPosition="right"
            icon="filter_list"
            isDisabled={isFetching}
          />
        </div>
      </ListHeader>
      <NewsSearchTableColumns>
        <ColumnTextMobile>Published / News</ColumnTextMobile>
        <ColumnText>Published</ColumnText>
        <ColumnText>News</ColumnText>
      </NewsSearchTableColumns>
      {children}

    </NewsSearchListWrapper>
  )
}
