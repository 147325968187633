import React from 'react';
import styled from 'styled-components';

export const Message = styled.p`
  margin-top: 10px;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.secondary};
`

const SimpleTextInfo = ({ text }: { text: string }) => {

  return <Message>{text}</Message>
}

export default SimpleTextInfo