import styled from 'styled-components'
import { device } from '../../global-styles/mediaQueries'
import { SelectDropDownHeader } from '../global-components/CustomDropdown.styled'

export const TextWrapper = styled.div`
  width: 280px;
  margin-bottom: 30px;
  @media ${device.tablet} {
    width: 160px;
    margin-right: 36px;
    margin-bottom: 0;
  }
  @media ${device.tabletL} {
    width: 200px;
    margin-right: 56px;
  }
  @media ${device.desktopS} {
    width: 180px;
    margin-right: 76px;
  }
  @media ${device.desktop} {
    width: 200px;
    margin-right: 96px;
  }
`
export const NotificationsWrapper = styled.div<{ isEditMode: boolean }>`
  position: relative;
  padding: 16px 16px 40px 16px;
  background-color: ${({ theme, isEditMode }) => isEditMode ? theme.colors.highlight_500 : theme.colors.white};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0 5px 5px -4px rgba(49, 65, 97, 0.08);
  @media ${device.mobileL} {
    padding: 24px 24px 40px 24px;
  }
  @media ${device.tablet} {
    padding: 50px 130px 50px 34px;
  }
  @media ${device.tabletL} {
    padding: 50px 174px 50px 34px;
  }
  @media ${device.desktopS} {
    padding: 50px 160px 50px 34px;
  }
  @media ${device.desktop} {
    padding: 50px 258px 50px 34px;
  }
`

export const TextAndButtonsWrapper = styled.div`
  h5 {
    font-size: 14px;
  }
  @media ${device.tablet} {
    display: flex;
  }
`

export const Subtitle = styled.p`
  margin-top: 8px;
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.secondary};
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 30px;
`

export const EditButton = styled.div<{ isEditMode?: boolean }>`
  position: absolute;
  top: 16px;
  right: 16px;
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;
  svg {
    fill: ${({ theme }) => theme.colors.secondary};
  }

  &:focus-visible {
    outline: ${({ theme, isEditMode }) => isEditMode ? 'none' : `2px solid ${theme.colors.accent}`};
    padding: ${({ isEditMode }) => isEditMode ? '0' : '2px'};
    border-radius: ${({ isEditMode }) => isEditMode ? 'none' : '4px'};
  }
  &:active {
    color: ${({ theme }) => theme.colors.accentDark};
    outline: none;
  }
  @media ${device.mobileL} {
    top: 24px;
    right: 24px;
  }
  @media ${device.tablet} {
    top: 50px;
    right: 34px;
  }
`

export const SelectionButtons = styled.div<{ disablePointerEvents: boolean }>`
  padding: 26px 0px 0px;
  pointer-events: ${({ disablePointerEvents }) => disablePointerEvents ? 'none' : 'auto'};
`


export const SelectionsDropDownWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  &:last-of-type {
    margin-bottom: 0px;
  }
`

export const DropDownWrapper = styled.div<{ enableClick: boolean, enableColor: boolean }>`
  margin-left: 3px;
  pointer-events: ${({ enableClick }) => enableClick ? 'all' : 'none'};
  ${SelectDropDownHeader} {
    color: ${({ theme, enableColor }) => enableColor ? theme.colors.primary : theme.colors.secondary};
    :after {
      background-color: ${({ theme, enableColor }) => enableColor ? theme.colors.primary : theme.colors.secondary};
    }
  }
`

export const DropDownContainer = styled.div<{ enablePointer: boolean }>`
  position: relative;
  display: inline-block;
  height: 20px;
  margin-top: -5px;
  cursor: ${({ enablePointer }) => enablePointer ? 'pointer' : 'default'};
`

export const SelectionsLabel = styled.label<{ isEditMode: boolean }>`
  display: inline;
  color: ${({ theme, isEditMode }) => isEditMode ? theme.colors.primary : theme.colors.secondary};
  cursor: ${({ isEditMode }) => isEditMode ? 'pointer' : 'default'};
`

export const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 18px;
  &:last-of-type {
    margin-bottom: 0px;
  }
`

export const SelectionsBoxText = styled.div`
  margin-top: 2px;
  margin-left: 10px;
`