import React, { useState, useEffect, useRef, useCallback } from 'react'

// Components
import ListPagination from '../global-components/ListPagination'
import CustomSelect from '../global-components/CustomSelectButton'
import LatestNewsDownload from './LatestNewsDownload'
import LatestNewsList from './LatestNewsList'
import AdvancedSearchDropdown from '../global-components/AdvancedSearchDropdown'
import LatestNewsSearchInfo from './LatestNewsSearchInfo'

// Redux
import { useAppDispatch, useAppSelector } from '../../state/hooks/hooks'
import { useGetGnewsSearchDataQuery } from '../../state/query/queryApi'
import { fregnirApi } from '../../state/query/queryApi'
import { setLastPublishedNewsKey } from '../../state/slices/latestNewsSlice'
import { Error } from '../../state/query/types/globalQueryTypes'

// Hooks
import usePreviousStateValue from '../../hooks/usePreviousStateValue'
import useStorageListener from '../../hooks/useStorageListener'
import useHistoryState from '../../hooks/useHistoryState'

// Styles
import { List, ListHeader, ListFooter, TableTitle } from './LatestNewsTableWrapper.styled'

//Utils
import { isCacheRecordExisting } from '../../utils'


export interface DateRange {
  startDate: string | null;
  endDate: string | null;
}

interface TableWrapperProps {
  title: string;
  resetFilters: boolean;
  setResetFilters: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactElement | React.ReactElement[];
}


/**
 * LatestNewsTableWrapper is a wrapper containing all child components that we use for the latest news(LatestNewsList, LatestNewsSearchInfo, LatestNewsDownload, AdvancedSearchDropdown, CustomSelect, ListPagination)
 * 
 */
const LatestNewsTableWrapper = ({ title, resetFilters, setResetFilters, children }: TableWrapperProps) => {

  const dispatch = useAppDispatch()

  const allCachedData = useAppSelector(state => state.fregnirApi.queries);

  const [pageNum, setPageNum] = useHistoryState("page", 1)
  const [filterBy, setFilterBy] = useHistoryState<'newest' | 'oldest' | 'engagement'>("filter", "newest")
  const [searchKeywords, setSearchKeywords] = useHistoryState<string>("search_keyword", "");
  const [dateRange, setDateRange] = useHistoryState<DateRange>("search_range", { startDate: null, endDate: null })
  const [dateRangeOptionName, setDateRangeOptionName] = useHistoryState("date_range_option_name", '');

  const [showListLodader, setShowListLoader] = useState(false)
  const [triggerPrevStateChange, setTriggerPrevStateChange] = useState(false)
  const [noInternetConnection, setNoInternetConnection] = useState(false)
  const [noConnectionRefetchTrigger, setNoConnectionRefetchTrigger] = useState(false)
  const [latestErrorMessage, setLatestErrorMessage] = useState('')

  const prevState = usePreviousStateValue({ filterBy, searchKeywords, dateRange, dateRangeOptionName }, [triggerPrevStateChange])

  const tableRef = useRef<HTMLDivElement>(null);

  const isDefaultFilters =
    filterBy === 'newest' &&
    dateRange.startDate === null &&
    dateRange.endDate === null &&
    searchKeywords === '' &&
    pageNum === 1;


  // Skip fetching data if there is no cache and filters are not set to default value.
  const skipDataFetch = !isCacheRecordExisting(allCachedData, "getGnewsSearchData") && !isDefaultFilters

  const { data: queryData, isFetching: queryIsFetching, error } = useGetGnewsSearchDataQuery({
    filter: filterBy,
    search_keyword: searchKeywords,
    search_range: {
      from: dateRange.startDate,
      to: dateRange.endDate
    },
    page: pageNum
  }, { skip: noInternetConnection || skipDataFetch })


  const resetSearchFilters = () => {
    setFilterBy('newest')
    setSearchKeywords('')
    setDateRangeOptionName("")
    setDateRange({ startDate: null, endDate: null })
    setResetFilters(false)
    setPageNum(1)
  }

  const handleSelectFilter = (value: string): void => {
    setPageNum(1)
    setFilterBy(value as 'newest' | 'oldest' | 'engagement')
  }

  const handleSearchParams = (seachString: string, dateRange: DateRange, optionName: string): void => {
    setPageNum(1)
    setSearchKeywords(seachString)
    setDateRange(dateRange)
    setDateRangeOptionName(optionName)
  }

  const handleSearchFilterReset = () => {
    setPageNum(1)
    setSearchKeywords("")
    setDateRangeOptionName("")
    setDateRange({ startDate: null, endDate: null })
  }

  const handleDateRangeOption = (option: DateRange, optionName?: string): void => {
    setPageNum(1)
    setDateRange(option)
    if (optionName) setDateRangeOptionName(optionName)
  }

  const memoizedRefetchAfterConnectionLost = useCallback(() => setNoConnectionRefetchTrigger(true), []);

  const clearSearchTerms = () => setSearchKeywords("")

  const searchMemoParams = React.useMemo(() => {
    return {
      filter: filterBy,
      search_keyword: searchKeywords,
      search_range: {
        from: dateRange.startDate,
        to: dateRange.endDate
      },
      page: pageNum
    }
  }, [filterBy, searchKeywords, dateRange.startDate, dateRange.endDate, pageNum])

  useEffect(() => {

    if (error && 'status' in error) {
      dispatch(fregnirApi.util.invalidateTags(["gNews"]))
      setNoInternetConnection(true)
      setLatestErrorMessage((error as Error).status as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])


  useEffect(() => {
    if (noConnectionRefetchTrigger) {
      setNoInternetConnection(false)
      setShowListLoader(true)
      dispatch(fregnirApi.util.invalidateTags(["gNews"]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noConnectionRefetchTrigger])


  useEffect(() => {
    if (pageNum === 1 && queryIsFetching) {
      // compare states from previous and current render and trigger loader if there are differences
      if (
        prevState?.filterBy !== filterBy ||
        prevState.searchKeywords !== searchKeywords ||
        prevState.dateRange.startDate !== dateRange.startDate ||
        prevState.dateRange.endDate !== dateRange.endDate || noConnectionRefetchTrigger
      ) {
        setShowListLoader(true)
        setTriggerPrevStateChange(prevState => !prevState)
      }
    } else {
      if (!noInternetConnection) {
        setShowListLoader(false)
        setNoConnectionRefetchTrigger(false)
      }
    }
    // if reset filter iz triggered from NewArticlesInfo component set filters to initial value and invalidate cache 
    if (resetFilters) {
      resetSearchFilters()
      setShowListLoader(true)
      dispatch(fregnirApi.util.invalidateTags(["gNews"]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData, queryIsFetching, pageNum, searchKeywords, dateRange.startDate, dateRange.endDate, dateRangeOptionName, filterBy, resetFilters, noConnectionRefetchTrigger])

  useEffect(() => {
    // add most recent news id to global state so we could check for new articles from NewArticlesInfo component
    if (queryData && (queryData.data?.length > 0) && isDefaultFilters) {
      dispatch(setLastPublishedNewsKey(queryData?.data[0].id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData, searchKeywords, dateRange.startDate, dateRange.endDate, filterBy, pageNum])


  useEffect(() => {
    // if data fetch is skiped, reset filters and fetch again
    if (skipDataFetch) {
      resetSearchFilters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useStorageListener('updated_article_action', (action) => {
    if (action) {
      dispatch(fregnirApi.util.invalidateTags(["gNews"]))
    }
  });

  return (
    <>
      <List ref={tableRef}>
        <ListHeader>
          <TableTitle>{title}</TableTitle>
          <div className="action-bars">
            <CustomSelect
              type="select-button"
              options={["newest", "oldest", "engagement"]}
              value={filterBy}
              onSelect={handleSelectFilter}
              dropdownPosition="right"
              icon="filter_list"
              location='latest-news'
            />
            <AdvancedSearchDropdown
              handleSearchParams={handleSearchParams}
              prevValue={searchKeywords}
              optionName={dateRangeOptionName}
              dateRangeOption={dateRange}
            />
            <LatestNewsDownload />
          </div>
        </ListHeader>
        <LatestNewsSearchInfo
          searchTerms={searchKeywords}
          optionName={dateRangeOptionName}
          handleDateRangeOption={handleDateRangeOption}
          handleSearchFilterReset={handleSearchFilterReset}
          clearSearchTerms={clearSearchTerms}
        />
        <div>
          {children}
          <LatestNewsList
            data={queryData?.data}
            isFetching={showListLodader || queryData === undefined}
            noInternetConnection={noInternetConnection}
            searchParams={searchMemoParams}
            refetchAfterConnectionLost={memoizedRefetchAfterConnectionLost}
            error={latestErrorMessage}
          />

        </div>
        <ListFooter>
          <ListPagination
            page={queryData?.current_page || 0}
            pageLength={10}
            totalRecords={queryData?.total}
            ref={tableRef}
            pageNum={pageNum}
            changePageNum={(page: number): void => setPageNum(page)}
          />
        </ListFooter>
      </List>
    </>
  )
}


export default LatestNewsTableWrapper;

