import React, { ReactElement } from 'react'
//Styles
import { SectionTitle, SettingsSectionWrapper } from './Settings.styled'
import { AccountColumnWrapper } from './Account.styled'

export default function AccountDeactivation(): ReactElement {
  return (
    <SettingsSectionWrapper hasBorderRadius={true} hasBoxShadow={true}>
      <SectionTitle>
        Account Deactivation
      </SectionTitle>
      <AccountColumnWrapper>
        <p className="grey-text">
          To deactivate your account, please contact us at:
        </p>
        <a className="mail-link" href="mailto:ratsja@ratsja.is"> ratsja@ratsja.is</a>
      </AccountColumnWrapper>
    </SettingsSectionWrapper>
  )
}
