import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { nanoid } from '@reduxjs/toolkit'


type event = 'inform' | 'success' | 'warning' | 'error';
type popupType = 'alert' | 'info' | 'additionalInfo';
type direction = 'top' | 'bottom' | 'left' | 'right';

export interface PopupTypes {
  event: event;
  type: popupType;
  direction: direction;
  props: AditionalInfoProps;
}

interface ExtendedPopupTypes extends PopupTypes {
  show: boolean;
  popupID: string;
  dontHide: boolean;
  forceClose: boolean;
}

interface AditionalInfoProps {
  popupTitle: string;
  popupText?: string;
  additionalComponentName?: string;
}

const initialState: ExtendedPopupTypes = {
  event: 'inform',
  type: 'alert',
  direction: 'bottom',
  props: {
    popupTitle: '',
    additionalComponentName: ''
  },
  show: false,
  popupID: '',
  dontHide: false,
  forceClose: false
}


const popupSlice = createSlice({
  name: 'popups',
  initialState,
  reducers: {
    showPopup: (state, action: PayloadAction<{ event: event, type: popupType, direction: direction, props: AditionalInfoProps, dontHide?: boolean }>) => {
      state.event = action.payload.event;
      state.type = action.payload.type;
      state.direction = action.payload.direction;
      state.props = action.payload.props;
      state.popupID = nanoid();
      state.show = true;
      state.forceClose = false;
      if (action.payload.dontHide) {
        state.dontHide = action.payload.dontHide
      } else {
        if (state.dontHide) state.dontHide = false
      }
    },
    resetPopupState: () => ({ ...initialState }),
    forceClose: (state) => {
      state.forceClose = true
    }
  }
})

export const {
  showPopup,
  resetPopupState,
  forceClose
} = popupSlice.actions


export default popupSlice.reducer