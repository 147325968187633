import React, { useContext } from 'react';
import { useAppDispatch } from '../../state/hooks/hooks'
import { closeModal } from '../../state/slices/modalsSlice'
import { showPopup } from '../../state/slices/popupSlice'
//Components
import ModalButton from '../global-components/ModalButton'
import MaterialIcon from '../global-components/MaterialIcon'
import { ThemeContext } from 'styled-components'
import { ModalBox, TitleWrapper, NewsTitleExcerpt, DisclaimerText, ButtonWrapper } from './DeleteArticle.styled'
import { useDeleteNewsMutation } from '../../state/query/queryApi'
import { LatestNewsFetchParams } from '../../state/query/types/LatestNewsTypes'
import { prepareTitleExcerpt } from '../../utils';

interface DeleteArticleProps {
  articleTitle: string;
  id: number;
  newsKey: number;
  modalType: string;
  modalContent: string;
  searchParams: LatestNewsFetchParams
}

/**
 * DeleteArticleModal component
 */
const DeleteArticleModal = ({ payload }: { payload: DeleteArticleProps }): JSX.Element => {

  const dispatch = useAppDispatch();
  const { colors } = useContext(ThemeContext)
  const [deleteLatestNews, { isLoading }] = useDeleteNewsMutation()
  const handleCloseModal = () => dispatch({ type: closeModal.type })

  const handleDeleteLatestNewsArticle = () => deleteLatestNews({
    newsKey: payload.newsKey,
    params: payload.searchParams
  }).unwrap()
    .then(() => {
      handleCloseModal()
      dispatch(showPopup({
        event: 'success',
        type: 'alert',
        direction: 'bottom',
        props: {
          popupTitle: 'Article has been deleted'
        }
      }))
    })
    .catch((rejected) => {
      handleCloseModal()
      dispatch(showPopup({
        event: 'error',
        type: 'alert',
        direction: 'bottom',
        props: {
          popupTitle: `${rejected.status !== 'FETCH_ERROR' ? rejected.data.Message : 'Check your network connection!'}`
        }
      }))
    })

  return (
    <ModalBox>
      <TitleWrapper>
        <MaterialIcon iconName="warning_filled" color={colors.redAlert} size={24} />
        <h3>Delete this article?</h3>
      </TitleWrapper>
      <NewsTitleExcerpt>{prepareTitleExcerpt(payload.articleTitle)}</NewsTitleExcerpt>
      <DisclaimerText>Once you delete this article, it will no longer be visible in the lates news feed</DisclaimerText>
      <ButtonWrapper>
        <ModalButton type="cancel" onClick={handleCloseModal}>Cancel</ModalButton>
        <ModalButton type="confirm" color={colors.redAlert} isLoading={isLoading} onClick={handleDeleteLatestNewsArticle}>Delete</ModalButton>
      </ButtonWrapper>
    </ModalBox>
  );
}

export default DeleteArticleModal;
