import React, { useState, useEffect } from 'react';
import EventPopupIcon from './EventPopupIcon'
import { PopupBox, Header, CloseButton } from './EventPopup.styled'
import AdditionalInfo from './EventPopupAdditional'
import { useAppDispatch } from '../../../state/hooks/hooks'
import { resetPopupState } from '../../../state/slices/popupSlice'


interface EventPopupProps {
  eventType: 'inform' | 'success' | 'warning' | 'error';
  popupType: 'alert' | 'info' | 'additionalInfo';
  props: {
    popupTitle: string;
    popupText?: string;
    additionalComponentName?: string;
  },
  direction: "top" | "bottom" | "left" | "right";
  popupID: string;
  dontHide: boolean;
  forceClose: boolean;
}


const EventPopup = ({ eventType, popupType, props, direction, popupID, dontHide, forceClose }: EventPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [shouldShow, setShouldShow] = useState(false)


  const prepareTitle = (title: string): string => {
    return title
  }

  /**
   * Handle close
   */
  const handleClose = () => {
    setShouldShow(false)
    setTimeout(() => dispatch({ type: resetPopupState.type }), 1000)
  }


  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    // if shown and another popup is triggered,
    // start closing animation by setiing shouldShow to false
    // and tring opening animation after closing animation is finished 
    if (shouldShow) setShouldShow(false)
    setTimeout(() => setShouldShow(true), 250)
    return () => clearTimeout(timeout)
    // eslint-disable-next-line
  }, [popupID])

  useEffect(() => {
    let timeout!: ReturnType<typeof setTimeout>;
    // close after x number of seconds
    if (shouldShow && !dontHide) {
      timeout = setTimeout(handleClose, 7000)
      return () => clearTimeout(timeout)
    }
    // eslint-disable-next-line
  }, [shouldShow])

  useEffect(() => {
    forceClose && handleClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceClose])

  return (
    <PopupBox eventType={eventType} shouldShow={shouldShow} direction={direction}>
      <Header popupType={popupType}>
        <EventPopupIcon type={eventType} />
        <p>{prepareTitle(props.popupTitle)}</p>
        <CloseButton onClick={handleClose}></CloseButton>
      </Header>
      {popupType === 'additionalInfo' && props.additionalComponentName && <AdditionalInfo popupText={props.popupText} component={props.additionalComponentName} onClose={handleClose} />}
    </PopupBox>
  );
}

export default EventPopup;
