import React from 'react';
import { Link } from 'react-router-dom'


/**
 *
 * AS POPUPS CAN CONTAIN ADDITIONAL INFO WITH LINKS THAT POINTS THE
 * TO EXTERNAL URL OR REACT ROUTER LINKS AND CAN HAVE SIDEFFECT, WE CAN'T JUST PASS DATA ON DISPATCHING
 * WE NEED TO CREATE COMPONENTS THAT CAN HANDLE SUCH SITUATIONS, THIS IS EXAMPLE OF SUCH COMPONENT
 *
 * Dispatch action example:
 *
 * {
 *  type: showPopup.type,
 *  payload: {
 *    event: 'inform',
 *    type: additionalInfo,
 *    direction: 'bottom',
 *    props: {
 *      popupTitle: 'Article has been deleted',
 *      component: 'ExampleOne'
 *    }
 * }
 *
 */
const ExampleOne = ({ sideEffect }: { sideEffect: () => void }) => {

  return (
    <>
      <p>This is additional info example with <Link to="/settings" onClick={sideEffect}>settings</Link> link.</p>
    </>
  );

}
  
export default ExampleOne;
