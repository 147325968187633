import React from 'react'
import { useAppSelector } from '../../../state/hooks/hooks'
import TooltipBody from './TooltipBody'
import BasicTooltip from './BasicTooltip'
import SocialMediaRankTooltip from './SocialMediaRankTooltip'


export default function TooltipRenderer() {

  const tooltipState = useAppSelector(state => state.tooltips)

  if (tooltipState.open) {
    return (
      <TooltipBody tooltipState={tooltipState}>
        {((name) => {
          switch (name) {
            case 'basic':
              return <BasicTooltip content={tooltipState.props.content} isInNoDataChart={tooltipState.props.isInNoDataChart} />
            case 'socMediaRank':
              return <SocialMediaRankTooltip />
          }
        })(tooltipState.name)}
      </TooltipBody>
    )
  }
  return null

}
