// Components
import SearchTermsBox from './SearchTermsBox'
import SubTermsBox from './SubTermsBox'
import NeedHelp from './NeedHelp'

import { SearchTermsSectionWrapper, SearchTermsBoxes, SearchTermsPharagraph, SubTermsBoxResults, SubTermsBoxWrapper } from './SearchTerms.styled'

export type SearchTermsProps = {
  negative: null;
  term_id: number;
  term: string;
  subterms: string[];
}


export interface SearchTermsDataProp {
  onboard_terms: SearchTermsProps[]
}

const SearchTerms = ({ termsData }: { termsData: SearchTermsDataProp | undefined }) => {

  return (
    <>
      {
        termsData && <SearchTermsSectionWrapper>
          <SearchTermsBoxes>
            <SearchTermsBox onboardTerms={termsData.onboard_terms} />
            <h5>Sub-Terms</h5>
            <SearchTermsPharagraph hasMarginLeft={false} isSmallFontSize={false}>Add or remove sub-terms to improve search for specific query. Start typing sub-terms in the field and hit tab, comma, or return to complete.</SearchTermsPharagraph>
            <SubTermsBoxResults>
              {
                termsData.onboard_terms && termsData.onboard_terms.map((item: SearchTermsProps) => {
                  return (
                    <SubTermsBoxWrapper key={item.term_id}>
                      <SubTermsBox
                        term={item.term}
                        id={item.term_id}
                        subterms={item.subterms}
                      />
                    </SubTermsBoxWrapper>
                  )
                })
              }
            </SubTermsBoxResults>
          </SearchTermsBoxes>
          <NeedHelp />
        </SearchTermsSectionWrapper>
      }
    </>
  );
}

export default SearchTerms;