import React from 'react';
import Layout from '../global-components/GeneralLayout'
import {
  Route,
  Switch,
  useRouteMatch,
  Redirect
} from "react-router-dom";

import Overview from './Overview'
import NewsSearch from './NewsSearch'
import TopNews from './TopNews'
import SocialAlerts from './SocialAlerts'
import SingleArticle from '../single-article/SingleArticle';

const Dashboard = () => {

  const { path } = useRouteMatch();

  return (
    <>
      <Layout>
        <Switch>
          <Route path={`${path}`} component={Overview} exact />
          <Route path={`${path}/top-news`} component={TopNews} exact />
          <Route path={`${path}/news-search`} component={NewsSearch} exact />
          <Route path={`${path}/social-alerts`} component={SocialAlerts} exact />
          <Route path={`${path}/news/:current_news_key`} component={SingleArticle} exact />
          <Redirect to={`${path}`} />
        </Switch>
      </Layout>
    </>
  );
}

export default Dashboard;
