import React, { useState, useEffect, useRef } from 'react'
import { ThemeContext } from "styled-components"

//Styled Components
import {
  NewsDistributionTopBar,
  NewsDistributionSection,
  NewsDistributionFooter,
  NewsDistributionSideLabels,
  ChartLegend,
  NewsDistributionSideLabelsWrapp
} from './NewsDistribution.styled'
import { SectionTitleBold } from '../global-components/TextAndLabelStyles.styled'

//Components
import NewsDistributionChart from './NewsDistributionChart'
import CustomSelect from '../global-components/CustomSelectButton'
import { NewsDistributionData } from '../../state/query/types/OverviewTypes'
import LoadSpinner from '../global-components/LoadSpinner'

import { icelandicDays, icelandicDaysShort, shortMonthNames, monthNames } from '../../utils'

export interface DistributionData {
  count: number,
  social: number,
  date: Date,
  timeframe: 'day' | 'week' | 'month'
}

interface ChartDataWithDate {
  day: DistributionData[],
  week: DistributionData[],
  month: DistributionData[],
}


interface DistributionChartItem {
  name: string;
  engagement: number;
  articles: number;
  labelName?: string;
}


export default function NewsDistribution({ data, isLoading }: { data: NewsDistributionData[] | undefined, isLoading: boolean }) {

  const theme = React.useContext(ThemeContext)

  //Filter options for graph
  const [selected, setSelected] = useState<string>('Last year')

  const [weekData, setWeekData] = useState<DistributionChartItem[]>([])
  const [monthData, setMonthData] = useState<DistributionChartItem[]>([])
  const [yearData, setYearData] = useState<DistributionChartItem[]>([])
  const [finalChartData, setFinalChartData] = useState<DistributionChartItem[]>([])

  //Sorted data by timerange (day, week, month ) 
  const periodData = useRef<ChartDataWithDate>()

  const handleSelect = (value: string): void => {
    setSelected(value)
  }

  //Separate data by time range ( day, week, year )
  function separateDataForPeriods() {

    const period = data?.reduce((acc: ChartDataWithDate, curr: NewsDistributionData): ChartDataWithDate => {
      acc[curr.timeframe].push({ ...curr, date: new Date(curr.date) })
      return acc
    }, { day: [], week: [], month: [] })

    return period

  }

  // Return data shape redy for Rechart
  function prepareWeekGraphData(data: DistributionData[]): DistributionChartItem[] {

    const weekData = data
      .sort((a, b) => a.date.getTime() - b.date.getTime()) //Sort days 
      .slice(Math.max(data.length - 7, 0)) //Get latest 7 days from array
      .reduce((acc: DistributionChartItem[], curr: DistributionData): DistributionChartItem[] => {

        const singleDayItem = {
          name: `${Object.values(icelandicDaysShort)[curr.date.getDay()]}`,
          engagement: curr.social,
          articles: curr.count,
          labelName: `${Object.values(icelandicDays)[curr.date.getDay()]} - ${curr.date.getDate()}.${monthNames[curr.date.getMonth()]}`,
        }
        acc.push(singleDayItem)
        return acc

      }, [])

    return weekData

  }

  function processMonthData(data: DistributionData[]) {
    //Will decide in which period in reducer items will be stored
    let itemToPush = -1
    const latestDaysInMonth =
      data.sort((a, b) => a.date.getTime() - b.date.getTime()) //Sort data by date
        .slice(Math.max(data.length - 28, 0)) //Get 28 latest days 
        .reduce((acc: any, curr: DistributionData, index: number): any => {
          if (index % 7 === 0) {
            itemToPush = itemToPush + 1 //ItemToPush will decide in which object from accumulator item will be pushed
          }

          //Create tooltip name for each item ( this will represent range of first and last date from array ) exapmple 21.06 - 28.06 
          if (index % 7 === 1) {
            acc[itemToPush].name = `${curr.date.getDate()}.${curr.date.getMonth() + 1} -`
          } else if (index % 7 === 6) {
            acc[itemToPush].name = `${acc[itemToPush].name} ${curr.date.getDate()}.${curr.date.getMonth() + 1}`
          }
          //This is only case when label is same as the name of X axis
          acc[itemToPush].labelName = acc[itemToPush].name
          //Set shape for Rechart library 
          acc[itemToPush].engagement = acc[itemToPush].engagement + curr.social
          acc[itemToPush].articles = acc[itemToPush].articles + curr.count
          return acc

        },
          [
            { name: '', engagement: 0, articles: 0, labelName: '' },
            { name: '', engagement: 0, articles: 0, labelName: '' },
            { name: '', engagement: 0, articles: 0, labelName: '' },
            { name: '', engagement: 0, articles: 0, labelName: '' },
          ])
    return latestDaysInMonth
  }

  function returnWeekData(data: DistributionData[]) {
    /*
    *  prepareWeekGraphData will need to be executed only once 
    *  when it is executed it will set weekData state and we will keep data there
    */
    if (weekData.length !== 7) {
      const latest = prepareWeekGraphData(data)
      setWeekData(latest)
      return latest
    } else {
      return weekData
    }

  }

  // Each month will be devided in 4 periods, each of them will contain DistributionChartItem shape 
  function returnMonthGraphData(data: DistributionData[]): DistributionChartItem[] {

    /*
    *  processMonthData will need to be executed only once 
    *  when it is executed it will set monthData state and we will keep data there
    */
    if (monthData.length !== 4) {

      const latestDaysInMonth = processMonthData(data)
      setMonthData(latestDaysInMonth)
      return latestDaysInMonth

    } else {

      return monthData

    }
  }

  function returnYearGraphDAta(data: DistributionData[]): DistributionChartItem[] {

    /*
    *  prepareYearGraphData will need to be executed only once 
    *  when it is executed it will set yearData state and we will keep data there
    */
    if (yearData.length !== 12) {

      const preparedYearData = prepareYearGraphData(data)
      setYearData(preparedYearData)
      return preparedYearData

    } else {

      return yearData

    }
  }

  function prepareYearGraphData(data: DistributionData[]): DistributionChartItem[] {

    // Oldest will be first item in array, newest will be last one ( chart goes from left to righ )
    const yearGraphData = data.sort((a, b) => a.date.getTime() - b.date.getTime())
      .reduce((acc: DistributionChartItem[], curr: DistributionData): DistributionChartItem[] | [] => {
        const singleItem = {
          name: shortMonthNames[curr.date.getMonth()],
          engagement: curr.social,
          articles: curr.count,
          labelName: `${monthNames[curr.date.getMonth()]}, ${curr.date.getFullYear()}`,
        }
        acc.push(singleItem)
        return acc
      }, [])

    return yearGraphData

  }

  useEffect(() => {
    //On the first render prepare data for year and pass it to the chart component
    if (!isLoading) {
      periodData.current = separateDataForPeriods()
    }
    if (!isLoading && periodData.current) {
      setFinalChartData(prepareYearGraphData(periodData.current.month))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    if (!isLoading && periodData.current) {
      switch (selected) {
        case "Last week":
          return setFinalChartData(returnWeekData(periodData.current.day))
        case "Last month":
          return setFinalChartData(returnMonthGraphData(periodData.current.day))
        case "Last year":
          return setFinalChartData(returnYearGraphDAta(periodData.current.month))
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, isLoading])


  return (
    <NewsDistributionSection>

      <NewsDistributionTopBar>
        <SectionTitleBold>News Distribution</SectionTitleBold>
        <CustomSelect
          type="select-button"
          options={["Last week", "Last month", "Last year"]}
          value={selected}
          onSelect={handleSelect}
          dropdownPosition="right"
        />
      </NewsDistributionTopBar>

      {isLoading
        ? <LoadSpinner size="large" /> :
        <>
          <NewsDistributionChart chartData={finalChartData} />
          <NewsDistributionSideLabels>
            <NewsDistributionSideLabelsWrapp>
              <p>Engagement</p>
              <p>Articles</p>

            </NewsDistributionSideLabelsWrapp>
          </NewsDistributionSideLabels>

          <NewsDistributionFooter>
            <ChartLegend bgColor={theme.colors.accent}>Articles</ChartLegend>
            <ChartLegend bgColor={theme.colors.green}>Engagement</ChartLegend>
          </NewsDistributionFooter>
        </>}
    </NewsDistributionSection>
  )
}
