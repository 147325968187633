import React, { useState, KeyboardEvent } from 'react'
import OutsideClickWrapper from './OutsideClickWrapper'
import { v4 as uuidv4 } from 'uuid'
import { ReactComponent as ButtonArrow } from '../../assets/icons/material/expand_more.svg'
import { ReactComponent as LinkArrow } from '../../assets/icons/material/arrow_drop_down.svg'
import MaterialIcon, { IconName } from './MaterialIcon'
import {
  DropDownContainer,
  SelectDropDownHeader,
  HeaderIcon,
  DropdownIcon,
  DropDownListContainer,
  DropDownList,
  ListItem
} from './CustomDropdown.styled'

import { capitalizeFirstLetter } from '../../utils'


interface CustomSelectProps {
  type: 'select-button' | 'select-link';
  options: string[];
  value: string;
  onSelect: (value: string) => void;
  dropdownPosition?: 'left' | 'center' | 'right';
  icon?: IconName;
  iconColor?: string;
  visibleItems?: number;
  location?: string;
  isDisabled?: boolean;
}

export default function CustomSelect(
  {
    type,
    options,
    value,
    onSelect,
    dropdownPosition = 'right',
    icon,
    iconColor = "#0284fe",
    visibleItems,
    location,
    isDisabled = false
  }: CustomSelectProps
) {

  const [isOpen, setIsOpen] = useState(false);

  const toggling = () => setIsOpen(!isOpen);

  const handleOnKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      toggling();
    }
  };

  const onOptionClicked = (value: string) => () => {
    onSelect(value);
    setIsOpen(false);
  };

  // Is a HOF(it's a function that returns another function).The returned function is the actual event handler that will be called when a keydown event occurs.
  const handleListItemKeyDown = (optionValue: string) => (e: KeyboardEvent<HTMLLIElement>) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      // Since `onOptionClicked` returns a function, we immediately invoke it
      onOptionClicked(optionValue)();
    }
  };

  const calculateDropdownHeight = (numberOfItems: number | undefined, numberOfOptions: number) => {
    if (numberOfItems && numberOfOptions) {
      if (numberOfItems > 1 && numberOfItems < numberOfOptions) {
        return numberOfItems
      }
    }
    return null
  }

  const closeOnClickOutside = () => setIsOpen(false)

  return (
    <DropDownContainer location={location}>
      <OutsideClickWrapper handlerFunc={closeOnClickOutside} enabled={isOpen}>
        <SelectDropDownHeader
          onClick={toggling}
          onKeyDown={handleOnKeyDown}
          type={type}
          isActive={isOpen}
          hasIcon={typeof icon !== 'undefined'}
          isDisabled={isDisabled}
          tabIndex={0}
        >
          {icon && <HeaderIcon isDisabled={isDisabled} color={iconColor}><MaterialIcon iconName={icon} /></HeaderIcon>}
          {capitalizeFirstLetter(value) || capitalizeFirstLetter(options[0])}
          <DropdownIcon type={type} isActive={isOpen} className="dropdown-icon">
            {type === 'select-button' ? <ButtonArrow /> : <LinkArrow />}
          </DropdownIcon>
        </SelectDropDownHeader>
        {isOpen && (
          <DropDownListContainer position={dropdownPosition} >
            <DropDownList visibleItems={calculateDropdownHeight(visibleItems, options.length)}>
              {options && options.map(option => (
                <ListItem
                  onClick={onOptionClicked(option)}
                  onKeyDown={handleListItemKeyDown(option)}
                  key={uuidv4()}
                  isSelected={value === option}
                  tabIndex={0}
                >
                  {capitalizeFirstLetter(option)}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </OutsideClickWrapper>
    </DropDownContainer>
  )
}
