import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fregnirApi } from '../query/queryApi'
export interface UserContact {
  company_name: string;
  ceo_name: string;
  email: string;
  phone: string;
  city: string;
  street_address: string;
}

export interface UserState {
  username: string;
  account_type: string;
  image: string;
  contactData: UserContact
  created_at: string;
  updated_at: string;
  email_verified_at: null | string;
  group_id: number;
  id: number;
  auth_token: string;

}

const initialState: UserState = {
  username: "",
  image: "",
  account_type: "Executive",
  contactData: {
    company_name: "",
    ceo_name: "",
    email: "",
    phone: "",
    city: "",
    street_address: "",
  },
  auth_token: "",
  created_at: "",
  updated_at: "",
  email_verified_at: null,
  group_id: 0,
  id: 0,
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    editUser: (state, action: PayloadAction<UserState>) => {
      return { ...state, ...action.payload }

    },
    editContactData: (state, action: PayloadAction<{ contactData: UserContact }>) => {
      state.contactData = action.payload.contactData
    },
    setNewUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload
    },
    editEmail: (state, action: PayloadAction<string>) => {
      state.contactData.email = action.payload
    },
    addToken: (state, action: PayloadAction<{ auth_token: string }>) => {
      state.auth_token = action.payload.auth_token
    },
    updateOnReload: (state, action: PayloadAction<{ auth_token: string, username: string, email: string, image: string }>) => {
      state.auth_token = action.payload.auth_token
      state.username = action.payload.username
      state.contactData.email = action.payload.email
      state.image = action.payload.image
    },
    editUserImage: (state, action: PayloadAction<string>) => {
      state.image = action.payload
    },
    resetUserState: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    // builder.addCase(populateSlicesData, (state, action) => {
    //   return { ...state, ...action.payload.user }
    // }),
    builder.addMatcher(
      fregnirApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.auth_token = payload.token
        state.username = payload.user.email
        state.created_at = payload.user.created_at
        state.updated_at = payload.user.updated_at
        console.log('payload', payload)
      }
    )
  }
})

export const { setNewUsername, editUser, editContactData, addToken, updateOnReload, editEmail, editUserImage, resetUserState } = slice.actions

export default slice.reducer