import React, { useContext } from 'react';
import { CustomTooltip, Label, LabelText, LabelCount } from './SimpleWeekChart.styled'
import MaterialIcon from '../global-components/MaterialIcon'
import { ThemeContext } from 'styled-components'



const WeekChartTooltip = ({ data, posTop, posLeft, isFloating }: { data: any, posTop: number, posLeft: number, isFloating?: boolean }) => {

  const { colors } = useContext(ThemeContext)

  const calculateTimeDifference = (pointIndex: number): string | undefined => {
    switch (pointIndex) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return `${pointIndex}h`
      case 6:
        return '24h'
      case 7:
        return '7d'
    }
  }

  return (
    <CustomTooltip posTop={posTop} posLeft={posLeft} isFloating={isFloating} >
      <Label separator={true} type="date">
        <LabelText>{data.publishedAt}</LabelText>
      </Label>
      {data.pointIndex > 1 && (
        <Label separator={true} type="engagement">
          <LabelText>
            <svg width="10" height="10">
              <circle cx="5" cy="5" r="4" strokeWidth="1" stroke={colors.white} fill={colors.green} />
            </svg>
            <span>New ({calculateTimeDifference(data.pointIndex)}):</span>
          </LabelText>
          <LabelCount>{data.new}</LabelCount>
        </Label>
      )}
      <Label separator={false} type="engagement">
        {
          data.pointIndex ? (
            <>
              <LabelText>
                <span>Total Engagement:</span>
              </LabelText>
              <LabelCount>{data.total}</LabelCount>
            </>
          ) : (
            <LabelText firstPoint={true}><MaterialIcon iconName={'feed'} color={colors.green} size={18} />Published!</LabelText>
          )
        }
      </Label>
    </CustomTooltip>
  );
}

export default WeekChartTooltip;
