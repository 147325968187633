import React from 'react';
import Routes from './routes/index';
import { ThemeProvider } from 'styled-components';
import { theme } from './global-styles/globalTheme'

import GlobalStyles from './global-styles/globalStyles'
import PopupRenderer from './components/global-components/popups/PopupRenderer'
import TooltipRenderer from './components/global-components/tooltips/TooltipRenderer'
import ModalRenderer from './components/modals/ModalRenderer'
import PreventInputZoomIOS from './components/global-components/PreventInputZoomIOS';

import './translations/i18n'
import './global-styles/normalize.scss'

// Using a provider to encapsulate Helmet state for our react tree
import { HelmetProvider } from 'react-helmet-async'


function App() {

  return (
    <>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <div className="App" style={{ backgroundColor: '#eef1f9' }}>
            <PreventInputZoomIOS />
            <GlobalStyles />
            <Routes>
              <PopupRenderer />
              <ModalRenderer />
              <TooltipRenderer />
            </Routes>
          </div>
        </HelmetProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
