import { isEmpty } from '../utils'
import { ChangedValuesInEditModeType } from '../state/slices/appState'

export function checkEditMode(valuesInEditMode: ChangedValuesInEditModeType) {

  //Check does object contains some properties
  const checkIsInEditMode = () => !isEmpty(valuesInEditMode)
  //In object ( global state )  we store data in shape = fieldName: [prevVal, currentVal] - so we are checking is prevVal !== currentVal
  const isValuesInObjectDifferent = (obj: ChangedValuesInEditModeType): boolean[] => Object.values(obj).map(item => item[0] !== item[1])

  const removeDuplicateFromArr = (arr: boolean[]): boolean[] => Array.from(new Set(arr));

  const doesArrayContainTrue = (arr: boolean[]): boolean => arr.includes(true)

  const isEditModeActve =
    (checkIsInEditMode: () => boolean) =>
      (isValuesInObjectDifferent: (valuesInEditMode: ChangedValuesInEditModeType) => boolean[]) =>
        (removeDuplicates: (arr: boolean[]) => boolean[]) =>
          (doesArrayContainTrue: (arr: boolean[]) => boolean) =>
            checkIsInEditMode()
              ? doesArrayContainTrue(removeDuplicates(isValuesInObjectDifferent(valuesInEditMode)))
              : false


  return isEditModeActve(checkIsInEditMode)(isValuesInObjectDifferent)(removeDuplicateFromArr)(doesArrayContainTrue)

}