import styled from 'styled-components'
import { addOpacityToHex } from '../../utils'
import { device } from '../../global-styles/mediaQueries'


export const ModalOuter = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  background-color: ${({ theme }) => addOpacityToHex(theme.colors.primary, 0.5)};
  cursor: unset;
  z-index: 10;

  @media ${device.tablet} {
    padding-top: 60px;
  }

  @media ${device.desktop} {
    padding-top: 80px;
  }
`

export const Labels = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primary};

  span {
    width: 50%;
    &:nth-child(1) {
      padding-left: 12px;
    }

    &:nth-child(2) {
      padding-left: 6px;
    }
  }
`

export const Wrapper = styled.div`
  width: 100%;
  max-width: 328px;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;

  h3 { 
    margin: 20px 0 24px;
    font-family: Arial;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: ${({ theme }) => theme.colors.primary};
  }

  .rdrDateDisplayItem {
    border-width: 2px;
  }

  .rdrDays {
    min-height: 216px;

    button {
      max-height:36px;
    }
  }

  .rdrDateRangeWrapper {
    width: 100%;
    max-width:360px
  }
    
  .rdrMonth {
    width: 100%;
  } 


  @media ${device.tablet} {
    max-width: 372px;
    padding: 20px;
  }

  @media ${device.desktop} {
    max-width: 380px;
    padding: 24px;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`