import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { checkEditMode } from '../../utils/checkEditMode'

export type ChangedValuesInEditModeType = {
  [key: string]: string[] | boolean[]
}
interface PayloadForEditMode {
  fieldName: string
  fieldValue: string | boolean
  startFieldValue: string | boolean
}

export interface AppState {
  isLoading: boolean,
  error: boolean,
  errorMessage: string,
  isEditModeActive: boolean,
  changedValsInEditMode: ChangedValuesInEditModeType | {}
  hiddenField?: string
}

const initialState: AppState = {
  isLoading: false,
  error: false,
  errorMessage: '',
  isEditModeActive: false,
  changedValsInEditMode: {},
  hiddenField: ''
}

const slice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    updateEditMode: (state, action: PayloadAction<PayloadForEditMode>) => {
      const { payload: { fieldName, fieldValue, startFieldValue } } = action
      const fieldObject = {
        [fieldName]: [startFieldValue, fieldValue]
      }
      state.changedValsInEditMode = { ...state.changedValsInEditMode, ...fieldObject }
      state.isEditModeActive = checkEditMode(state.changedValsInEditMode)

    },
    // This reducer will clean all values in changedValsInEditMode and deactivate edit mode.
    cleanEditMode: (state, action: PayloadAction<string>) => {
      state.changedValsInEditMode = {}
      state.isEditModeActive = false
      state.hiddenField = action.payload

    },
    // This reducer will clean only values that are sent in payload, and will keep the rest of them.
    // This is usefull, if there are more editable sections on a page, so sections in edit mode will remain there, if multiple are edited at the same time.
    cleanValuesInEditMode: (state, action: PayloadAction<string[]>) => {
      // Creating temporary state so properties in changedValsInEditMode can be deleted.
      const tempState = { ...state.changedValsInEditMode }

      // Looping trough payload and deleting items in tempState if they exist.
      action.payload.forEach((item: string) => {
        if (tempState[item]) {
          delete tempState[item]
        }
      })

      state.changedValsInEditMode = { ...tempState }

      if (Object.keys(state.changedValsInEditMode).length === 0) {
        state.isEditModeActive = false
      }
    }
  }
})

export const { updateEditMode, cleanEditMode, cleanValuesInEditMode } = slice.actions

export default slice.reducer