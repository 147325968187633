import styled from 'styled-components'
import { device } from '../../global-styles/mediaQueries'


export const SingleContentBox = styled.div`
  display: flex;
  flex-flow: column;
  @media ${device.tablet} {
    display: grid;
    grid-template-columns: [expires] 100px [content] 350px [engagement] 110px;
    grid-template-rows: repeat(2);
  }
  @media ${device.tabletL} {
    grid-template-columns: [expires] 127px [content] 390px [chart] 160px [space] 44px [engagement] 132px ;
    grid-template-rows: 70px;
  }
  @media ${device.desktopS} {
    grid-template-columns: [expires] 111px [content] 360px [chart] 160px [engagement] 132px ;
  }
  @media ${device.desktop} {
    grid-template-columns: [expires] 148px [content] 470px [chart] 200px [engagement] 132px ;
  }
`

export const SocialAlertRow = styled.div<{ isOpen: boolean }>`
  position: relative;
  width: 100%;
  padding: 30px 16px 0 70px;
  background-color: ${({ theme, isOpen }) => isOpen ? theme.colors.highlight : theme.colors.white};
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    left: 20px;
    bottom: 0px;
    width: calc(100% - 40px);
    height: 1px;
    background-color: ${({ theme }) => theme.colors.borderPrimary};
  }
  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    &:after {
      display: none;
    }
  }
  @media ${device.mobileL} {
    padding: 30px 20px 0 74px;
  }
  @media ${device.tablet} {
    padding: 30px 110px 0 86px;
    .social-alert-dropdown {
      top: 26px;
      left: 24px;
    }
  }
  @media ${device.tabletL} {
    padding: 28px 112px 40px 24px;
    .social-alert-dropdown {
      display: none;
    }
  }
`

export const ExpiresDate = styled.div`
display: flex;
align-items: center;
margin-bottom: 14px;
font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.secondary};
  svg {
    margin-right: 8px;
  }
  @media ${device.tablet} {
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: 18px;
  }
`
export const TextWrapper = styled.div`
  @media ${device.tablet} {
    padding-right: 38px;
  }
  @media ${device.tabletL} {
    padding-right: 35px;
  }
  @media ${device.desktopS} {
    padding-right: 25px;
  }
  @media ${device.desktopL} {
    padding-right: 44px;
  }
`

export const RowTitle = styled.a`
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: underline;
  }
`

export const RowText = styled.p`
  margin-bottom: 32px;
  margin-top: 6px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  color: ${({ theme }) => theme.colors.secondary};
  @media ${device.tablet} {
    margin-bottom: 36px;
  }
`

export const EngagemnetWrapper = styled.div`
  flex-flow: column;
  @media ${device.tablet} {}

`

export const TotalEngagements = styled.div<{ isOpen: boolean }>`
  display:  ${({ isOpen }) => isOpen ? 'flex' : 'none'};
  flex-flow: column;
  margin-bottom: 32px;
  p {
    font-size: 13px;
    font-weight: normal;
    line-height: 1.54;
    ${({ theme }) => theme.colors.primary}
    &:first-child {
      margin-bottom: 14px;
      font-weight: bold;
      line-height: 1.38;
    }
  }
  @media ${device.tablet} {
    display: flex;
    height: 100%;
    align-items: flex-start;
    flex-flow: row;
    justify-content: center;
    margin: 0;
    padding-top: 5px;
    p {
      font-weight: bold;
    }
    .tablet-hidden {
      display: none;
    }
  }
  @media ${device.tabletL} {
    align-items: center;
    grid-column-start: engagement;
    padding-top: 0px;

  }
`
export const DalilyEngagements = styled.div<{ isOpen: boolean }>`
  display:  ${({ isOpen }) => isOpen ? 'flex' : 'none'};
  flex-flow: column;
  margin-bottom: 34px;
  position: relative;
  p {
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: bold;
    line-height: 1.38;
    ${({ theme }) => theme.colors.primary}
  }
  @media ${device.tablet} {
    grid-column-start: content;
    flex-flow: row;
  
    .desktop-hidden {
      position: absolute;
      left: -156px;
      top: 0px;
    }
  }
  @media ${device.tablet} {
    grid-column-start: content;
  }
  @media ${device.tabletL} {
    display: flex;
    grid-column-start: chart;
    grid-row-start: 1;
    margin: 0;

    .desktop-hidden {
      display: none;
    }
  }
`