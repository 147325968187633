import React from 'react'

//Styles
import { TopNewsTableHeader, SocialMediaTableWrapper, ToolTipWrapper } from './SocialMediaTab.styled'

//Components
import LoadSpinner from '../global-components/LoadSpinner'
import SingleMostReadItem from './SingleMostReadItem'
import GeneralTooltipCaller from '../global-components/tooltips/GeneralTooltipCaller'
import ConnectionLostComponent from '../global-components/ConnectionLostComponent'

//Redux
import { useGetMostReadNewsQuery } from '../../state/query/queryApi'
import { Error } from '../../state/query/types/globalQueryTypes'
import useStorageListener from '../../hooks/useStorageListener'


export default function MostReadTab() {

  const { data: posts, isLoading, isError, error, refetch } = useGetMostReadNewsQuery('', {
    pollingInterval: 600000,
    refetchOnReconnect: true,
  })

  useStorageListener('updated_article_action', (action) => {
    if (action === "STOP_ALERT" || action === "SET_ALERT") {
      refetch()
    }
  });

  return (
    <SocialMediaTableWrapper>
      <TopNewsTableHeader>
        <p className="first">Rank</p>
        <p className="second">News <span> / Trend</span></p>
        <p className="third hide-mobile">Trend</p>
        <ToolTipWrapper>
          <GeneralTooltipCaller name="basic" content="This indicates the level of social media engagement for each article." />
        </ToolTipWrapper>
      </TopNewsTableHeader>
      {isLoading
        ? <LoadSpinner size="large" />
        : !isError
          ? posts?.data.map(item =>
            <SingleMostReadItem post={item} key={item.id} />
          )
          : <ConnectionLostComponent error={(error as Error)?.status} invalidateTags="MostRead" />
      }

    </SocialMediaTableWrapper >
  )
}
