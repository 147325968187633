import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
// import { RootState } from '../store';
// import { mainSplitApi } from './mainSplitApi' if we decide to split queries we will use this
import { current } from '@reduxjs/toolkit'


// export const 
import { decrypt } from '../../utils'

//Types
import {
  ActionCreatorsStrings, SetSocialAlertProp,
  RemoveSocialAlertProp,
  GetMostReadResponseInterface,
  GetSocialMediaResponseInterface
} from './types/TopNewsAndSocialAlertsTypes'
import { OverviewData } from './types/OverviewTypes'
import {
  GlobalNewsFetchParams,
  LatestNewsFetchParams,
  LatestNewsResponseData,
  SingleNewsData,
  KeyAndSearchParams,
  RemoveSocialAlerts,
  ChangeSentimentArgs,
  ResponseMessage,
  UpdateTagsArgs,
  AddAlertsArgs
} from './types/LatestNewsTypes'
import { SingleSavedSearch, SaveNewsSearchBodyRequest } from './types/SavedSearchTypes'

import { ReportData, InvoiceData } from './types/ReportsAndInvoicesTypes'
import {
  SettingsResponseData,
  ContactData,
  NotificationsSelectionsData,
  NotificationsEmails,
  NotificationsEmailRoutes,
  SearchSubTermsData,
  editEmail,
  editPassword,
  UserImageUpload
} from './types/SettingsTypes'
import { CacheTags } from './types/globalQueryTypes'

import { ResponseUserDataOnPasswordChange } from '../../components/settings/SetPassword'

export const fregnirApi = createApi({
  reducerPath: 'fregnirApi',
  baseQuery: fetchBaseQuery({

    baseUrl: 'https://api.fregnir.is/api/',

    prepareHeaders: (headers, { getState }) => { //We can use getState if we need to set auth_token to store

      // const currentState = getState() => way to access to global state 

      let token = localStorage.getItem("token")
      token = token && decrypt(token as string, process.env.REACT_APP_SECRET as string)

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),

  tagTypes: ['SocialAlerts', 'MostRead', 'SocialMedia', 'gNews', 'gNewsSingle', 'Dashboard', 'Reports', 'Invoices', 'SavedSearch', 'Settings', 'GlobalSearch'],

  endpoints: (builder) => ({
    //Just demo mutations but you can use it if you need :)  
    login: builder.mutation({
      query: (credentials) => ({
        url: 'login',
        method: 'POST',
        body: credentials
      })
    }),

    // ===== Queries ===== 
    getSocialAlerts: builder.query({ // <TypeFromServer, TypeForQuery>Here is going return type from server 
      query: () => `getalerts`, //Add to baseUrl
      providesTags: ['SocialAlerts'],

    }),

    getMostReadNews: builder.query<GetMostReadResponseInterface, string>({
      query: () => 'toplists/mostread',
      providesTags: ['MostRead']
    }),

    getSocialMedia: builder.query<GetSocialMediaResponseInterface, string>({
      query: () => `toplists/socialmedia`,
      providesTags: ['SocialMedia']
    }),

    getDashboardData: builder.query<OverviewData, any>({
      query: () => 'dashboard',
      providesTags: ['Dashboard']

    }),
    getSavedSearch: builder.query<SingleSavedSearch[], string>({
      query: () => `savedsearches`,
      providesTags: ['SavedSearch']
    }),

    getReports: builder.query<ReportData[], void>({ // <TypeFromServer, TypeForQuery>Here is going return type from server 
      query: () => `reports`, //Add to baseUrl
      providesTags: ['Reports'],
    }),

    getInvoices: builder.query<InvoiceData[], void>({ // <TypeFromServer, TypeForQuery>Here is going return type from server 
      query: () => `invoices`, //Add to baseUrl
      providesTags: ['Invoices'],
    }),

    getSettingsData: builder.query<SettingsResponseData, void>({
      query: () => 'settings',
      providesTags: ['Settings'],
    }),

    // ===== Mutations ===== 
    //Save News Search
    saveNewsSearch: builder.mutation<SingleSavedSearch[], SingleSavedSearch>({
      query: ({ search_name, search_term, date }: SaveNewsSearchBodyRequest) => ({
        url: 'savedsearches',
        method: 'POST',
        body: {
          search_name,
          search_term,
          date
        }
      }),
      invalidatesTags: ['SavedSearch']
    }),
    deleteNewsSearch: builder.mutation<number, any>({
      query: (id: number) => ({
        url: 'savedsearches',
        method: 'DELETE',
        body: {
          "savedsearches": [id]
        }
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {

        const patchResult = dispatch(
          fregnirApi.util.updateQueryData('getSavedSearch', '', (draft) => {

            const currState = current(draft)
            let filterById = currState.filter(item => item.id !== id)
            draft.length = 0 //empty array
            draft.push.apply(draft, filterById) //Add filtered arr to state 

          })
        )

        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }

      }

    }),
    //Set alert 
    setSocialAlert: builder.mutation({
      query: ({ id, body }: SetSocialAlertProp) => ({
        url: `setalerts/${id}`,
        method: 'POST',
        body: body //fetchBaseQuery automatically adds `content-type: application/json` to the Headers and calls `JSON.stringify(patch)`
      }),
      async onQueryStarted({ id, post, cacheType, globalNewsFetchParams }, { dispatch, queryFulfilled }) {

        //Depeands of cacheType we will call different query
        let endpointName = '' as ActionCreatorsStrings

        switch (cacheType) {
          case 'MostRead':
            endpointName = 'getMostReadNews'
            break;
          case 'SocialMedia':
            endpointName = 'getSocialMedia'
            break;
          case 'SocialAlerts':
            endpointName = 'getSocialAlerts'
            break;
          case 'GlobalSearch':
            endpointName = 'getGlobalSearch'
            break;
          default:
            endpointName = 'getSocialAlerts'
            break;
        }

        const requestArgs = cacheType === 'GlobalSearch' ? globalNewsFetchParams : ''

        try {
          const { data } = await queryFulfilled

          dispatch(
            /**
             * updateQueryData(endpointName, args, updateRecipe) 
             * => endpointName: string -> name of our created query action creator
             * => args: string | number -> that is arg of query crator, not arg for chaning cache 
             * => updateRecipe => void fn() callback which will be executed if QueryStatus !== unitilaized
             *
             * 1.1 updateQueryData will executed (api.endpoints[endpointsName] as ApiEndpointQuery).select(args)(getState())
             * 1.2 Where api is object of slices, endpoints, enchancers, middlewares, etc. So it will access to endpoints
             * 1.3 Then endpoints will acceess to our [endpointName] which is action cretor which will be serialized with args.  example: "getPost(15)" || "getPosts()"
             * 1.4 Then it will check does serialized (example: getPosts('') | getPost('13') ) exist in our memory and does it contains 'data' 
             * => if ('data' in currentState)  ( source: buildThunk.ts )*  
             * 1.5 if that condition is true then it will return previous state from cache and new data from updateRecipie fn 
             * => const [, patches, inversePatches] = produceWithPatches(currentState.data, updateRecipe)
             * 1.6 if they are not the same then it will assing new data instead of previous
             * 
             * Draft contains read only data, so if I tried to do draft.data[0].notification => Unhandled Rejection (TypeError): Cannot assign to read only property 'notification' of object '#<Object>'
             */
            fregnirApi.util.updateQueryData(endpointName, requestArgs, (draft) => { //Draft is Proxy, immer returns it 
              if (endpointName === "getSocialAlerts") {
                // When adding custom news in social alerts, alert is being created from response, and updated in cache.
                const newPost = { ...data.shape, id: data.alert_id }
                draft.data.push(newPost)
              }
              else {
                const newPost = Object.assign({}, post) //Clone object of my current post which I pass as prop 
                newPost.alert_id = data.alert_id // Change prop which I need to 
                const currState = current(draft) // We have to use current because draft is Proxy in current moment
                const myData = currState.data.map((singlePost: any) => {
                  return parseInt(singlePost.current_news_key) === id ? newPost : singlePost
                })
                Object.assign(draft.data, myData)
              }
            }))
          localStorage.setItem('updated_article_action', "SET_ALERT")
        } catch (err) {
          console.log(err);
        }
      },
      invalidatesTags: (result, error, arg) => ['SocialAlerts', 'MostRead', 'SocialMedia', 'GlobalSearch'].filter((cache) => arg.cacheType !== cache) as CacheTags
    }),

    //Stop alerts 
    stopAlerts: builder.mutation({
      query: ({ id }: RemoveSocialAlertProp) => ({
        url: `/remalerts/${id}`,
        method: 'GET'
      }),
      async onQueryStarted({ id, cacheType, isTopNewsQuery, post, globalNewsFetchParams }, { dispatch, queryFulfilled }) {

        // Depends of cacheType we will call different query
        let endpointName = '' as ActionCreatorsStrings

        switch (cacheType) {
          case 'MostRead':
            endpointName = 'getMostReadNews'
            break;
          case 'SocialMedia':
            endpointName = 'getSocialMedia'
            break;
          case 'SocialAlerts':
            endpointName = 'getSocialAlerts'
            break;
          case 'GlobalSearch':
            endpointName = 'getGlobalSearch'
            break;
          default:
            endpointName = 'getSocialAlerts'
            break;
        }

        const requestArgs = cacheType === 'GlobalSearch' ? globalNewsFetchParams : ''

        try {
          await queryFulfilled

          dispatch(
            fregnirApi.util.updateQueryData(endpointName, requestArgs, (draft) => {
              const currState = current(draft)
              if (isTopNewsQuery) {
                const newPost = Object.assign({}, post)
                newPost.alert_id = null
                const myData = currState.data.map((singlePost: any) => {
                  return singlePost.alert_id === id ? newPost : singlePost
                })
                Object.assign(draft.data, myData)
              } else {
                draft.data = currState.data.filter((singlePost: any) => singlePost.id !== id)
              }
            })
          )
          localStorage.setItem('updated_article_action', "STOP_ALERT")
        } catch (err) {
          console.log(err)
        }
      },
      // Invalidating cache for all cache types, except from where was query called from.
      invalidatesTags: (result, error, arg) => ['SocialAlerts', 'MostRead', 'SocialMedia', "GlobalSearch"].filter((cache) => arg.cacheType !== cache) as CacheTags
    }),

    // GNEWS SEARCH
    getGnewsSearchData: builder.query<LatestNewsResponseData, LatestNewsFetchParams>({
      query: (params) => ({
        url: `/gnews/search?page=${params.page}`,
        method: 'POST',
        body: params,
      }),
      keepUnusedDataFor: 600,
      providesTags: ['gNews']
    }),

    // SINGLE GNEWS ARTICLE
    getSingleArticle: builder.query<SingleNewsData, number>({
      query: (id) => ({
        url: `gnews/single/${id}`,
        method: 'GET'
      }),
      providesTags: ['gNewsSingle']
    }),

    getGlobalSearch: builder.query<any, GlobalNewsFetchParams>({
      query: (params) => ({
        url: `/anews/search?page=${params.page}`,
        method: 'POST',
        body: params
      }),
      providesTags: ['GlobalSearch']
    }),

    // DELETE REPORTS
    deleteReports: builder.mutation<ReportData[], { reports: number[] }>({
      query: (params) => ({
        url: 'reports',
        method: 'DELETE',
        body: params
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled

          dispatch(
            fregnirApi.util.updateQueryData('getReports', undefined, (draft) => { //when endpoint is used with no argument we need to pass "undefined" as argument
              const currState = current(draft)
              draft.splice(0, currState.length)
              draft.push(...response.data)
            })
          )
        } catch (err) {
          console.error(err)
        }
      }
    }),

    // DELETE INVOICES
    deleteInvoices: builder.mutation<InvoiceData[], { invoices: number[] }>({
      query: (params) => ({
        url: 'invoices',
        method: 'DELETE',
        body: params
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled

          dispatch(
            fregnirApi.util.updateQueryData('getInvoices', undefined, (draft) => { //when endpoint is used with no argument we need to pass "undefined" as argument
              const currState = current(draft)
              draft.splice(0, currState.length)
              draft.push(...response.data)
            })
          )
        } catch (err) {
          console.error(err)
        }
      }
    }),

    // GNEWS DELETE
    deleteNews: builder.mutation<SingleNewsData, KeyAndSearchParams>({
      query: (args) => ({
        url: `/gnews/delete/${args.newsKey}?page=${args.params.page}`,
        method: 'POST',
        body: args.params,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled
          dispatch(
            fregnirApi.util.updateQueryData('getGnewsSearchData', args.params, (draft) => {
              const currState = current(draft)
              const index = currState.data.findIndex((item: any) => {
                return item.current_news_key === args.newsKey
              })
              draft.data.splice(index, 1)
              if (response.data?.id) draft.data.push(response.data)
            })
          )
          // if we invalidate tags with invalidatesTags mutation prop we can't perform pesimistic update
          // so we dispatch invalidateTags action here instead
          dispatch(fregnirApi.util.invalidateTags(['gNews']))
          localStorage.setItem('updated_article_action', "DELETE_ARTICLE")
        } catch (err) {
          console.log(err)
        }
      }
    }),
    // GNEWS SENTIMENT
    changeSentiment: builder.mutation<SingleNewsData, ChangeSentimentArgs>({
      query: (args) => ({
        url: `/gnews/sentiment/${args.newsKey}`,
        method: 'POST',
        body: args.sentiment
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        let patchResult
        if (args.params) {
          patchResult = dispatch(
            fregnirApi.util.updateQueryData('getGnewsSearchData', args.params, (draft) => {
              const currState = current(draft)
              const index = currState.data.findIndex((item: any) => {
                return item.current_news_key === args.newsKey
              })
              draft.data[index].sentiment = args.sentiment.sentiment
            })
          )
        }

        const patchSingleArticleResult = dispatch(
          fregnirApi.util.updateQueryData('getSingleArticle', args.newsKey, (draft) => {
            const currState = current(draft)

            if (args.newsKey === currState.current_news_key) {
              draft.sentiment = args.sentiment.sentiment
            }
          })
        )
        try {
          await queryFulfilled
          localStorage.setItem('updated_article_action', "CHANGE_SENTIMENT")
        } catch {
          patchResult && patchResult.undo()
          patchSingleArticleResult.undo()
        }
      }
    }),
    // GNEWS TAGS
    addOrRemoveTags: builder.mutation<SingleNewsData, UpdateTagsArgs>({
      query: (args) => ({
        url: `/gnews/tags/custom/update/${args.newsKey}`,
        method: 'POST',
        body: args.requestBody
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        let patchResult
        if (args.params) {
          patchResult = dispatch(
            fregnirApi.util.updateQueryData('getGnewsSearchData', args.params, (draft) => {
              const currState = current(draft)
              const index = currState.data.findIndex((item: any) => {
                return item.current_news_key === args.newsKey
              })
              draft.data[index].tags_custom = args.requestBody.tags_json
            })
          )
        }

        const patchSingleArticleResult = dispatch(
          fregnirApi.util.updateQueryData('getSingleArticle', args.newsKey, (draft) => {
            const currState = current(draft)
            if (args.newsKey === currState.current_news_key) {
              draft.tags_custom = args.requestBody.tags_json
            }
          })
        )
        try {
          await queryFulfilled
          localStorage.setItem('updated_article_action', "SET_TAGS")
        } catch {
          patchResult && patchResult.undo()
          patchSingleArticleResult.undo()
        }
      }
    }),
    // GNEWS SET ALERT
    setAlertLatestNews: builder.mutation<ResponseMessage, AddAlertsArgs>({
      query: (args) => ({
        url: `/setalerts/${args.newsKey}`,
        method: 'POST',
        body: args.requestBody
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          if (args.params) {
            dispatch(
              fregnirApi.util.updateQueryData('getGnewsSearchData', args.params, (draft) => {
                const currState = current(draft)
                const index = currState.data.findIndex((item: any) => {
                  return item.current_news_key === args.newsKey
                })

                draft.data[index].alert_id = data.alert_id
              })
            )
          }

          dispatch(
            fregnirApi.util.updateQueryData('getSingleArticle', args.newsKey, (draft) => {
              const currState = current(draft)
              if (args.newsKey === currState.current_news_key) {
                draft.alert_id = data.alert_id
              }
            })
          )

          localStorage.setItem('updated_article_action', "SET_ALERT")
        } catch (err) {
          console.log(err)
        }
      }
    }),
    // GNEWS STOP ALERT
    stopAlertLatestNews: builder.mutation<ResponseMessage, RemoveSocialAlerts>({
      query: (args) => ({
        url: `/remalerts/${args.alert_id}`,
        method: 'GET'
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          if (args.params) {
            dispatch(
              fregnirApi.util.updateQueryData('getGnewsSearchData', args.params, (draft) => {
                const currState = current(draft)
                const index = currState.data.findIndex((item: any) => {
                  return item.alert_id === args.alert_id
                })
                draft.data[index].alert_id = null
              })
            )
          }
          dispatch(
            fregnirApi.util.updateQueryData('getSingleArticle', args.newsKey, (draft) => {
              const currState = current(draft)
              if (args.alert_id === currState.alert_id) {
                draft.alert_id = null
              }
            })
          )
          localStorage.setItem('updated_article_action', "STOP_ALERT")
        } catch (err) {
          console.log(err)
        }
      }
    }),

    // ==== SETTINGS ====
    //Need to change logic, but will continue with this
    // resetPassword: builder.mutation<any, any>({
    //   query: (args) => ({
    //     url: '/resetpassword'
    //     method: 'POST',
    //     body: args
    //   }),
    //   async onQueryStarted(args, {dispatch, queryFulfilled}) {

    //     const results = dispatch(
    //     )
    //   }
    // }),

    editSettingsUser: builder.mutation<ContactData, ContactData>({
      query: (args: ContactData) => ({
        url: '/settings/edit',
        method: 'POST',
        body: args
      }),
      async onQueryStarted(args: ContactData, { dispatch, queryFulfilled }) {

        const results = dispatch(
          fregnirApi.util.updateQueryData('getSettingsData', undefined, (draft) => {
            Object.assign(draft.contactData[0], args)
          })
        )

        try {
          await queryFulfilled
        } catch {
          results.undo()
        }
      }

    }),


    editSettingsNotifications: builder.mutation<NotificationsSelectionsData, NotificationsSelectionsData>({
      query: (args) => ({
        url: `/settings/${args.endpoint}`,
        method: 'POST',
        body: args.requestBody
      }),
      async onQueryStarted(args: NotificationsSelectionsData, { dispatch, queryFulfilled }) {

        const results = dispatch(
          fregnirApi.util.updateQueryData('getSettingsData', undefined, (draft) => {
            draft.notification_and_alerts[args.endpoint as NotificationsEmailRoutes].forEach((item: NotificationsSelectionsData['requestBody'][''][0], index: number) => {
              item.id = args.requestBody[args.endpoint][index].id
              item.checked = args.requestBody[args.endpoint][index].checked
              item.extension = args.requestBody[args.endpoint][index].extension
              item.name = args.requestBody[args.endpoint][index].name
            })
          })
        )

        try {
          await queryFulfilled
        } catch {
          results.undo()
        }
      },
    }),


    editSettingsNotificationsEmails: builder.mutation<NotificationsEmails['emails'], NotificationsEmails>({
      query: (args: NotificationsEmails) => ({
        url: `/settings/emails`,
        method: 'POST',
        body: args
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedEmails } = await queryFulfilled

          dispatch(
            fregnirApi.util.updateQueryData('getSettingsData', undefined, (draft) => {
              draft.notification_and_alerts.emails = updatedEmails
            })
          )
        } catch (err) {
          console.log(err);
        }
      }
    }),

    editSearchSubTerms: builder.mutation<SearchSubTermsData, SearchSubTermsData>({
      query: (args: SearchSubTermsData) => ({
        url: '/settings/edit/term',
        method: 'POST',
        body: args
      }),
      async onQueryStarted(args: SearchSubTermsData, { dispatch, queryFulfilled }) {
        const results = dispatch(
          fregnirApi.util.updateQueryData('getSettingsData', undefined, (draft) => {
            draft.search_terms.onboard_terms[0].subterms = [...args.subterms]
          })
        )
        try {
          await queryFulfilled
        } catch {
          results.undo()
        }
      },
    }),


    editEmail: builder.mutation<editEmail, editEmail>({
      query: (args: editEmail) => ({
        url: '/change_email',
        method: 'POST',
        body: args
      })
    }),

    editPassword: builder.mutation<ResponseUserDataOnPasswordChange, editPassword>({
      query: (args: editPassword) => ({
        url: '/change_password',
        method: 'POST',
        body: args
      })
    }),

    editUserImage: builder.mutation<UserImageUpload, UserImageUpload>({
      query: (args: UserImageUpload) => ({
        url: '/upload/image',
        method: 'POST',
        body: args
      })
    })
  })
})

export const {
  useGetSocialAlertsQuery,
  useGetSocialMediaQuery,
  useGetMostReadNewsQuery,
  useGetDashboardDataQuery,
  useGetSavedSearchQuery,
  useGetReportsQuery,
  useGetInvoicesQuery,
  useGetSettingsDataQuery,
  useLoginMutation,
  useSaveNewsSearchMutation,
  useSetSocialAlertMutation,
  useDeleteNewsSearchMutation,
  useStopAlertsMutation,
  useGetGnewsSearchDataQuery,
  useDeleteReportsMutation,
  useDeleteInvoicesMutation,
  useDeleteNewsMutation,
  useChangeSentimentMutation,
  useAddOrRemoveTagsMutation,
  useSetAlertLatestNewsMutation,
  useStopAlertLatestNewsMutation,
  useEditSettingsUserMutation,
  useEditSettingsNotificationsMutation,
  useEditSettingsNotificationsEmailsMutation,
  useEditSearchSubTermsMutation,
  useGetSingleArticleQuery,
  useEditEmailMutation,
  useEditPasswordMutation,
  useEditUserImageMutation
} = fregnirApi