import React from 'react'
import styled from 'styled-components'
import MaterialIcon from './MaterialIcon'

const NotificationBarWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.accent};
`

const IconBox = styled.div`
width: 22px;
height: 22px;
display: flex;
justify-content: center;
align-items: center;
padding: 2px;
background-color: ${({ theme }) => theme.colors.accent};
border-radius: 4px;


`
export default function NotificationBar() {
  return (
    <NotificationBarWrapper>
      <IconBox>
        <MaterialIcon iconName="notifications_filled_active" size={18} color='white' />
      </IconBox>
    </NotificationBarWrapper>
  )
}
