import styled from 'styled-components'
import { RowTitle } from '../global-components/checkbox-table-components//CheckboxTableListItem.styled'
import { device } from '../../global-styles/mediaQueries'
import { PropertyName, PropertyValue } from '../global-components/checkbox-table-components/CheckboxTableListItem.styled'
import { ReportData } from '../../state/query/types/ReportsAndInvoicesTypes'


interface SingleReportProps {
  isOpen: boolean;
  reportData: ReportData;
}


export const DateName = styled(PropertyName)`
  grid-row: 2/3;

  @media ${device.tablet} {
    display: none
  }
`

export const DateValue = styled(PropertyValue)`
  grid-row: 2/3;

  @media ${device.tablet} {
    display: block;
    grid-row: 1/2;
    grid-column: 3/4;
    padding-top: 0;
    align-self: center;
  }
`

export const PeriodName = styled(PropertyName)`
  grid-row: 3/4;

  @media ${device.tablet} {
    grid-row: 2/3;
  }
  @media ${device.tabletL} {
   display: none;
  }
`

export const PeriodValue = styled(PropertyValue)`
  grid-row: 3/4;

  @media ${device.tablet} {
    grid-row: 2/3;
    grid-column: 3/5;
    align-self: center;
  }
  @media ${device.tabletL} {
    display: block;
    grid-row: 1/2;
    grid-column: 5/6;
    padding-top: 0;
  }
`


export default function SingleReport({ isOpen, reportData }: SingleReportProps) {

  return (
    <>
      <p style={{ display: 'none' }}>isOpen: {isOpen}</p>
      <RowTitle href={reportData.download_url} target="_black" rel="noreferrer">{reportData.title}</RowTitle>
      <DateName isOpen={isOpen}>Date:</DateName>
      <DateValue isOpen={isOpen}>{new Date(reportData.created_at).toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' })}</DateValue>
      <PeriodName isOpen={isOpen}>Period:</PeriodName>
      <PeriodValue isOpen={isOpen}>{reportData.period_text}</PeriodValue>
    </>
  )
}
