import AES from 'crypto-js/aes'
import Utf8 from 'crypto-js/enc-utf8'
import moment from 'moment'


import defaultImagePlaceholder from './assets/images/image_placeholder.png'

import { EndpointDefinitions } from '@reduxjs/toolkit/dist/query'
import { QueryState } from '@reduxjs/toolkit/dist/query/core/apiState'


export function validateEmail(email: string): boolean {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9_-]+\.)+[a-zA-Z0-9_-]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

type OpacityType = 0.05 | 0.1 | 0.2 | 0.3 | 0.4 | 0.5 | 0.6 | 0.7 | 0.8 | 0.9

//Generate hex with opacity
export function addOpacityToHex(hex: string, opacity: OpacityType) {

  switch (opacity) {
    case 0.05:
      return `${hex}0D`
    case 0.1:
      return `${hex}1A`
    case 0.2:
      return `${hex}33`
    case 0.3:
      return `${hex}4D`
    case 0.4:
      return `${hex}66`
    case 0.5:
      return `${hex}80`
    case 0.6:
      return `${hex}99`
    case 0.7:
      return `${hex}B3`
    case 0.8:
      return `${hex}CC`
    case 0.9:
      return `${hex}E6`

  }
}

export function formatThousandNumbers(value: number): number | string {
  return Math.abs(value) > 999 ? (value / 1000).toFixed(1) + 'k' : value
}

//Allow user to insert only letters & numbers
export const handleInputSanitize = (e: React.ChangeEvent<HTMLInputElement>, setInputValue: (e: any) => void) => {
  const regex = RegExp(/^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ÐðđĐÞþÆæ# '-]+$/u)

  if (regex.test(e.target.value)) {
    setInputValue(e.target.value)
  } else {
    if (e.target.value === '') setInputValue(e.target.value)
  }

}


export function validURL(str: string) {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

//If we add load more option in the future we can use this
export function loadMore<T>(url: string, requestOptions: RequestInit, callback: (data: T[]) => void) {
  return fetch(url, requestOptions as RequestInit)
    .then(response => response.json())
    .then(res => callback(res.data))
}

/**
 * Encripts string
 */
export const makeHash = (token: string, secret: string) => AES.encrypt(token, secret).toString()


/**
 * Decripts string encripted with makeHash function
 */
export const decrypt = (hashedString: string, secret: string) => {
  const bytes = AES.decrypt(hashedString, secret)
  let originalText = ""
  try {
    originalText = bytes.toString(Utf8)
  } catch (e) {
    console.error("Error on token decrypt")
  }
  return originalText
}

/**
 * Capitalize first letter in a string
 * 
 * @param str 
 * @returns 
 */
export const capitalizeFirstLetter = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1)


export function calculatedDurationFromCreationTime(date: string) {

  const today = new Date();

  const currentDay = parseInt(String(today.getDate()).padStart(2, '0'));
  const currentMonth = parseInt(String(today.getMonth() + 1).padStart(2, '0')); //January is 0!
  const currentYear = today.getFullYear();
  const todayHours = today.getHours();
  const incommingDateAndDate = date.replace(' ', 'T');

  let incommingDateAndTime = incommingDateAndDate.split('T')
  let incomingDate: string | string[] = incommingDateAndTime[0];
  incomingDate = incomingDate.split('-')
  const incomingDay = parseInt(incomingDate[2])
  const incominMonth = parseInt(incomingDate[1])
  const incominYear = parseInt(incomingDate[0])


  let incomingTime: string | string[] = incommingDateAndTime[1];

  incomingTime = incomingTime.split(':')
  const incomingHour = parseInt(incomingTime[0]);
  const incomingMin = incomingTime[1];


  const daysBeforeToday = currentDay - incomingDay

  if (currentYear > incominYear || currentMonth > incominMonth) {
    return convertDateToIcelandic(incommingDateAndDate, "MMDDYY");

  } else if (daysBeforeToday > 1) {
    return convertDateToIcelandic(incommingDateAndDate, "MMDDYY");

  } else if (daysBeforeToday === 1) {
    return `Yesterday at: ${incomingHour}:${incomingMin}`;

  } else {
    const x = todayHours - incomingHour;
    return `${x} hours ago`;
  }
}

/**
 * Detect whether or not user is on iOS device
 */
export function isIOS() {
  var iosQuirkPresent = function () {
    var audio = new Audio();

    audio.volume = 0.5;
    return audio.volume === 1;   // volume cannot be changed from "1" on iOS 12 and below
  };

  var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  var isAppleDevice = navigator.userAgent.includes('Macintosh');
  var isTouchScreen = navigator.maxTouchPoints >= 1;   // true for iOS 13 (and hopefully beyond)

  return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
}


/**
 * Wrapper functon that adds timeout handling for fetch api
 */
export const fetchWithTimeout = (fetchFunc: (data?: any) => any, timeout: number, timeoutMessage?: string, data?: any) => {
  return Promise.race([
    data ? fetchFunc(data) : fetchFunc(),
    new Promise((_, reject) => setTimeout(() => reject(new Error(`${timeoutMessage ? timeoutMessage : 'network request timeout'}`)), timeout))
  ]);
}


// Handling image if there is an error and showing default image placeholder
export const handleOnErrorImage = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
  e.currentTarget.src = defaultImagePlaceholder
}


/**
 * Trims input of all unnecessary whitespaces.
 */
export const trimInput = (input: string) => input.replace(/\s\s+/g, ' ').trimStart().replace(". ", " ")


//Just named iceladic days
export const icelandicDays = {
  monday: "mánudagur",
  tuesday: "þriðjudagur",
  wednesda: "miðvikudagur",
  thursday: "fimmtudagur",
  friday: "föstudagur",
  saturday: "laugardagur",
  sunday: "sunnudagur"
};
export const icelandicDaysShort = ["Mán", "Þri", "Mið", "Fim", "Fös", "Lau", "Sun"];
export const monthNames = ['Janúar', 'Febrúar', 'Mars', 'April', 'Maí', 'Júní', 'July', 'Ágúst', 'September', 'Október', 'Nóvember', 'Desember'];
export const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'Maí', 'Jún', 'Jul', 'Ágú', 'Sep', 'Okt', 'Nóv', 'Des'];
export const icelandicDaysArr = ['mánudagur', 'þriðjudagur', 'miðvikudagur', 'fimmtudagur', 'föstudagur', 'laugardagur', 'sunnudagur'];

export function getTodaysDay() {
  return new Date().getDay();
};

export function getCurrMonth(date: Date) {
  return date.getMonth();
};

export function calculateDayBeforeXDays(xDays: number) {
  return new Date(Date.now() - xDays * 24 * 60 * 60 * 1000);
};
export function convertDateToIcelandic(postDate: string | Date, format?: string) {
  if (format === void 0) {
    format = 'DDMMYY';
  }

  var date = new Date(postDate),
    dateMonth = getCurrMonth(date),
    dateDay = date.getDate(),
    dateYear = date.getFullYear();

  switch (format) {
    case 'DDMMYY':
      return dateDay + " " + monthNames[dateMonth] + ", " + dateYear;

    case 'MMDDYY':
      return monthNames[dateMonth] + " " + dateDay + ", " + dateYear;

    case 'MMYY':
      return monthNames[dateMonth] + ", " + dateYear;

    case 'DDMM':
      return dateDay + ", " + monthNames[dateMonth];

    default:
      return dateDay + " " + monthNames[dateMonth] + ", " + dateYear;
  }
};

export function isEmpty<T>(value: T): boolean {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length === 0;
  }

  if (typeof value === 'object') {
    if (value instanceof Date) {
      return false;
    }
    if (value instanceof Map || value instanceof Set) {
      return value.size === 0;
    }
    return Object.keys(value).length === 0;
  }

  if (typeof value === 'symbol') {
    return value.toString().length === 0
  }

  return false;
}
/**
 * Checks if cache record for a certain endoint exists.
 */
export const isCacheRecordExisting = (cachedData: QueryState<EndpointDefinitions>, endpointName: string): boolean => {
  return Object.values(cachedData).some(query => query?.endpointName === endpointName);
}


/**
 *  Prepared titles for the modals label
 */
export const prepareTitleExcerpt = (title: string): string => {
  let preparedTitle: string;

  if (title.length < 32) {
    preparedTitle = title;
  } else {
    preparedTitle = title.substring(0, 29).trim()
    const lastChar = preparedTitle.substring(preparedTitle.length - 1)
    if (lastChar === '.' || lastChar === ',') {
      preparedTitle = preparedTitle.substring(0, preparedTitle.length - 1)
    }
    preparedTitle += '...'
  }

  return preparedTitle;
}



/**
 * Limits characters in a text for a given value.
 */
export const limitCharacters = (text: string, limit: number): string => {

  if (typeof text !== 'string' || typeof limit !== 'number' || limit < 0) {
    console.error('Invalid input');
    return ""
  }

  if (text.length > limit) {
    return limit > 3
      ? text.slice(0, limit - 1).trim() + '…'
      : text.slice(0, limit).trim();
  }

  return text
}

// Calculate the difference in minutes between a given date and the current time.
export const differenceInMinutesFromNow = (date: string | Date) => {
  return moment().diff(moment(date), 'minutes');
}
/**
 * Calculates the difference in hours between two Date objects.
 *
 * @param {Date} currentDate - The current date.
 * @param {Date} publishedDate - The published date.
 * @returns {number} - The difference in hours.
 */
export const getHoursDifference = (currentDate: Date, publishedDate: Date): number => {
  const differenceInMilliseconds = currentDate.getTime() - publishedDate.getTime();
  return Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
}
/**
 * Determines the maximum index of a data point to consider based on the number 
 * of hours that have passed since publishing.
 *
 * @param {number} hours - The number of hours that have passed.
 * @returns {number} - The data point index.
 */
export const getDataPointIndex = (hours: number): number => {
  switch (true) {
    case hours >= 168:
      return 6;
    case hours >= 24:
      return 5;
    case hours >= 5:
      return 4;
    case hours >= 4:
      return 3;
    case hours >= 3:
      return 2;
    case hours >= 2:
      return 1;
    default:
      return 0;
  }
}


/**
 * Converts array of strings to csv.
 * e.g. ["test@gmail.com", "test2@gmail.com", "test3@gmail.com"] => "test@gmail.com, test2@gmail.com, test3@gmail.com"
 */
export const convertArrayToCsv = (array: string[]): string => array.join(', ');