import React from 'react'
import { ThemeContext } from 'styled-components'

const risingTriangle = (color: string) => <svg viewBox="0 0 10 10" width="10" xmlns="http://www.w3.org/2000/svg">
  <polygon points="4,2 0,6 8,6" fill={color} />
</svg>

const fallingTriangle = (color: string) => <svg viewBox="0 0 10 10" width="10" xmlns="http://www.w3.org/2000/svg">
  <polygon points="0,0 8,0 4,4" fill={color} />
</svg>

export default function Rank({ isRising }: { isRising: number }) {

  const themeContext = React.useContext(ThemeContext)

  //Show svg triangle if news is rising/falling
  function showIsRising(isRising: number) {
    if (Object.is(isRising, 1)) {
      return risingTriangle(themeContext.colors.green)
    } else if (Object.is(isRising, 0)) {
      return
    } else if (Object.is(isRising, -1)) {
      return fallingTriangle(themeContext.colors.redAlert)
    }
  }
  return (
    <>
      {showIsRising(isRising)}
    </>
  )
}
