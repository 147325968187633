import React from 'react'

//Import material icons svg as React Component
import { ReactComponent as ArrowDropdown } from '../../assets/icons/material/arrow_drop_down.svg'
import { ReactComponent as AcUnit } from '../../assets/icons/material/ac_unit_black_18dp.svg'
import { ReactComponent as ExpandMore } from '../../assets/icons/material/expand_more.svg'
import { ReactComponent as FilterList } from '../../assets/icons/material/filter_list.svg'
import { ReactComponent as MoreVert } from '../../assets/icons/material/more_vert.svg'
import { ReactComponent as Dashboard } from '../../assets/icons/material/dashboard_black_18dp.svg'
import { ReactComponent as TextSnippet } from '../../assets/icons/material/text_snippet_black_18dp.svg'
import { ReactComponent as Settings } from '../../assets/icons/material/settings_black_18dp.svg'
import { ReactComponent as BarChart } from '../../assets/icons/material/bar_chart_black_18dp.svg'
import { ReactComponent as Grade } from '../../assets/icons/material/grade_black_18dp.svg'
import { ReactComponent as Search } from '../../assets/icons/material/search_black_18dp.svg'
import { ReactComponent as Notifications } from '../../assets/icons/material/notifications_black_18dp.svg'
import { ReactComponent as NotificationsActive } from '../../assets/icons/material/notifications_active_black_18dp.svg'
import { ReactComponent as NotificationAdd } from '../../assets/icons/material/notification_add_black_18dp.svg'
import { ReactComponent as NotificationsOff } from '../../assets/icons/material/notifications_off_black_24dp.svg'
import { ReactComponent as Article } from '../../assets/icons/material/article_black_18dp.svg'
import { ReactComponent as Calendar } from '../../assets/icons/material/calendar_today_black_18dp.svg'
import { ReactComponent as Print } from '../../assets/icons/material/print_black_18dp.svg'
import { ReactComponent as FileDownload } from '../../assets/icons/material/file_download_black_18dp.svg'
import { ReactComponent as BubbleChart } from '../../assets/icons/material/bubble_chart_black_18dp.svg'
import { ReactComponent as ShowChart } from '../../assets/icons/material/show_chart_black_18dp.svg'
import { ReactComponent as ArrowBack } from '../../assets/icons/material/arrow_back_black_18dp.svg'
import { ReactComponent as ArrowUp } from '../../assets/icons/material/arrow_upward_black_18dp.svg'
import { ReactComponent as NorthEast } from '../../assets/icons/material/north_east_black_18dp.svg'
import { ReactComponent as SouthEast } from '../../assets/icons/material/south_east_black_18dp.svg'
import { ReactComponent as PeopleAlt } from '../../assets/icons/material/people_alt_black_18dp.svg'
import { ReactComponent as LocalFire } from '../../assets/icons/material/local_fire_department_black_18dp.svg'
import { ReactComponent as Whatshot } from '../../assets/icons/material/whatshot_black_18dp.svg'
import { ReactComponent as WhatshotFilled } from '../../assets/icons/material/whatshot_black_filled_18dp.svg'
import { ReactComponent as Public } from '../../assets/icons/material/public_black_18dp.svg'
import { ReactComponent as ArrowDropUp } from '../../assets/icons/material/arrow_drop_up_black_18dp.svg'
import { ReactComponent as Close } from '../../assets/icons/material/close_black_18dp.svg'
import { ReactComponent as Add } from '../../assets/icons/material/add_black_18dp.svg'
import { ReactComponent as Remove } from '../../assets/icons/material/remove_black_18dp.svg'
import { ReactComponent as Brightness } from '../../assets/icons/material/brightness_1_black_18dp.svg'
import { ReactComponent as ChevronLeft } from '../../assets/icons/material/chevron_left_black_18dp.svg'
import { ReactComponent as ChevronRight } from '../../assets/icons/material/chevron_right_black_18dp.svg'
import { ReactComponent as ExpandLess } from '../../assets/icons/material/expand_less_black_18dp.svg'
import { ReactComponent as ThumbUp } from '../../assets/icons/material/thumb_up_black_18dp.svg'
import { ReactComponent as InsertComment } from '../../assets/icons/material/insert_comment_black_18dp.svg'
import { ReactComponent as Share } from '../../assets/icons/material/share_black_18dp.svg'
import { ReactComponent as Timer } from '../../assets/icons/material/timer_black_18dp.svg'
import { ReactComponent as Bookmark } from '../../assets/icons/material/bookmark_border_black_18dp.svg'
import { ReactComponent as DateRange } from '../../assets/icons/material/date_range_black_18dp.svg'
import { ReactComponent as InsertChart } from '../../assets/icons/material/insert_chart_black_18dp.svg'
import { ReactComponent as Done } from '../../assets/icons/material/done_black_18dp.svg'
import { ReactComponent as Delete } from '../../assets/icons/material/delete_black_18dp.svg'
import { ReactComponent as CompareArrow } from '../../assets/icons/material/compare_arrows_black_18dp.svg'
import { ReactComponent as Feed } from '../../assets/icons/material/feed_black_18dp.svg'
import { ReactComponent as Link } from '../../assets/icons/material/link_black_18dp.svg'
import { ReactComponent as AddLink } from '../../assets/icons/material/add_link_black_18dp.svg'
import { ReactComponent as Launch } from '../../assets/icons/material/launch_black_18dp.svg'
import { ReactComponent as Refresh } from '../../assets/icons/material/refresh_black_18dp.svg'
import { ReactComponent as ModeEdit } from '../../assets/icons/material/mode_edit_black_18dp.svg'
import { ReactComponent as Visibility } from '../../assets/icons/material/visibility_black_18dp.svg'
import { ReactComponent as VisibilityOff } from '../../assets/icons/material/visibility_off_black_18dp.svg'
import { ReactComponent as RSSFeed } from '../../assets/icons/material/rss_feed_black_18dp.svg'
import { ReactComponent as ContentCopy } from '../../assets/icons/material/content_copy_black_18dp.svg'
import { ReactComponent as ManageAccounts } from '../../assets/icons/material/manage_accounts_black_18dp.svg'
import { ReactComponent as ReceiptLong } from '../../assets/icons/material/receipt_long_black_18dp.svg'
import { ReactComponent as Logout } from '../../assets/icons/material/logout_black_18dp.svg'
import { ReactComponent as Payment } from '../../assets/icons/material/payment_black_18dp.svg'
import { ReactComponent as AddCircle } from '../../assets/icons/material/add_circle_outline_black_18dp.svg'
import { ReactComponent as RemoveCircle } from '../../assets/icons/material/remove_circle_outline_black_18dp.svg'
import { ReactComponent as Cancel } from '../../assets/icons/material/cancel_black_18dp.svg'
import { ReactComponent as Block } from '../../assets/icons/material/block_black_18dp.svg'
import { ReactComponent as CheckCircle } from '../../assets/icons/material/check_circle_black_18dp.svg'
import { ReactComponent as DropDownCircle } from '../../assets/icons/material/arrow_drop_down_circle_black_18dp.svg'
import { ReactComponent as AccontCircle } from '../../assets/icons/material/account_circle_black_18dp.svg'
import { ReactComponent as QueryBuilder } from '../../assets/icons/material/query_builder_black_18dp.svg'
import { ReactComponent as HelpOutline } from '../../assets/icons/material/help_outline_black_18dp.svg'
import { ReactComponent as LocalPost } from '../../assets/icons/material/local_post_office_black_18dp.svg'
import { ReactComponent as Lock } from '../../assets/icons/material/lock_black_18dp.svg'
import { ReactComponent as NotificationsFilled } from '../../assets/icons/material/notifications_filled_black_18dp.svg'
import { ReactComponent as NotificationsActiveFilled } from '../../assets/icons/material/notifications_filled_active_black_18dp.svg'
import { ReactComponent as CheckCircleFilled } from '../../assets/icons/material/check_circle_filled_black_18dp.svg'
import { ReactComponent as Error } from '../../assets/icons/material/error_filled_black_18dp.svg'
import { ReactComponent as Info } from '../../assets/icons/material/info_filled_black_18dp.svg'
import { ReactComponent as Facebook } from '../../assets/icons/material/facebook_filled_black_18dp.svg'
import { ReactComponent as Warning } from '../../assets/icons/material/warning_filled_black_18dp.svg'
import { ReactComponent as Groups } from '../../assets/icons/material/groups_black_18dp.svg'
import { ReactComponent as Weak } from '../../assets/icons/material/weak.svg'
import { ReactComponent as Hot } from '../../assets/icons/material/hot.svg'
import { ReactComponent as VeryHot } from '../../assets/icons/material/very-hot.svg'
import { ReactComponent as EventAvailable } from '../../assets/icons/material/event_available_black_18dp.svg'
import { ReactComponent as History } from '../../assets/icons/material/history_black_18dp.svg'

export type IconName =
  'ac_unit' | 'arrow_drop_down' | 'expand_more' | 'filter_list' | 'more_vert' | 'dashboard' | 'text_snippet' | 'settings' | 'bar_chart' | 'grade' | 'search' |
  'notifications' | 'notifications_active' | 'notification_add' | 'notifications_off' | 'more_vert' | 'article' | 'article' | 'print' | 'file_download' | 'bubble_chart' |
  'show_chart' | 'arrow_back' | 'arrow_up' | 'north_east' | 'south_east' | 'people_alt' | 'local_fire' | 'whatshot' | 'whatshot_filled' | 'public' | 'arrow_drop_up' | 'calendar_today' |
  'close' | 'add' | 'remove' | 'brightness' | 'chevron_left' | 'chevron_right' | 'expand_less' | 'thumb_up' | 'insert_comment' | 'share' |
  'timer' | 'bookmark_border' | 'date_range' | 'insert_chart' | 'done' | 'delete' | 'compare_arrows' | 'feed' | 'link' | 'add_link' | 'launch' |
  'refresh' | 'mode_edit' | 'visibility' | 'visibility_off' | 'rss_feed' | 'content_copy' | 'manage_accounts' | 'receipt_long' | 'logout' | 'payment' |
  'add_circle' | 'remove_circle' | 'cancel' | 'block' | 'check_circle' | 'drop_down_circle' | 'account_circle' | 'query_builder' | 'help_outline' | 'local_post_office' |
  'lock' | 'notifications_filled' | 'notifications_filled_active' | 'check_circle_filled' | 'error_filled' | 'info_filled' | 'facebook_filled' | 'warning_filled' | 'groups'
  | 'weak' | 'hot' | 'very_hot' | 'event_available' | 'history'


interface MaterialIconProps {
  iconName: IconName;
  color?: string;
  size?: 14 | 18 | 24 | 36 | 48;
  additionalClassName?: string;
}

export default function MaterialIcon({ iconName, color, size = 18, additionalClassName = "" }: MaterialIconProps) {
  return (
    <>
      {((name) => {
        switch (name) {
          case 'ac_unit':
            return <AcUnit fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'arrow_drop_down':
            return <ArrowDropdown fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'expand_more':
            return <ExpandMore fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'filter_list':
            return <FilterList fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'more_vert':
            return <MoreVert fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'dashboard':
            return <Dashboard fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'text_snippet':
            return <TextSnippet fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'settings':
            return <Settings fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'bar_chart':
            return <BarChart fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'grade':
            return <Grade fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'search':
            return <Search fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'notifications':
            return <Notifications fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'notifications_active':
            return <NotificationsActive fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'notification_add':
            return <NotificationAdd fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'notifications_off':
            return <NotificationsOff fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'article':
            return <Article fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'calendar_today':
            return <Calendar fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'print':
            return <Print fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'file_download':
            return <FileDownload fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'bubble_chart':
            return <BubbleChart fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'show_chart':
            return <ShowChart fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'arrow_back':
            return <ArrowBack fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'arrow_up':
            return <ArrowUp fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'north_east':
            return <NorthEast fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'south_east':
            return <SouthEast fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'people_alt':
            return <PeopleAlt fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'local_fire':
            return <LocalFire fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'whatshot':
            return <Whatshot fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'whatshot_filled':
            return <WhatshotFilled fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'public':
            return <Public fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'arrow_drop_up':
            return <ArrowDropUp fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'close':
            return <Close fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'add':
            return <Add fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'remove':
            return <Remove fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'brightness':
            return <Brightness fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'chevron_left':
            return <ChevronLeft fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'chevron_right':
            return <ChevronRight fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'expand_less':
            return <ExpandLess fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'thumb_up':
            return <ThumbUp fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'insert_comment':
            return <InsertComment fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'share':
            return <Share fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'timer':
            return <Timer fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'bookmark_border':
            return <Bookmark fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'date_range':
            return <DateRange fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'insert_chart':
            return <InsertChart fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'done':
            return <Done fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'delete':
            return <Delete fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'compare_arrows':
            return <CompareArrow fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'feed':
            return <Feed fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'link':
            return <Link fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'add_link':
            return <AddLink fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'launch':
            return <Launch fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'refresh':
            return <Refresh fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'mode_edit':
            return <ModeEdit fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'visibility':
            return <Visibility fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'visibility_off':
            return <VisibilityOff fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'rss_feed':
            return <RSSFeed fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'content_copy':
            return <ContentCopy fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'manage_accounts':
            return <ManageAccounts fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'receipt_long':
            return <ReceiptLong fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'logout':
            return <Logout fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'payment':
            return <Payment fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'add_circle':
            return <AddCircle fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'remove_circle':
            return <RemoveCircle fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'cancel':
            return <Cancel fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'block':
            return <Block fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'check_circle':
            return <CheckCircle fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'drop_down_circle':
            return <DropDownCircle fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'account_circle':
            return <AccontCircle fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'query_builder':
            return <QueryBuilder fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'help_outline':
            return <HelpOutline fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'local_post_office':
            return <LocalPost fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'lock':
            return <Lock fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'notifications_filled':
            return <NotificationsFilled fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'notifications_filled_active':
            return <NotificationsActiveFilled fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'check_circle_filled':
            return <CheckCircleFilled fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'error_filled':
            return <Error fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'info_filled':
            return <Info fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'facebook_filled':
            return <Facebook fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'warning_filled':
            return <Warning fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'groups':
            return <Groups fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'weak':
            return <Weak fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'hot':
            return <Hot fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'very_hot':
            return <VeryHot fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'event_available':
            return <EventAvailable fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
          case 'history':
            return <History fill={color} height={size} width={size} className={`material-icon ${additionalClassName}`} />
        }
      })(iconName)}
    </>
  )
}
