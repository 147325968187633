import React, { useState, useRef } from 'react'
import { SubtermWrapper, ErrorTag, SingleTag, TransparentTextInput, CancelIconWrapper } from './SubTermInput.styled'
import MaterialIcon from '../global-components/MaterialIcon'
import { v4 as uuidv4 } from 'uuid'
import { ErrorMessage } from '../global-components/CustomInput.styled'
import { ThemeContext } from 'styled-components';
import { validateEmail } from '../../utils'
import OutsideClickWrapper from '../global-components/OutsideClickWrapper'


/**
 * Iz nekog razloga mi nije dao da prosledim e pa da iskoristim code. 
 * Posto mi je vracao da code ne postoji u event objektu.
 * Pa sam morao da njihov KeyboardEvent interface nadogradim sa ovim kako bi prosao. 
 * Nisam skontao kako ovo drugacije da odradim. 
 * Ostavljam ti ovde comment cisto ako se tebi isto zadesi da znas kako sam ja resio. 
 * Obrisacemo ovo kasnije 
 */
interface EventCodeExtended extends React.KeyboardEvent<HTMLInputElement> {
  code: string;
}

export type InputStyle = 'dark' | 'light';
export type SubTermInputType = 'email' | 'text'
export type TagStyle = 'primary' | 'secondary' | 'secondary-dark' | 'error';


export interface SubTermInputInterface {
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>
  inputName: string;
  inputStyle: InputStyle;
  tagStyle?: TagStyle;
  isError?: boolean;
  isDisabled?: boolean;
  customErrorMessage?: string;
  placeholder?: string;
}

/**
 * Component where you can add terms & tags.
 * One div wrapper which holds input field & array of tags.
 * On submit imput state array will be fiiled up with strings.
 * Strings will be shown as SingleTag component.
 * 
 * @param tags destructured value from useState, should be an array 
 * @param setTags destructured callback from useState ( setState callback ) 
 * @param inputName Need for autocomplete
 * @param tagStyle Define bg & color of single tag
 * @param inputStyle Define bg color of whole element 
 * @param isDisabled disable whole button and change style 
 * 
*/
export default function SubTermInput({
  tags,
  setTags,
  inputName,
  tagStyle = 'primary',
  inputStyle = 'dark',
  isDisabled = false,
  isError,
  customErrorMessage = 'Invalid email format',
  placeholder = 'Add item' }: SubTermInputInterface) {

  const [inputValue, setInputValue] = useState('')
  const inputField = useRef<HTMLInputElement>(null)
  const themeContext = React.useContext(ThemeContext)

  function setTagsStat(e: EventCodeExtended) {
    const value = (e.target as HTMLTextAreaElement).value
    if (value.trim().length !== 0 && (e.code === 'Space' || e.code === 'Enter' || e.code === 'Tab')) {
      // preventDeafult is used here, to prevent adding space when entering space, or tab to jump to next element.
      e.preventDefault()

      if (!tags.includes(value.trim())) {
        setTags((tags: string[]): string[] => [...tags, value.trim()]);
        setInputValue('');
      }
    }
    else {
      if (e.code === 'Backspace' && tags.length > 0 && value.length === 0) {
        // Creating temporary shallow copy of tags, and poping last on backspace key.
        const tempTags: string[] = [...tags]
        tempTags.pop()
        setTags(tempTags)
      }
    }
  }

  const removeTag = (dropIndex: number) => setTags((tags: string[]) => tags.filter((_: string, index: number) => index !== dropIndex))


  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value.includes(',')) {
      if (value.indexOf(',') > 0) {
        setTags([...tags, value.slice(0, value.length - 1)])
        setInputValue('')
      } else {
        setInputValue(value)
      }
    } else {
      setInputValue(value)
    }
  }

  const handleOutsideClick = (value: string) => {
    if (value.trim().length !== 0) {
      setTags((tags: string[]): string[] => [...tags, value.trim()]);
    }
    setInputValue('')
  }

  return (
    <div style={{ position: 'relative' }}>
      <SubtermWrapper
        tabIndex={0}
        inputStyle={inputStyle}
        tagStyle={tagStyle}
        isError={isError}
        isDisabled={isDisabled}
        onClick={() => {
          inputField.current && inputField.current.focus()
        }}
      >
        {/* Sinle tags in component */}
        {tags.length !== 0 && tags.map((tag: string, index: number) =>
          !validateEmail(tag)
            ? <ErrorTag key={uuidv4()} tagStyle={'error'}>
              {tag}
              <CancelIconWrapper onClick={() => removeTag(index)}>
                <MaterialIcon size={14} iconName='close' color='#fff' />
              </CancelIconWrapper>
            </ErrorTag>
            :
            <SingleTag key={uuidv4()} tagStyle={tagStyle} isDisabled={isDisabled}>
              {tag}
              <CancelIconWrapper onClick={() => removeTag(index)}>
                <MaterialIcon size={14} iconName='close' color={tagStyle === 'primary' ? themeContext.colors.white : themeContext.colors.primary} />
              </CancelIconWrapper>
            </SingleTag>)
        }
        {/* Input field */}
        <OutsideClickWrapper handlerFunc={() => handleOutsideClick(inputValue)}>
          <TransparentTextInput
            autoComplete="on"
            ref={inputField}
            placeholder={placeholder}
            onChange={handleOnChange}
            value={inputValue}
            onKeyDown={(e: EventCodeExtended): void => setTagsStat(e)}
            name={inputName}
          />
        </OutsideClickWrapper>
      </SubtermWrapper >
      {
        tags.length !== 0 && tags.map((tag: string) =>
          !validateEmail(tag) &&
          <ErrorMessage key={uuidv4()}>
            <MaterialIcon iconName="error_filled" color={themeContext.colors.redAlert} />
            {customErrorMessage}
          </ErrorMessage>
        )}
      {
        tags.length === 0 && isError &&
        <ErrorMessage>
          <MaterialIcon iconName="error_filled" color={themeContext.colors.redAlert} />
          {"Please provide at least one email address"}
        </ErrorMessage>
      }
    </div>
  )
}