import React, { KeyboardEvent } from 'react'
import MaterialIcon from '../global-components/MaterialIcon'
import { useAppDispatch } from '../../state/hooks/hooks'
import { ButtonWrapper, SentimentButton } from './SentimentButtons.styled'
import { ThemeContext } from 'styled-components';
import { showPopup } from '../../state/slices/popupSlice'
import { useChangeSentimentMutation } from '../../state/query/queryApi'
import { LatestNewsFetchParams } from '../../state/query/types/LatestNewsTypes'



interface SentimentProps {
  sentiment: null | -1 | 0 | 1;
  newsId: number;
  newsKey: number;
  searchParams?: LatestNewsFetchParams
}

export default function SentimentButtons({ sentiment, newsKey, searchParams }: SentimentProps) {


  const dispatch = useAppDispatch();
  const theme = React.useContext(ThemeContext);
  const [changeSentimentTrigger] = useChangeSentimentMutation()


  const handleClick = (num: -1 | 0 | 1) => () => {
    changeSentimentTrigger({
      newsKey: newsKey,
      params: searchParams,
      sentiment: {
        sentiment: num !== sentiment ? num : null
      }
    }).unwrap().catch(() => dispatch(showPopup({
      event: 'error',
      type: 'alert',
      direction: 'bottom',
      props: {
        popupTitle: `Changing sentiment failed. Check internet connection and try again.`
      }
    })))
  }

  const handleOnKeyDown = (num: -1 | 0 | 1) => (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      handleClick(num)();
    }
  }


  return (
    <ButtonWrapper>
      <SentimentButton type="negative" selected={sentiment} onClick={handleClick(-1)} onKeyDown={handleOnKeyDown(-1)} tabIndex={0}>
        <MaterialIcon iconName="remove" color={theme.colors.secondary} size={14} />
      </SentimentButton>
      <SentimentButton type="neutral" selected={sentiment} onClick={handleClick(0)} onKeyDown={handleOnKeyDown(0)} tabIndex={0}>
        <MaterialIcon iconName="brightness" color={theme.colors.secondary} size={14} />
      </SentimentButton>
      <SentimentButton type="positive" selected={sentiment} onClick={handleClick(1)} onKeyDown={handleOnKeyDown(1)} tabIndex={0}>
        <MaterialIcon iconName="add" color={theme.colors.secondary} size={14} />
      </SentimentButton>
    </ButtonWrapper>
  )
}
