import React, { useState } from 'react'
import { ThemeContext } from 'styled-components';
import { Link } from "react-router-dom";
import moment from 'moment'

import SimpleWeekChart, { NoDataChart } from './SimpleWeekChart'
import SingleNewsTags from './SingleNewsTags'
import MaterialIcon from '../global-components/MaterialIcon'
import SingleNewsDropdown from './SingleNewsDropdown'
import SentimentButtons from './SentimentButtons'
import SingleNewsSocialMediaRank from './SingleNewsSocialMediaRank'
import GeneralTooltipCaller from '../global-components/tooltips/GeneralTooltipCaller'

import { nanoid } from '@reduxjs/toolkit'
import { useAppDispatch } from '../../state/hooks/hooks'
import { openModal } from '../../state/slices/modalsSlice'
import { LatestNewsFetchParams, SocialEngagement, Rank, LatestNewsSearchRange } from '../../state/query/types/LatestNewsTypes'

import { handleOnErrorImage, differenceInMinutesFromNow } from '../../utils'

import {
  SingleNewsWrapper,
  DropdownArrow,
  Info,
  TextWrapper,
  Tags,
  Sentiment,
  Mentions,
  MentionsIcon,
  Engagements,
  MediaStats,
  LableText,
  StatsWrapper,
  SingleStat,
  StatIcon,
  SingleStatInfo,
  SingleStatInfoTitle,
  NotificationIcon,
  NewsSourceIconWrapper,
  EngagementChartWrapper,
  SingleStatInfoWrapper
} from './SingleNews.styled'


export interface SingleNewsProps {
  full_url: string;
  media_logo: string;
  title: string;
  full_text: string;
  alert_status: number;
  tags_custom: string[];
  tags_company?: string[];
  mentions: string[];
  id: number;
  newsKey: number;
  alert_id: number | null;
  created_at: string;
  sentiment: null | -1 | 0 | 1;
  total_engagement: number;
  social_engagement: SocialEngagement[];
  rank_number: number;
  rank: Rank;
  rank_trend: number;
  searchParams: LatestNewsFetchParams;
  full_text_raw: string;
  seconds_in_top_ten: number;
  article_distribution: null | number;
  top_ten_rank: number;
  time_in_top_ten: number;
  published_date: string;
}


const SingleNews = ({
  full_url,
  media_logo,
  title,
  full_text,
  alert_status,
  tags_custom,
  tags_company,
  mentions,
  id,
  newsKey,
  alert_id,
  sentiment,
  total_engagement,
  social_engagement,
  searchParams,
  rank,
  published_date
}: SingleNewsProps) => {

  const dispatch = useAppDispatch()
  const themeContext = React.useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState(false)

  const isNotificationSet = alert_id !== null

  const isPublishedInLastHour = differenceInMinutesFromNow(published_date) <= 60;

  const toggleAccordion = () => setIsOpen(isOpen => !isOpen)

  const formateDate = (date: string): string => {
    const d = moment(date)
    const now = moment()
    const today = now.clone().startOf('day');
    const yesterday = now.clone().subtract(1, 'days').startOf('day');

    if (d.isSame(today, 'd')) {
      return d.fromNow()
    } else if (d.isSame(yesterday, 'd')) {
      return `Yesterday at ${d.format("HH:mm")}`
    } else {
      return d.format("Do MMM YYYY, HH:mm")
    }
  }

  const handleDeleteNews = () => {
    dispatch({
      type: openModal.type,
      payload: {
        name: 'deleteArticle',
        props: {
          id: id,
          newsKey: newsKey,
          articleTitle: title,
          modalType: 'latestNews',
          modalContent: "Once you delete this article, it will no longer be visible in the latest news feed",
          searchParams
        }
      }
    })
  }

  const handleGetAlerts = () => {
    dispatch(openModal({
      name: 'latestNewsCoverage',
      props: {
        id: id,
        newsKey: newsKey,
        newsTitle: title,
        newsURL: full_url,
        newsType: 'existing',
        searchParams: {
          filter: searchParams?.filter as 'newest' | 'oldest' | 'engagement',
          search_keyword: searchParams?.search_keyword as string,
          search_range: searchParams?.search_range as LatestNewsSearchRange,
          page: searchParams?.page
        }
      }
    }))
  }

  const handleStopAlerts = () => {
    dispatch({
      type: openModal.type,
      payload: {
        name: 'stopAlertsModal',
        props: {
          alert_id,
          newsKey,
          params: {
            filter: searchParams?.filter as 'newest' | 'oldest' | 'engagement',
            search_keyword: searchParams?.search_keyword as string,
            search_range: searchParams?.search_range as LatestNewsSearchRange,
            page: searchParams?.page
          },
          labelTitle: title
        }
      }
    })
  }

  return (
    <SingleNewsWrapper
      isOpen={isOpen}
      onClick={(e) => { if (e.target === e.currentTarget && window.innerWidth >= 1140) toggleAccordion() }}
    >
      {isNotificationSet && (
        <NotificationIcon>
          <MaterialIcon iconName="notifications_filled_active" color={themeContext.colors.white} />
        </NotificationIcon>
      )}
      <DropdownArrow
        isOpen={isOpen}
        onClick={toggleAccordion}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.keyCode === 13) {
            toggleAccordion()
          }
        }}
        className="dropdown-arrow"
        tabIndex={0}
        role="button"
      >
        <MaterialIcon iconName="expand_more" color={themeContext.colors.accent} />
      </DropdownArrow>

      <SingleNewsDropdown
        newsId={id}
        notification={isNotificationSet}
        handleDeleteNews={handleDeleteNews}
        handleStopAlerts={handleStopAlerts}
        handleGetAlerts={handleGetAlerts}
      />

      <Info>
        <NewsSourceIconWrapper>
          {
            <img src={media_logo} alt="news logo" onError={handleOnErrorImage} />
          }
        </NewsSourceIconWrapper>
        <span>{formateDate(published_date)}</span>
      </Info>

      <TextWrapper>
        <Link to={{
          pathname: `/dashboard/news/${newsKey}`,
          state: {
            searchParams,
          }
        }}><h3>{title}</h3></Link>
        <p>{full_text.substring(0, 200)}...</p>
      </TextWrapper>

      <Tags isOpen={isOpen}>
        <LableText>Tags:</LableText>
        <SingleNewsTags tags={tags_custom} tags_company={tags_company} newsId={id} newsKey={newsKey} searchParams={searchParams} />
      </Tags>

      <Sentiment isOpen={isOpen}>
        <LableText>Sentiment:</LableText>
        <SentimentButtons sentiment={sentiment} newsId={id} newsKey={newsKey} searchParams={searchParams} />
      </Sentiment>

      <Mentions isOpen={isOpen}>
        <MentionsIcon><MaterialIcon iconName="people_alt" color={themeContext.colors.accent} /></MentionsIcon>
        <LableText>Mentions:</LableText>
        {mentions.length ? <div>{mentions.map((tag, index) => <span key={nanoid()}>{tag}{index !== mentions.length - 1 ? ", " : ''}</span>)}</div> : "--"}
      </Mentions>

      {social_engagement.length > 0 && (
        <Engagements isOpen={isOpen}>
          <LableText>Engagement:</LableText>
          {isPublishedInLastHour ? <NoDataChart /> : <EngagementChartWrapper><SimpleWeekChart data={social_engagement} published={published_date} /></EngagementChartWrapper>}
        </Engagements>
      )}

      <MediaStats isOpen={isOpen}>
        <LableText>Social media stats:</LableText>
        <StatsWrapper>
          <SingleStat>
            <StatIcon color={themeContext.colors.green}><MaterialIcon iconName="groups" color={themeContext.colors.green} /></StatIcon>
            <SingleStatInfo>
              <SingleStatInfoTitle>Total Engagement</SingleStatInfoTitle>
              <div>
                <span className='total_engagement'>{total_engagement}</span>
                {/* engagement high does not exist as a prop currently*/}
                {/* check if it is gona be returned to single news object and delete this comments */}
                {/* {engagement_high && <EngagementHigh />} */}
              </div>
            </SingleStatInfo>
          </SingleStat>
          <SingleStat>
            <StatIcon color={themeContext.colors.accent}><MaterialIcon iconName="bar_chart" color={themeContext.colors.accent} /></StatIcon>
            <SingleStatInfo>
              <SingleStatInfoWrapper>
                <SingleStatInfoTitle>Social Media Rank</SingleStatInfoTitle>
                <GeneralTooltipCaller name="socMediaRank" />
              </SingleStatInfoWrapper>
              <SingleNewsSocialMediaRank rankStats={rank} publishedDate={published_date} />
            </SingleStatInfo>
          </SingleStat>
        </StatsWrapper>
      </MediaStats>
    </SingleNewsWrapper>
  )
}

export default React.memo(SingleNews);