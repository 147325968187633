import React, { useState, useContext } from 'react' 
import { ThemeContext } from 'styled-components'

// Components
import SearchTermsSubTermBox from './SearchTermsSubTermBox'
import MaterialIcon from '../global-components/MaterialIcon'

// Styles
import { SubTermsBoxSingleResult, SearchTermsQuoteTitle, SubTermsSubTitle, SubTermsSuccessMessage } from './SearchTerms.styled'

type SubTermsProps = {
  id: number;
  term: string;
  subterms: string[];
}

export default function SubTermsBox({ term, subterms, id }: SubTermsProps) {

  const [showSavedMessage, setShowSavedMessage] = useState<boolean>(false)

  const themeContext = useContext(ThemeContext)

  return (
    <SubTermsBoxSingleResult>
      {showSavedMessage && <SubTermsSuccessMessage>
          <MaterialIcon iconName="check_circle_filled" color={themeContext.colors.green} size={18} />
          <p>Saved</p>
        </SubTermsSuccessMessage>
      }
      <SearchTermsQuoteTitle hasMarginBottom={true}>{term}</SearchTermsQuoteTitle> 
      <SubTermsSubTitle>Sub-Terms</SubTermsSubTitle>
      
        <SearchTermsSubTermBox 
          id={id}
          inputName={term}
          inputStyle="dark"
          tagStyle="primary"
          type="text"
          tags={subterms}
          isDisabled={false}
          placeholder={"+ Add sub-term…"}
          setShowSavedMessage={setShowSavedMessage}
        />
    </SubTermsBoxSingleResult>
  )
}
