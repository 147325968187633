import styled from 'styled-components'

export const NewsIconWrapp = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
`
export const TooltipWrapper = styled.div`
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
`
export const NewsImageFromArray = styled.img<{ zIndex?: number }>`
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid white;
  z-index: ${({ zIndex }) => zIndex};
  &:first-child  {
      margin-left: 0px;
  }
  &:hover {
    z-index: 200;
    & ~ ${TooltipWrapper} {
      opacity: 1;
    }
  }
`
export const SingleNewsIcon = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  margin-left: -6px;
`