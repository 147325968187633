import React from 'react'
import DateRangeSelect from '../global-components/DateRangeSelect'
import { DateRange } from './LatestNewsTableWrapper'
import MaterialIcon from '../global-components/MaterialIcon'
import { ThemeContext } from 'styled-components';

import { InfoWrapper, TermsAndRangeWrapper, SearchTerm, ClearAllButton } from './LatestNewsSearchInfo.styled'

export interface LatestNewsSearchInfoProps {
  searchTerms: string;
  optionName: string;
  handleDateRangeOption: (option: DateRange, optionName?: string) => void,
  handleSearchFilterReset: () => void;
  clearSearchTerms: () => void;
}

const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }
  return text;
}

export default function LatestNewsSearchInfo({
  searchTerms,
  optionName,
  handleDateRangeOption,
  handleSearchFilterReset,
  clearSearchTerms
}: LatestNewsSearchInfoProps) {
  const themeContext = React.useContext(ThemeContext)

  if (searchTerms || optionName !== '') {
    return (
      <InfoWrapper>
        <>
          <TermsAndRangeWrapper>
            {searchTerms && (

              <SearchTerm>
                <p>{truncateText(searchTerms, 26)}</p>
                <button onClick={() => clearSearchTerms()}>
                  <MaterialIcon size={14} iconName='close' color={themeContext.colors.primary} />
                </button>
              </SearchTerm>)}
            <DateRangeSelect
              handleSelect={handleDateRangeOption}
              optionName={optionName}
              type={'date-range-filter'}
              location="latest-news-search-info"
              dropdownPosition="left"
            />
          </TermsAndRangeWrapper>
          <ClearAllButton onClick={() => handleSearchFilterReset()} tabIndex={0}>
            <MaterialIcon size={18} iconName='close' color={themeContext.colors.secondary} />
            <span>Clear all</span>
          </ClearAllButton>
        </>
      </InfoWrapper>
    )
  }
  return null;

}
