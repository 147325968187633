import styled, { css } from 'styled-components'
import { device } from "../../global-styles/mediaQueries";
import { addOpacityToHex } from '../../utils';
import { globalFocusStyles } from '../../global-styles/globalStyles';

export const NewsSearchSpinnerWrapper = styled.div`
  width: 100%;
  padding: 40px;
  background: ${({ theme }) => theme.colors.white};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`
export const NewsSearchWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 16px;
  border-radius: 6px;
  @media ${device.mobileL} {
    padding: 20px;
  }
  @media ${device.tablet} {
    padding: 24px;
  }
`

export const NewsSearchFormWrapper = styled.div`
 width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  border-radius: 4px;
  button {
    max-height: 48px;
  }
  @media ${device.tablet} {
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .expiration-wrapper {
      width: 48%;
    }
    button:first-of-type {
      width: 48%;
      margin-top: 25px;
    }
  }
  @media ${device.tabletL} {
    button:first-of-type {
      width: 107px;
    }
  }
`

export const InputLabel = styled.label<{ isDisabled: boolean }>`
  margin-bottom: 10px;
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${({ theme, isDisabled }) => isDisabled ? theme.colors.primary_700 : theme.colors.primary};
`

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  margin-bottom: 24px;
  
  @media ${device.tablet} {
    width: 100%;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
  @media ${device.tabletL} {
    width: 100%;
    &:first-of-type {
      flex-grow: 2;
      width: 519px;
      margin-right: 16px;
      margin-bottom: 0;
    }
    &:last-of-type {
      width: 270px;
      margin-right: 16px;
    }
  }
  @media ${device.desktopS} {
    &:first-of-type {
      width: 400px;
    }
  }
`

export const FlexRowWrapper = styled.div`
  display: flex;
  flex-flow: column;
  @media ${device.mobileL} {
    flex-flow: row;
    justify-content: space-between;
    &:first-of-type {
        width: 50%;
      }
      &:last-child {
        width: 35%;
      }
    ${InputWrapper} {
      &:first-of-type {
        width: 100%;
      }
      &:last-of-type {
        min-width: 168px;
        max-width: 168px;
        margin-left: 20px;
          div {
            min-width: 168px //In comopnent min width is set to 214
        }
      }
    }
  }
`

export const SaveSearchExpand = styled.div<{ isDisabled: boolean }>`
  display: flex;
  align-items: center;
  width: fit-content;
  margin-top: 24px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;
  outline: none;
  p {
    margin-left: 3px;
  }
  // Custom focus styles
  ${({ isDisabled }) => !isDisabled && css`
    ${globalFocusStyles}
  `}

  ${({ isDisabled }) => isDisabled && css`
    pointer-events: none;
    color: ${({ theme }) => addOpacityToHex(theme.colors.secondary, 0.5)};
    svg {
      fill: ${({ theme }) => addOpacityToHex(theme.colors.secondary, 0.5)};
    }
  `}
`

export const SavedSearchWrapper = styled.div`
  width: 100%;
`

export const SaveSearchBox = styled.div`
  padding: 16px;
  margin-top: 20px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.highlight};
  @media ${device.mobileL} {
    padding: 20px;
  }
  @media ${device.tabletL} {
    ${InputWrapper} {
      width: 100%;
    }
  }
  @media ${device.tablet} {
    padding: 24px;
  }

`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  @media ${device.tablet} {
    margin-top: 16px;
  }
`

export const ExplanationText = styled.div`
  display: none;
  @media ${device.tablet} {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: ${({ theme }) => theme.colors.secondary};
  }
  svg {
    margin-right: 6px;
  }
`

export const Buttons = styled.div`
  display: flex;
  width: fit-content;
  flex-grow: 0;
  button {
    &:first-of-type {
      width : auto;
    }
  }
`