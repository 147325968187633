import React, { useState, useEffect } from 'react'

import LineInput from '../global-components/LineInput'

// Styles
import { TextWrapper, Subtitle } from './NotificationsSelections.styled'
import { NotificationsMailWrapper, EmailListWrapper, AddEmailButton } from './NotificationsMailList.styled'

// Redux
import { useEditSettingsNotificationsEmailsMutation } from '../../state/query/queryApi'
import { useAppDispatch } from '../../state/hooks/hooks'
import { showPopup } from '../../state/slices/popupSlice'

// utils
import { showErrorOrSuccessPopup } from '../../utils/showPopups'


type Props = {
  data: string[] | null;
}

export default function NotificationsMailList({ data }: Props) {

  const [emailList, setEmailList] = useState<string[]>(data !== null ? data : [])
  const [currentEditFiledIndex, setCurrentEditFieldIndex] = useState<number | null>(null);

  const [emailExistError, setEmailExistError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const [editSettingsNotificationsEmails, { isLoading }] = useEditSettingsNotificationsEmailsMutation()

  const dispatch = useAppDispatch()


  async function onSaveChanges(emailList: string[]) {

    const response = await editSettingsNotificationsEmails({
      emails: emailList
    })

    if (response.hasOwnProperty('error')) {
      dispatch(showPopup({
        event: 'error',
        type: 'additionalInfo',
        direction: 'bottom',
        props: {
          popupTitle: 'Error during upload!',
          popupText: 'A network error occurred. Please try again later.',
          additionalComponentName: 'SimpleTextInfo'
        }
      }))
      setEmailList(data ? data : [])
      typeof currentEditFiledIndex === 'number' && setCurrentEditFieldIndex(null)
    }
    else {
      showErrorOrSuccessPopup(dispatch, 'Email list updated', 'bottom', 'success', 'alert')
    }
  }


  const onDelete = (value: string) => {

    // Removing selected email from emailList
    const filteredEmailList = emailList.filter(email => email !== value)

    // Need to remove empty string from list, so UI will update and not trigger onSaveChanges.
    if (value !== "") {
      onSaveChanges(filteredEmailList)
    }
    else {
      setEmailList(filteredEmailList)
    }
  }


  const onSave = (value: string, previousValue: string) => {
    // Checking if updated email value already exists
    if (!emailList.includes(value)) {
      // Making a shallow copy of the array.
      let tempEmailList: string[] = [...emailList];

      // Finding index of email that's being updated.
      const emailIndex: number = tempEmailList.indexOf(previousValue);

      // Putting it back in temporary list
      tempEmailList[emailIndex] = value

      onSaveChanges(tempEmailList);
      setEmailList(tempEmailList)
    }
    else {
      if (previousValue !== value) {
        setEmailExistError(true)
        setErrorMessage('Email already exist')
      }
    }
  }


  const addNewLineInput = (index: number) => {
    // Checking if we already have one new field added to the list.
    if (!emailList.includes("")) {
      setEmailList([...emailList, ""])
      setCurrentEditFieldIndex(index)
    }
  }


  useEffect(() => {
    // If arrays are compared like emailList === data, will return false, so this is the only way without looping through both arrays.
    if (JSON.stringify(emailList) !== JSON.stringify(data)) {
      setEmailList(data !== null ? data : [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])


  useEffect(() => {
    if (!emailExistError) {
      setErrorMessage('Please insert valid email')
    }
  }, [emailExistError])


  return (
    <NotificationsMailWrapper>
      <TextWrapper>
        <h5>Default Emails</h5>
        <Subtitle>Set default emails when creating social alerts campaign</Subtitle>
      </TextWrapper>

      <div>
        <h5>Include these emails:</h5>

        <EmailListWrapper>
          {
            emailList && emailList.map((email: string, index: number) => {

              const isNewInput: boolean = email === "" ? true : false;

              return (
                <LineInput
                  value={email}
                  placeholder={'Type new email...'}
                  errorMessage={errorMessage}
                  onDeleteAction={value => onDelete(value)}
                  onSaveAction={(value, previousValue) => onSave(value, previousValue)}
                  key={email}
                  isActiveOnMount={isNewInput}
                  hasGlobalEditMode={true}
                  currentEditFiledIndex={currentEditFiledIndex}
                  setCurrentEditFieldIndex={setCurrentEditFieldIndex}
                  index={index}
                  isLoading={isLoading}
                  hasAdditionalError={emailExistError}
                  setHasAdditionalError={setEmailExistError}
                />
              )
            })
          }
          <AddEmailButton
            // button is disabled when there is no new or currently active fields, or deleting is in progress
            isDisabled={emailList.includes("") || currentEditFiledIndex !== null}
            tabIndex={0}
            onClick={() => {
              currentEditFiledIndex === null && addNewLineInput(emailList.length)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.keyCode === 13) {
                currentEditFiledIndex === null && addNewLineInput(emailList.length)
              }
            }}
          >
            Add email
          </AddEmailButton>
        </EmailListWrapper>
      </div>
    </NotificationsMailWrapper>
  )
}
