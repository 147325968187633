import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { trimInput } from '../../utils';
import MaterialIcon from '../global-components/MaterialIcon'

import { InputWrapper, ErrorMessage, StyledInput, LockIcon, PasswordVisibleToggle } from './UserOrPasswordInput.styled'


export interface UserOrPasswordInputProps {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  option: 'username' | 'password';
  inputType: string;
  showError: boolean;
  errorMessage: string;
  hasLockIcon?: boolean;
  visible?: boolean;
  inEditableField?: boolean;
  toggleVisibility?: () => void;
  name?: string
  keyDownHandler?: () => void;
  autocomplete?: string
}

export default function UserOrPasswordInput({
  value,
  placeholder,
  onChange,
  option,
  inputType,
  showError,
  errorMessage,
  hasLockIcon,
  visible,
  inEditableField,
  toggleVisibility,
  name,
  keyDownHandler,
  autocomplete
}: UserOrPasswordInputProps) {

  const { colors } = useContext(ThemeContext)

  return (
    <InputWrapper>
      <StyledInput
        value={value}
        placeholder={placeholder}
        type={inputType}
        onChange={(e) => onChange(trimInput((e.target as HTMLInputElement).value))}
        showError={showError}
        inEditableField={inEditableField}
        hasLockIcon={hasLockIcon}
        name={name}
        autoComplete={autocomplete}
        onKeyDown={(e) => {
          if (keyDownHandler && e.key === 'Enter') {
            keyDownHandler()
          }
        }}
      />
      {option === 'password' && hasLockIcon && <LockIcon><MaterialIcon iconName="lock" color={colors.secondary} /></LockIcon>}
      {option === 'password' && (
        <PasswordVisibleToggle onClick={toggleVisibility}>
          <MaterialIcon iconName={visible ? "visibility" : "visibility_off"} color={colors.secondary} />
        </PasswordVisibleToggle>
      )}
      {showError &&
        <ErrorMessage>
          <MaterialIcon iconName="error_filled" color={colors.redAlert} />
          {errorMessage}
        </ErrorMessage>}
    </InputWrapper>
  )
}
