import React from 'react'
import CustomDropdownList from '../../global-components/CustomDropdownList'
import { IconName } from '../../global-components/MaterialIcon'
import styled from 'styled-components'
import { device } from '../../../global-styles/mediaQueries'

interface DropdownListOptionsTypes {
  text: string;
  icon: IconName;
  onClick: () => void
}

interface CheckedListItemDropdownProps {
  handleDelete?: () => void;
  handleDownload?: () => void;
  openCloseSideeffect?: (open: boolean) => void;
}


export const StyledWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 16px;

  @media ${device.tablet} {
    top: 20px;
    right: 24px;
  }
`


export default function CheckedListItemDropdown({
  handleDelete,
  handleDownload,
  openCloseSideeffect
}: CheckedListItemDropdownProps) {


  const deleteOption: DropdownListOptionsTypes = {
    text: "Delete",
    icon: "delete",
    onClick: function () {
      handleDelete && handleDelete()
    }
  }

  // Temporarily removed.
  // const downloadOption: DropdownListOptionsTypes = {
  //   text: "Download",
  //   icon: "file_download",
  //   onClick: function () {
  //     handleDownload && handleDownload()
  //   }
  // }

  return (
    <StyledWrapper>
      <CustomDropdownList
        // options={[deleteOption, downloadOption]}
        options={[deleteOption]}
        dropdownPosition="right"
        icon="more_vert"
        openCloseSideeffect={openCloseSideeffect}
      />
    </StyledWrapper>
  )
}
