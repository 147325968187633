import { ReactElement, useEffect, useState } from 'react'

//Components
import CustomInput from '../global-components/CustomInput'
import UserOrPasswordInput from '../login/UserOrPasswordInput'
import MaterialIcon from '../global-components/MaterialIcon';
import { CancelButton } from '../global-components/ModalButton';
import Spinner from '../global-components/ButtonSpinner'

//Redux
import { useEditEmailMutation } from '../../state/query/queryApi';
import { useAppSelector } from '../../state/hooks/hooks';
import { showPopup } from '../../state/slices/popupSlice';
import { showErrorOrSuccessPopup } from '../../utils/showPopups';
import { editEmail } from '../../state/slices/userSlice';
import { cleanValuesInEditMode } from '../../state/slices/appState';
import { AppDispatch } from '../../state/store';

//Styles
import { AccountColumnWrapper } from './Account.styled'
import { ButtonWrapper } from '../modals/DeleteArticle.styled'
import { EditButton } from './Account.styled'
import { SectionTitle, SettingsSectionWrapper } from './Settings.styled'
import { InputWrapper, InputLabel } from '../modals//NewsCoverageModal.styled'
import { SettingsConfirmButton } from '../settings/Settings.styled'

//Utils
import { validateEmail } from '../../utils';



export interface ResponseUserDataOnEmailChange {
  data: {
    email: string;
  }
  error: {
    data: {
      message: string
    }
  }
}

interface SetUsernameProps {
  setChangesInEditModeGlobalState: (startFieldValue: string, fieldValue: string, fieldName: string) => void;
  dispatch: AppDispatch;
}


export default function SetUsername({ setChangesInEditModeGlobalState, dispatch }: SetUsernameProps): ReactElement {

  const [editUsername, { isLoading }] = useEditEmailMutation()

  const globalEmail = useAppSelector(state => state.user.contactData.email)

  const [isSectionDisabled, setIsSectionDisabled] = useState<boolean>(true)
  const [email, setEmail] = useState<string>(globalEmail)
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isInvalidEmailFormat, setIsInvalidEmailFormat] = useState<boolean>(false)
  const [isPasswordIncorrect, setIsPasswordIncorrect] = useState<boolean>(false)
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);


  function onSectionActiveMode() {
    setIsSectionDisabled(false)
  }

  /**
   * Handles state updates on change for confirm password for email update.
   */
  function handleOnchangeConfirmPassword(value: string) {
    setConfirmPassword(value)
    setChangesInEditModeGlobalState('', value, "Password")
    isPasswordIncorrect && setIsPasswordIncorrect(false)
  }

  /**
   * resets error states when when clicked on cancel button
   */
  function resetErrorSatesOnCancelAction() {
    isPasswordIncorrect && setIsPasswordIncorrect(false)
  }


  function onCancelHandler() {
    setEmail(globalEmail)
    setConfirmPassword('')
    setIsSectionDisabled(true)
    setIsInvalidEmailFormat(false)
    dispatch(cleanValuesInEditMode(["Password", "UsernameEmail"]))
    resetErrorSatesOnCancelAction()
  }


  async function onSaveUsernameHandler() {

    if (validateEmail(email)) {

      const response = await editUsername({
        email: email,
        password: confirmPassword
      })

      if (response.hasOwnProperty('error')) {

        // checking if error has a messega in reponse for incorrect password
        if ((response as ResponseUserDataOnEmailChange).error?.data?.message) {
          setIsPasswordIncorrect(true)
        }
        else {
          dispatch(showPopup({
            event: 'error',
            type: 'additionalInfo',
            direction: 'bottom',
            props: {
              popupTitle: 'The server can’t be reached',
              popupText: 'Please try again later',
              additionalComponentName: 'SimpleTextInfo'
            }
          }))
        }
      }
      else {
        // updating new email to global state and session storage
        showErrorOrSuccessPopup(dispatch, 'Username saved', 'bottom', 'success', 'alert')
        dispatch(editEmail(email))
        localStorage.setItem("email", email)

        // reseting component states
        setIsInvalidEmailFormat(false)
        setIsSectionDisabled(true)
        dispatch(cleanValuesInEditMode(["Password", "UsernameEmail"]))
        setConfirmPassword('')
      }
    }
    else {
      setIsInvalidEmailFormat(true)
    }
  }


  useEffect(() => {
    // removing error for invalid email on change
    if (validateEmail(email)) {
      isInvalidEmailFormat && setIsInvalidEmailFormat(false)
    }

    /**
     * Reverting back to global username if section is not in edit mode. 
     * This is needed for password managers that are overwriting value of username, while section is not editable.
     */
    if (email !== globalEmail && isSectionDisabled) {
      setEmail(globalEmail)
      dispatch(cleanValuesInEditMode(["UsernameEmail"]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email])



  return (
    <SettingsSectionWrapper isInEditMode={!isSectionDisabled}>
      <SectionTitle>Username</SectionTitle>
      <EditButton
        isGrey={false}
        onClick={onSectionActiveMode}
      >
        {isSectionDisabled
          ? 'Edit'
          : <MaterialIcon iconName="mode_edit" />
        }
      </EditButton>
      <AccountColumnWrapper>
        <InputWrapper>
          <CustomInput
            label="Email"
            inputVal={email}
            setInputVal={setEmail}
            isGray={false}
            placeholder={email}
            isDisabled={isSectionDisabled}
            name="UsernameEmail"
            hasCancelIcon={true}
            hasEditMode={true}
            isError={isInvalidEmailFormat}
            customErrorMessage={"Invalid email format"}
          />
        </InputWrapper>
        {
          !isSectionDisabled &&
          <>
            <InputWrapper>
              <InputLabel>
                Confirm Password
              </InputLabel>
              <UserOrPasswordInput
                value={confirmPassword}
                inputType={isPasswordVisible ? "text" : "password"}
                onChange={(value) => handleOnchangeConfirmPassword(value)}
                option="password"
                showError={isPasswordIncorrect}
                errorMessage={"Password is incorrect"}
                visible={isPasswordVisible}
                name="Password"
                toggleVisibility={() => setIsPasswordVisible(visible => !visible)}
                inEditableField={true}
              />
            </InputWrapper>

            <ButtonWrapper>

              <CancelButton
                onClick={() => {
                  onCancelHandler()
                  setIsPasswordVisible(false)
                }}
              >Cancel
              </CancelButton>

              <SettingsConfirmButton
                disabledPointerOnLoad={isLoading || (globalEmail === email || confirmPassword === "")}
                isDisabled={(globalEmail === email || confirmPassword === "") && !isLoading}
                width={"77px"}
                bgColorActiveState="blue"
                onClick={() => {
                  onSaveUsernameHandler()
                  setIsPasswordVisible(false)
                }}
              >
                {isLoading
                  ? <Spinner size={18} />
                  : "Save"
                }
              </SettingsConfirmButton>

            </ButtonWrapper>
          </>
        }
      </AccountColumnWrapper>
    </SettingsSectionWrapper>
  )
}
