import { resetUserState } from '../state/slices/userSlice';

/**
 * Remove specified keys from local storage.
 *
 * @param {string[]} keys - An array of keys to remove from local storage.
 */
export function removeLocalStorageItems(keys: string[]) {
  keys.forEach(key => {
    localStorage.removeItem(key);
  });
}

/**
 * Log out the user by making an API request. This function will reject if 
 * the request takes longer than 10 seconds.
 *
 * @param {string} token - The authentication token for the user.
 * @returns {Promise<void>} A promise that resolves if logout was successful and rejects otherwise.
 */
export const logOut = (token: string): Promise<void> => {

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({})
  };

  const timeoutPromise = new Promise<void>((_, reject) =>
    setTimeout(() => reject(new Error("Request timeout after 10 seconds")), 10000)
  );

  return Promise.race([
    fetch("https://api.fregnir.is/api/logout", requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Logout failed');
        }
      }),
    timeoutPromise
  ]);
}

/**
 * Handle logout process. This involves:
 * 1. Making an API call to log out.
 * 2. Removing specific items from local storage.
 * 3. Dispatching an action to remove the token from the Redux store.
 *
 * @param {string} token - The authentication token for the user.
 * @param {any} dispatch - The dispatch function from Redux's useDispatch.
 */
export const handleLogout = (token: string, dispatch: any) => {
  logOut(token).catch(err => {
    console.error("Error during logout:", err);
  })
  removeLocalStorageItems(['name', 'email', 'image', 'token']);
  dispatch(resetUserState());
}




