import React, {useState, useEffect} from 'react'
import OutsideClickWrapper from '../global-components/OutsideClickWrapper'
import { NavigationWrapper, Container, Header, CloseButton, Body} from './Notifications.styled'
import MaterialIcon from '../global-components/MaterialIcon'


export default function Notifications({ shouldShow, handleClose}: {shouldShow: boolean, handleClose: () => void}) {

  const [hideWrapper, setHideWrapper] = useState(true)

  
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if(shouldShow) {
      setHideWrapper(false)
    } else {
      if(!shouldShow) setTimeout(() => setHideWrapper(true), 300)
    }

    return () => clearTimeout(timeout)
  }, [shouldShow])

  return (
    <NavigationWrapper shouldShow={shouldShow} hideWrapper={hideWrapper}>
      <OutsideClickWrapper handlerFunc={handleClose} enabled={shouldShow}>
        <Container shouldShow={shouldShow} className="notifications">
          <Header>
            <h6>Notifications</h6>
            <CloseButton onClick={handleClose}>
              <MaterialIcon iconName="close" />
            </CloseButton>
          </Header>
          <Body>
            Notifications Sidebar Content Goes Here
          </Body>
        </Container>
      </OutsideClickWrapper>
    </NavigationWrapper>
  )
}
