import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RemoveSocialAlertProp } from '../query/types/TopNewsAndSocialAlertsTypes';
interface Modals {
  name: string;
  props: DeleteModalProps | ExistingNewsCoverage | {};
  open: boolean;
}

interface DeleteModalProps {
  articleTitle: string;
  id: number;
  newsKey: number;
  searchParams: any
}

interface DownloadFileProps {
  fileType: 'excel' | 'csv' | 'pdf';
}

interface ExistingNewsCoverage {
  id?: number;
  newsKey: number;
  newsTitle: string;
  newsURL: string;
  newsType: 'new' | 'existing';
}

interface DateRangePicker {
  location: string
}

export interface UnsavedChangesModalPayload {
  fieldName: string;
  pathTo?: string;
  hiddenField?: string;
  inputRef?: any
}
const initialState = {
  name: '',
  props: {},
  open: false
} as Modals


const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {

    openModal: (state, action: PayloadAction<{ name: string, props?: DeleteModalProps | DownloadFileProps | ExistingNewsCoverage | DateRangePicker | UnsavedChangesModalPayload | RemoveSocialAlertProp }>) => {
      state.name = action.payload.name;
      if (action.payload.props) state.props = action.payload.props;
      state.open = true;
    },
    closeModal: (state) => {
      state.name = '';
      state.props = {};
      state.open = false;
    },
  },
})


export const {
  openModal,
  closeModal
} = modalsSlice.actions

export default modalsSlice.reducer