import styled, { css } from 'styled-components'


export const InputWrapper = styled.div`
  position: relative;
`
export const ErrorMessage = styled.p`
  display: flex;
  align-items: center;
  padding-left: 2px;
  margin-top: 4px;
  color: ${({ theme: { colors } }) => colors.redAlert};
  font-size: 13px;
  line-height: 1.38;
  svg {
    margin-right: 6px;
  }
`;

export const StyledInput = styled.input<{ type: string, showError: boolean, inEditableField?: boolean, hasLockIcon?: boolean }>`
  width: 100%;
  height: 46px;
  padding: ${({ hasLockIcon }) => hasLockIcon ? '0 40px 0 42px' : '0 40px 0 16px'};
  transition: 0.1s;
  border: ${({ theme, showError }) => !showError ? `solid 1px ${theme.colors.borderSecondary}` : `solid 2px ${theme.colors.redAlert}`};
  box-sizing: border-box;
  font-family: 'Arial';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  outline: none;
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme, inEditableField }) => inEditableField ? theme.colors.white : theme.colors.highlight};
  border-radius: 4px;


  ${({ showError, hasLockIcon }) => !showError && css`
    &:hover {
        border: ${({ theme }) => `1px solid ${theme.colors.accent}`};
    }

    &:focus {
      padding: ${hasLockIcon ? '0 39px 0 41px' : '0 39px 0 15px;'};
      background-color: ${({ theme: { colors } }) => colors.white};
      border: ${({ theme }) => `solid 2px ${theme.colors.accent}`};
    }
  `}
`

export const LockIcon = styled.div`
  position: absolute;
  top: 13px;
  left: 14px;
`
export const PasswordVisibleToggle = styled.div`
  position: absolute;
  top: 15px;
  right: 0;
  padding: 0 14px 0 5px;
  cursor: pointer;
`
