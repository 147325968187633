import styled from 'styled-components'
import { device } from '../../global-styles/mediaQueries'
import { globalFocusStyles } from '../../global-styles/globalStyles'

export const AccountGridWrapper = styled.div`
  display: grid;
  grid-template-columns: [image] auto [text] 1fr;
  grid-template-rows: [first] auto [second] auto;
  p {
    font-family: Arial;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: ${({ theme }) => theme.colors.secondary};
  }
  @media ${device.tablet} {
    p {
      max-width: 288px;
    }
  }
  @media ${device.tabletL} {
    p {
      max-width: 440px;
    }
  }
`

export const AccountColumnWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  .grey-text {
    width: 80%;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 1.71;
    color: ${({ theme }) => theme.colors.secondary};
  }
  .mail-link {
    width: fit-content;
    color: ${({ theme }) => theme.colors.accent};
    font-size: 14px;
    text-decoration: none;

    // Custom focus styles
    ${globalFocusStyles}

    &:hover,
    &:active {
      text-decoration: underline;
    }
    &:active {
      color: ${({ theme }) => theme.colors.accentDark};
    }
  }
  @media ${device.tablet} {
    max-width: 360px;
    .grey-text {
      width: 90%;
    }
  }
  @media ${device.tabletL} {
    max-width: 512px;
  }
  @media ${device.tabletL} {
    max-width: 410px;
  }
  @media ${device.desktop} {
    max-width: 512px;
  }
`

export const InfoWrapper = styled.div<{ hasMarginTop?: boolean, hasDifferentPaddingRight?: boolean }>`
  padding-left: 18px;
  h5 {
    margin-bottom: 8px;
  }
  @media ${device.mobileL} {
    margin-top: ${({ hasMarginTop }) => hasMarginTop ? '13px' : '0px'};
  }

  @media ${device.tablet} {
    padding-left: ${({ hasDifferentPaddingRight }) => hasDifferentPaddingRight ? '18px' : '24px'};
  }
`

export const UploadActionButton = styled.button<{ isGrey: boolean }>`
  padding: 2px 0;
  margin-right: 21px;
  margin-bottom: 8px;
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #0284fe;
  text-transform: capitalize;
  color: ${({ isGrey, theme }) => isGrey ? theme.colors.secondary : theme.colors.accent};
  cursor: pointer;

  // Custom focus styles
  ${globalFocusStyles}

  &:active {
    color: ${({ theme }) => theme.colors.accentDark};
  }
`

export const EditButton = styled(UploadActionButton)`
  position: absolute;
  right: 16px;
  top: 16px;
  margin: 0;
  @media ${device.mobileL} {
    right: 24px;
    top: 20px;
  }
  @media ${device.tablet} {
    top: 50px;
    right: 34px;

  }
`

export const ResetPasswordBox = styled.div`
  margin-top: 50px;
  text-align: center;
  p, a {
    font-size: 14px;
    line-height: 1.71;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-decoration: none;

  }
  p {
    color: ${({ theme }) => theme.colors.secondary};
  }
  a {
    color: ${({ theme }) => theme.colors.accent};

    // Custom focus styles
    ${globalFocusStyles}
  }
  
  @media ${device.tablet} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    p {
      margin-right: 5px;
    }
  }
`

export const NewPasswordInfoLabel = styled.span`
  margin-top: 10px;
  font-size: 13px;
  line-height: 1.54;
  color: ${({ theme }) => theme.colors.secondary};
  @media ${device.mobileL} {
    margin-top: 10px;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
`