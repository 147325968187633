import React, { useState, useContext, useEffect } from 'react';
import DateRangeSelect from './DateRangeSelect'
import OutsideClickWrapper from './OutsideClickWrapper'
import MaterialIcon from './MaterialIcon'
import CustomInput from './CustomInput'
import CustomButton from './CustomButton'
import { ThemeContext } from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../state/hooks/hooks'
import { resetRange } from '../../state/slices/dateRangePickerSlice'

import { InputWrapper, InputLabel, ButtonWrapper, CancelButton } from '../modals/NewsCoverageModal.styled'

import {
  AdvancedSearchSelect,
  AdvancedSearchHeader,
  DropdownContainer
} from './AdvancedSearchDropdown.styled'

import { EventCodeExtended } from '../global-components/CustomInput'

export interface DateRange {
  startDate: string | null;
  endDate: string | null;
}

interface AdvancedSearchDropdownProps {
  prevValue: string;
  handleSearchParams: (searchString: string, dateRange: DateRange, optionName: string) => void;
  optionName: string;
  dateRangeOption: DateRange;
}

const AdvancedSearchDropdown = ({ prevValue, handleSearchParams, optionName, dateRangeOption }: AdvancedSearchDropdownProps) => {
  const dispatch = useAppDispatch()
  const { name: modalName } = useAppSelector(state => state.modals)
  const themeContext = useContext(ThemeContext)

  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [dateRange, setDateRange] = useState<DateRange>({ startDate: null, endDate: null })
  const [dateRangeOptionName, setDateRangeOptionName] = useState("");


  const toggling = () => setIsOpen(!isOpen);
  const closeOnClickOutside = () => setIsOpen(false)

  const handleDateRangeOption = (option: DateRange, optionName?: string): void => {
    setDateRange(option)
    if (optionName) setDateRangeOptionName(optionName)
  }

  const handleOnKeyPress = (e: EventCodeExtended) => {
    const event = e.target as HTMLTextAreaElement;

    if (event.value.trim().length !== 0) {
      if (e.code === 'Enter' || e.key === 'Enter') {
        handleSearchParams(inputValue, dateRange, dateRangeOptionName)
        dispatch(resetRange())
        toggling()
      }
    }
  }

  useEffect(() => {
    setInputValue(prevValue)
    if (!optionName) {
      setDateRangeOptionName('Anythime')
    } else {
      setDateRangeOptionName(optionName)
    }
    setDateRange(dateRangeOption)

  }, [prevValue, optionName, dateRangeOption])

  return (
    <AdvancedSearchSelect>
      <OutsideClickWrapper handlerFunc={closeOnClickOutside} enabled={modalName !== 'dateRangePicker'}>
        <AdvancedSearchHeader
          onClick={toggling}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              toggling();
            }
          }}
          isActive={isOpen}
          tabIndex={0}
        >
          <MaterialIcon iconName='search' color={themeContext.colors.accent} />
          <span>Advanced Search</span>
        </AdvancedSearchHeader>
        {isOpen && (
          <DropdownContainer>
            <h4>Advanced Search</h4>
            <InputWrapper>
              <CustomInput
                label="Search"
                inputVal={inputValue}
                setInputVal={setInputValue}
                isGray={true}
                placeholder="Search for articles"
                hasCancelIcon={true}
                name="Search"
                onKeyDown={handleOnKeyPress}
              />
            </InputWrapper>
            <InputWrapper>
              <InputLabel>
                Date Range
              </InputLabel>
              <DateRangeSelect
                handleSelect={handleDateRangeOption}
                disabled={false}
                optionName={optionName}
                usedInModal={false}
                location="advanced-search"
              />
            </InputWrapper>

            <ButtonWrapper>
              <CancelButton onClick={() => toggling()}>Cancel</CancelButton>
              <CustomButton
                size="medium"
                bgColor={themeContext.colors.accent}
                onClick={() => {
                  handleSearchParams(inputValue, dateRange, dateRangeOptionName)
                  dispatch(resetRange())
                  toggling()
                }}
              >
                Apply
              </CustomButton>
            </ButtonWrapper>
          </DropdownContainer>
        )}

      </OutsideClickWrapper>
    </AdvancedSearchSelect>
  );
}

export default AdvancedSearchDropdown;
