import React, { useContext } from 'react'

import { ThemeContext } from 'styled-components'
import MaterialIcon from '../global-components/MaterialIcon'

import { SearchTermsPharagraph, NeedHelpButtonText, SearchTermsButtonLink, NeedHelpWrapper, NeedHelpContentBox } from './SearchTerms.styled'

export default function NeedHelp() {

  const themeContext = useContext(ThemeContext)

  return (
    <NeedHelpWrapper>
      <MaterialIcon iconName="help_outline" color={themeContext.colors.accent} size={24} />
      <NeedHelpContentBox>
        <h5>Need Help?</h5>
        <SearchTermsPharagraph isSmallFontSize={true}>Not sure how this works or maybe not happy with current terms? Don't hesitate to contact our administator if you need help.</SearchTermsPharagraph>
        <SearchTermsButtonLink href="mailto:ratsja@ratsja.is">
          <NeedHelpButtonText>
            Contact Admin
          </NeedHelpButtonText>
        </SearchTermsButtonLink>
      </NeedHelpContentBox>
    </NeedHelpWrapper>
  )
}
