import React from 'react'

//Styled components
import { ThemeContext } from 'styled-components';
import {
  HeatStatusContainer,
  HeatStatusBox,
  HeatSatWrapper,
  HeatIconWrapper,
  AnimatedHeatBar,
  HeatInfoBox,
  HeatStatInfo
} from './HeatStatus.styled'

//Components
import MaterialIcon, { IconName } from '../global-components/MaterialIcon'

//Redux
import { useAppSelector } from '../../state/hooks/hooks'

//Types
import { HeatStatusInterface } from '../../state/query/types/OverviewTypes'

//Utils
import { addOpacityToHex } from '../../utils'

interface HeatDataInterface {
  val: number,
  iconData: {
    name: IconName,
    color: string
  },
  mainText: string;
  subText: string;
}

export default function HeatStatus({ data }: { data: HeatStatusInterface | undefined }) {

  //Heat status info 
  const userName = useAppSelector(state => state.user.username)
  const themeContext = React.useContext(ThemeContext);

  //This is function which will determinate value of stroke-dashoffset in SVG animation 
  function calcHeatStatusAnimationPercentage(value: number): number {
    let startingDashoffsetValue = 400;
    let onePercentOfDashOffset = 2;
    let totalAnimationDashOffsetValue = startingDashoffsetValue + (onePercentOfDashOffset * value)
    return totalAnimationDashOffsetValue
  }

  const prepareIconColor = (value: number): string => value > 60 ? themeContext.colors.redAlert : addOpacityToHex(themeContext.colors.secondary, 0.2)

  //This is created to avoid repeating components
  const heatData = [
    {
      val: data?.today,
      iconData: {
        name: 'local_fire',
        color: data ? prepareIconColor(data?.today) : addOpacityToHex(themeContext.colors.secondary, 0.2)
      },
      mainText: "Today's News Heat",
      subText: userName
    },
    {
      val: data?.overall,
      iconData: {
        name: 'public',
        color: data ? prepareIconColor(data?.overall) : addOpacityToHex(themeContext.colors.secondary, 0.2)
      },
      mainText: "Overall Heat",
      subText: "Iceland news heat"
    }
  ] as HeatDataInterface[]

  return (
    <HeatStatusContainer>
      {heatData.map(heat =>
        <HeatStatusBox key={heat.iconData.name}>

          {/* Icon in left top corner (absolute positioned)  */}
          <HeatIconWrapper>
            <MaterialIcon iconName={heat.iconData.name} color={heat.iconData.color} size={24} />
          </HeatIconWrapper>

          {/* Main heat bar ( animated svg ) with value */}
          <HeatSatWrapper>
            <AnimatedHeatBar heat={heat.val ? calcHeatStatusAnimationPercentage(heat.val) : 0} />
            <HeatInfoBox>
              <p>{heat.val}</p><p>/100</p>
            </HeatInfoBox>
          </HeatSatWrapper>

          {/* Bottom part of component with text */}
          <HeatStatInfo>
            <p>{heat.mainText}</p>
            <p>{heat.subText}</p>
          </HeatStatInfo>

        </HeatStatusBox>
      )}
    </HeatStatusContainer>
  )

}
