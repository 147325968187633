import React, { useState, useEffect } from 'react';
import MaterialIcon from '../global-components/MaterialIcon';
import { InfoWrapper } from './NewArticlesInfo.styled'
import { useAppSelector } from '../../state/hooks/hooks'

/**
 * NewArticlesInfo component fetches the most recent articles via API
 * 
 */
const NewArticlesInfo = ({ setResetFilters }: { setResetFilters: React.Dispatch<React.SetStateAction<boolean>> }): JSX.Element | null => {
  const token = useAppSelector(state => state.user.auth_token)
  const lastPublishedNewsKey = useAppSelector(state => state.latestNews.lastPublishedNewsKey);

  const [articleCount, setArticleCount] = useState(0)


  const handleOnClick = () => {
    setArticleCount(0)
    setResetFilters(true)
  }

  const checkForNew = (newsId: number, token: string): void => {
    if (!!newsId) {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: 'GET',
        headers: myHeaders
      };

      fetch(`https://api.fregnir.is/api/gnews/checkfornew/${newsId}`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error("Please check your internet connection!")
          }
        })
        .then(result => {
          if (result.length) setArticleCount(result.length)
        })
        .catch(error => {
          console.error(error.message)
        })
    }
  }

  useEffect(() => {
    const timer = setInterval(() => checkForNew(lastPublishedNewsKey, token), 60000)
    return () => clearInterval(timer)
  })

  if (articleCount) {
    return (
      <InfoWrapper>
        <MaterialIcon iconName="text_snippet" />
        <p>There {articleCount > 1 ? 'are' : 'is'} <span>{articleCount} new {articleCount > 1 ? 'articles' : 'article'}</span> available</p>
        <button onClick={handleOnClick} tabIndex={0}>Show</button>
      </InfoWrapper>
    );
  }
  return null;
}

export default NewArticlesInfo;
