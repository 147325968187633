import React, { useState, useContext, ReactElement, useEffect } from 'react'
import { ThemeContext } from 'styled-components'

//Styled components 
import { SavedSearchWrapper, SaveSearchExpand, ExplanationText, InputWrapper, SaveSearchBox, ButtonWrapper, Buttons } from './CustomNewsSearchBar.styled'
//Components
import CustomInput from '../global-components/CustomInput'
import { CancelButton } from '../global-components/ModalButton'
import MaterialIcon from '../global-components/MaterialIcon';
import CustomButton from '../global-components/CustomButton'
import Spinner from '../global-components/ButtonSpinner'

//Redux
import { useAppDispatch } from '../../state/hooks/hooks'
import { openModal } from '../../state/slices/modalsSlice'
import { SingleSavedSearch } from '../../state/query/types/SavedSearchTypes'

//Utils
import { showErrorOrSuccessPopup } from '../../utils/showPopups'


interface Props {
  isDisabled: boolean,
  saveNewsSearch: (search_name: string) => Promise<any>
  isLoading: boolean
  NewsSearchInputVal: string
  showSavedSearch: boolean
  setShowSavedSearch: React.Dispatch<React.SetStateAction<boolean>>
  savedSearches: SingleSavedSearch[] | undefined
}

export interface saveSearchResponse {
  data: {
    Message: string
  }
  error: {
    status: string
    error: string
  }
}


export default function SavedSearch({ showSavedSearch, setShowSavedSearch, isDisabled, isLoading, saveNewsSearch, NewsSearchInputVal, savedSearches }: Props): ReactElement {

  const context = useContext(ThemeContext)
  const { colors } = context

  const [saveSearchInputVal, setSaveSearchvedInputVal] = useState(NewsSearchInputVal)
  const [searchAlreadyExists, setSearchAlreadyExists] = useState(false)

  const dispatch = useAppDispatch()

  const doesSearchAlreadyExist = (savedSearches: SingleSavedSearch[] | undefined, searchName: string): boolean => {
    let doesSearchExist = false

    savedSearches?.forEach(search => {
      if (search.search_name === searchName) doesSearchExist = true
    })

    return doesSearchExist
  }

  function handleSavedNewsSubmit() {
    if (!doesSearchAlreadyExist(savedSearches, saveSearchInputVal)) {
      saveNewsSearch(saveSearchInputVal)
        .then((fullfiled: saveSearchResponse) => fullfiled?.hasOwnProperty("data")
          ? handleOpenModal()
          : showErrorOrSuccessPopup(dispatch, fullfiled?.error?.error, 'bottom', 'error', 'additionalInfo'))
        .then(() => {
          setShowSavedSearch(false)
          setSaveSearchvedInputVal('')
        })
        .catch(rejected => showErrorOrSuccessPopup(dispatch, rejected.data.Message, 'bottom', 'error', 'additionalInfo'))
    }
    else {
      setSearchAlreadyExists(true)
    }
  }

  function handleOpenModal() {

    showErrorOrSuccessPopup(dispatch, 'Search is Saved', 'bottom', 'success', 'additionalInfo')

    let shouldOpenModal = localStorage.getItem('saveSearchModal');

    shouldOpenModal !== 'hide' &&
      dispatch({
        type: openModal.type,
        payload: {
          name: 'searchFirstTime',
          props: {
            title: 'Search Is Saved!',
            text: 'To access saved searches, use advanced search in “Search” field',
            checkboxText: 'Don’t show this again',
            buttonText: 'Okay',
            buttonColor: colors.accent,
            iconColor: colors.green
          }

        }
      })
  }


  useEffect(() => {
    setSaveSearchvedInputVal(NewsSearchInputVal)
    //eslint-disable-next-line
  }, [NewsSearchInputVal])


  useEffect(() => {
    searchAlreadyExists && setSearchAlreadyExists(false)
    //eslint-disable-next-line
  }, [saveSearchInputVal])


  return (

    <SavedSearchWrapper>
      <SaveSearchExpand
        onClick={() => setShowSavedSearch(showSavedSearch => !showSavedSearch)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.keyCode === 13) {
            setShowSavedSearch(showSavedSearch => !showSavedSearch)
          }
        }}
        isDisabled={isDisabled}
        tabIndex={0}
      >
        <MaterialIcon iconName="bookmark_border" color={colors.accent} size={18} />
        <p>Save search</p>
        <svg
          style={{ transform: `${showSavedSearch ? 'rotate(180deg)' : 'rotate(0deg)'}` }}
          xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill={colors.accent}>
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M7 10l5 5 5-5H7z" />
        </svg>
      </SaveSearchExpand>

      {
        showSavedSearch && <SaveSearchBox>
          <InputWrapper>
            <CustomInput
              label='Search Name'
              inputVal={saveSearchInputVal}
              setInputVal={setSaveSearchvedInputVal}
              isGray={false}
              placeholder={"Search Name"}
              name="savedsearch"
              focusOnMount={true}
              isError={searchAlreadyExists}
              customErrorMessage='Search name already exists'
            />
          </InputWrapper>

          <ButtonWrapper>
            <ExplanationText>
              <MaterialIcon iconName="info_filled" size={18} color={colors.accent} />
              <p>Access saved searches in the “Search” dropdown tool ( ▾ )</p>
            </ExplanationText>

            <Buttons>
              <CancelButton
                onClick={() => {
                  setShowSavedSearch(false)
                }}
              >
                Cancel
              </CancelButton>

              <CustomButton
                size="medium"
                bgColor={colors.accent}
                isDisabled={saveSearchInputVal.length < 2}
                onClick={handleSavedNewsSubmit}
              >
                {isLoading
                  ? <Spinner />
                  : 'Save'
                }
              </CustomButton>
            </Buttons>
          </ButtonWrapper>
        </SaveSearchBox>
      }

    </SavedSearchWrapper >
  )
}
