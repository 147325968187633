import React, { useRef } from 'react';
import { useOnClickOutside } from '../../hooks/useOutsideClick'

interface WrapperProps {
  children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
  handlerFunc: () => void;
  enabled?: boolean;
  refsToIgnore?: React.RefObject<HTMLElement>[];
  tabIndex?: number;
}

/**
 * OutsideClickWrapper component
 * Allows us to perform action passed as 'handleFunc' if click or touch events occur outside this wrapper component
 */
export default function OutsideClickWrapper({ children, handlerFunc, enabled = true, refsToIgnore, tabIndex }: WrapperProps) {
  const wrapperRef = useRef(null);

  useOnClickOutside(wrapperRef, handlerFunc, enabled, refsToIgnore);

  return <div className="outside-wrapper" tabIndex={tabIndex} ref={wrapperRef} >{children}</div>;
}
