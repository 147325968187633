import React, { useState, useEffect } from 'react';

//Components
import AccountType from './AccountType'
import AccountImage from './AccountImage'
import ContactInfo from './ContactInfo'
import AccountDeactivation from './AccountDeactivation'

import { useAppSelector } from '../../state/hooks/hooks'

export interface ContactDataProps {
  ceo_name: string
  city: string
  invoice_email: string
  name: string
  phone: string
  street: string
}

export interface AccountDataProps {
  account_image_url: string;
  account_type: string
  contactData: ContactDataProps[]
  created_at: string
  email_verified_at: null
  group_id: number
  rss_feed: string
  updated_at: string
  username: string
}


const Account = ({ accountData }: { accountData: AccountDataProps | undefined }) => {

  const hiddenField = useAppSelector(state => state.appState.hiddenField)
  const [isContactInfoDisabled, setIsContactInfoDisabled] = useState(true)
  const [isAccountImageEnabled, setIsAccountImageEnabled] = useState(false)

  useEffect(() => {
    if (hiddenField === 'contactInfo') {
      setIsContactInfoDisabled(true)
      setIsAccountImageEnabled(true)
    }
  }, [hiddenField])

  return (
    <div>
      {accountData &&
        <>
          <AccountType accountType={accountData.account_type} />
          <AccountImage
            isAccountImageEnabled={isAccountImageEnabled}
            setIsAccountImageEnabled={setIsAccountImageEnabled}
            imageUrl={accountData.account_image_url}
          />
          <ContactInfo
            isInputDisabled={isContactInfoDisabled}
            setIsInputDisabled={setIsContactInfoDisabled}
            contactData={accountData.contactData[0]}
          />
          <AccountDeactivation />
        </>
      }
    </div>
  );
}

export default Account;
