import { useEffect, useRef } from 'react';

type Callback = (action: string) => void;

const useStorageListener = (localStorageKey: string, callback: Callback, deps: any[] = []) => {
  const callbackRef = useRef(callback);

  // Updating our ref to keep track of the latest version of the callback
  // in case it changes because of a state or props update
  useEffect(() => {
    callbackRef.current = callback;
    // eslint-disable-next-line 
  }, [callback, ...deps]);

  useEffect(() => {
    const handleStorageChange = () => {
      const value = localStorage.getItem(localStorageKey)

      if (value) {
        callbackRef.current(value);
        localStorage.removeItem(localStorageKey)
      }
    }

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorageKey, ...deps]);
};

export default useStorageListener;
