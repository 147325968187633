import React, { useState } from 'react';

//Components
import TabHeader from './TabHeader'
import SocialMediaTab from './SocialMediaTab'
import MostReadTab from './MostReadTab'
import TodaysCalendar from '../global-components/TodaysCalendar'
import DashboardPageWrapper from './DashboardPageWrapper';

//Styled components
import { PageHeader } from './SocialMediaTab.styled'

/**
 * Top News is a wrapper component containing everything on a dashbord under top news tab.
 * 
 * @returns {JSX}
 */
const TopNews = () => {

  const [isTabActive, setisTabActive] = useState(0)

  return (
    <DashboardPageWrapper title="Top news">
      <PageHeader>
        <h3>Top news</h3>
        <TodaysCalendar />
      </PageHeader>
      <React.StrictMode>
        <TabHeader setisTabActive={setisTabActive} isTabActive={isTabActive} />
        {isTabActive === 0 && <MostReadTab />}
        {isTabActive === 1 && <SocialMediaTab />}
      </React.StrictMode>

    </DashboardPageWrapper>
  );
}

export default TopNews;
