import React, { useState } from 'react'
import CustomCheckbox from '../../global-components/CustomCheckbox'
import MaterialIcon from '../../global-components/MaterialIcon'
import { ThemeContext } from 'styled-components';
import SingleReport from '../../reports/SingleReport'
import SingleInvoice from '../../invoices/SingleInvoice'
import CheckedListItemDropdown from './CheckedListItemDropdown';

import { DropdownArrow } from '../../dashboard/SingleNews.styled'
import { SingleItemWrapper, ArrowAndCheckbowWrapper } from './CheckboxTableListItem.styled'

import { ReportData, InvoiceData } from '../../../state/query/types/ReportsAndInvoicesTypes'

interface CheckboxListItemProps {
  isChecked: boolean;
  handleCheck: (e: React.ChangeEvent<HTMLInputElement>) => void;
  labelText?: string;
  itemData: ReportData | InvoiceData;
  changeParentStyles?: (change: boolean) => void;
  handleDelete: () => void;
  handleDownload: () => void;
  listType: 'report' | 'invoice';
}


const CheckboxListItem = ({ isChecked, handleCheck, handleDelete, handleDownload, labelText, itemData, changeParentStyles, listType }: CheckboxListItemProps) => {
  const themeContext = React.useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState(false)
  const toggleAccordion = () => setIsOpen(isOpen => !isOpen)


  return (
    <SingleItemWrapper type={listType} isOpen={isOpen} isChecked={isChecked}>
      <ArrowAndCheckbowWrapper>
        <DropdownArrow isOpen={isOpen} onClick={toggleAccordion} className="dropdown-arrow">
          <MaterialIcon iconName="expand_more" color={themeContext.colors.accent} />
        </DropdownArrow>
        <CustomCheckbox
          onChange={handleCheck}
          checked={isChecked}
          labelText={labelText}
        />
      </ArrowAndCheckbowWrapper>

      {listType === 'report' ? <SingleReport isOpen={isOpen} reportData={(itemData as ReportData)} /> : <SingleInvoice isOpen={isOpen} invoiceData={(itemData as InvoiceData)} />}

      <CheckedListItemDropdown
        handleDelete={handleDelete}
        handleDownload={handleDownload}
        openCloseSideeffect={changeParentStyles}
      />
    </SingleItemWrapper>
  )
}

export default CheckboxListItem;