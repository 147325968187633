import React, { useRef, useEffect, useState } from 'react'
import CheckboxListItem from './CheckboxListItem'

import { RowWrapper, ListSeparator } from './CheckboxTableListItem.styled'
import { CheckedItems } from './CheckboxTable'
import { ReportData, InvoiceData } from '../../../state/query/types/ReportsAndInvoicesTypes'


interface TableCheckoxListProps {
  data: ReportData[] | InvoiceData[];
  page: number;
  checkedItems: CheckedItems;
  handleCheck: (id: number, page: number, checked: CheckedItems) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  yearSeparator?: boolean;
  listType: 'report' | 'invoice';
  handleDelete: (id: number[], title: string | null) => void
}

interface Separators {
  byYear: {
    [key: number]: string
  },
  firstSeparatorYear: number
}

const ChecboxTableList = ({ data, page, checkedItems, handleCheck, listType, handleDelete }: TableCheckoxListProps) => {

  const [separators, setSeparators] = useState<Separators>()

  const createSeparators = (data: ReportData[] | InvoiceData[]) => {
    const sep = {} as { [key: number]: string }
    let firstYearAdded: number | undefined;

    data.forEach((item: ReportData | InvoiceData) => {
      const date = item.created_at;
      const createdAtYear = new Date(date).getFullYear()

      if (!sep[createdAtYear]) {
        if (!firstYearAdded) firstYearAdded = createdAtYear
        sep[createdAtYear] = date;
      }
    })

    return {
      byYear: sep,
      firstSeparatorYear: firstYearAdded
    } as Separators
  }

  const showListSeparator = (date: string) => {
    const createdAtYear = new Date(date).getFullYear()

    if (separators && Object.values(separators.byYear).includes(date)) {
      if (createdAtYear === separators?.firstSeparatorYear) {
        return false
      }
      return true
    }
    return false
  }


  // eslint-disable-next-line
  const refs = useRef(new Array());

  const changeListItemStackingOrder = (index: number, refsArr: React.MutableRefObject<HTMLDivElement[]>) => (change: boolean): void => {
    if (refsArr.current) {
      if (change) {
        refsArr.current[index].style.zIndex = '3'
      } else {
        refsArr.current[index].style.zIndex = '2'
      }
    }
  }


  useEffect(() => {
    setSeparators(createSeparators(data))
  }, [page, data])

  return (
    <>
      {
        data.slice((page - 1) * 10, page * 10).map((item: ReportData | InvoiceData, index: number) => {

          const isChecked = !!checkedItems[page] && checkedItems[page].indexOf(item.id) !== -1

          return (
            <RowWrapper key={index} ref={el => refs.current[index] = el}>
              {showListSeparator(item.created_at) && <ListSeparator>{new Date(item.created_at).getFullYear()}</ListSeparator>}
              <CheckboxListItem
                handleCheck={handleCheck(item.id, page, checkedItems)}
                isChecked={isChecked}
                key={item.id}
                itemData={item}
                changeParentStyles={changeListItemStackingOrder(index, refs)}
                handleDelete={() => handleDelete([item.id], (listType === 'report' ? (item as ReportData).title : (item as InvoiceData).invoice_id))}
                handleDownload={() => console.log("Download item with id: ", item.id)}
                listType={listType}
              />
            </RowWrapper>
          )
        })

      }
    </>
  )

}


export default ChecboxTableList