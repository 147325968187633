import styled, { css } from 'styled-components'
import { device } from '../../global-styles/mediaQueries'
import { globalFocusStyles } from '../../global-styles/globalStyles';

export const FormSelectContainer = styled.div<{ type?: 'expiration' | 'date-range' | 'date-range-filter' }>`
  position: relative;
  display: inline-block;
  width: ${({ type }) => type === 'date-range-filter' ? 'auto' : '100%'};
  margin: ${({ type }) => type === 'date-range-filter' ? '8px 8px 8px 0' : '0px'};
  cursor: pointer;
  
  @media ${device.tabletL} {
    min-width: ${({ type }) => type === 'expiration' ? '214px' : `${type !== 'date-range-filter' ? '270px' : 'unset'}`};
  }
`;

export const SelectFormHeader = styled.div<{
  type?: 'expiration' | 'date-range' | 'date-range-filter',
  isActive: boolean,
  disabled?: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  height: ${({ type }) => type === 'date-range-filter' ? '26px' : '48px'};
  width: 100%;
  margin-bottom: 5px;
  margin-bottom: ${({ type }) => type === 'date-range-filter' ? '0' : '5px'};
  padding: ${({ isActive, type }) => isActive && type !== 'date-range-filter' ? `0 13px` : `0 6px 0 10px`};
  font-family: ${({ theme }) => theme.fonts};
  font-size: ${({ type }) => type === 'date-range-filter' ? '12px' : '14px'};
  font-weight: ${({ type }) => type === 'date-range-filter' ? 'bold' : 'normal'};
  letter-spacing: normal;
  color: ${({ isActive, theme, type }) => isActive ? theme.colors.primary : (type === 'date-range-filter' ? theme.colors.primary : theme.colors.secondary)};
  background-color: ${({ isActive, theme }) => isActive ? theme.colors.white : theme.colors.highlight};
  border: ${({ isActive, theme, type }) => isActive ? `${type !== 'date-range-filter' ? '2px' : '1px'} solid ${theme.colors.accent}` : `1px solid ${theme.colors.tagBorder}`};
  border-radius: ${({ type }) => type === 'date-range-filter' ? '13px' : '4px'};
  outline: none;

  // Custom focus styles
  ${globalFocusStyles}

  ${({ disabled, theme }) => disabled && css`
    background-color: ${theme.colors.highlight_800};
    color: ${theme.colors.default_400};
    cursor: default;
  `}

  ${({ disabled, theme }) => !disabled && css`
    &:hover {
      border-color: ${theme.colors.accent};
    }
  `}

  span { 
    display: inline-block;
    line-height: 1.29;
  }

  .calendar-icon {
    display: ${({ type }) => type === 'date-range-filter' ? 'none' : 'inherit'};
    margin-right: 10px;
    fill: ${({ theme, disabled }) => !disabled ? theme.colors.secondary : theme.colors.default_400};
  }

  .dropdown-icon {
    margin-left: auto;
    fill: ${({ theme, disabled }) => !disabled ? theme.colors.secondary : theme.colors.default_400};
  }
`;

export const FormSelectListItems = styled.li<{ isSelected?: boolean, lastItemSeparator?: boolean, type?: 'expiration' | 'date-range' | 'date-range-filter', }>`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 36px;
  font-size: 14px;
  line-height: 1.29;
  padding-left: ${({ type }) => type === 'expiration' ? '14px' : '42px'};
  padding-right: ${({ type }) => type === 'date-range-filter' ? '38px' : '14px'};
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: ${({ theme }) => theme.fonts};
  list-style: none;
  color: ${({ isSelected, theme }) => isSelected ? theme.colors.accent : theme.colors.primary};
  background-color: ${({ isSelected, theme }) => isSelected ? theme.colors.highlight : theme.colors.white};

  svg {
    position: absolute;
    left: 14px;
    top: 9px;
    display: block;
    fill: ${({ isSelected, theme }) => isSelected ? theme.colors.accent : theme.colors.secondary};
    margin-right: 10px
  }

  span {
    white-space: nowrap;
  }

  // Custom focus styles
  &:focus-visible {
    outline: none;
    border: 2px solid ${({ theme }) => theme.colors.accent};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.highlight};
  }

  ${({ lastItemSeparator, theme }) => lastItemSeparator && `
    &:last-child {
      border-top: 1px solid ${theme.colors.borderPrimary};
    } 
  `}
`;

