import React, { useState, useEffect, ReactElement } from 'react'
import { SavedSearchResults, SavedSearchInput, SavedSearchTitle, SavedSearchBoxWrapper, SavedSearchHeader } from './SavedSearchBox.styled'
import { GraySmallText } from './TextAndLabelStyles.styled'

import SingleSavedSearchResult from './SingleSavedSearchResult'
import { SingleSavedSearch } from '../../state/query/types/SavedSearchTypes'
import { DateRange } from '../global-components/DateRangeSelect'
import LoadSpinner from '../global-components/LoadSpinner'

export default function SavedSearchDropdown(
  { isLoading, data, setSearchInputVal, setExpirationOption, setValuesToQuery, setIsBoxVisible, setSetDateRangeForSavedSearch }:
    {
      isLoading: boolean
      data: SingleSavedSearch[] | undefined,
      setSearchInputVal: React.Dispatch<React.SetStateAction<string>>
      setExpirationOption: React.Dispatch<React.SetStateAction<DateRange>>
      setValuesToQuery: (search_term: string, date: DateRange) => void
      setIsBoxVisible: () => void
      handleExpiredOption?: (option: DateRange) => void
      setSetDateRangeForSavedSearch: React.Dispatch<React.SetStateAction<string | undefined>>
    }): ReactElement {


  const [inputVal, setInputVal] = useState('')
  const [localSavedSearches, setLocalSavedSearches] = useState<(SingleSavedSearch | undefined)[] | undefined>(data)



  function filterSavedSearches(term: string) {

    const filteredTerms: SingleSavedSearch[] = []

    data?.forEach((item) => {
      const searchName = item.search_name.toLowerCase()
      if (searchName.includes(term.toLowerCase())) {
        filteredTerms.push(item)
      }
    })

    setLocalSavedSearches(filteredTerms)
  }

  useEffect(() => {
    filterSavedSearches(inputVal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputVal])

  //After delete rerender component 
  useEffect(() => {
    setLocalSavedSearches(data)
  }, [data])


  return (

    <SavedSearchBoxWrapper>

      <SavedSearchHeader>
        <SavedSearchTitle>Saved Searches</SavedSearchTitle>
        <GraySmallText>{localSavedSearches?.length} entries</GraySmallText>
        <SavedSearchInput value={inputVal} onChange={(e) => setInputVal(e.target.value)} tabIndex={0} />
      </SavedSearchHeader>

      <SavedSearchResults>
        {isLoading ? <LoadSpinner size='normal' />
          : localSavedSearches?.map(item => item !== undefined
            && <SingleSavedSearchResult
              key={item.id}
              setValuesToQuery={setValuesToQuery}
              setExpirationOption={setExpirationOption}
              setSearchInputVal={setSearchInputVal}
              id={item.id}
              search_term={item.search_term}
              date={item.date}
              search_name={item.search_name}
              setIsBoxVisible={setIsBoxVisible}
              setSetDateRangeForSavedSearch={setSetDateRangeForSavedSearch}
            />)}
      </SavedSearchResults>
    </SavedSearchBoxWrapper>
  )
}
