import React from 'react'
import styled from 'styled-components'

export type SizeProp = 'large' | 'medium' | 'small'

export const getSize = (size: SizeProp) => {
  switch(size) {
    case 'large':
      return '80px'
    case 'medium':
      return '42px'
    case 'small':
      return '32px'
    default: 
      return null
  }
}

const DefaultImagePlaceholder = styled.div<{isLarge: boolean, hasBorder: boolean, size: SizeProp}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({size}) => getSize(size)};
  width: ${({size}) => getSize(size)};
  background-color: ${({theme, hasBorder}) => hasBorder ? theme.colors.highlight : theme.colors.white};
  padding: ${({isLarge}) => isLarge ? '2px' : '1px'};
  font-size: ${({isLarge}) => isLarge ? '15px' : '12px'};
  line-height: 1;
  color: ${({theme}) => theme.colors.primary};
  border: ${({ theme, isLarge, hasBorder }) => hasBorder && (isLarge ? `2px solid ${theme.colors.white}` : `1px solid ${theme.colors.white}`)};
  border-radius: 100%;
  ::after {
    position: absolute;
    width: ${({size}) => getSize(size)};
    height: ${({size}) => getSize(size)};
    content: "";
    border: 1px solid ${({ theme }) => theme.colors.borderSecondary};
    border-radius: 100%;
  }
`

type LogoPlaceholderProps = {
  name: string;
  size: SizeProp;
  hasBorder: boolean;
}

export default function LogoPlaceholder({name, size, hasBorder}: LogoPlaceholderProps) {

  return <DefaultImagePlaceholder isLarge={size === 'large'} size={size} hasBorder={hasBorder}>{name.charAt(0).toUpperCase()}</DefaultImagePlaceholder>
}
