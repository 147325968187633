import React from 'react'
import MaterialIcon, { IconName } from '../MaterialIcon'
import styled, { ThemeContext } from 'styled-components'


interface TableHeaderButtonProps {
  onClick: () => void;
  iconName: IconName;
}

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 28px;
  min-width: 28px;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.highlight};
    svg {
      fill: ${({ theme }) => theme.colors.secondary};
    }
  }
`

export default function TableHeaderButton({ onClick, iconName }: TableHeaderButtonProps) {
  const theme = React.useContext(ThemeContext)

  return (
    <Button onClick={onClick}>
      <MaterialIcon iconName={iconName} color={theme.colors.secondary} size={18} />
    </Button>
  )
}
