import React, { ReactElement } from 'react'

//Styles
import { OverviewTopWrapper } from './Overview.styled'

//Components
import NewsDistribution from './NewsDistribution'
import SocialStats from './SocialStats'
import HeatStatus from './HeatStatus'
import ConnectionLostComponent from '../global-components/ConnectionLostComponent'

//Query
import { useGetDashboardDataQuery } from '../../state/query/queryApi'
import { Error } from '../../state/query/types/globalQueryTypes'


export default function OverviewDistribution(): ReactElement {

  const { data, isError, isLoading, error } = useGetDashboardDataQuery('')


  return (
    <>
      {isError
        ? <ConnectionLostComponent error={(error as Error)?.status} isCard={true} invalidateTags="Dashboard" />
        : <OverviewTopWrapper>
          <HeatStatus data={data?.heat_status} />
          <SocialStats data={data?.social_stats} isLoading={isLoading} />
          <NewsDistribution data={data?.newsDistribution} isLoading={isLoading} />
        </OverviewTopWrapper>
      }
    </>
  )
}
