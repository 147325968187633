import styled from 'styled-components'
import { ReactComponent as LogoLarge } from '../../assets/icons/logo_positive.svg'
import { device } from '../../global-styles/mediaQueries'
import { globalFocusStyles } from '../../global-styles/globalStyles'


export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 16px;

  @media ${device.tablet} {
    padding-top: 60px;
  }

  @media ${device.tabletL} {
    padding-top: 80px;
  }

  @media ${device.desktopS} {
    padding-top: 40px;
  }

  @media ${device.desktop} {
    padding-top: 80px;
  }
`

export const LoginForm = styled.form`
  width: 100%;
  max-width: 448px;
  padding: 30px 16px 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: 0 5px 5px -4px rgba(49, 65, 97, 0.08);

  @media ${device.mobileL} {
    padding: 30px 20px 20px;
  }

  @media ${device.tablet} {
    padding: 30px 24px 24px;
  }
`

export const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`

export const Logo = styled(LogoLarge)`
  height: 28px;
  width: auto;
  margin-bottom: 8px;
`

export const HeaderText = styled.span`
  font-size: 13px;
  line-height: 1.54;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.secondary};
`
export const WrongCredentials = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 14px;
  padding: 12px 26px;
  border-radius: 6px;
  border: solid 1px ${({ theme }) => theme.colors.redAlert};
  background-color: rgba(248, 98, 98, 0.05);
  overflow: hidden;

  svg {
    flex-shrink: 0;
    display: block;
    margin-right: 15px;
  }

  p {
    width: auto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: ${({ theme }) => theme.colors.redAlertDark};
  }



  &::before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 6px;
    background-color: ${({ theme: { colors } }) => colors.redAlert};
  }
`



export const RememberForgotWrapper = styled.div`
  a {
    color: ${({ theme }) => theme.colors.accent};
    text-decoration: none;
  }
`

export const RememberMeInput = styled.input`

`

export const LoginButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  margin-top: 30px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.white};
  outline: none;
  border: none;
  cursor: pointer;

  // Custom focus styles
  ${globalFocusStyles}
`


export const ContactUsMessage = styled.p`
  max-width: 448px;
  margin-top: 20px;
  margin-bottom: auto;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};

  a {
    color: ${({ theme }) => theme.colors.accent};
    text-decoration: none;
  }
`

export const CopyRightLabel = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.secondary};
`