import styled from "styled-components";
import { device } from '../../global-styles/mediaQueries'
import { globalFocusStyles } from "../../global-styles/globalStyles";

export const InfoWrapper = styled.div`
display: flex;
justify-content: center;
align-items: flex-start;
padding: 13px 16px;
background-color: ${({ theme }) => theme.colors.positive_100};
svg {
  fill: ${({ theme }) => theme.colors.green};
  margin-right: 8px;
  flex-shrink: 0;
}

p { 
  margin-right: 16px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.green};
  span {
    font-weight: bold;
  }
}

button {
  padding: 7px 18px;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.green};
  outline: none;
  border: none;
  border-radius: 4px;

  // Custom focus styles
  ${globalFocusStyles}

  &:hover {
    cursor: pointer;
  }
}

@media ${device.mobileL} {
  align-items: center;
  padding: 9px 16px;
}
`