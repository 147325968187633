import React, { useState } from 'react';
import ModalButton from '../global-components/ModalButton'
import { DateRange, Range } from 'react-date-range';
import { ModalOuter, Wrapper, Labels, ButtonWrapper } from './DateRangeCalendar.styled'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
const rdrLocales = require('react-date-range/dist/locale')


export default function DateRangeCalendar({ onSelectCustom, closeModal, }: { onSelectCustom: (startDate: any, endDate: any) => void, closeModal: () => void }) {

  const [state, setState] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);


  return (
    <ModalOuter>
      <Wrapper>
        <h3>Select Date Range</h3>
        <Labels><span>From: </span><span>To: </span></Labels>
        <DateRange
          editableDateInputs={true}
          onChange={item => setState([item.selection])}
          ranges={state}
          minDate={new Date('2017-07-05T20:05:00Z')}
          maxDate={new Date()}
          locale={rdrLocales.is}
          fixedHeight={true}
        />
        <ButtonWrapper>
          <ModalButton type="cancel" onClick={closeModal}>Cancel</ModalButton>
          <ModalButton
            type="confirm"
            disabled={state[0].startDate?.getTime() === state[0].endDate?.getTime()}
            onClick={() => onSelectCustom(state[0].startDate, state[0].endDate)}
          >
            Apply
          </ModalButton>
        </ButtonWrapper>
      </Wrapper>
    </ModalOuter>
  )
}
