import React from 'react'

//Components
import SingleSocialMedia from './SingleSocialMedia'
import LoadSpinner from '../global-components/LoadSpinner'
import GeneralTooltipCaller from '../global-components/tooltips/GeneralTooltipCaller'
import ConnectionLostComponent from '../global-components/ConnectionLostComponent'

//Style components
import { TopNewsTableHeader, SocialMediaTableWrapper, ToolTipWrapper } from './SocialMediaTab.styled'

//Redux
import { useGetSocialMediaQuery } from '../../state/query/queryApi'
import { Error } from '../../state/query/types/globalQueryTypes'
import useStorageListener from '../../hooks/useStorageListener'


export default function SocialMediaTab() {

  const { data: posts, isLoading, isError, error, refetch } = useGetSocialMediaQuery('', {
    pollingInterval: 600000,
    refetchOnReconnect: true,
  })

  useStorageListener('updated_article_action', (action) => {
    if (action === "STOP_ALERT" || action === "SET_ALERT") {
      refetch()
    }
  });


  return (
    <SocialMediaTableWrapper>
      <TopNewsTableHeader>
        <p className="first">Rank</p>
        <p className="second">News <span> /Distribution</span></p>
        <p className="third hide-mobile">Distribution</p>
        <ToolTipWrapper>
          <GeneralTooltipCaller name="basic" content="Share of social media engagement of the top 10 articles of the day." />
        </ToolTipWrapper>
      </TopNewsTableHeader>
      {isError
        ? <ConnectionLostComponent error={(error as Error)?.status} invalidateTags="SocialMedia" />
        : isLoading
          ? <LoadSpinner size="large" />
          : posts?.data.map(singlePost =>
            <SingleSocialMedia post={singlePost} key={singlePost.id} />)
      }

    </SocialMediaTableWrapper>
  )
}


