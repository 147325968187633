import React from 'react'
import Layout from '../global-components/GeneralLayout'

export default function Help() {
  return (
    <Layout title="Help">
      <div>
        Help Route
      </div>
    </Layout>
  )
}
