import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface PrivateRouteProps extends RouteProps {
  isAuthenticated: boolean
}

export default function AuthRoute({ children, isAuthenticated, ...rest }: PrivateRouteProps) {
  return (
    isAuthenticated ? <Route {...rest} /> : <Redirect to="/login" />
  )
}