import React from 'react'

// Components
import CustomButton from './CustomButton'
import { SpinnerWrapper } from '../modals/DownloadNewsButton'
import Spinner from './ButtonSpinner'

// Hooks
import { useAppDispatch } from '../../state/hooks/hooks'

// Slices
import { showPopup } from '../../state/slices/popupSlice'

// Query
import { useStopAlertLatestNewsMutation, useStopAlertsMutation } from '../../state/query/queryApi'

// Types
import { RemoveSocialAlerts } from '../../state/query/types/LatestNewsTypes'
import { RemoveSocialAlertProp } from '../../state/query/types/TopNewsAndSocialAlertsTypes'

type PayloadProps = RemoveSocialAlerts | RemoveSocialAlertProp

// Getting proper mutation based on the right payload
function getProperMutation(payload: PayloadProps) {
  if (payload.hasOwnProperty('cacheType')) {
    return useStopAlertsMutation;
  } else if (payload.hasOwnProperty('alert_id') && payload.hasOwnProperty('newsKey') && payload.hasOwnProperty('params')) {
    return useStopAlertLatestNewsMutation;
  } 
  throw new Error('Unexpected payload type');
}

export default function StopAlertsButton({ payload, color, handleCloseModal}: {payload: PayloadProps, color: string, handleCloseModal: () => unknown }) {

  const dispatch = useAppDispatch()
  const [stopAlerts, { isLoading }] = getProperMutation(payload)()

  const handleStopAlertsOnClick = () => {
    stopAlerts(payload as RemoveSocialAlertProp & RemoveSocialAlerts).unwrap()
      .then(() => {
        handleCloseModal()
        dispatch(showPopup({
          event: 'success',
          type: 'additionalInfo',
          direction: 'bottom',
          props: {
            popupTitle: 'Alerts stopped',
            popupText: 'You will no longer receive notifications for specific article',
            additionalComponentName: 'SimpleTextInfo'
          }
        }))
      })
      .catch((rejected) => {
        handleCloseModal()
        dispatch(showPopup({
          event: 'error',
          type: 'alert',
          direction: 'bottom',
          props: {
            popupTitle: `${rejected.status !== 'FETCH_ERROR' ? rejected.data.Message : 'Check your network connection!'}`
          }
        }))

      })
  }

  return (
    <CustomButton
      size="medium"
      bgColor={color}
      onClick={handleStopAlertsOnClick}
    >
      Stop alerts
      {isLoading && <SpinnerWrapper><Spinner size={18} /></SpinnerWrapper>}
    </CustomButton>
  )
}

