import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
//Components
import TodaysCalendar from '../global-components/TodaysCalendar'
import MaterialIcon from '../global-components/MaterialIcon';
import { GraySmallText } from '../global-components/TextAndLabelStyles.styled'
import CustomButton from '../global-components/CustomButton'
//Styled  mponents
import { PageHeader } from './SocialMediaTab.styled'
import { AddNewBox, IconWrapper, AddNewTextBox } from './SocialAlerts.styled'
//Utils
import { addOpacityToHex } from '../../utils'

interface SocialAlertsHeaderProps {
  handleSocialAlertModal: () => void
}

export default function SocialAlertsHeader({ handleSocialAlertModal }: SocialAlertsHeaderProps) {

  const themeContext = useContext(ThemeContext)

  return (
    <div>
      <PageHeader>
        <h3>Social alerts</h3>
        <TodaysCalendar />
      </PageHeader>

      <AddNewBox>
        <IconWrapper>
          <MaterialIcon iconName="add_link" color={themeContext.colors.accent} size={36} />
        </IconWrapper>
        <AddNewTextBox>
          <h5>Add New Coverage</h5>
          <GraySmallText>Monitor distribution on social media</GraySmallText>
        </AddNewTextBox>
        <CustomButton
          bgColor={themeContext.colors.accent}
          hoverColor={addOpacityToHex(themeContext.colors.accent, 0.9)}
          size="medium"
          hasIcon={true}
          iconName="add"
          onClick={handleSocialAlertModal}
        >Add new</CustomButton>
      </AddNewBox>
    </div>
  )
}
