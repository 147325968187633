import styled from 'styled-components'

export const TooltipTextWrapper = styled.div<{ isInNoDataChart?: boolean }>`
  width: max-content;
  max-width: 200px;
  padding: 8px 12px 10px 12px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.primaryDark};

  p {
    font-family: Arial;
    font-size: ${({ isInNoDataChart }) => isInNoDataChart ? '12px' : '13px'};
    font-weight: normal;
    font-stretch: normal;
    font-style: ${({ isInNoDataChart }) => isInNoDataChart ? 'italic' : 'normal'};
    line-height: ${({ isInNoDataChart }) => isInNoDataChart ? '16px' : '1.54'};
    letter-spacing: normal;
    color: ${({ theme, isInNoDataChart }) => isInNoDataChart ? theme.colors.default_400 : theme.colors.white};
  }
`