import styled from "styled-components";
import { device } from "../../global-styles/mediaQueries";
import { NavLink } from "react-router-dom";
import { globalFocusStyles } from "../../global-styles/globalStyles";

export const NavigationWrapper = styled.div<{ isRound?: boolean }>`
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: white;
  border-radius: ${({ isRound }) => isRound ? '6px' : '0'};

  span {
    display: none;
  }
  @media ${device.desktopS} {
  flex-flow: row;
  span {
      display: block;
      min-width: 241px;
      margin-right: 20px;
      border-right: 1px solid ${({ theme }) => theme.colors.borderPrimary};
    }
  }
  @media ${device.desktop} {
  flex-flow: row;
  box-shadow: 0 5px 5px -4px rgba(49, 65, 97, 0.08);

  span {
      display: block;
      min-width: 241px;
      margin-right: 0px;

    }
  }
`
export const NavigationContainer = styled.div<{ isMenuOpen: boolean, isRound?: boolean }>`
  display: border-box;
  flex-flow: column;
  display: ${({ isMenuOpen }) => isMenuOpen ? 'flex' : 'none'};
  border-radius: ${({ isRound }) => isRound ? '6px' : '0'};
  box-shadow: 0 4px 16px -2px rgba(49, 65, 97, 0.15);
  @media ${device.tablet} {
    display:flex;
    width: 100%;
    padding-left: 25px;
    padding: 14px 25px;
    flex-flow: row;
    background-color: white;
    box-shadow: none;
  }
  @media ${device.desktopS} {
    max-width: 1100px;
    margin: 0 auto;
    padding-left: 0;
  }
  @media ${device.desktop} {
    box-shadow: none;
  }
`

export const SettingsNavigationContainer = styled(NavigationContainer)`
  @media ${device.tablet} {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`

export const DashboardNavigationContainer = styled.div`
  padding-top: 60px;
  position: fixed;
  width: 100%;
  z-index: 100;
`
export const SingleNavItem = styled(NavLink) <{ isActive?: boolean }>`
  position: relative;
  display: flex;
  padding: 18px 16px;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.secondary};
  svg {
    margin-right: 10px;
    fill: ${({ theme }) => theme.colors.secondary};
  }
  &.active {
    svg {
      fill: ${({ theme }) => theme.colors.accent};
    }  
  }
  &:focus-visible {
    outline: none;
    border: 2px solid ${({ theme }) => theme.colors.accent};
  }
  
  &:after {
    content: '';
    position: absolute;
    width: calc(100% - 32px);
    height: 1px;
    left: 16px;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.borderPrimary};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.highlight};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.primary};
    svg {
      fill: ${({ theme }) => theme.colors.accent};
    }
  }
  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    
    &:after {
      content: none;
    }
  }
  @media ${device.mobileL} {
    padding: 20px 18px;

  }

  @media ${device.tablet} {
    padding: 10px;
    margin-right: 20px;
    background-color: white;
    &:after {
      width: 100%;
      opacity: 0;
      height: 3px;
      margin: 15px 0 0;
      left: 0;
      bottom: -14px;
      background-color: #0284fe;
      transition: 0.3s;
      pointer-events: none;
    }

    &:focus {
      border: none;
    }
    // Custom focus styles
    ${globalFocusStyles}

    &.active {
      &:after {
        opacity: 1;
      }
    }
    &:last-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      &:after {
        content: '';
      }
    }
  }
`

export const ActiveTab = styled.div<{ isRound?: boolean }>`
  display: flex;
  position: relative;
  margin-bottom: 4px;
  padding: 18px 16px;
  background-color: white;
  border: solid 1px ${({ theme }) => theme.colors.borderPrimary};
  box-shadow: 0 5px 5px -4px rgba(49, 65, 97, 0.08);
  cursor: pointer;
  border-radius: ${({ isRound }) => isRound ? '6px' : '0'};

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.accent};
  }

  p {
    margin: 0;
    font-family: Arial;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: ${({ theme }) => theme.colors.primary};
  } 
  svg {
    fill: ${({ theme }) => theme.colors.accent};
    // The purpose of this is because one of the X lines does not change color
    g {
      line {
        stroke: ${({ theme }) => theme.colors.accent};
      }
    }
  }
  .openingSvg {
    position: absolute;
    top: 18px;
    right: 18px;
  }
  @media ${device.mobileL} {
    padding: 20px 18px;
  }
  @media ${device.tablet} {
    display: none;
  }

`