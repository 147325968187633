import React from 'react'
import styled from 'styled-components'
import { device } from '../../global-styles/mediaQueries'


import { ReactComponent as NothingWatching } from '../../assets/icons/nothing-watching.svg'
const NoAlertWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  
  max-width: 80%;
  margin: 0 auto;
  padding-top: 24px;
  text-align: center;
  svg {
    margin-bottom: 16px;
  }
  h4 {
    margin: 0 auto 8px auto;
  }
  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color:${({ theme }) => theme.colors.secondary};
  }
  @media ${device.tablet} {
    padding-top: 40px;
  }
  @media ${device.tabletL} {
    padding-top: 50px;
  }
  @media ${device.desktopS} {
    padding-top: 20px;
  }
  @media ${device.desktop} {
    padding-top: 50px;
  }

`

export default function NoAlertsScreen() {
  return (
    <NoAlertWrapper>
      <NothingWatching />
      <h4>It seems you don’t monitor anything yet</h4>
      <p>Hit “Add New” button to start receiving notifications for specific news</p>
    </NoAlertWrapper>
  )
}
