import React from 'react'
import { TooltipBodyWrapper, TooltipRow } from './SocialMediaRankTooltip.styled'

export default function SocialMediaRankTooltip() {
  return (
    <TooltipBodyWrapper >
      <TooltipRow hasDivider={true}>
        <h6><span>#</span>Rank</h6>
        <p>Article rank on social media.</p>
      </TooltipRow>
      <TooltipRow hasDivider={true}>
        <h6><span># - #</span>Rank range</h6>
        <p>If article shares the same place with other articles, rank range is displayed.</p>
      </TooltipRow>
      <TooltipRow hasDivider={false}>
        <p>For news older than 24 hours, system will show the highest position achieved on the day of publishing.</p>
      </TooltipRow>
    </TooltipBodyWrapper>
  )
}
