import React, { useState } from 'react';
//Components & Elements
import MaterialIcon from '../global-components/MaterialIcon';
import CustomButton from '../global-components/CustomButton';
import searchImageUrl from '../../assets/search-modal-image.webp'
import CustomCheckbox from '../global-components/CustomCheckbox';
//Hooks & SLices
import { closeModal } from '../../state/slices/modalsSlice'
import { useAppDispatch } from '../../state/hooks/hooks'
//Styled
import styled from 'styled-components'
import { device } from "../../global-styles/mediaQueries";

interface Props {
  title: string;
  text: string;
  checkboxText: string;
  buttonText: string;
  buttonColor: string;
  iconColor: string;
}

const ModalBox = styled.div`
  max-width: 420px;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 5px 5px -4px rgba(49, 65, 97, 0.08);
  background-color: ${({ theme }) => theme.colors.white};
  @media ${device.mobileL} {
    padding: 20px;
  }
  @media ${device.tablet} {
    max-width: 640px;
  }
`

const SearchModalTitle = styled.h4`
  margin: 14px 0 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  svg {
    margin-right: 10px;
  }
  @media ${device.mobileL} {
    margin: 20px 0 12px 0;
  }
  @media ${device.tablet} {
    margin: 24px 0 12px 0;
  }
`
const SearchModalText = styled.p`
  margin-bottom: 22px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};
  @media ${device.tablet} {
    margin-bottom: 42px;

  }
`

const CheckboxWrapper = styled.div`
  margin-right: 24px;
`

const BottomBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;

  @media ${device.mobileL} {
    justify-content: flex-end;
  }
  @media ${device.tablet} {
    margin-top: 40px;
  }
`
const ModalImage = styled.img`
  width: 100%;
  @media ${device.tablet} {
    width: 500px;
  }
 `

const SearchFirstTime = ({ payload }: { payload: Props }) => {

  const { title, text, checkboxText, buttonText, buttonColor, iconColor } = payload
  const [isChecked, setisChecked] = useState(false)

  const dispatch = useAppDispatch()

  function setSearchModalStateToLocalStorage() {
    localStorage.setItem('saveSearchModal', 'hide');
  }

  function handleSubmitModal() {
    isChecked && setSearchModalStateToLocalStorage()
    dispatch({
      type: closeModal.type
    })

  }
  return (
    <ModalBox>
      <SearchModalTitle>
        <MaterialIcon iconName={'check_circle_filled'} size={18} color={iconColor} />
        {title}
      </SearchModalTitle>
      <SearchModalText>
        {text}
      </SearchModalText>
      <ModalImage src={searchImageUrl} alt="saved search" />
      <BottomBox>
        <CheckboxWrapper>
          <CustomCheckbox onChange={(e) => setisChecked(e.target.checked)} checked={isChecked} name="hide-modal" labelText={checkboxText} />
        </CheckboxWrapper>

        <CustomButton
          size="medium"
          bgColor={buttonColor}
          onClick={handleSubmitModal}
        >
          {buttonText}
        </CustomButton>
      </BottomBox>

    </ModalBox>
  );
}

export default SearchFirstTime;
